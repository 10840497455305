'use strict';

import MarkerElement from '../markerElement';
import paper from 'paper';

class KeymapSelftest extends MarkerElement {
  private _labels: any[];
  private _selection: any;
  private _cell: any;
  private _index: any;
  private _label: any;
  private _rectangle: any;

  constructor(
    model,
    private _format,
    private _localPaper,
    private _mainLayer,
    private _container,
    public _imageScale = 1,
    public _imageOffset?
  ) {
    super(model);
    this._labels = [];
    this._selection = null;

    this._init();
  }

  _init() {
    if (!this._element) {
      this._element = new this._localPaper.Group();
      this._mainLayer.addChild(this._element);
    }
    this.draw();
  }

  draw() {
    this._element._children = [];
    if (!this._model.fields.label) {
      return;
    }

    if (!this._model.fields.selftest) {
      this._model.fields.selftest = {
        x: this._model.fields.source.x,
        y: this._model.fields.source.y,
      };
    }

    if (!this._model.fields.selftest.x || this._model.fields.selftest.x === 0 || this._model.fields.selftest.x === null) {
      this._model.fields.selftest.x = this._model.fields.source.x;
    }

    if (!this._model.fields.selftest.y || this._model.fields.selftest.y === 0 || this._model.fields.selftest.y === null) {
      this._model.fields.selftest.y = this._model.fields.source.y;
    }

    const source = this._container.getAbsoluteCoords(
      this._model.fields.selftest.x,
      this._model.fields.selftest.y,
      this._imageOffset,
      this._imageScale
    );

    const rowHeight = [], columnWidth = [];
    columnWidth[-1] = 0;
    rowHeight[-1] = 0;

    const digitModel = this.getDigitModel();
    const digitsCount = this.countIndexDigits();

    const column = this._model.fields.label[0];
    const label = column[0];

    const rowId = column.indexOf(label),
      indexContent = ' '.repeat(digitsCount - label.index.toString().length) + label.index;
    rowHeight[rowId] = rowHeight[rowId] ? rowHeight[rowId] : 0;

    this._cell = new this._localPaper.Group();
    this._index = new this._localPaper.Group();

    for (let position = 0; position < digitsCount; position++) {
      const digit = this.drawDigit(source, position, digitModel, indexContent);
      this._index.addChild(digit);
    }

    this._label = new paper.PointText(new paper.Point(
      source.x + (this._model.fields.style.indexWidth / this._imageScale) + (digitsCount - 1) * digitModel.width,
      source.y)
    );

    this._label.fontFamily = this._format.fontFamily;
    this._label.fontWeight = this._format.fontWeight;
    this._label.fontSize = (this._model.fields.style.fontSize || this._format.fontSize) / this._imageScale;
    this._label.fillColor = this._model.fields.style.color || this._format.color;
    this._label.content = label.text;
    this._label.keymap = this._index.keymap = this;

    this._cell.addChild(this._index);
    this._cell.addChild(this._label);

    this._element.addChild(this._cell);

    columnWidth[0] = Math.max(this._cell.bounds._width, columnWidth[0]);
    rowHeight[rowId] = Math.max(this._cell.bounds._height, rowHeight[rowId]);

    this.drawRectangle(columnWidth, rowHeight);
    this._element.reverseChildren();
  }

  drawDigit(source, position, digitModel, indexContent) {
    const digit = new paper.PointText(
      new paper.Point(
        source.x + position * digitModel.width,
        source.y
      )
    );
    digit.fontFamily = this._format.fontFamily;
    digit.fontWeight = this._format.fontWeight;
    digit.fontSize = (this._model.fields.style.fontSize || this._format.fontSize) / this._imageScale;
    digit.fillColor = this._model.fields.style.color || this._format.color;
    digit.content = indexContent[position];
    digit.translate(new paper.Point((digitModel.width - digit.bounds._width) / 2, 0));
    return digit;
  }

  drawRectangle(columnWidth, rowHeight) {
    const width = this._element.bounds._width,
      height = this._element.bounds._height;

    const source = this._container.getAbsoluteCoords(
      this._model.fields.selftest.x,
      this._model.fields.selftest.y,
      this._imageOffset,
      this._imageScale)
    ;

    source.x = source.x - (this._model.fields.style.columnDistance / this._imageScale / 2);
    source.y = source.y - (this._model.fields.style.rowDistance / this._imageScale / 2) - this._label.fontSize;

    const rectangle = new paper.Rectangle(
      new paper.Point(source.x, source.y),
      new paper.Size(
        width + this._model.fields.style.columnDistance / this._imageScale,
        height + this._model.fields.style.rowDistance / this._imageScale
      )
    );
    this._rectangle = new paper.Path.Rectangle(rectangle);

    this._rectangle.fillColor = this._model.fields.style.background ? this._format.border.fillColor : undefined;
    if (this._rectangle.fillColor) {
      this._rectangle.fillColor.alpha = this._model.fields.style.opacity;
    }

    this._rectangle.strokeColor = this._format.border.strokeColor;
    this._rectangle.strokeWidth = this._model.fields.style.border ? this._format.border.strokeWidth : 0;

    if (this._isSelected) {
      this._rectangle.fillColor = this._format.selection.color;
      this._rectangle.fillColor.alpha = this._format.selection.opacity;
      this._rectangle.strokeColor = this._format.selection.color;
      this._rectangle.strokeWidth = this._format.selection.strokeWidth;
    }
    this._rectangle.keymap = this;

    this._element.addChild(this._rectangle);
  }

  getDigitModel() {
    const index = new paper.PointText(new paper.Point(0, 0));
    index.fontFamily = this._format.fontFamily;
    index.fontWeight = this._format.fontWeight;
    index.fontSize = (this._model.fields.style.fontSize || this._format.fontSize) / this._imageScale;
    index.content = 0;
    index.remove();
    return {width: index.bounds._width, height: index.bounds._height};
  }

  countIndexDigits() {
    let max = 0;
    this._model.fields.label.forEach(currentColumn => {
      let columnMax = 0;
      currentColumn.forEach(currentCell => {
        // tslint:disable-next-line:no-bitwise
        if (currentCell.index >>> 0 === Number.parseFloat(currentCell.index)) {
          columnMax = Math.max(Math.log10(currentCell.index === 0 ? 1 : currentCell.index), columnMax);
        } else {
          columnMax = Math.max(currentCell.index.length - 1, columnMax);
        }
      });
      max = Math.max(columnMax, max);
    });

    return Math.floor(max) + 1;
  }

  arrange() {
    this.draw();
  }

  cleanup() {
    this._element.removeChildren();
    super.cleanUp();
  }

  /**
   * When no label is present in the keymap model, the image can not be drawn
   * @returns {[null]}
   */
  getDefaultLabel() {
    return [[
      {
        'index': 1,
        'text': 'Legende 1',
        'pivot': 'bottomCenter'
      }
    ]];
  }
}

export default KeymapSelftest;
