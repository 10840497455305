import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthState,
  AlertState,
  IndexState,
  SlideState,
  NavigationState,
} from '@medsurf/state';
import { UpdateSlides, UpdateSlidesSuccess, SaveIndex, SaveIndexSuccess, Logout, Breadcrumb, AddAlert} from '@medsurf/actions';
import { SETTINGS, Settings,  } from '@medsurf/services';
import { Alert, AlertType, Page, User, } from '@medsurf/models';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { GeneralControlState } from '@medsurf/flat-states';
import { GeneralControlActions } from '@medsurf/flat-actions';

declare let HS;

@Component({
  selector: 'medsurf-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
  @Select(IndexState.isDirty)
  public isIndexDirty$: Observable<boolean>;

  @Select(SlideState.isDirty)
  public isSlidesDirty$: Observable<boolean>;

  @Select(AlertState.alerts)
  public alerts$: Observable<Alert[]>;

  @Select(AuthState.user)
  public user$: Observable<User>;

  @Select(NavigationState.breadcrumbs)
  public breadcrumbs$: Observable<Breadcrumb[]>;

  @Select(GeneralControlState.apiVersion$)
  public apiVersion$: Observable<string>;

  @Input() public message: string;
  @Input() public page: Page;

  private _destroyed = new Subject<boolean>();
  public currentLanguage: string;
  public availableLanguages = ['de', 'en'];
  public isSaving = false;
  public searchString: string;

  constructor(private router: Router,
              public route: ActivatedRoute,
              private translate: TranslateService,
              private store: Store,
              private actions$: Actions,
              @Inject(SETTINGS) public settings: Settings) {
    this.readCurrentLanguage();
  }

  ngOnInit() {
    this.actions$.pipe(takeUntil(this._destroyed), ofActionCompleted(SaveIndexSuccess, UpdateSlidesSuccess)).subscribe(() => {
      const isSlidesDirty: boolean = this.store.selectSnapshot(SlideState.isDirty);
      const isIndexDirty: boolean = this.store.selectSnapshot(IndexState.isDirty);
      this.isSaving = isSlidesDirty || isIndexDirty;
    });

    this.alerts$.pipe(takeUntil(this._destroyed)).subscribe((alerts: Alert[]) => {
      this.isSaving = false;
    });

    this.apiVersion$.pipe(takeUntil(this._destroyed), filter(apiVersion => !!apiVersion)).subscribe((apiVersion: string) => {
      const [apiMajor, apiMinor] = apiVersion.split('.');
      const [authorMajor, authorMinor] = this.settings.version.split('.');
      if (apiMinor !== authorMinor || apiMajor !== authorMajor) {
        this.store.dispatch(new AddAlert({
          message: 'version_missmatch',
          params: {apiVersion: [apiMajor, apiMinor].join('.'), authorVersion: [authorMajor, authorMinor].join('.')},
          dismissable: true,
          type: AlertType.WARNING
        }));
      }
    });
    this.store.dispatch(new GeneralControlActions.GetApiVersion());
  }

  public get currentLocation() {
    return this.route.data.pipe(map(data => data.title));
  }

  private readCurrentLanguage() {
    this.currentLanguage = this.translate.currentLang;
  }

  public onSave() {
    this.isSaving = true;
    setTimeout(() => this.store.dispatch(new SaveIndex()), 500);
    this.store.dispatch(new UpdateSlides());
  }

  public logout() {
    if (this.store.selectSnapshot(AuthState.isAuthenticated)) {
      this.store.dispatch(new Logout());
    }
  }

  public openContactModal() {
    HS.beacon.open();
  }

  public changeLanguage(langKey) {
    this.translate.use(langKey).subscribe(() => {
      this.readCurrentLanguage();
    });
  }

  /**
   * Is fired when enter key pressed
   * Fetches all models of slides which fit to the search string (marker and slide text)
   *
   * @return void
   */
  public search(): void {
    this.router.navigate(['search'], {queryParams: {searchString : encodeURI(this.searchString)}});
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
