import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideDetailComponent } from '../slide-detail/slide-detail.component';
import { ConstantListComponent } from './constant-list/constant-list.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UsersComponent } from './users/users.component';
import { ConstantDetailComponent } from './constant-detail/constant-detail.component';
import { MetadataComponent } from './metadata/metadata.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MedsurfCommonModule } from '../common/medsurf-common.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { createTranslateLoader } from '../translate.loader';
import { MomentModule } from 'ngx-moment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MedsurfLibModule } from '@medsurf/module';
import { PanelModule } from '../slide-detail/panel/panel.module';
import { MediaLibraryComponent } from './media-library/media-library.component';
import { DetachedSlidesComponent } from './detached-slides/detached-slides.component';
import { ClassValidatorFormBuilderModule } from 'ngx-reactive-form-class-validator';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    UsersComponent,
    UserDetailComponent,
    SlideDetailComponent,
    ConstantListComponent,
    ConstantDetailComponent,
    MetadataComponent,
    MediaLibraryComponent,
    DetachedSlidesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MedsurfCommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MomentModule,
    NgbModule,
    RouterModule,
    MedsurfLibModule,
    PanelModule,
    ReactiveFormsModule,
    ClassValidatorFormBuilderModule.forRoot()
  ],
  exports: [
    UsersComponent,
    UserDetailComponent,
    SlideDetailComponent,
    ConstantListComponent,
    ConstantDetailComponent,
    MetadataComponent,
    MediaLibraryComponent,
    DetachedSlidesComponent
  ]
})
export class AdminModule {
}
