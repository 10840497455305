'use strict';

import paper from 'paper';

class Overlay {
  private _viewer: any;
  private _containerWidth: number;
  private _containerHeight: number;
  private _canvasDiv: HTMLDivElement;
  private _canvas: HTMLCanvasElement;
  public localPaper: paper.PaperScope;
  public mainLayer: any;

  /**
   * @param {OpenSeadragon.Viewer} viewer
   */
  constructor(viewer) {

    this._viewer = viewer;

    this._containerWidth = 0;
    this._containerHeight = 0;

    this._canvasDiv = document.createElement('div');
    this._canvasDiv.style.position = 'absolute';
    this._canvasDiv.style.left = '0';
    this._canvasDiv.style.top = '0';
    this._canvasDiv.style.width = '100%';
    this._canvasDiv.style.height = '100%';
    this._viewer.canvas.appendChild(this._canvasDiv);

    this._canvas = document.createElement('canvas');
    this._canvas.setAttribute('id', 'virtualm-overlay');
    this._canvasDiv.appendChild(this._canvas);
    this.resize();

    this.localPaper = new paper.PaperScope();

    this.localPaper.setup(this._canvas);

    this.mainLayer = new this.localPaper.Layer();

    this.localPaper.view.autoUpdate = false;

    const handler = () => {
      this.resize();
      this.resizeCanvas();
    };

    this._viewer.addHandler('zoom', handler);
    this._viewer.addHandler('pan', handler);
    this._viewer.addHandler('open', handler);
    this._viewer.addHandler('animation-finish', handler);
    this._viewer.addHandler('animation-start', handler);
    this._viewer.addHandler('animation', handler);
    this._viewer.addHandler('update-viewport', handler);

    this.resize();
  }

  /**
   * @name OpenSeadragon.Overlay#resize
   * @function
   */
  resize() {
    if (this._viewer.container) {
      if (this._containerWidth !== this._viewer.container.clientWidth) {
        this._containerWidth = this._viewer.container.clientWidth;
        this._canvasDiv.setAttribute('width', String(this._containerWidth));
        this._canvas.setAttribute('width', String(this._containerWidth));
      }
      if (this._containerHeight !== this._viewer.container.clientHeight) {
        this._containerHeight = this._viewer.container.clientHeight;
        this._canvasDiv.setAttribute('height', String(this._containerHeight));
        this._canvas.setAttribute('height', String(this._containerHeight));
      }
    }
  }

  /**
   * @name OpenSeadragon.Overlay#resizeCanvas
   * @function
   */
  resizeCanvas() {
    this._canvasDiv.setAttribute('width', String(this._containerWidth));
    this._canvasDiv.setAttribute('height', String(this._containerHeight));
    this.localPaper.view.viewSize = new paper.Size(this._containerWidth, this._containerHeight);
    const viewportZoom = this._viewer.viewport.getZoom(true);
    const image = this._viewer.world._items.find((item) => item.getOpacity() > 0.1);
    this.localPaper.view.zoom = image ? image.viewportToImageZoom(viewportZoom) : 1;
    const center = image?.viewportToImageCoordinates(this._viewer.viewport.getCenter(true));
    this.localPaper.view.center = new paper.Point(center?.x, center?.y);
    this.localPaper.view.update();
  }

  /**
   * @returns {HTMLCanvasElement}
   */
  get paperCanvas() {
    return this._canvas;
  }

}

export default Overlay;
