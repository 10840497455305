import { Component, Input, OnInit } from '@angular/core';
import { SlideNode } from '@medsurf/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LinkFormat } from '../../format';


@Component({
  selector: 'medsurf-code-editor-link',
  templateUrl: './link-editor.component.html',
  styleUrls: ['./link-editor.component.scss'],
})
export class LinkEditorComponent implements OnInit {

  @Input()
  public format: LinkFormat | string;

  @Input()
  public linkText: string;

  @Input()
  public target: '_blank' | '_self';
  
  public isTargetBlank = false;
  public isAddButtonDisabled = false;
  public isInternalLink = false;
  public node: SlideNode;

  constructor(
    public modal: NgbActiveModal, 
  ) {}

  ngOnInit(): void {
    this.target = this.target || '_self';
    this.isInternalLink = this.format === LinkFormat.INTERNAL;
    this.isTargetBlank = this.target === '_blank';
  }

  public changeTarget() {
    this.target = this.isTargetBlank ? '_blank' : '_self';
  }

  public changeNode(node: SlideNode): void {
    this.linkText = node?.page ? `${node.page.type}: ${node.page.title} ${node.page.slideId ? ' (' + node.page.slideId + ')' : ''}` : this.linkText;
    this.node = node;
  }

  public close() {
    if(this.format === LinkFormat.INTERNAL){
      this.modal.close({linkText: this.linkText, target: this.target, id: this.node?.id});
    }

    this.modal.close({linkText: this.linkText, target: this.target});
  }
}
