'use strict';

/** @type {OpenSeadragon} OpenSeadragon */
import OpenSeaDragon from 'openseadragon';

import Overlay from './overlay';

OpenSeaDragon.Viewer.prototype.paperjsOverlay = function () {
  return this._paperjsOverlayInfo || new Overlay(this);
};

export default OpenSeaDragon;
