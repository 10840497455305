import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MedsurfLibModule } from '@medsurf/module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { ClassValidatorFormBuilderModule } from 'ngx-reactive-form-class-validator';
import { createTranslateLoader } from '../translate.loader';
import { MedsurfCommonModule } from '../common/medsurf-common.module';
import { PanelModule } from '../slide-detail/panel/panel.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

@NgModule({
  declarations: [
    ResetPasswordComponent,
    LoginComponent,
    UserProfileComponent,
    ForgotPasswordComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MedsurfCommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ReactiveFormsModule,
    MomentModule,
    NgbModule,
    RouterModule,
    MedsurfLibModule,
    PanelModule,
    ClassValidatorFormBuilderModule.forRoot()
  ],
  exports: [
    ResetPasswordComponent,
    LoginComponent,
    UserProfileComponent,
    ForgotPasswordComponent
  ]
})
export class UserModule {
}
