import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IndexChanged } from '@medsurf/actions';
import { getRouteString } from '@medsurf/helpers';
import { NodeSettings, populateNodeSettings, SubjectNode } from '@medsurf/models';
import { NavigationState } from '@medsurf/state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DEFAULT_VALUES } from '../../../common/constants/default-values';

@Component({
  selector: 'medsurf-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnChanges {

  @Select(NavigationState.settingsInherited)
  public settingsInherited$: Observable<any>;

  @Input()
  public selectedBlock: SubjectNode;

  @Input()
  public isBlockSelected: boolean;

  @Input()
  public isSubject: boolean;

  public settingsDefault: NodeSettings = populateNodeSettings({});
  public settings: NodeSettings = {};

  public settingInputTypes = {
    hideDownloadPdf: 'radio',
    hideHomeButton: 'radio',
    hideAnnotations: 'radio',
    pageTitleColor: 'color'
  };

  constructor(
    private store: Store,
    @Inject(DEFAULT_VALUES) public defaultValues
  ) {
  }

  public isUndefined(value): boolean {
    return typeof value === 'undefined';
  }

  public onClear(key) {
    delete this.settings[key];
    this._update();
  }

  public onRouteChange() {
    this.store.dispatch(new IndexChanged([this.selectedBlock]));
  }

  public setRouteFromTitle() {
    this.selectedBlock.route = getRouteString(this.selectedBlock.page?.title);
    this.onRouteChange();
  }

  public onChange(key, $event) {
    const value = $event.target.value;
    if (value) {
      this.settings[key] = value;
    } else {
      delete this.settings[key];
    }
  }

  public onRadioChange(key, value) {
    this.settings[key] = value;
    this._update();
  }

  public onColorChange(key, value) {
    this.settings[key] = value;
    this._update();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.settings = this.selectedBlock.settings || {};
  }

  private _update() {
    this.selectedBlock.settings = Object.assign({}, this.settings);
    this.store.dispatch(new IndexChanged([this.selectedBlock]));
  }
}
