import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { DEFAULT_VALUES } from '../../common/constants/default-values';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { AnnotationType, Slide, Annotation, ImagePosition, User, Image, Media, Bounds } from '@medsurf/models';
import { SlideState } from '@medsurf/state';
import { MediaService } from '@medsurf/services';
import { PathModel } from '@medsurf/actions';

export interface FreeToolEvent {
  mObj: any;
}

export interface AddMarkerEvent {
  endType: string;
  text: string;
}

export interface DeleteMarkerEvent {
  marker: Annotation;
}

// TODO interface for addMarker

@Component({
  selector: 'medsurf-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnChanges, OnDestroy {
  @Select(SlideState.position)
  public position$: Observable<ImagePosition>;

  @Select(SlideState.bounds)
  public bounds$: Observable<Bounds>;

  @ViewChild('panelNav') panelNav: NgbNav;
  @ViewChild('settingsNav') settingsNav: NgbNav;

  @Output() handlePickBackgroundColor = new EventEmitter<void>();
  @Output() activateFreeTool = new EventEmitter<FreeToolEvent>();
  @Output() deleteMarker = new EventEmitter<DeleteMarkerEvent>();
  @Output() deleteSlide = new EventEmitter<void>();
  @Output() goToPoi = new EventEmitter();
  @Output() markerChange = new EventEmitter<Annotation>();
  @Output() changeMedia = new EventEmitter<void>();
  @Output() hoverMarkerChange = new EventEmitter<Annotation>();

  @Input() slide: Slide;
  @Input() user: User;
  @Input() users: User[];
  @Input() path: PathModel;
  @Input() comments: any;
  @Input() hoverMarker: Annotation;
  @Input() marker: Annotation;
  @Input() selftestMarker: any;
  @Input() props: any;
  @Input() sequenceId: number;
  @Input() layerId: number;

  @Select(SlideState.image)
  public image$: Observable<Image>;

  @Select(SlideState.media)
  public media$: Observable<Media>;

  public showWarnings: boolean;
  public activePanel: string;
  public activeSetting: string;
  public activeGroup: string;
  public activeAnnotation: string;
  public AnnotationType = AnnotationType;
  private _destroyed = new Subject<boolean>();

  constructor(
    @Inject(DEFAULT_VALUES) public defaultValues,
    public mediaService: MediaService,
    private store: Store
  ) {

    /**
     * Setup sequence and layer
     */
    if (!this.sequenceId) {
      this.sequenceId = 0;
    }
    if (!this.layerId) {
      this.layerId = 0;
    }

    /**
     * set variables
     */
    this.showWarnings = false;

    this.activePanel = 'panel.settings';
    this.activeSetting = 'metadata';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.marker && changes.marker.currentValue?.id !== changes.marker.previousValue?.id) {
      this.handleMarkerChange();
    }
  }

  /**
   * Select handler
   */
  public handleMarkerChange() {
    if (this.marker) {
      this.panelNav?.select('panel.annotations');

      /**
       * Setup background object
       * TODO check for keymap -> +1 depth
       */
      if (
        this.marker.label &&
        (typeof (this.marker.label.background) === 'boolean' || typeof (this.marker.label.background) === 'undefined')
      ) {
        if (this.marker.label.background) {
          this.marker.label.background = {opacity: 0.4};
        } else {
          this.marker.label.background = {opacity: 0};
        }
      }
    }
  }

  /**
   * Set marker
   */
  public setMarker(marker) {
    this.marker = marker;
    this.markerChange.emit(this.marker);
  }

  /**
   * Hover marker
   */
  public setHoverMarker(marker) {
    this.hoverMarker = marker;
    this.hoverMarkerChange.emit(this.hoverMarker);
  }

  /**
   * Set selftest marker
   */
  public setSelftestKeymap(marker) {
    this.selftestMarker = marker;
  }

  /**
   * property functions
   */


  public localDeleteMarker(marker) {
    this.deleteMarker.emit(marker);
  }

  public localPickBackgroundColor() {
    this.handlePickBackgroundColor.emit();
  }

  public sortIndex(keymap, type) {
    let index = 0, oldValue;
    if (type === 'vertical') {
      keymap.fields.label.forEach((column, columnId) => {
        column.forEach((key, keyId) => {
          oldValue = key.index;
          key.index = ++index;
          this.switchKeymapIndex(keymap, columnId, keyId, oldValue);
        });
      });
    } else {
      let row = 0, maxColumnSize = 1;
      while (row < maxColumnSize) {
        keymap.fields.label.forEach((column, columnId) => {
          maxColumnSize = maxColumnSize < column.length ? column.length : maxColumnSize;
          if (column[row]) {
            oldValue = column[row].index;
            column[row].index = ++index;
            this.switchKeymapIndex(keymap, columnId, row, oldValue);
          }
        });
        row++;
      }
    }
  }

  public switchKeymapIndex(keymap, columnId, keyId, oldValue) {
    const [marker] = this.slide.layers[this.layerId].images[this.sequenceId].annotations.filter(markerElement => {
      return markerElement.type === AnnotationType.MARKER && String(markerElement.label.text) === String(oldValue);
    });
    if (marker) {
      marker.label.text = String(keymap.fields.label[columnId][keyId].index);
    }
  }

  /**
   * Pois
   */
  public addMarkerToPoi(poiId) {
    for (const poi of this.slide.layers[this.layerId].images[this.sequenceId].pois) {
      if (!poi.annotations) {
        poi.annotations = [];
      }
      if (poi.id === poiId && poi.annotations.findIndex(marker => marker.id === this.marker.id) === -1) {
        poi.annotations.push(this.marker);
        break;
      }
    }
  }

  /**
   * Groups
   */
  public addMarkerToGroup(groupId) {
    // for (const group of this.slide.images[this.sequenceId].groups) {
    //   if (!group.markers) {
    //     group.markers = [];
    //   }
    //   if (group.id === groupId && group.markers.indexOf(this.marker.fields._id) === -1) {
    //     group.markers.push(this.marker.fields._id);
    //     break;
    //   }
    // }
  }

  /**
   * Circle
   */
// this.circleRadius = this.marker.fields.target[0].end.radius;
  public setCircle(newValue) {
    if (newValue) {
      this.marker.targets.forEach((target) => {
        target.endRadius = newValue;
      });
    }
    return this.marker.targets[0].endRadius;
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
