<h3 class="bg-primary p-2 text-white">{{ currentItem.page.title }}

  <span class="float-right">

    <span class="badge badge-secondary mr-1" *ngIf="!isBlockSelected">
      <span class="fa fa-text fa-sm"></span>
      <span class="fa fa-check fa-sm ml-1" *ngIf="currentItem.page.description"></span>
      <span class="fa fa-times fa-sm ml-1" *ngIf="!currentItem.page.description"></span>
    </span>

    <span class="badge badge-secondary mr-1" *ngIf="isBlockSelected">
      <span class="fa fa-presentation fa-sm"></span> {{ currentItem?.children?.length }}
    </span>

    <span class="badge badge-secondary mr-1">{{ currentItem.page.type | translate }}</span>

    <span class="badge badge-secondary mr-1" *ngIf="!isBlockSelected">
      <span class="fa fa-file-image fa-sm"></span>
      <span class="fa fa-check fa-sm ml-1" *ngIf="currentItem.page.image"></span>
      <span class="fa fa-times fa-sm ml-1" *ngIf="!currentItem.page.image"></span>
    </span>

    <span class="badge" *ngIf="currentItem.page.themeColor"
          [style.background-color]="themeColorService.getHexValue(currentItem.page.themeColor)"> &nbsp;
    </span>

    <span class="badge badge-secondary mr-1" *ngIf="currentItem.page.type === 'Vocabulary'">
      <span class="fa fa-th-list fa-sm"></span>
    </span>

    <span class="badge badge-secondary mr-1" *ngIf="currentItem.page.type === 'QuizStart'">
      <span class="fa fa-th-list fa-sm"></span>
    </span>

    </span>
</h3>
