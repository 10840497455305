import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SlideNode, Node } from '@medsurf/models';

import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { BehaviorSubject, debounceTime, filter, Observable, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { GetAutofill } from '@medsurf/actions';
import * as MedsurfActions from '@medsurf/actions';


@Component({
  selector: 'medsurf-link-typeahead',
  templateUrl: './link-typeahead.component.html',
  styleUrls: ['./link-typeahead.component.scss']
})
export class LinkTypeaheadComponent implements OnInit, OnDestroy {

  @Output() internalLink = new EventEmitter<SlideNode>();

  @Output() isEditing = new EventEmitter<boolean>();

  @Input()
  public linkText: string;

  @Input()
  public placeholder: string;

  @Input()
  public linkLabel = 'Link';

  private _destroyed = new Subject<boolean>();
  private autofill = new BehaviorSubject<Node[]>([]);

  public node: SlideNode;
  
  constructor(
    private store: Store,
    private actions$: Actions
  ) {}

  ngOnInit(): void {
    this.actions$.pipe(takeUntil(this._destroyed), ofActionDispatched(MedsurfActions.GetAutofillSuccess))
    .subscribe((action: MedsurfActions.GetAutofillSuccess) => {
      this.autofill.next(action.nodes);
    });
  }

  public formatter = (node: SlideNode ) => {
    this.linkText = node?.page ? `${node.page.type}: ${node.page.title} ${node.page.slideId ? ' (' + node.page.slideId + ')' : ''}` : this.linkText;
    return this.linkText;
  };

  public onSelect(event: NgbTypeaheadSelectItemEvent): void {
    this.node = event.item;
    this.linkText = `/node/${this.node.id}`;

    this.isEditing.emit(false);
    this.internalLink.emit(this.node);
  }

  public autofillTypeahead = (searchText: Observable<string>): Observable<Node[]> => {
    return searchText.pipe(
        debounceTime(200),
        tap(()=> this.autofill.next([])),
        filter(term => term.length >= 2),
        switchMap((term) => {
          this.isEditing.emit(true);
          this.autofill.next([null]);
          if(term?.[0]){
            this.store.dispatch(new GetAutofill(term));
          }
          return this.autofill;
        })
    );
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
