<div class="d-flex flex-col h-100">
    <medsurf-navigation></medsurf-navigation>
    <div class="panel panel-default scrollable d-flex flex-col">
        <div class="panel-heading">
            <button id="btn-append-to-to-body" title="Neue" type="button" class="btn btn-secondary" (click)="newConstant()">
                <span class="fa fa-fw fa-plus"></span>
            </button>
        </div>
        <div class="panel-body panel-body-full-margin">
            <div class="list">
                <div class="card">
                    <table class="table table-hover table-vcenter">
                        <thead class="bg-secondary text-white">
                        <tr>
                            <ng-container [ngSwitch]="constantType$ | async">
                                <ng-container *ngSwitchCase="'Modality'">
                                    <th><span translate="name"></span></th>
                                    <th><span translate="shortcut"></span></th>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Stain'">
                                    <th><span translate="name"></span></th>
                                    <th><span translate="shortcut"></span></th>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Copyright'">
                                    <th><span translate="text"></span></th>
                                </ng-container>
                            </ng-container>
                        </tr>
                        </thead>
                        <tbody [ngSwitch]="constantType$ | async">
                            <ng-container *ngSwitchCase="'Modality'">
                                <tr *ngFor="let modality of (modalities$ | async)" (click)="goToDetail(modality)">
                                    <td>{{ modality.name }}</td>
                                    <td>{{ modality.shortcut }}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngSwitchCase="'Stain'">
                                <tr *ngFor="let stain of (stains$ | async)" (click)="goToDetail(stain)">
                                    <td>{{ stain.name }}</td>
                                    <td>{{ stain.shortcut }}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngSwitchCase="'Copyright'">
                                <tr *ngFor="let copyright of (copyrights$ | async)" (click)="goToDetail(copyright)">
                                    <td>{{ copyright.text }}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

