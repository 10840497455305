import { Pipe, PipeTransform } from '@angular/core';
import { Choice } from '@medsurf/models';

@Pipe({
  name: 'decodeChoices'
})
export class DecodeChoicesPipe implements PipeTransform {

  transform(value: Choice[], ...args: unknown[]): unknown {
    const txt = document.createElement("textarea");

    return value.map(el => {
      txt.innerHTML = el.label;
      el.label = txt.value;
      return el;
    });
  }

}
