import { ConfigSettings } from './config';

export const getPluginsConfig = (settings: ConfigSettings) => {
  const pluginConfig = {
    plugins: [
      'fullscreen',
      'lists',
      'code',
      'charmap',
      'searchreplace',
      'table',
      'help',
      'wordcount'
    ]
  };
  if (settings.autogrow) {
    pluginConfig.plugins.push('autoresize');
  }
  return pluginConfig;
};
