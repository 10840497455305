<medsurf-type
    [selectedBlock]="selectedBlock"
    [isBlockSelected]="isBlockSelected">
</medsurf-type>

<medsurf-chapters-main-settings-pdf
  [selectedBlock]="selectedBlock"
  *ngIf="isBlockSelected">
</medsurf-chapters-main-settings-pdf>

<medsurf-theme-color
    *ngIf="isSubject"
    [selectedBlock]="selectedBlock">
</medsurf-theme-color>

<div class="form-group form-row">
    <div class="col-sm-4">
        <label for="nodeRoute">{{ 'route' | translate }}</label>
        <div class="input-group">
            <input type="text" class="form-control" id="nodeRoute" [(ngModel)]="selectedBlock.route" (ngModelChange)="onRouteChange()">
            <div class="input-group-append">
                <button class="btn btn-secondary" type="button" (click)="setRouteFromTitle()" [title]="'generate_route_from_title' | translate">
                    <i class="fa fa-fw fa-arrows-rotate"></i>
                </button>
            </div>
        </div>
        <small id="nodeHelp" class="form-text text-muted">{{ 'route_change_warning' | translate }}</small>
    </div>
</div>

<h3 class="mt-4 mb-1 text-secondary">{{ 'settings' | translate}}</h3>
<small id="nodeHelp" class="form-text text-muted mb-3">{{ 'settings_message' | translate }}</small>
<div *ngIf="settingsInherited$ | async; let settingsInherited">
    <div *ngFor="let setting of settingsDefault | keyvalue" class="form-group mb-4">
        <label [translate]="setting.key" [for]="setting.key"></label>

        <div>
            <div *ngIf="settingInputTypes[setting.key] === 'radio'" class="btn-group mr-2" role="group">
                <button
                    type="button"
                    [class.btn-secondary]="settings[setting.key] === true"
                    [class.btn-primary]="settings[setting.key] !== true"
                    class="btn"
                    (click)="onRadioChange(setting.key, true)">{{ 'yes' | translate }}</button>
                <button
                    type="button"
                    [class.btn-secondary]="settings[setting.key] === false"
                    [class.btn-primary]="settings[setting.key] !== false"
                    class="btn"
                    (click)="onRadioChange(setting.key, false)">{{ 'no' | translate }}</button>
            </div>
            <input
                *ngIf="settingInputTypes[setting.key] === 'text'"
                (change)="onChange(setting.key, $event)"
                [name]="setting.key"
                [id]="setting.key"
                [value]="settings[setting.key] || ''"
                type="text"
                class="form-control">

            <div class="color-palette-container mr-2" *ngIf="settingInputTypes[setting.key] === 'color'" >
                <medsurf-color-palette
                    [name]="setting.key"
                    [id]="setting.key"
                    [colorList]="defaultValues.colors"
                    [ngModel]="settings[setting.key]"
                    (ngModelChange)="onColorChange(setting.key, $event)"
                ></medsurf-color-palette>
            </div>

            <span
                *ngIf="isUndefined(settings[setting.key])"
            >
                {{ 'inherited' | translate }}:  {{ (!isUndefined(settingsInherited[setting.key])) ? settingsInherited[setting.key] : setting.value }}
            </span>
            <button *ngIf="!isUndefined(settings[setting.key])" class="btn btn-sm btn-secondary" type="button" (click)="onClear(setting.key)">{{ 'reset' | translate }}</button>
        </div>
    </div>
</div>
