<div class="layout-container">
  <header *ngIf="!preview">
    <medsurf-navigation
      [page]="slide$ | async"
      [message]="message">
    </medsurf-navigation>
  </header>
  <section class="main hbox space-between">
    <ng-container>
      <article>
        <div class="flex-row">
          <div class="panel panel-default flex-col">
            <div class="panel-body panel-body-noborders flex-grow">
              <aside class="sidebar">
                <medsurf-section [title]="'chapter' | translate">
                  <div dropdownMenu>
                    <button ngbDropdownItem
                            (click)="newSlide()"
                            [disabled]="(isIndexDirty$ | async) || (isSlidesDirty$ | async)"
                            title="{{ 'new_slide_description' | translate }}">
                      {{ 'new_slide' | translate }}
                      <small *ngIf="(isIndexDirty$ | async) || (isSlidesDirty$ | async)">({{ 'save_first' | translate}})</small>
                    </button>
                  </div>
                  <medsurf-siblings-sidebar #siblingsSidebarComponent
                          [siblings]="siblings"
                          [currentSlide]="slide$ | async"
                          (slideSelected)="navigateToSlide($event)">
                  </medsurf-siblings-sidebar>
                </medsurf-section>
              </aside>
              <ng-container *ngIf="(slide$ | async); else spinner">
                <aside class="sequence">
                  <medsurf-section [title]="'image_series' | translate">
                    <medsurf-sequence-navigation
                            [slide]="slide$ | async"
                            (navigationChange)="onNavigationChange()"
                            class="flex-grow">
                    </medsurf-sequence-navigation>
                  </medsurf-section>
                </aside>

                <div class="main-section">
                  <div class="panel-heading">
                    <medsurf-dropdown
                            [slide]="slide$ | async"
                            [zoom]="zoom"
                            [canRedo]="canRedo$ | async"
                            [canUndo]="canUndo$ | async"
                            [preview]="preview"
                            [marker]="marker"
                            (addMarker)="addMarker($event)"
                            (addFreeform)="addFreeForm($event)"
                            (addKeymap)="addKeymap()"
                            (addText)="addText()"
                            (setZoom)="setZoom($event)"
                            (handleRedo)="handleRedo()"
                            (handleUndo)="handleUndo()"
                            (gotoSlideDetail)="gotoSlideDetail()"
                            (togglePreview)="togglePreview()"
                            (copyMarker)="copyMarker($event)"
                            (pasteMarker)="pasteMarker($event)"
                            [(layout)]="layout"
                            [isSaving]="isSaving"
                            [(grid)]="grid">
                    </medsurf-dropdown>
                  </div>
                  <div class="panel-body panel-body-noborders flex-grow">
                    <medsurf-wysiwyg
                            [slide]="slide$ | async"
                            [sequenceId]="sequenceNumber$ | async"
                            [layerId]="layerNumber$ | async">
                      <medsurf-microscope #microscope
                                          *ngIf=" ((slide$ | async)?.layers?.length > 0 && (slide$ | async)?.layers[0]?.images.length > 0)"
                                          class="canvas-container"
                                          [state]="state"
                                          [zoomPreset]="zoompreset"
                                          [grid]="grid"
                                          [layout]="layout"
                                          [preview]="preview"
                                          [hoverMarker]="hoverMarker"
                                          [selftestMarker]="selftestMarker"
                                          [error]="error"
                                          [props]="props"
                                          [command]="command"
                                          [selectedPoi]="selectedPoi"
                                          (microscopeChange)="updateSlide()"
                                          [(marker)]="marker"
                                          [(zoom)]="zoom">
                      </medsurf-microscope>
                    </medsurf-wysiwyg>
                  </div>
                </div>

                <aside *ngIf="!preview" class="properties">
                  <medsurf-section [title]="'edit' | translate">
                    <div dropdownMenu>
                    </div>
                    <medsurf-panel
                            [slide]="slide$ | async"
                            [users]="users$ | async"
                            [user]="user$ | async"
                            [path]="path$ | async"
                            [props]="props"
                            [selftestMarker]="selftestMarker"
                            [sequenceId]="sequenceNumber$ | async"
                            [layerId]="layerNumber$ | async"
                            (deleteMarker)="deleteMarker($event)"
                            (activateFreeTool)="activateFreeTool($event)"
                            (deleteSlide)="deleteSlide()"
                            (changeMedia)="changeMedia()"
                            (goToPoi)="goToPoi($event)"
                            [(marker)]="marker"
                            [(hoverMarker)]="hoverMarker">
                    </medsurf-panel>
                  </medsurf-section>
                </aside>
              </ng-container>

              <ng-template #spinner>
                <medsurf-spinner></medsurf-spinner>
              </ng-template>
            </div>
          </div>
        </div>
      </article>
    </ng-container>
  </section>
</div>


<!-- Slide remove confirmation modal -->
<ng-template #ConfirmSlideRemoveDialog let-modal>
  <header class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">{{ 'bulk_remove_slides' | translate }}</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Button')">
          <span aria-hidden="true">&times;</span>
      </button>
  </header>
  <div class="modal-body">
    <p>{{ 'bulk_remove_slides_confirm' | translate }}</p>

    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
      <ngb-panel type="light" *ngIf="(slide$ | async) as slide">
        <ng-template ngbPanelTitle>
          <img *ngIf="slide.thumbnailMedia" [src]="mediaService.getUrl(slide.thumbnailMedia, 'thumbnail')" class="thumbnail-sm mr-3" [alt]="slide.thumbnailMedia.altText">
          <div class="thumbnail-sm border bg-primary text-dark mr-3" *ngIf="!slide.thumbnailMedia">
            <span [class]="'fa ' + mediaService.getMediaTypeIcon({ type: slide.mediaType })"></span>
          </div>
          <span>{{ slide.title }}</span>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-container *ngIf="relatedNodes.length > 0">
            <p>{{ 'media_remove_confirm_with_links' | translate }}</p>
            <ul class="ml-4">
              <li *ngFor="let node of relatedNodes">
                  <a target="_blank" [href]="'/node/' + node.id">{{ node.page.title }}</a>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="relatedNodes.length === 0">
            <p>{{ 'is_not_used' | translate }}</p>
          </ng-container>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <footer class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">{{ 'cancel' | translate }}</button>
      <button type="button" class="btn btn-danger" (click)="modal.close()">{{ 'delete' | translate }}</button>
  </footer>
</ng-template>
