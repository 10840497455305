import { EventEmitter } from 'events';

class Command extends EventEmitter {
  private _currentCommand: any;
  private _historyIndex: number;
  private _currentVersion: any;
  private _history: any[];
  private _cmdPressed: boolean;
  private _ctrlPressed: boolean;

    constructor(private _model) {
      super();
      this._currentCommand = null;
      this._historyIndex = 0;
      this._currentVersion = this._model;
      this._history = [JSON.parse(JSON.stringify(this._model))];
      this._init();
    }

    _init() {
      this._cmdPressed = false;
      this._ctrlPressed = false;
      addEventListener('keydown', this._keyDown.bind(this), false);
      addEventListener('keyup', this._keyUp.bind(this), false);
    }

    _keyDown(event) {
      if (event.keyCode === 91) {
        this._cmdPressed = true;
      }
      if (event.keyCode === 17) {
        this._ctrlPressed = true;
      }
      if (event.keyCode === 90 && (this._cmdPressed || event.ctrlKey)) {
        event.stopPropagation();
        event.preventDefault();
        this.undo();
      }
      if (event.shiftKey && (event.keyCode === 90 && (this._cmdPressed || event.ctrlKey))) {
        event.stopPropagation();
        event.preventDefault();
        this.redo();
      }
    }

    _keyUp() {
      this._cmdPressed = false;
    }

    addToHistory(version) {
      this._history.push(version);
      this._historyIndex++;
    }

    doCommand(fn, description) {
      const newVersion = fn(this._model);
      this._currentVersion = JSON.parse(JSON.stringify(newVersion));
      this._currentCommand = description;
    }

    undo() {
      if (this._historyIndex > 0) {
        this._historyIndex-- ;
      }
      this._model = this._history[this._historyIndex];
      this.emit('undoredo');
    }

    redo() {
      if (this._historyIndex < this._history.length - 1) {
        this._historyIndex++;
      }
      this._model = this._history[this._historyIndex];
      this.emit('undoredo');
    }

    canUndo () {
      return (this._historyIndex > 0);
    }

    canRedo () {
      return (this._historyIndex < this._history.length - 1);
    }

    set currentCommand(value) {
      this._currentCommand = value;
    }

    get currentCommand() {
      return this._currentCommand;
    }

    set currentVersion(value) {
      this._currentVersion = value;
    }

    get currentVersion() {
      return this._currentVersion;
    }

    get model() {
      return this._model;
    }

    cleanUp() {
      removeEventListener('keydown', this._keyDown);
      removeEventListener('keyup', this._keyUp);
    }
}

export default Command;
