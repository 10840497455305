import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'medsurf-reference-dropdown',
  templateUrl: './reference-dropdown.component.html',
  styleUrls: ['./reference-dropdown.component.scss']
})
export class ReferenceDropdownComponent {
  @Input() dropdownId: string;
  @Input() labelKey: string;
  @Input() list: { id: string }[];
  @Input() map: { count: number, items: { [key: string]: boolean }};
  @Input() subject: { id: string }
  @Input() classes: string;
  @Input() noResultLabel: string;
  @Input() labelIcon: string;
  @Input() title: string;

  public isOpen = false;

  @Output() updateSelected = new EventEmitter<{ subject: {id: string}, items: {id: string}[], add: boolean}>();

  public update(items: typeof this.list, add: boolean) {
    this.updateSelected.emit({subject: this.subject, items, add});
  }

  public trackById(index: number, entity: { id: string}) {
    return entity.id || index;
  }
}
