import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivationStart, Params, Router } from '@angular/router';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import {
  Login,
  LoginSuccess,
  LoginInvalid,
} from '@medsurf/actions';
import { LoginValidator } from '@medsurf/validators';
import { AuthState } from '@medsurf/state';
import { Actions, ofActionCompleted, ofActionDispatched, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { ClassValidatorFormBuilderService, ClassValidatorFormGroup } from 'ngx-reactive-form-class-validator';

@Component({
  selector: 'medsurf-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  /**
   * Members
   */
  public loginForm: ClassValidatorFormGroup;
  public isSubmitted = false;
  private queryParams: Params;

  private _destroyed = new Subject<boolean>();

  /**
   * Constructor
   * @param router
   * @param store
   * @param actions$
   * @param fb
   */
  constructor(
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private fb: ClassValidatorFormBuilderService
  ) {
    this.router.events
      .pipe(filter((event: ActivationStart) => !!event.snapshot), take(1), map(event => event.snapshot))
      .subscribe((event: any) => {
        this.queryParams = event.queryParams;
      });
  }

  /**
   * NgOnInit
   */
  public async ngOnInit(): Promise<void> {
    this.loginForm = this.fb.group(LoginValidator, {
      email: [],
      password: []
    });

    if (this.store.selectSnapshot(AuthState.isAuthenticated)) {
      this.navigateToQueryUrl();
    }

    this.actions$.pipe(ofActionCompleted(LoginSuccess), takeUntil(this._destroyed)).subscribe(() => {
      this.navigateToQueryUrl();
    });

    this.actions$.pipe(ofActionDispatched(LoginInvalid), takeUntil(this._destroyed)).subscribe((action: LoginInvalid) => {
      this.loginForm.get('password').setValue('');
      action.errors.forEach((err) => {
        this.loginForm.get(err.property).setErrors(err.constraints);
      });
      this.isSubmitted = false;
    });
  }

  get f() { return this.loginForm.controls; }

  private async navigateToQueryUrl() {
    const url = this.queryParams.r ? this.queryParams.r : '/';
    await this.router.navigate([url]);
  }

  /**
   * Execute login
   */
  public async onSubmit(): Promise<void> {
    if (this.isSubmitted || this.loginForm.invalid) {
      return;
    }
    this.isSubmitted = true;

    const { email, password } = this.loginForm.value;
    this.store.dispatch(new Login(email, password));
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
