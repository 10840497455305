import { Inject, Injectable } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Settings, SETTINGS } from '@medsurf/services';
import { AddAlert } from '@medsurf/actions';
import { Store } from '@ngxs/store';
import { reject } from 'lodash';

interface FormDataItem {
  name?: string;
  value?: string;
}

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private sliceSize = 10 * 1024 * 1024;

  constructor(@Inject(SETTINGS) private settings: Settings,
              private store: Store,
              private http: HttpClient) {
  }

  public uploadFile(file: File, formDataArray?: FormDataItem[], path?: string): Promise<any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const sendObservables: Observable<any>[] = await this.getSendObservables<any>(file, formDataArray, path);

      merge(...sendObservables).subscribe((response: any) => {
          if (response.status === 'finished') {
            resolve(response.result);
          }
        },
        (httpError: HttpErrorResponse) => {
          this.store.dispatch(new AddAlert({message: httpError?.error?.error ?? httpError.message}));
          reject(httpError.message);
        });
    });
  }

  /**
   * @param {File} file
   * @param {HeaderOptions} headerOptions
   * @returns {Promise<Observable<T>[]>}
   */
  public async getSendObservables<T>(file: File, formDataArray?: FormDataItem[], path?: string): Promise<Observable<T>[]> {
    const uploadPath = path ?? 'v1/file';
    const start = 0;
    const end: number = start + this.sliceSize;
    const chunks: Blob[] = this.formChunks(start, end, file, []);
    const contentId: string = Math.floor(Math.random() * 100000).toString();

    // Remove Unicode Chars from filename for MacOS
    // eslint-disable-next-line no-useless-escape
    const contentName = file.name.replace(/[^a-zA-Z0-9_\-\.\/]/g, '_');

    const sendObservablesPromises: Promise<Observable<T>>[] = chunks.map(async (chunk: Blob, index: number): Promise<Observable<T>> => {
      const headers: HttpHeaders = new HttpHeaders()
        .append('x-chunk-id', index.toString())
        .append('x-content-length', file.size.toString())
        .append('x-content-type', file.type)
        .append('x-content-name', contentName)
        .append('x-chunks-quantity', chunks.length.toString())
        .append('x-content-id', contentId);

      const options = {headers};
      const formData: FormData = new FormData();
      formData.append('file', chunk);

      if (formDataArray) {
        for (const item of formDataArray) {
          formData.append(item.name, item.value);
        }
      }

      return this.http.put<T>(this.settings.endpointUrl + uploadPath, formData, options);
    });

    return await Promise.all(sendObservablesPromises);
  }


  /**
   * Form chunks out of a base64 encoded File
   *
   * @param {number} start
   * @param {number} end
   * @param {string} file
   * @param {string[]} chunks
   * @returns {string[]}
   * @private
   */
  private formChunks(start: number, end: number, file: File, chunks: Blob[]): Blob[] {
    if (file.size - end < 0) {
      end = file.size;
    }
    const piece: Blob = file.slice.bind(file)(start, end);
    chunks.push(piece);
    if (end < file.size) {
      start += this.sliceSize;
      end += this.sliceSize;
      chunks = this.formChunks(start, end, file, chunks);
    }
    return chunks;
  }
}
