<ng-container class="row custom-typeahead">


  <input #searchInput (selectItem)="onSelect($event)" [ngModel]="linkText"
    [focusFirst]="false"
    [ngbTypeahead]="autofillTypeahead"
    [resultTemplate]="autofill"
    [inputFormatter]="formatter"
    [placeholder]="placeholder"
    autocomplete="off"
    class="search typeahead col-sm-12 form-control input-md"
    id="search"
    name="search"
    title="search"
    type="search">
  

  <ng-template #autofill let-r="result" class="dropdown-template">

    <ng-container *ngIf="!!r?.page">
      <span class="suggestion">
        {{ r.page.type }}:  {{r.page.title}} <span *ngIf="r.page.slideId">({{r.page.slideId}})</span>
      </span>
    </ng-container>

    <div #spinnerDiv class="loading-spinner" *ngIf="!!r?.page === false">
      <div class="spinner-border p-2" role="status">
        <span class="sr-only"></span>
      </div>
    </div>

  </ng-template>
    
</ng-container>

