import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';

interface TreeItem {
  key?: string;
  value?: string;
  expanded?: boolean;
  children?: TreeItem[];
}

@Component({
  selector: 'medsurf-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss']
})
export class TreeSelectComponent implements OnDestroy {

  @Input() label = 'Input';
  @Input() options: TreeItem[] = [];
  @Input() multiple = false;
  @Input() selected: TreeItem[] = [];
  @Output() selectedChange = new EventEmitter<TreeItem[]>();

  public selectedLabels: string[] = [];

  private _destroyed = new Subject<boolean>();

  isSelected(item: TreeItem) {
    return this.selected.find(({key}) => item.key === key);
  }

  expand(item: TreeItem) {
    item.expanded = !item.expanded;
  }

  select(item: TreeItem) {
    const index = this.selected.findIndex(({key}) => item.key === key);
    if (index !== -1) {
      this.selected.splice(index, 1);
      this.selectedChange.emit(this.selected);
    } else {
      this.selected.push(item);
      this.selectedChange.emit(this.selected);
    }
  }


  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
