import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MediaService, Settings, SETTINGS } from '@medsurf/services';
import { Slide, SlideNode } from '@medsurf/models';

@Component({
  selector: 'medsurf-siblings-sidebar',
  templateUrl: './siblings-sidebar.component.html',
  styleUrls: ['./siblings-sidebar.component.scss']
})
export class SiblingsSidebarComponent implements OnInit, OnChanges {

  @Input() siblings: SlideNode[];
  @Input() currentSlide: Slide;

  @Output() slideSelected = new EventEmitter<SlideNode>();

  @ViewChild('siblingsSidebar')
  public siblingsSidebar: ElementRef;

  constructor(
    @Inject(SETTINGS) private settings: Settings,
    public mediaService: MediaService
  ) {}

  ngOnInit() {
    this.scrollToId(this.currentSlide?.id);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.scrollToId(this.currentSlide?.id);
  }

  public scrollToId(id: string): void {
    if (!id) {
      return;
    }
    const htmlElement: Element = (this.siblingsSidebar?.nativeElement as HTMLDivElement)?.children?.namedItem(id);
    htmlElement?.scrollIntoView();
  }

}
