<div class="nav-tab-content">
  <form class="form" *ngIf="keymap && isKeymap(keymap)">

    <!-- Label -->
    <fieldset>

      <div class='row'>
        <div class='col-sm-6'>
          <div class='form-group'>
            <label for="labelColor" translate="label_color"></label>
            <medsurf-color-palette id="labelColor" name="labelColor" [colorList]="defaultValues.colors" [(ngModel)]="keymap.keymapStyle.color" (ngModelChange)="onChange()"></medsurf-color-palette>
          </div>
        </div>
        <div class="col-sm-6" *ngIf="!keymap.keymapStyle.fontSize || keymap.keymapStyle.fontSize <= 20">
          <label for="labelFontSize" translate="label_font_size"></label>
          <select id="labelFontSize" name="labelFontSize" class="form-control input-md" [(ngModel)]="keymap.keymapStyle.fontSize" (ngModelChange)="onChange()">
            <option *ngFor="let fontSize of defaultValues.fontSizes" [ngValue]="fontSize.value">{{fontSize.name}}</option>
          </select>
        </div>
        <div class='col-sm-6' *ngIf="keymap.keymapStyle.fontSize > 20">
          <div class='form-group'>
            <label for="labelFontSize" translate="label_font_size"></label>
            <input id="labelFontSize" name="labelFontSize" class="form-control input-md" type="number" [(ngModel)]="keymap.keymapStyle.fontSize" (ngModelChange)="onChange()"/>
          </div>
        </div>
      </div>

      <div class='row'>
        <div class='col-sm-6'>
          <div class='form-group'>
            <input id="labelBorder" name="labelBorder" type="checkbox" [(ngModel)]="keymap.keymapStyle.border" (ngModelChange)="onChange()"> {{ 'with_border' | translate }}
          </div>
        </div>
      </div>

      <div class="row" *ngIf="keymap.keymapStyle.border">
        <div class='col-sm-6'>
          <label for="labelStrokeColor" translate="label_stroke_color"></label>
          <medsurf-color-palette id="labelStrokeColor" name="labelStrokeColor" [colorList]="defaultValues.colors" [(ngModel)]="keymap.keymapStyle.strokeColor" (ngModelChange)="onChange()"></medsurf-color-palette>
        </div>
      </div>

      <div class="row" *ngIf="keymap.keymapStyle.border">
        <div class='col-sm-6'>
          <div class='form-group'>
            <label for="labelStrokeDashArray" translate="label_stroke_dash"></label>
            <select id="labelStrokeDashArray" name="labelStrokeDashArray" class="form-control input-md" [(ngModel)]="keymap.keymapStyle.dash" (ngModelChange)="onChange()">
              <option *ngFor="let dash of defaultValues.dashArray" [value]="dash.value">{{dash.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6">
          <div class='form-group'>
            <label for="labelStrokeWidth" translate="label_stroke_width"></label>
            <input id="labelStrokeWidth" name="strokeWidth" class="form-control input-md" type="number" [(ngModel)]="keymap.keymapStyle.strokeWidth" (ngModelChange)="onChange()"/>
          </div>
        </div>
      </div>

      <div class='row'>
        <div class='col-sm-6'>
          <div class='form-group'>
            <input id="labelBackground" name="labelBackground" type="checkbox" [(ngModel)]="keymap.keymapStyle.background" (ngModelChange)="onChange()"> {{ 'with_background' | translate }}
          </div>
        </div>
        <div class='col-sm-6'>
          <div class='form-group'>
            <input type="range" class="form-control-range" id="labelBackgroundOpacity" name="labelBackgroundOpacity" [(ngModel)]="keymap.keymapStyle.opacity" (ngModelChange)="onChange()" min="0" max="1" step="0.01"> {{ keymap.keymapStyle.opacity * 100 + "%" }}
          </div>
        </div>
      </div>

      <div class='row' *ngIf="keymap.keymapStyle.opacity > 0">
        <div class='col-sm-6'>
          <div class='form-group'>
            <label for="labelBackgroundColor" translate="label_background_color"></label>
            <medsurf-color-palette id="labelBackgroundColor" name="labelBackgroundColor" [colorList]="defaultValues.colors" [(ngModel)]="keymap.keymapStyle.backgroundColor" (ngModelChange)="onChange()"></medsurf-color-palette>
          </div>
        </div>
        <div class='col-sm-6'>
          <div class='form-group'>
            <input id="labelBackgroundInBorderColor" name="labelBackgroundInBorderColor" type="checkbox" [(ngModel)]="keymap.keymapStyle.backgroundInBorderColor" (ngModelChange)="onChange()"> {{ 'label_background_bordercolor' | translate }}
          </div>
        </div>
      </div>

      <hr />

    </fieldset>

    <!-- Keymap -->
    <fieldset>

      <div class='row'>
        <div class='col-sm-6'>
          <div class='form-group'>
            <label for="column-distance" translate="column_distance"></label>
            <input type="number" id="column-distance" name="column-distance" class="form-control input-md" [(ngModel)]="keymap.keymapStyle.columnDistance" (ngModelChange)="onChange()"/>
          </div>
        </div>
        <div class='col-sm-6'>
          <div class='form-group'>
            <label for="row-distance" translate="row_distance"></label>
            <input type="number" id="row-distance" name="column-distance" class="form-control input-md" [(ngModel)]="keymap.keymapStyle.rowDistance" (ngModelChange)="onChange()"/>
          </div>
        </div>
      </div>

      <div class='row'>
        <div class='col-sm-6'>
          <div class='form-group'>
            <label for="index-distance" translate="index_distance"></label>
            <input type="number" id="index-distance" name="column-distance" class="form-control input-md" [(ngModel)]="keymap.keymapStyle.indexWidth" (ngModelChange)="onChange()"/>
          </div>
        </div>
      </div>

      <div class="row">
        <div class='col-sm-6'>
          <div class='form-group'>
            <input id="columnSeparrator" name="columnSeparator" type="checkbox" [(ngModel)]="keymap.keymapStyle.columnSeparators" (ngModelChange)="onChange()"> {{ "column_separators" | translate}}
          </div>
        </div>
        <div class='col-sm-6'>
          <div class='form-group'>
            <input id="rowSeparator" name="rowSeparator" type="checkbox" [(ngModel)]="keymap.keymapStyle.rowSeparators" (ngModelChange)="onChange()"> {{ "row_separators" | translate}}
          </div>
        </div>
      </div>

      <hr />

    </fieldset>

    <!-- Columns -->
    <fieldset>

      <div class='row'>
        <div class='col-sm-6'>
          <div class='form-group'>
            <label class="control-label" translate="columns"></label>
            <input id="labelLength" name="labelLength" type="text" class="form-control input-md" [(ngModel)]="keymap.columns.length" (ngModelChange)="onChange()" disabled/>
          </div>
        </div>
        <div class='col-sm-6'>
          <div class='form-group'>
            <label class="control-label">&nbsp;</label>
            <br>
            <div class="btn-group">
              <button class="btn btn-secondary" (click)="addColumn(keymap)">
                <span class="fa fa-fw fa-plus"></span>
              </button>
              <button class="btn btn-secondary" (click)="removeColumn(keymap)">
                <span class="fa fa-fw fa-minus"></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr />

    </fieldset>

    <!-- Sorting -->
    <fieldset>

      <div class="row">
        <div class="col-sm-12">
          <label class="control-label" translate="sort_index"></label>
        </div>
        <div class="col-sm-6">
          <label>
            <input type="radio" name="horizontal" id="horizontal" value="horizontal" [(ngModel)]="sorting" (change)="sortIndex(keymap, 'horizontal')"> {{ "sort_horizontal" | translate }}
          </label>
        </div>
        <div class="col-sm-6">
          <label>
            <input type="radio" name="vertical" id="vertical" value="vertical" [(ngModel)]="sorting" (change)="sortIndex(keymap, 'vertical')" checked> {{ "sort_vertikal" | translate }}
          </label>
        </div>
      </div>

      <hr />

    </fieldset>

    <!-- Keymap -->
    <fieldset>
      <label class="control-label" translate="add_keymap"></label>

      <div class='row'>
        <div class="col-sm-12">
          <ul class="nav nav-tabs">
            <li class="nav-item" *ngFor="let column of keymap.columns; let colId = index">
              <a class="nav-link" [ngClass]="{ 'active': selectedColumn === colId }" (click)="setActiveColumn(colId)">{{colId + 1}}</a>
            </li>
          </ul>
        </div>
      </div>

      <div *ngFor="let column of keymap.columns; let colId = index">
        <ng-container *ngIf="selectedColumn === colId">
          <div class="row mt-3 mb-3">
            <div class="col-sm-2 font-weight-bold">#</div>
            <div class="col-sm-7 font-weight-bold" translate="text"></div>
            <div class="col-sm-1 font-weight-bold"></div>
          </div>
          <div class="row" *ngFor="let row of column.labels; let rowId = index">
            <div class='form-group col-sm-2'>
              <input id="keymapIndex-{{colId}}-{{rowId}}" name="keymapIndex-{{colId}}-{{rowId}}" type="text" class="form-control input-md" [(ngModel)]="row.index" ng-model-options='{ debounce: 500 }' ng-init="oldValue = 0" (focus)="oldValue = row.index" (change)="switchKeymapIndex(keymap, colId, rowId, oldValue)"/>
            </div>
            <div class='form-group col-sm-8'>
              <textarea id="keymapText-{{colId}}-{{rowId}}" name="keymapText-{{colId}}-{{rowId}}" type="text" class="form-control input-md" rows="1" [(ngModel)]="row.text" ng-model-options='{ debounce: 500 }' (ngModelChange)="onChange()"></textarea>
            </div>
            <div class='form-group col-sm-1'>
              <button class="btn btn-danger" (click)="removeKey(keymap, colId, rowId)" [disabled]="colId === 0 && column.labels.length === 1">X</button>
            </div>
          </div>
        </ng-container>
        <div class="row" *ngIf="selectedColumn === colId">
          <div class="form-group col-sm-12">
            <div class="btn-group">
              <button type="button" class="btn btn-secondary" (click)="addKey(keymap, colId)" translate="add_key"></button>
            </div>
          </div>
        </div>
      </div>

      <hr />

    </fieldset>

    <!-- Actions -->
    <div class="btn-group">
      <button type="button" class="btn btn-secondary" (click)="setSelftestKeymap.emit(keymap)" translate="set_selftest_keymap"></button>
    </div>

  </form>
</div>
