'use strict';

import paper from 'paper';
import OpenSeadragon from '../../microscope/osd/osd.prototype';
import Element from './element';

const cursors = {
  pentool: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAABCFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAjIyMAAAAAAAAYFxhGRkZDQ0NMTEwAAAAAAAAAAAAAAAAfHh8/Pz9NTU1OT05XVlZKSklNT00AAAAAAAAODg4jIyM0NDQqKio1NTUqKyo1NTVFRUU7OzocHRxERERMTExQUFBFQ0VVVVVWVlZYWFhSUlJhYGA/Pz9ISEgqKypTU1NeXl4AAABLS0v///8AAACqqqqAgIA/QD8rKyvU09POzs7ExMS4t7eqq6qLi4tqamplZGRfX19JSkpFRUUREhH//v7l5eXh4OHa2trIyMihoaGfn5+ZmZmYmJiTkpKFhYV8fHx3dnZXVlcxMTFaKPm7AAAAN3RSTlMABRUKCCEdEA76PjH96oV2NSsnGf7mzcjCs0pJOfz79fTw6+nf393b09LLuauik42Ng21pX1AsL1/fxwAAAQxJREFUKM+tkcdWwzAURG2ruTu20xN6750nlySQTu/w/3+COIAVQ5aZhRZz9Wak8xRFxaoyTai0fsIQ/g+0Gh/Pre3VdaT+AfUxf3n94Ivlg3OcA5bzziNodUfc3mETU3pQTSJBAG6fhyuaJJh6/Dril/Cl/rIkqlZYuIdfUtzVZZa/NQBBkjcBuqthNoLJvi2sEedPABdLBGdZYdPuADxy3oGrh1KAsizmb/RBXG6LY7AZyBJMjvgNfOsuLefetZ22f0grrchfImJWisNeL4kFiY1TWc+IeVx1nNp8LHyPYiUjOrXMRuPs0IgNtyBKpBALCfFN13CbFCk5qRgxanmW9CfnNKpN8+X+Z6tPmWAmPYhmZ+sAAAAASUVORK5CYII=',
  pentool_free: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAqFBMVEUAAAAAAAAAAAAdHR0AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA5OTkODg4AAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAASEhIMDAwvMC8ODg4PDw8QEBALCgoAAAAAAAAAAAAAAQAUFRUAAAD///8AAADU1NSqqqqjo6OdnZ5ubm7u7+/a29rNzMyqqqurq6qYmZl1dXV1dHVjYmNYWFhGR0c8PDwwMDBy6LJPAAAAJHRSTlMABxn5VELADjgTBKsu+LuzcGRgUUc8I/j19O/o3dy9oYh/bh/YlQXIAAAApklEQVQoz73R2RKCIBQGYMEUEDQ1tX2HStv393+zaHJkArrt3H7z/xzmOD8HLKJZwpAJZB6ew67oAB1gLDjnpWjpgpLeXsrGEOCNT1zOWpegaLsrq5Dc/8hR2wBAz28ymmS1HAzxJipjb7uJ2CrXuyUjt64eW/lT6HwLpu5zJ9v6aaDL9N12GbIalOTULatBBhtQ79BRlBbqNkoYxktkuyYiBDj/mxft4BG5lPeiEAAAAABJRU5ErkJggg=='
}
class Drawable extends Element {  
  protected _marker: any;
  protected _container: any;
  private _tool: paper.Tool;

  constructor(model) {
    super(model);
  }

  drawTool(viewer, sequenceNumber: number) {
    return new Promise<void>((resolve) => {
      const pathArray = [];
      const marker = this._marker || this;
      let path;
      let previewLine;
      const pressPosition = { x: 0, y: 0};
      this.setCursor('pentool');

      // Hide current Model
      marker.state = 'hidden';
      marker._localPaper.view.update();


      const onPress = (event) => {
        pressPosition.x = event.originalEvent.pageX;
        pressPosition.y = event.originalEvent.pageY;
      }

      const onDblClick = () => {
        path.removeSegment(path._segments.length - 1);
        finishPath();
      }

      const onMove = (event) => {
        const absolutePoint = getAbsolutePoint(event.position);
        if (path?.firstSegment) {
          previewLine.lastSegment.point.x = absolutePoint.x;
          previewLine.lastSegment.point.y = absolutePoint.y;
          marker._localPaper.view.update();
        }

        if (event.originalEvent.altKey) {
          onRelease(event, true);
        }
      }

      const onRelease = (event, freeform = false) => {
        if (!freeform && (pressPosition.x - event.originalEvent.pageX) ** 2 + (pressPosition.y - event.originalEvent.pageY) ** 2 > 4 ** 2) {
          return;
        }
        const absolutePoint = getAbsolutePoint(event.position);
        
        if (path) {
          const factor = getFactor(marker);
          if (path._segments.length > 2) {
            const distToFirst = (absolutePoint.x - path.firstSegment.point.x) ** 2 + (absolutePoint.y - path.firstSegment.point.y) ** 2;
            if (distToFirst < (factor * 10) ** 2) {
              finishPath(true);
              return false;
            }
          }
          if (freeform) {
            const dist = (path.lastSegment.point.x - absolutePoint.x) ** 2 + (path.lastSegment.point.y - absolutePoint.y) ** 2;
            if (dist < (40 * factor) ** 2) {
              return;
            }
          }
           
          path.add(absolutePoint);
          marker._localPaper.view.update();
        } else {
          previewLine = new paper.Path.Line({
            from: [absolutePoint.x, absolutePoint.y],
            to: [absolutePoint.x, absolutePoint.y],
            strokeColor: 'black',
            strokeWidth: 2,
            fullySelected: true
          });
          path = new paper.Path({
            segments: [absolutePoint],
            strokeColor: 'black',
            strokeWidth: 2,
            fullySelected: true
          });
        }
        previewLine.firstSegment.point.x = absolutePoint.x;
        previewLine.firstSegment.point.y = absolutePoint.y;
      };

      const onKeyDown = (event: KeyboardEvent) => {
        switch (event.key) {
          case 'Alt':
            this.setCursor('pentool_free');
            viewer.setMouseNavEnabled(false);
            break;
          case 'Backspace':
          case 'Delete':
            removeLastPoint();
            break;
        }
      }

      const onKeyUp = (event: KeyboardEvent) => {
        switch (event.key) {
          case 'Escape':
          case 'Enter':
          case 'Space':
            finishPath();
            break;
          case 'Alt':
            event.preventDefault();
            this.setCursor('pentool');
            viewer.setMouseNavEnabled(true);
            break;
        }
      }

      const removeLastPoint = () => {
        if (path._segments.length > 1) {
          path.removeSegment(path._segments.length - 1);
          previewLine.firstSegment.point.x = path.lastSegment.point.x;
          previewLine.firstSegment.point.y = path.lastSegment.point.y;
          marker._localPaper.view.update();
        } else {
          path.remove();
          previewLine.remove();
          path = null;
          previewLine = null;
          marker._localPaper.view.update();
        }
      }

      const finishPath = (closePath = false) => {
        if (path?._segments?.[0]) {
          let firstpoint;
          for (const segment of path._segments) {
            const absolutePoint = segment._point;
            const container = this._container || marker._container;
            const point = container.getRelativeCoords(
              absolutePoint._x || absolutePoint.x,
              absolutePoint._y || absolutePoint.y,
              marker._imageOffset,
              marker._imageScale);
            pathArray.push({
                x: point.x,
                y: point.y
            });
            if (!firstpoint) {
              firstpoint = point;
            }
          }
  
          this._model.source.position.x = firstpoint.x;
          this._model.source.position.y = firstpoint.y;
          this._model.path = [...pathArray];
          this._model.freeFormStyle.closePath = closePath;
        }
        
        path?.remove();
        previewLine?.remove();
        marker.state = 'visible';
        this._tool.setTracking(false);

        setTimeout(() => {
          window.removeEventListener('keydown', onKeyDown);
          window.removeEventListener('keyup', onKeyUp);
          this.removeTool();
          resolve();
        }, 200);
      };

      const getFactor = (marker) => {
        return Math.max(1 / marker._localPaper.view.zoom, 1);
      }

      const getAbsolutePoint = (position) => {
        const point = new OpenSeadragon.Point(position.x, position.y);
        const viewportPoint = viewer.viewport.pointFromPixel(point);
        const currentImage = viewer.world.getItemAt(sequenceNumber ?? 0);
        return currentImage?.viewportToImageCoordinates(viewportPoint);
      }

      this.removeTool();
      this._tool = new OpenSeadragon.MouseTracker({
        element: viewer.canvas,
        pressHandler: onPress,
        releaseHandler: onRelease,
        moveHandler: onMove,
        dblClickHandler: onDblClick
      });

      window.addEventListener('keydown', onKeyDown);
      window.addEventListener('keyup', onKeyUp);
    });
  }

  removeTool() {
    if (this._tool) {
      this._tool.destroy();
      this.removeCursor();
    }
  }

  setCursor(type: keyof typeof cursors) {
    const dataURL = cursors[type];
    document.body.style.cursor = 'url(' + dataURL + '), auto';
  }

  removeCursor() {
    document.body.style.cursor = 'default';
  }
}

export default Drawable;
