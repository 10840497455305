import paper from 'paper';
import Drawable from '../drawable';

import End from '../marker/pointer/end/end';
import Label from '../marker/label/label';
import Pointer from '../marker/pointer/pointer';
import Line from '../marker/pointer/line';
import { FreeForm, TipType } from '@medsurf/models';
import Selection from '../marker/selection';

class InteractiveArea extends Drawable {
  private _segments: any[];
  private _labels: any[];
  private _pointers: any[];
  private _selection: any;
  private _forkLine: any;
  private _endType: any;
  private _end: End;


  constructor(protected _model: FreeForm, private _localPaper, private _format, public _marker) {
    super(_model);
    this._element = null;
    this._segments = [];
    this._labels = [];
    this._pointers = [];
    this._selection = null;
    this._forkLine = null;
    if (this._model.target) {
      this._endType = this._model.target.endType;
    }
    this._init();
  }

  _init() {
    this.draw();
  }

  draw() {
    if (this._model.deleted) {
      return;
    }
    this.cleanUp();
    this.drawPath();
    if (this._segments.length > 0) {
      if (this._model.label && !this._model.label.hidden) {
        this.drawPointers();
        this.drawForkLine();
        this.drawLabels();
      }
      this.drawSelection();
    }
    this.arrange();
  }

  drawPath() {
    let end;
    if (this._model.path && this._model.path.length > 1) {
      for (const pathPoint of this._model.path) {
        this._segments.push(new paper.Point(this._marker._container.getAbsoluteCoords(
          pathPoint.x,
          pathPoint.y,
          this._marker._imageOffset,
          this._marker._imageScale
        )));
      }
      end = new paper.Point(this._marker._container.getAbsoluteCoords(
        this._model.path[this._model.path.length - 1].x,
        this._model.path[this._model.path.length - 1].y,
        this._marker._imageOffset,
        this._marker._imageScale
      ));
    } else {
      return;
    }

    this._element = new paper.Path(this._segments);

    this._element.strokeColor = this._model.freeFormStyle.strokeColor || this._model.freeFormStyle.color || this._format.border.strokeColor;
    this._element.strokeWidth = (this._model.freeFormStyle.strokeWidth || this._format.border.strokeWidth) / this._marker._imageScale;
    this._element.dashArray = [1, 0];
    if (this._model?.freeFormStyle?.dash) {
      const [dashLength, gapLength]: string[] = this._model.freeFormStyle.dash.split(',');
      if (dashLength && gapLength) {
        const factor =  this._marker?._model?.freeFormStyle?.strokeWidth ? this._marker._model.freeFormStyle.strokeWidth : 1;
        this._element.dashArray = [
          Number.parseInt(dashLength, 10) * factor,
          Number.parseInt(gapLength, 10) * factor
        ];
      }
    }
    this._element._model = this._model;

    if (this._model.freeFormStyle.closePath) {
      this._element.closed = true;
      this._element.fillColor = this._model.freeFormStyle.background ?
        (this._model.freeFormStyle.backgroundInBorderColor ?
          this._model.freeFormStyle.strokeColor || this._model.freeFormStyle.color || this._format.border.strokeColor :
          this._model.freeFormStyle.color || this._format.border.fillColor) :
        undefined;
      if (this._element.fillColor) {
        this._element.fillColor.alpha = this._model.freeFormStyle.opacity;
      }
    }

    if (this._model.freeFormStyle.smooth) {
      this._element.smooth({
        type: 'geometric',
        factor: this._model.freeFormStyle.smoothFactor ?? 0
      });
    }

    this._element.freeform = this._marker;
    this._marker._element.addChild(this._element);

    if (!this._model.freeFormStyle.closePath) {
      this.drawEnd(end);
    }
  }

  drawEnd(end) {
    this._format.strokeColor = this._element.strokeColor;
    this._format.circleRadius = this._model.freeFormStyle.endRadius || 10;
    this._end = new End(this._endType, this._format, end, this._marker);

    if (this._endType === 'dot' || this._endType === 'arrow') {
      this._end.element.element.firstChild.freeform = this._marker;
      this._marker._element.addChild(this._end.element.element.firstChild);
      this._end.element.element.radius =
        this._end.element.element.radius + (this._model.freeFormStyle.strokeWidth || this._format.border.strokeWidth);
    } else {
      this._end.element.element.freeform = this._marker;
      this._marker._element.addChild(this._end.element.element);
    }

    if (this._endType === 'arrow') {
      this._end.element.path.rotation = 90 + this._end.element.angle(
        this._element.segments[this._element.segments.length - 2].point.x,
        this._element.segments[this._element.segments.length - 2].point.y,
        this._element.segments[this._element.segments.length - 1].point.x,
        this._element.segments[this._element.segments.length - 1].point.y
      );
    } else if (this._endType === 'circle') {
      const intersections = this._element.segments[0].path.getIntersections(this._end.element.element);
      if (intersections.length) {
        this._element.segments[this._element.segments.length - 1].point = intersections[0].point;
      }
      this._end.element.element.strokeWidth = this._model.freeFormStyle.strokeWidth || this._format.border.strokeWidth;
    }
  }

  drawSelection() {
    if (this._selection) {
      this.removeSelection();
    }
    this._selection = new Selection(this._model, this._format, this._localPaper, this._marker._container, this);
    this._marker._element.addChild(this._selection.element);
  }

  drawPointers() {
    let source = this._marker._container.getAbsoluteCoords(
      this._model.source.position.x,
      this._model.source.position.y,
      this._marker._imageOffset,
      this._marker._imageScale
    );
    if (this._model.fork.x && this._model.fork.y) {
      source = this._marker._container.getAbsoluteCoords(
        this._model.fork.x,
        this._model.fork.y,
        this._marker._imageOffset,
        this._marker._imageScale
      );
    }
    if (this._model.freeFormStyle.closePath && this._element.contains(source)) {
      return;
    }
    const target = this._element.getNearestPoint(new paper.Point(source));
    if (!this._model.target) {
      this._model.target = {
        endType: TipType.NONE,
        position: {
          x: 0.5,
          y: 0.5
        }
      };
    }
    const pointer = new Pointer(
      (this._model.target && this._model.target.endType) ? (this._model.target.endType) : 'none',
      this._format,
      source,
      target,
      this._marker
    );
    this._marker._element.addChild(pointer.end.element.element);
    this._marker._element.addChild(pointer.line.element);
    this._pointers.push(pointer);
  }

  drawForkLine() {
    if (this._model.fork.x && this._model.fork.y) {
      if (this._forkLine) {
        this.removeForkLine();
      }
      const source = this._marker._container.getAbsoluteCoords(
        this._model.source.position.x,
        this._model.source.position.y,
        this._marker._imageOffset,
        this._marker._imageScale
      );
      const target = this._marker._container.getAbsoluteCoords(
        this._model.fork.x,
        this._model.fork.y,
        this._marker._imageOffset,
        this._marker._imageScale
      );
      this._forkLine = new Line(this._format, source, target, this._marker);
      this._forkLine.element.marker = this;
      this._marker._element.addChild(this._forkLine.element);
    }
  }

  drawLabels() {
    if (!this._model.label) {
      return;
    }
    const label = new Label(this._model.label.text, this._format, this._marker);
    this._marker._element.addChild(label.element);
    this._labels.push(label);
  }

  get element() {
    return this._element;
  }

  setScale() {
    this.element.strokeWidth = this._format.strokeWidth;
  }

  removeLabels() {
    for (let label of this._labels) {
      label.cleanUp();
      label = null;
    }
    this._labels = [];
  }

  removePointers() {
    for (let pointer of this._pointers) {
      pointer.cleanUp();
      pointer = null;
    }
    this._pointers = [];
  }

  removeSelection() {
    if (this._selection) {
      this._selection.cleanUp();
      this._selection = null;
    }
  }

  removeForkLine() {
    if (this._forkLine) {
      this._forkLine.cleanUp();
      this._forkLine = null;
    }
  }

  cleanUp() {
    this.removeSelection();
    this.removeLabels();
    this.removePointers();
    this.removeForkLine();
    if (this._element) {
      this._element.remove();
      this._element = null;
      this._segments = [];
    }
  }

  arrange() {
    const zoom = this._localPaper.view.zoom;
    const coords = this._marker._container.getAbsoluteCoords(
      this._model.source.position.x,
      this._model.source.position.y,
      this._marker._imageOffset,
      this._marker._imageScale
    );
    let forkCoords = coords;
    this.scale(zoom);
    if (this._labels.length > 0) {
      this._labels[0].setPosition(coords.x, coords.y);
    }
    if (this._forkLine) {
      this._forkLine.setScale(zoom);
    }
    if (this._marker._isSelected) {
      if (this._selection) {
        if (this._model.fork.x && this._model.fork.y) {
          forkCoords = this._marker._container.getAbsoluteCoords(
            this._model.fork.x,
            this._model.fork.y,
            this._marker._imageOffset,
            this._marker._imageScale
          );
        }
        this._selection.setScale(zoom);
        if (this._selection.rectangle) {
          this._selection.rectangle.position = coords;
        }
        if (this._selection.circle) {
          this._selection.circle.position = forkCoords;
        }
        this._selection.arrange();
        this._selection.element.bringToFront();
      }
    } else {
      this.removeSelection();
    }
  }

  scale(zoom) {
    if (this._labels.length > 0) {
      this._labels[0].setScale(zoom);
    }
    for (const pointer of this._pointers) {
      pointer.setScale(zoom);
    }
  }
}

export default InteractiveArea;
