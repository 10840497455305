<div class="d-flex flex-col h-100 scrollable">
  <medsurf-navigation></medsurf-navigation>
  <div class="panel panel-default scrollable d-flex flex-col" *ngIf="metadata$ | async; let metadata">
    <div class="panel-heading">
      <button id="btn-append-to-to-body" title="Update Metadata" type="button" class="btn btn-secondary"
              (click)="updateMetadata(metadata)">
        {{ 'save_metadata' | translate }}
      </button>
    </div>
    <div class="panel-body panel-body-full-margin">
      <div class="list">

        <div class="card mb-5 mt-3">
          <div class="card-header bg-primary">
            <h3 class="text-secondary mb-0">{{ 'start_page' | translate }}
              <a *ngIf="startPageExpanded" (click)="startPageExpanded = !startPageExpanded">
                <span class="fa fa-minus-square float-right text-secondary"></span>
              </a>
              <a *ngIf="!startPageExpanded" (click)="startPageExpanded = !startPageExpanded">
                <span class="fa fa-plus-square float-right text-secondary"></span>
              </a>
            </h3>
          </div>
          <div class="card-body" [ngbCollapse]="!startPageExpanded">
            <form>
              <div class="form-group row m-2 mb-4">
                <label for="carouselTitle" class="col-sm-2 col-form-label">{{ 'title' | translate}}</label>
                <div class="col-sm-8">
                  <input type="text"
                         class="form-control"
                         id="carouselTitle"
                         name="carouselTitle"
                         placeholder="Titel"
                         [(ngModel)]="metadata.carouselTitle">
                </div>
              </div>
              <div class="form-group row m-2">
                <label for="carouselCopyright" class="col-sm-2 col-form-label">{{ 'copyright' | translate}}</label>
                <div class="col-sm-8">
                  <input type="text"
                         class="form-control"
                         id="carouselCopyright"
                         name="carouselCopyright"
                         placeholder="Copyright"
                         [(ngModel)]="metadata.carouselCopyright">
                </div>
              </div>
              <div class="form-group row m-2 mb-4">
                <label for="carouselTextColor" class="col-sm-2 col-form-label">{{ 'text_color' | translate}}</label>
                <div class="col-sm-8">
                  <medsurf-color-palette id="carouselTextColor2" name="carouselTextColor" [colorList]="defaultValues.colors" [(ngModel)]="metadata.carouselTextColor"></medsurf-color-palette>
                </div>
              </div>
              <div class="form-group row m-2 mb-4">
                <label for="carouselShowSubjects" class="col-sm-2 col-form-label">{{ 'show_subjects' | translate}}</label>
                <div class="col-sm-8">
                  <input id="carouselShowSubjects" name="carouselShowSubjects" type="checkbox" [(ngModel)]="metadata.carouselShowSubjects">
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="card mb-5">
          <div class="card-header bg-primary">
            <h3 class="text-secondary mb-0">{{ 'html_metadata' | translate }}
              <a *ngIf="htmlMetadataExpanded" (click)="htmlMetadataExpanded = !htmlMetadataExpanded">
                <span class="fa fa-minus-square float-right text-secondary"></span>
              </a>
              <a *ngIf="!htmlMetadataExpanded" (click)="htmlMetadataExpanded = !htmlMetadataExpanded">
                <span class="fa fa-plus-square float-right text-secondary"></span>
              </a>
            </h3>
          </div>
          <div class="card-body" [ngbCollapse]="!htmlMetadataExpanded">
            <form>
              <div class="form-group row m-2 mb-4">
                <label for="title" class="col-sm-2 col-form-label">{{ 'title' | translate}}</label>
                <div class="col-sm-8">
                  <input type="text"
                         class="form-control"
                         id="title"
                         name="title"
                         placeholder="Titel"
                         [(ngModel)]="metadata.title">
                </div>
              </div>
              <div class="form-group row m-2 mb-4">
                <label for="keywords" class="col-sm-2 col-form-label">{{ 'keywords' | translate}}</label>
                <div class="col-sm-8">
                <textarea type="text"
                          class="form-control"
                          id="keywords"
                          name="keywords"
                          placeholder="Keywords"
                          [(ngModel)]="metadata.keywords">
                </textarea>
                </div>
              </div>
              <div class="form-group row m-2 mb-4">
                <label for="description" class="col-sm-2 col-form-label">{{ 'description' | translate}}</label>
                <div class="col-sm-8">
                <textarea type="text"
                          class="form-control"
                          id="description"
                          name="description"
                          placeholder="Description"
                          [(ngModel)]="metadata.description">
                </textarea>
                </div>
              </div>
              <div class="form-group row m-2">
                <label for="support_email" class="col-sm-2 col-form-label">{{ 'support_email' | translate}}</label>
                <div class="col-sm-8">
                  <input type="email"
                         class="form-control"
                         id="support_email"
                         name="support_email"
                         placeholder="E-Mail"
                         [(ngModel)]="metadata.supportEmail">
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="card mb-1 mt-3">
          <div class="card-header bg-primary">
            <h3 class="mb-0">{{ 'start_image' | translate }}</h3>
          </div>
          <div class="card-body position-relative">
            <div class="btn-toolbar position-absolute m-2" *ngIf="metadata.carouselMedia">
              <div class="btn-group mr-2">
                <button (click)="selectMedia(metadata)" class="btn btn-secondary">{{ 'exchange_image' | translate }}</button>
              </div>
              <div class="btn-group">
                <button (click)="removeMedia(metadata)" class="btn btn-primary">{{ 'image' | translate }} {{ 'remove' | translate }}</button>
              </div>
            </div>
            <img class="carousel-image mb-2" *ngIf="metadata.carouselMedia" [src]="mediaService.getUrl(metadata.carouselMedia)" [alt]="metadata.carouselMedia.altText">
            <div *ngIf="!metadata.carouselMedia">
              <label class="mr-3">{{"choose_an_image_file" | translate}}</label>
              <button class="btn btn-secondary" (click)="selectMedia(metadata)" translate="choose"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


