import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'medsurf-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {

  @Input() title: string;

  public isMinimized = false;

  ngOnInit() {
    this.isMinimized = localStorage.getItem(this.key) === 'true';
  }

  toggleMinimized() {
    this.isMinimized = !this.isMinimized;
    localStorage.setItem(this.key, this.isMinimized.toString());
  }

  private get key() {
    return 'settings.section.' + this.title + '.isMinimized';
  }
}
