<ul ngbNav [activeId]="activePanel" #panelNav="ngbNav" class="nav-tabs">

  <li [ngbNavItem]="'panel.settings'">
    <a ngbNavLink>{{ 'slide' | translate }}</a>
    <ng-template ngbNavContent class="panelContent h-100">
      <ul ngbNav [activeId]="activeSetting" #settingsNav="ngbNav" class="nav-tabs">

        <li [ngbNavItem]="'metadata'">
          <a ngbNavLink>{{ 'metadata' | translate }}</a>
          <ng-template ngbNavContent class="panelContent h-100">
            <medsurf-metadata 
              [props]="props"
              [slide]="slide"
              [user]="user"
              [users]="users"
              [path]="path"
              [sequenceId]="sequenceId"
              [layerId]="layerId"
              (localDeleteSlide)="deleteSlide.emit()"
            ></medsurf-metadata>
          </ng-template>
        </li>

        <li [ngbNavItem]="'media'">
          <a ngbNavLink>{{ 'media' | translate }}</a>
          <ng-template ngbNavContent class="panelContent h-100">
            <medsurf-media
              [slide]="slide"
              [sequenceId]="sequenceId"
              [layerId]="layerId"
              [props]="props"
              (changeMedia)="changeMedia.emit()"
            ></medsurf-media>
          </ng-template>
        </li>

        <li [ngbNavItem]="'defaults'">
          <a ngbNavLink>{{ 'defaults' | translate }}</a>
          <ng-template ngbNavContent class="panelContent h-100">
            <medsurf-defaults [slide]="slide"></medsurf-defaults>
          </ng-template>
        </li>

      </ul>
      <div [ngbNavOutlet]="settingsNav"></div>
    </ng-template>
  </li>

  <li [ngbNavItem]="'panel.annotations'">
    <a ngbNavLink>{{ 'annotations' | translate}}</a>
    <ng-template ngbNavContent class="panelContent h-100">
      <medsurf-list
        [marker]="marker"
        [slide]="slide"
        [sequenceId]="sequenceId"
        [layerId]="layerId"
        [currentPosition]="position$ | async"
        (setHoverMarker)="setHoverMarker($event)"
        (activateFreeTool)="activateFreeTool.emit($event)"
        (setMarker)="setMarker($event)"
      ></medsurf-list>
    </ng-template>
  </li>

  <li [ngbNavItem]="'panel.pois'">
    <a ngbNavLink>{{ 'pois' | translate}}</a>
    <ng-template ngbNavContent class="panelContent h-100">
      <medsurf-pois
        [marker]="marker"
        [slide]="slide"
        [sequenceId]="sequenceId"
        [layerId]="layerId"
        [currentPosition]="position$ | async"
        [currentBounds]="bounds$ | async"
        (goToPoi)="goToPoi.emit($event)"
        (setHoverMarker)="setHoverMarker($event)"
      ></medsurf-pois>
    </ng-template>
  </li>

  <li [ngbNavItem]="'panel.groups'">
    <a ngbNavLink>{{ 'groups' | translate}}</a>
    <ng-template ngbNavContent class="panelContent h-100">
      <medsurf-groups
        [marker]="marker"
        [slide]="slide"
        [sequenceId]="sequenceId"
        [layerId]="layerId"
        [currentPosition]="position$ | async"
        (setHoverMarker)="setHoverMarker($event)"
      ></medsurf-groups>
    </ng-template>
  </li>

</ul>
<div [ngbNavOutlet]="panelNav"></div>
