<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ 'edit_quiz_question' | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ngb-alert type="danger" (close)="closeAlert()" *ngIf="!!alert">{{ alert }}</ngb-alert>
  <form class="form">
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" translate="question_type"></label>
      <div class="col-sm-10">
        <select id="questionType" name="questionType"
                class="form-control input-md" #questionType
                [value]="question.type" (change)="questionTypeChanged(questionType.value)">
          <optgroup>
            <option [hidden]="question.type !== 'SingleChoiceQuestion'" value="SingleChoiceQuestion">{{ 'SingleChoiceQuestion' | translate }}</option>
            <option value="LongListQuestion">{{ 'LongListQuestion' | translate }}</option>
          </optgroup>
          <optgroup>
            <option [hidden]="question.type !== 'KprimeQuestion'" value="KprimeQuestion">{{ 'KprimeQuestion' | translate }}</option>
            <option value="TrueFalseQuestion">{{ 'TrueFalseQuestion' | translate }}</option>
          </optgroup>
          <optgroup>
            <option value="FreeTextQuestion">{{ 'FreeTextQuestion' | translate }}</option>
          </optgroup>
          <optgroup>
            <option value="IndicationQuestion">{{ 'IndicationQuestion' | translate }}</option>
          </optgroup>
          <optgroup>
            <option value="MappingQuestion">{{ 'MappingQuestion' | translate }}</option>
          </optgroup>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" translate="description"></label>
      <label class="col-sm-10 col-form-label">{{ question.type + '_description' | translate }}</label>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" translate="required_question"></label>
      <div class="col-sm-10">
        <input name="required" type="checkbox" class="h-100" [(ngModel)]="question.required"
               (change)="onChange()">
      </div>
    </div>
    <div class="form-group row" *ngIf="question.type === 'KprimeQuestion' || question.type === 'SingleChoiceQuestion'">
      <label class="col-sm-2 col-form-label" translate="shuffle_choices"></label>
      <div class="col-sm-10">
        <input name="shuffle" type="checkbox" class="h-100" [(ngModel)]="question.randomised"
               (change)="onChange()">
      </div>
    </div>
    <div class="form-group row" *ngIf="question.type === 'LongListQuestion' || question.type === 'MappingQuestion'">
      <label class="col-sm-2 col-form-label" translate="placeholder"></label>
      <div class="col-sm-10">
        <input name="placeholder" type="input" class="form-control"
               [placeholder]="'input_text' | translate"
               [(ngModel)]="question.placeholder"
               (change)="onChange()">
      </div>
    </div>
    <div class="form-group row"
         *ngIf="question.type === 'LongListQuestion' || question.type === 'SingleChoiceQuestion' || question.type === 'KprimeQuestion' || question.type === 'TrueFalseQuestion'">
      <label class="col-sm-2 col-form-label" translate="numbering_type"></label>
      <div class="col-sm-10">
        <select id="numberingType" name="numberingType"
                class="form-control input-md"
                [(ngModel)]="question.numberingType" (change)="onChange()">
          <option value="A">{{ 'letter' | translate }}</option>
          <option value="1">{{ 'number' | translate }}</option>
          <option value="I">{{ 'roman_number' | translate }}</option>
          <option value="None">{{ 'none' | translate }}</option>
        </select>
      </div>
    </div>
  </form>
</div>
