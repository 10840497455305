<div class="mb-3">{{'annotations' | translate}}</div>
<div class="annotaiton-container" (dragulaModelChange)="changeEmitter.emit()">
  <div class="tree-node-styled dark align-items-center" *ngFor="let annotation of group.annotations">
    <span class="mr-auto" *ngIf="panelService.getAnnotationInfo(annotation) as info">
      <small>{{ info.type }}</small><br>
      <strong>{{ info.title }}</strong>
    </span>

    <button class="btn btn-sm btn-secondary ml-2" *ngIf="imageAnnotationSet.has(annotation.id)" (click)="goToAnnotation(annotation)">
      <span class="fa fa-fw fa-location"></span>
    </button>

    <button class="btn btn-sm btn-danger ml-2" (click)="removeMarkerFromGroup(annotation)">
      <span class="fa fa-fw fa-times" title="{{ 'delete_entry_description' | translate }}"></span>
    </button>
  </div>
</div>

<div class="form-row">
  <div class="input-group mb-3 mx-3">
    <div ngbDropdown container="body" class="d-block">
      <button class="btn btn-secondary" ngbDropdownToggle [id]="'group-'+ group.id +'-add'">
        {{ 'add_to_group' | translate }}
      </button>
      <div ngbDropdownMenu>
        <button ngbDropdownItem *ngFor="let annotation of slide.annotations" [disabled]="groupAnnotationSet.has(annotation.id)" (click)="addMarkerToGroup(annotation)">
          <ng-container *ngIf="panelService.getAnnotationInfo(annotation) as info">
            <span>{{ info.title }}</span>
          </ng-container>
        </button>
      </div>
    </div>
  </div>
</div>
