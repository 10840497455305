<div class="layout-container">
  <medsurf-navigation
    [page]="currentItem?.page"
    [message]="message">
  </medsurf-navigation>
  <div class="panel panel-default flex-col">
    <div class="panel-heading">
      <div>
        <div class="btn-group">
          <button type="button" class="btn btn-primary" (click)="handleUndo()" [disabled]="(canUndo$ | async) === false">
            <span class="fa fa-fw fa-undo"></span>
          </button>
          <button type="button" class="btn btn-primary" (click)="handleRedo()" [disabled]="(canRedo$ | async) === false">
            <span class="fa fa-fw fa-redo"></span>
          </button>
        </div>
        <button type="button" class="btn btn-primary ml-3" (click)="gotoSlideDetail()">
          <span>{{ 'preview' | translate }}</span>
        </button>
      </div>
      <div>
        <button id="btn-new-elearning" title="{{ 'new_elearning_description' | translate }}" type="button"
                class="btn btn-primary mr-3" *ngIf="!isBlockSelected" (click)="newElearning()"
                medsurfRestrict access="admin">
          <span class="fa fa-fw fa-plus"></span> <span>{{ 'new_elearning' | translate }}</span>
        </button>
        <button id="btn-new-slide" title="{{ ((isIndexDirty$ | async) || (isSlidesDirty$ | async) ? 'save_first' : 'new_slide_description') | translate }}"  type="button"
                class="btn btn-primary mr-3" *ngIf="isBlockSelected" (click)="newSlide(false)"
                [disabled]="isCurrentItemLocked || (isIndexDirty$ | async) || (isSlidesDirty$ | async)">
          <span class="fa fa-fw fa-plus"></span> <span>{{ 'new_slide' | translate }}</span>
        </button>
        <button id="btn-new-quiz" title="{{ ((isIndexDirty$ | async) || (isSlidesDirty$ | async) ? 'save_first' : 'new_training_description') | translate }}" type="button"
                class="btn btn-primary mr-3" *ngIf="isBlockSelected" (click)="newSlide(true)"
                [disabled]="isCurrentItemLocked || (isIndexDirty$ | async) || (isSlidesDirty$ | async)">
          <span class="fa fa-fw fa-plus"></span> <span>{{ 'new_training' | translate }}</span>
        </button>
        <div id="input-group-container">
          <div class="input-group">
            <ng-container *ngIf="isBlockSelected">
              <medsurf-multiselect
                [regexFilter]="'[0-9]'"
                [(selectedItems)]="bulkSelectedSlideIds"
                [placeholder]="'Slide Ids'"
              ></medsurf-multiselect>

              <div class="input-group-append" ngbDropdown>
                <button id="btn-append-to-to-body" type="button" class="btn btn-primary" ngbDropdownToggle>
                  {{ 'slide_actions' | translate }} <span class="caret"></span>
                </button>
                <div ngbDropdownMenu role="menu" aria-labelledby="btn-append-to-to-body">
                  <button (click)="linkSlides(bulkSelectedSlideIds)" ngbDropdownItem [disabled]="bulkSelectedSlideIds.length === 0">
                    {{ 'add_slides' | translate }}
                  </button>
                  <button (click)="unlinkSlides(bulkSelectedSlideIds)" ngbDropdownItem [disabled]="bulkSelectedSlideIds.length === 0">
                    {{ 'remove_slides' | translate }}
                  </button>
                  <div class="dropdown-divider"></div>
                  <button (click)="copySlides(bulkSelectedSlideIds, false, false)" ngbDropdownItem [disabled]="bulkSelectedSlideIds.length === 0">
                    {{ 'copy_slides' | translate }}
                  </button>
                  <button (click)="copySlides(bulkSelectedSlideIds, true, false)" ngbDropdownItem [disabled]="bulkSelectedSlideIds.length === 0">
                    {{ 'copy_slides_with_annotation' | translate }}
                  </button>
                  <button (click)="copySlides(bulkSelectedSlideIds, false, true)" ngbDropdownItem [disabled]="bulkSelectedSlideIds.length === 0">
                    {{ 'copy_slides_as_training' | translate }}
                  </button>
                  <button (click)="copySlides(bulkSelectedSlideIds, true, true)" ngbDropdownItem [disabled]="bulkSelectedSlideIds.length === 0">
                    {{ 'copy_slides_as_training_with_annotation' | translate }}
                  </button>
                  <div class="dropdown-divider"></div>
                  <button [class.text-danger]="bulkSelectedSlideIds.length !== 0" (click)="deleteSlides(bulkSelectedSlideIds)" ngbDropdownItem [disabled]="bulkSelectedSlideIds.length === 0">
                    {{ 'delete_slides' | translate }}
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-body panel-body-noborders flex-grow">
      <div class="mdsf-chapter-container" *ngIf="(subjects$ | async)?.length > 0; else spinner">
        <medsurf-sidebar [currentItem]="currentItem" (selectItem)="selectItem($event)"></medsurf-sidebar>
        <section class="chapters" *ngIf="currentItem" medsurfAutoScroll>
          <div class="container-main" [class.locked]="editLocked(currentItem)">
            <medsurf-chapters-main #chaptersMain
                                   [isBlockSelected]="isBlockSelected"
                                   [currentItem]="currentItem"
                                   [bulkSelectedSlideIds]="bulkSelectedSlideIds"
                                   (handleIndexProperties)="handleIndexProperties()"
                                   (selectBulk)="handleBulkSelection($event)"
                                   (selectItem)="selectItem($event)">
            </medsurf-chapters-main>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>

<ng-template #spinner>
  <medsurf-spinner></medsurf-spinner>
</ng-template>


<!-- Bulk slide remove confirmation modal -->
<ng-template #ConfirmBulkRemoveDialog let-modal>
  <header class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">{{ 'bulk_remove_slides' | translate }}</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Button')">
          <span aria-hidden="true">&times;</span>
      </button>
  </header>
  <div class="modal-body">
    <p>{{ 'bulk_remove_slides_confirm' | translate }}</p>

    <ngb-accordion #acc="ngbAccordion">
      <ngb-panel type="light" *ngFor="let toDelete of slidesToDelete">
        <ng-template ngbPanelTitle>
          <img *ngIf="toDelete.slide.thumbnailMedia"
               src="{{ mediaService.getUrl(toDelete.slide.thumbnailMedia, 'thumbnail') }}"
               class="thumbnail-sm mr-3"
               [alt]="toDelete.slide.thumbnailMedia.altText">
          <div class="thumbnail-sm border bg-primary text-dark mr-3" *ngIf="!toDelete.slide.thumbnailMedia">
            <span [class]="'fa ' + mediaService.getMediaTypeIcon({ type: toDelete.slide.mediaType })"></span>
          </div>
          <span>{{ toDelete.slide.title }}</span>
        </ng-template>
        <ng-template ngbPanelContent *ngIf="toDelete.nodes; let related">
          <ng-container *ngIf="related.length > 0">
            <p>{{ 'media_remove_confirm_with_links' | translate }}</p>
            <ul class="ml-4">
              <li *ngFor="let node of related">
                  <a target="_blank" [href]="'/node/' + node.id">{{ node.page.title }}</a>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="related.length === 0">
            <p>{{ 'is_not_used' | translate }}</p>
          </ng-container>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <footer class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">{{ 'cancel' | translate }}</button>
      <button type="button" class="btn btn-danger" (click)="modal.close()">{{ 'delete' | translate }}</button>
  </footer>
</ng-template>
