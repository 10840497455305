import { Component, OnDestroy, OnInit } from '@angular/core';
import { GetReleaseNotes } from '@medsurf/actions';
import { Release } from '@medsurf/models';
import { ReleaseNotesState } from '@medsurf/state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { version } from '../../environments/version';

@Component({
  selector: 'medsurf-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss']
})
export class ChangelogComponent implements OnInit, OnDestroy {

  private _destroyed = new Subject<boolean>();

  @Select(ReleaseNotesState.releases)
  public releases$: Observable<Release[]>;

  public releases: Release[];
  public currentRelease: Release;
  public isLoading = false;

  constructor(
    public store: Store
  ) {}

  ngOnInit(): void {
    this.releases = [];
    this.releases$.pipe(takeUntil(this._destroyed)).subscribe((releases) => {
      this.releases.push(...releases);
      this.isLoading = false;
      this.onPushReleases();
    })
    this.store.dispatch(new GetReleaseNotes(version.version));
    this.isLoading = true;

    // also load legacy releases that are not in gitlab
    fetch('/assets/CHANGELOG.json').then(async(res) => {
      const data = await res.json();
      this.releases.push(...data.releases);
      this.onPushReleases();
    })
  }

  public isActive(currentRelease: Release, release: Release) {
    return new Date(release.released_at).getTime() <= new Date(currentRelease?.released_at).getTime();
  }

  private onPushReleases() {
    this.releases.sort((a, b) => new Date(b.released_at).getTime() - new Date(a.created_at).getTime());
    const release = this.releases.find(r => {
      const tag = r.tag_name.replace(/[^0-9.]/g, '');
      return tag === version.version;
    })
    this.currentRelease = release;
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
