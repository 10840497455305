import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { BlockNode } from '@medsurf/models';
import { SlideEvent } from '../grid/grid.component';
import { MediaService, SETTINGS, Settings } from '@medsurf/services';

@Component({
  selector: 'medsurf-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent {
  @Output() selectSlide = new EventEmitter<SlideEvent>();
  @Output() selectBulk = new EventEmitter<SlideEvent>();

  @Input() selectedBlock: BlockNode;
  @Input() bulkSelectedSlideIds: string[];

  @ViewChild('table')
  private table: ElementRef;

  constructor(
    @Inject(SETTINGS) private settings: Settings,
    public mediaService: MediaService
  ) {
  }

  public scrollToId(id: string): void {
    const htmlElement: Element = (this.table.nativeElement as HTMLTableElement).children.namedItem('table-body').children.namedItem(id);
    htmlElement?.scrollIntoView();
  }

  bulkSelect(event, slide): void {
    event.stopPropagation();
    this.selectBulk.emit({slide});
  }

  toggleBulkSelectAll(event: Event): void {
    if ((event.target as any).checked) {
      for (const slide of this.selectedBlock.children) {
        const slideId = slide.page.slideId.toString();
        if (!this.bulkSelectedSlideIds.includes(slideId)) {
          this.selectBulk.emit({slide});
        }
      }
    } else {
      for (const slide of this.selectedBlock.children) {
        const slideId = slide.page.slideId.toString();
        if (this.bulkSelectedSlideIds.includes(slideId)) {
          this.selectBulk.emit({slide});
        }
      }
    }
  }

  isBulkSelected(slide: any): boolean {
    if (slide?.page?.slideId) {
      const slideId = slide.page.slideId.toString();
      return this.bulkSelectedSlideIds && !!this.bulkSelectedSlideIds.includes(slideId);
    } else {
      return false;
    }
  }

}
