import Bitmap from './../image/elements/bitmap/bitmap';
import Freeform from './../image/elements/freeform/freeform';
import Tool from './tool';
import Position from './../helpers/position';
import KeymapSelftest from '../image/elements/marker/keymapSelftest';
import { AnnotationType, Shape, FreeForm } from '@medsurf/models';

let _preventMoving;

/**
 * MarkerTool class
 */
class MarkerTool extends Tool {
  private sequenceId: number;
  private layerId: number;
  private self: this;
  private _hitOptions: { fill: boolean; stroke: boolean; tolerance: number; segments: boolean };
  private _cmdPressed: boolean;
  private _ctrlPressed: boolean;
  private _segment: any;
  private _lastPoint: any;
  private _hitResults: any;
  private _hitResult: any;
  private _lockimage: any;
  private _startPoints: any;

  /**
   * Constructor
   *
   * @param localPaper
   * @param container
   * @param slide
   * @param _command
   * @param _imageScale
   * @param _imageOffset
   */
  constructor(public localPaper, container, public slide, private _command, private _imageScale = 1, private _imageOffset?) {
    super('MarkerTool', localPaper, null, null, null, container);
    this._hitOptions = {
      segments: true,
      stroke: true,
      fill: true,
      tolerance: 5
    };
    this._init();
    this.sequenceId = 0;
    this.layerId = 0;
  }

  /**
   * Init
   *
   * @private
   */
  _init() {
    super._init();
    this._cmdPressed = false;
    this._ctrlPressed = false;
    this._tool.self = this;
    this._tool.on({
      mousedown: this._handleMouseDown,
      mousedrag: this._handleMouseDrag,
      mouseup: this._handleMouseUp,
      keyup: this._keyUp.bind(this),
      keydown: this._keyDown.bind(this)
    });
  }

  /**
   * Event kesy down
   *
   * @param event
   * @private
   */
  _keyDown({event}) {
    if (event.target === document.body || !event.target.contains(this.localPaper.view.element)) {
      return;
    }
    if (event.keyCode === 91) {
      this._cmdPressed = true;
    }
    if (event.keyCode === 17) {
      this._ctrlPressed = true;
    }
    this._updateSelectedItems(event);
  }

  /**
   * Command move label
   *
   * @param marker
   * @param value
   * @private
   */
  _commandMoveLabel(marker, value) {
    this._command.doCommand(function (data) {
      marker.model.source.position = value;
      return data;
    }, 'moveLabel');
    this.emit('updateMarker');
  }

  /**
   * Command move target
   *
   * @param marker
   * @param index
   * @param value
   * @private
   */
  _commandMoveTarget(marker, index, value) {
    this._command.doCommand(function (data) {
      marker.model.targets[index].position.x += value.x;
      marker.model.targets[index].position.y += value.y;
      return data;
    }, 'moveTarget');
    this.emit('updateMarker');
  }

  /**
   * Command move image
   *
   * @param image
   * @param value
   * @private
   */
  _commandMoveImage(image, value) {
    this._command.doCommand(function (data) {
      image.offset = value;
      return data;
    }, 'moveImage');
    this.emit('updateMarker');
  }

  /**
   * Command move fork
   *
   * @param marker
   * @param value
   * @private
   */
  _commandMoveFork(marker, value) {
    this._command.doCommand(function (data) {
      marker.model.fork = value;
      return data;
    }, 'moveFork');
    this.emit('updateMarker');
  }

  /**
   * Command move pointer
   *
   * @param marker
   * @param value
   * @private
   */
  _commandMovePointer(marker, value) {
    this._command.doCommand(function (data) {
      for (const target of marker.model.targets) {
        target.x += value.x;
        target.y += value.y;
      }
      if (marker.model.label) {
        marker.model.source.position.x += value.x;
        marker.model.source.position.y += value.y;
      }
      if (marker.model.fork.x && marker.model.fork.y) {
        marker.model.fork.x += value.x;
        marker.model.fork.y += value.y;
      }
      return data;
    }, 'movePointer');
    this.emit('updateMarker');
  }

  /**
   * Command move keymap
   *
   * @param keymap
   * @param value
   * @private
   */
  _commandMoveKeymap(keymap, value) {
    if (keymap instanceof KeymapSelftest) {
      this._command.doCommand(function (data) {
        keymap.model.addSelftest(value);
        return data;
      }, 'moveKeymapSelftest');
    } else {
      this._command.doCommand(function (data) {
        keymap.model.source.position.x += value.x;
        keymap.model.source.position.y += value.y;
        return data;
      }, 'moveKeymap');
    }
    this.emit('updateMarker');
  }

  /**
   * Command move object
   *
   * @param object
   * @param value
   * @param relativePos
   * @private
   */
  _commandMoveObject(object, value, relativePos) {
    this._command.doCommand((data) => {
      this._addSource(object.model, value);
      return data;
    }, 'moveObject');
    this.emit('updateMarker');
  }

  _addSource(freeForm: FreeForm, value) {
    if (freeForm.shape === 'free' || freeForm.shape === 'interactiveArea') {
      freeForm.path.map(pathPoint => {
        pathPoint.x += this._round(value.x, 4);
        pathPoint.y += this._round(value.y, 4);
      });
    } else {
      freeForm.source.position.x += this._round(value.x, 4);
      freeForm.source.position.y += this._round(value.y, 4);
    }
  }

  _round(number, precision) {
    const factor = Math.pow(10, precision);
    const tempNumber = number * factor;
    const roundedTempNumber = Math.round(tempNumber);
    return roundedTempNumber / factor;
  }

  /**
   * Command move segment
   *
   * @param freeform
   * @param relativeDelta
   * @param relativePos
   * @private
   */
  _commandMoveSegment(freeform, relativeDelta, relativePos) {
    this._command.doCommand((data) => {
      this._moveSegment(freeform.model, relativeDelta, relativePos);
      return data;
    }, 'moveSegment');
    this.emit('updateMarker');
  }

  /**
   * Command resize freeform
   *
   * @param freeform
   * @param value
   * @private
   */
  _commandResizeFreeform(freeform, value) {
    this._command.doCommand((data) => {
      this._resize(freeform.model, value);
      return data;
    }, 'resizeFreeform');
    this.emit('updateMarker');
  }

  _resize(freeForm: FreeForm, value) {
    freeForm.freeFormStyle.width += this._round(value.x, 4);
    freeForm.freeFormStyle.height += this._round(value.y, 4);
  }

  _moveSegment(freeForm: FreeForm, value, index) {
    if (freeForm.shape === 'free' || freeForm.shape === 'interactiveArea') {
      if (freeForm.path[index]) {
        freeForm.path[index].x += this._round(value.x, 4);
        freeForm.path[index].y += this._round(value.y, 4);
      }
    } else {
      if (index === 0) {
        this._addSource(freeForm, value);
      } else {
        this._resize(freeForm, value);
      }
    }
  }

  /**
   * Command move multiple items
   *
   * @param elements
   * @param value
   * @private
   */
  _commandMoveMultibleItems(elements, value) {
    this._command.doCommand(function (data) {
      for (const element of elements) {
        if (element instanceof Bitmap) {
          if (element.isMovable) {
            element.model.offset.x += value.x;
            element.model.offset.y += value.y;
          }
        } else if (element instanceof Freeform) {
          if (element.model.addSource) {
            element.model.addSource(value);
          }
        } else {
          element.model.source.position.x += value.x;
          element.model.source.position.y += value.y;
          if (element.model.targets) {
            for (const target of element.model.targets) {
              target.x += value.x;
              target.y += value.y;
            }
          }
          if (element.model.fork.x && element.model.fork.y) {
            element.model.fork.x += value.x;
            element.model.fork.y += value.y;
          }
        }
      }
      return data;
    }, 'moveMultibleItems');
    this.emit('updateMarker');
  }

  /**
   * Update selected items
   *
   * @param event
   * @private
   */
  _updateSelectedItems(event) {
    const multiplier = (event.shiftKey) ? 0.1 : 0.01;
    const step = multiplier / (this._container.width / this.localPaper.view.size.width);
    let stepX = 0,
      stepY = 0;
    if (event.keyCode === 38) {
      stepY = -step;
    }
    if (event.keyCode === 40) {
      stepY = step;
    }
    if (event.keyCode === 39) {
      stepX = step;
    }
    if (event.keyCode === 37) {
      stepX = -step;
    }
    if (this._container.selection.length > 0) {
      this.moveMultibleItems(stepX, stepY);
    }
  }


  /**
   * Move multiple items
   *
   * @param stepX
   * @param stepY
   */
  moveMultibleItems(stepX, stepY) {
    const selectedItems = this._container.selection,
      relativeDelta = this._container.getRelativeDelta(
        stepX,
        stepY
      );
    this._commandMoveMultibleItems(selectedItems, relativeDelta);
  }

  /**
   * Event keyup
   *
   * @private
   */
  _keyUp() {
    this._cmdPressed = false;
  }

  /**
   * Handle mouse down
   *
   * @param event
   * @private
   */
  _handleMouseDown(event) {
    this._segment = null;
    this._lastPoint = this.self._localPaper.view.projectToView(event.point);

    this._hitResults = this.self._localPaper.project.hitTestAll(event.point, this.self._hitOptions);
    this._hitResult = this._hitResults.find(h => h.item && h.item.marker && h.item.marker._model.type === AnnotationType.MARKER);
    if (!this._hitResult) {
      this._hitResult = this._hitResults[0];
    }
    this._segment = this._hitResult;

    const addToSelection = !!event.event.shiftKey;
    if (!this._hitResult) {
      this.self.emit('unselectMarker');
      return;
    }
    if (this.self._container.allSelected && this._hitResult.type !== 'fill') {
      return;
    }
    if (this.self._container.selection.length > 1 && (this._hitResult.type !== 'fill' && this._hitResult.type !== 'pixel')) {
      return;
    }
    const marker =
      (this._hitResult.item && this._hitResult.item.marker && this._hitResult.item.marker._model.type === AnnotationType.MARKER) ?
        this._hitResult.item.marker :
        null;
    const keymap = (this._hitResult.item && this._hitResult.item.keymap) ? this._hitResult.item.keymap : null;
    let freeform = (this._hitResult.item && this._hitResult.item.freeform) ? this._hitResult.item.freeform : null;

    // TODO: Refactoring; isDirty hack for freeform labels
    // isDirty hack for freeform labels, freeform label inherits from markerElement class (class variable is this._marker)
    freeform =
      (this._hitResult.item && this._hitResult.item.marker && this._hitResult.item.marker._model.type === AnnotationType.FREE_FORM) ?
        this._hitResult.item.marker :
        freeform;

    switch (this._hitResult.type) {
      case 'fill':
        if (!(marker || keymap || freeform)) {
          this.self.emit('unselectMarker');
          return;
        }
        this._hitResult.item.parent.bringToFront();
        if (marker && marker.model.targets && marker.model.targets.length > 0) {
          this.self._startPoints = this.self._container.getAbsoluteCoords(
            marker.model.targets[0].position.x,
            marker.model.targets[0].position.y
          );
        }
        if (marker) {
          this.self.emit('selectMarker', {
            item: marker,
            add: addToSelection
          });
        } else if (keymap) {
          this.self.emit('selectKeymap', {
            item: keymap,
            add: addToSelection
          });
        } else if (freeform) {
          this.self.emit('selectFreeform', {
            item: freeform,
            add: addToSelection
          });
        }
        break;

      case 'pixel':
        // eslint-disable-next-line no-case-declarations
        const image = this.self._container.getElementById('image');
        if (image.isMovable) {
          this.self.emit('selectImage');
        } else {
          this.self.emit('unselectMarker');
        }
        break;

      case 'stroke':
      case 'segment':
        if (!(marker || keymap || freeform)) {
          return;
        }
        this._hitResult.item.parent.bringToFront();
        if (marker) {
          this.self.emit('selectMarker', {
            item: marker,
            add: addToSelection
          });
        } else if (keymap) {
          this.self.emit('selectKeymap', {
            item: keymap,
            add: addToSelection
          });
        } else if (freeform) {
          this.self.emit('selectFreeform', {
            item: freeform,
            add: addToSelection
          });
        }
        break;
      default:
        if (marker && marker.isMovable) {
          this.self.emit('selectMarker', {
            item: this._hitResult.item.class,
            add: addToSelection
          });
        } else {
          this.self.emit('unselectMarker');
        }
        break;
    }
  }

  /**
   * Handle mouse drag
   *
   * @param event
   * @private
   */
  _handleMouseDrag(event) {
    if (!this._hitResult) {
      return;
    }
    const marker = (this._hitResult.item && this._hitResult.item.marker) ? this._hitResult.item.marker : null;
    const keymap = (this._hitResult.item && this._hitResult.item.keymap) ? this._hitResult.item.keymap : null;
    let freeform = (this._hitResult.item && this._hitResult.item.freeform) ? this._hitResult.item.freeform : null;

    // TODO: Refactoring; isDirty hack for freeform labels
    // isDirty hack for freeform labels, freeform label inherits from markerElement class (class variable is this._marker)
    freeform =
      (this._hitResult.item && this._hitResult.item.marker && this._hitResult.item.marker._model.type === AnnotationType.FREE_FORM) ?
        this._hitResult.item.marker :
        freeform;

    if (this.self._container.selection.length > 1) {
      this.self.emit('moveItem', {
        item: undefined,
      });
      this.self.moveMultibleItems(event.delta.x, event.delta.y);
      return;
    }

    switch (this._hitResult.type) {
      case 'stroke':
        if (this._segment.item.data.selectionType === 'label') {
          if (marker || freeform) {
            this.self.emit('moveItem', {
              item: marker || freeform,
            });
            this.self.moveLabel(marker || freeform, event);
          }
        } else if (this._segment.item.data.selectionType === 'segment') {
          if (freeform) {
            this.self.emit('moveItem', {
              item: freeform,
            });
            this.self.moveSegment(freeform, event, this._hitResult.item.positionInPath);
          }
        } else if (this._segment.item.data.selectionType === 'resize') {
          this.self.emit('moveItem', {
            item: freeform,
          });
          this.self.resizeFreeform(freeform, event);
        } else if (keymap) {
          this.self.emit('moveItem', {
            item: keymap,
          });
          this.self.moveKeymap(keymap, event);
        } else if (freeform) {
          this.self.emit('moveItem', {
            item: freeform,
          });
          this.self.moveObject(freeform, event, this._hitResult.item.positionInPath);
        } else {
          if (marker || freeform) {
            this.self.emit('moveItem', {
              item: marker || freeform,
            });
            this.self.movePointer(marker || freeform, event);
          }
        }
        break;

      case 'fill':
        if (this._segment.item.data.selectionType === 'label') {
          if (marker || freeform) {
            this.self.emit('moveItem', {
              item: marker || freeform,
            });
            this.self.moveLabel(marker || freeform, event);
          }
        } else if (this._segment.item.data.selectionType === 'fork') {
          if (marker || freeform) {
            this.self.emit('moveItem', {
              item: marker || freeform,
            });
            this.self.moveFork(marker || freeform, event);
          }
        } else if (this._segment.item.data.selectionType === 'segment') {
          if (freeform) {
            this.self.emit('moveItem', {
              item: freeform,
            });
            this.self.moveSegment(freeform, event, this._hitResult.item.positionInPath);
          }
        } else if (this._segment.item.data.selectionType === 'resize') {
          this.self.emit('moveItem', {
            item: freeform,
          });
          this.self.resizeFreeform(freeform, event);
        } else if (keymap) {
          this.self.emit('moveItem', {
            item: keymap,
          });
          this.self.moveKeymap(keymap, event);
        } else if (freeform) {
          this.self.emit('moveItem', {
            item: freeform,
          });
          this.self.moveObject(freeform, event, this._hitResult.item.positionInPath);
        } else {
          if (
            marker &&
            marker.model.targets &&
            marker.model.targets.length > 0 &&
            Number.isFinite(this._segment.item.data.index)
          ) {
            this.self.emit('moveItem', {
              item: marker,
            });
            this.self.moveTarget(marker, event, this._segment.item.data.index);
          }
          // TODO
        }
        break;

      case 'pixel':
        if (this.self._container.getElementById('image').isMovable) {
          this.self.emit('moveItem', {
            item: undefined,
          });
          this.self.moveImage(event);
        }
        break;

      case 'segment':
        if (this._segment.item.data.selectionType === 'fork') {
          if (marker || freeform) {
            this.self.emit('moveItem', {
              item: marker || freeform,
            });
            this.self.moveFork(marker || freeform, event);
          }
        } else if (this._segment.item.data.selectionType === 'label') {
          if (marker || freeform) {
            this.self.emit('moveItem', {
              item: marker || freeform,
            });
            this.self.moveLabel(marker || freeform, event);
          }
        } else if (freeform) {
          if (
            freeform._model.shape === Shape ||
            freeform._model.shape === 'interactiveArea' ||
            freeform._model.shape === Shape.FREE ||
            freeform._model.shape === 'line'
          ) {
            this.self.emit('moveItem', {
              item: freeform,
            });
            this.self.moveSegment(freeform, event, this._hitResult.item.positionInPath);
          } else {
            this.self.emit('moveItem', {
              item: freeform,
            });
            this.self.resizeFreeform(freeform, event);
          }
        } else {
          if (
            marker &&
            marker.model.targets &&
            marker.model.targets.length > 0 &&
            Number.isFinite(this._segment.item.data.index)
          ) {
            this.self.emit('moveItem', {
              item: marker,
            });
            this.self.moveTarget(marker, event, this._segment.item.data.index);
          }
          // TODO
        }
        break;
    }
  }

  /**
   * Move label
   *
   * @param marker
   * @param event
   */
  moveLabel(marker, event) {
    marker._model.dirty = true;
    const absoluteCoords = this._container.getAbsoluteCoordsMarkerTool(
      marker.model.source.position.x,
      marker.model.source.position.y
    );
    const delta = event.pointerType === 'mouse' ? this._container.getAbsoluteDelta(event.delta.x, event.delta.y) : event.delta;
    
    const relativeCoords = this._container.getRelativeCoords(
      absoluteCoords.x + delta.x,
      absoluteCoords.y + delta.y
    );
    this._commandMoveLabel(marker, relativeCoords);
  }

  /**
   * Move Fork
   *
   * @param marker
   * @param event
   */
  moveFork(marker, event) {
    marker._model.dirty = true;
    if (_preventMoving) {
      return;
    }

    const relativeDelta = this._container.getRelativeDelta(
      event.delta.x,
      event.delta.y
    );

    if (event.event.altKey) {
      marker.model.fork.x = null;
      marker.model.fork.y = null;
      this.emit('updateMarker');
    } else {
      if (!marker.model.fork.x && !marker.model.fork.y) {
        marker.model.fork = (JSON.parse(JSON.stringify(marker.model.source.position)));
      }

      const position = {
        x: marker.model.fork.x + relativeDelta.x,
        y: marker.model.fork.y + relativeDelta.y
      };
  
      if (event.event.shiftKey) {
        const deltaX = marker.model.source.position.x - position.x;
        const deltaY = marker.model.source.position.y - position.y;
        const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
        const dir = Math.round(Math.atan2(deltaY, deltaX)/Math.PI*180/45) * 45;
        position.x = marker.model.source.position.x - Math.cos(dir / 180 * Math.PI) * distance
        position.y = marker.model.source.position.y - Math.sin(dir / 180 * Math.PI) * distance
      }
  
      this._commandMoveFork(marker, position);
    }
  }

  /**
   * Move target
   *
   * @param marker
   * @param event
   * @param index
   */
  moveTarget(marker, event, index) {
    marker._model.dirty = true;
    const relativeDelta = this._container.getRelativeDelta(
      event.delta.x,
      event.delta.y
    );
    this._commandMoveTarget(marker, index, relativeDelta);
  }

  /**
   * Move pointer
   *
   * @param marker
   * @param event
   */
  movePointer(marker, event) {
    marker._model.dirty = true;
    const relativeDelta = this._container.getRelativeDelta(
      event.delta.x,
      event.delta.y
    );
    this._commandMovePointer(marker, relativeDelta);
  }

  /**
   * Move keymap
   *
   * @param keymap
   * @param event
   */
  moveKeymap(keymap, event) {
    keymap._model.dirty = true;

    const relativeDelta = this._container.getRelativeDelta(
      event.delta.x,
      event.delta.y
    );
    this._commandMoveKeymap(keymap, relativeDelta);
  }

  /**
   * Move object
   *
   * @param object
   * @param event
   * @param index
   */
  moveObject(object, event, index) {
    object._model.dirty = true;
    const relativeDelta = this._container.getRelativeDelta(
      event.delta.x,
      event.delta.y
    );
    this._commandMoveObject(object, relativeDelta, index);
  }

  /**
   * Move segment
   *
   * @param freeform
   * @param event
   * @param index
   */
  moveSegment(freeform, event, index) {
    freeform._model.dirty = true;
    const relativeDelta = this._container.getRelativeDelta(
      event.delta.x,
      event.delta.y
    );
    this._commandMoveSegment(freeform, relativeDelta, index);
  }

  /**
   * Resize freeform
   *
   * @param freeform
   * @param event
   */
  resizeFreeform(freeform, event) {
    freeform._model.dirty = true;
    const relativeDelta = this._container.getRelativeDelta(
      event.delta.x,
      event.delta.y
    );

    if (event.event.shiftKey) {
      relativeDelta.y = relativeDelta.x;
    }
    this._commandResizeFreeform(freeform, relativeDelta);
  }

  /**
   * Move image
   *
   * @param event
   */
  moveImage(event) {
    const absoluteCoords = this._container.getAbsoluteCoordsMarkerTool(
      this.slide.images[this.sequenceId].offset.x,
      this.slide.images[this.sequenceId].offset.y
    );
    const relativeCoords = this._container.getRelativeCoords(
      absoluteCoords.x + event.delta.x,
      absoluteCoords.y + event.delta.y
    );
    this._commandMoveImage(this.slide.images[this.sequenceId], relativeCoords);
  }

  /**
   * Snap to angle
   *
   * @param startPoints
   * @param absoluteCoords
   * @param delta
   * @returns {{x: number, y: number}}
   * @private
   */
  _snapToAngle(startPoints, absoluteCoords, delta) {
    const startX = startPoints.x,
      startY = startPoints.y,
      x2 = (absoluteCoords.x + delta.x) - startX,
      y2 = (absoluteCoords.y + delta.y) - startY,
      r = Math.sqrt(x2 * x2 + y2 * y2);
    let angle = (Math.atan2(y2, x2) / Math.PI * 180);
    angle = (angle) % 360 + 180;
    // angle = parseInt(((angle + 22.4) % 360) / 45) * 45;
    angle = (((angle + 22.4) % 360) / 45) * 45;
    angle -= 180;
    const cosx = r * Math.cos(angle * Math.PI / 180),
      sinx = r * Math.sin(angle * Math.PI / 180);
    return this._container.getRelativeCoords(
      cosx + startX,
      sinx + startY
    );
  }

  /**
   * Handle mouse up
   *
   * @private
   */
  _handleMouseUp() {
    this._segment = null;
    _preventMoving = false;
    if (this.self._command.currentCommand) {
      this.self._command.addToHistory(this.self._command.currentVersion);
      this.self._command.currentCommand = null;
    }
    this.self.emit('endMoveItem');
  }

  /**
   * Getter lockimage
   *
   * @returns {*}
   */
  get lockimage() {
    return this._lockimage;
  }

  /**
   * Setter lockimage
   *
   * @param lockimage
   */
  set lockimage(lockimage) {
    this._lockimage = lockimage;
  }
}

export default MarkerTool;
