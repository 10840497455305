import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaDialogComponent } from '../../../common/media-dialog/media-dialog.component';
import { Settings, SETTINGS, MediaService } from '@medsurf/services';
import { Image, Folder, Media, MediaType } from '@medsurf/models';
import { MediaState } from '@medsurf/state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'medsurf-cover-image',
  templateUrl: './cover-image.component.html',
  styleUrls: ['./cover-image.component.scss']
})
export class CoverImageComponent {
  @Output() imageChange = new EventEmitter<Image>();

  @Input() page: Folder;

  constructor(
    @Inject(SETTINGS) private settings: Settings,
    private modalService: NgbModal,
    public mediaService: MediaService,
    private store: Store
  ) {}

  public selectImage(): void {
    const modalRef = this.modalService.open(MediaDialogComponent, {scrollable: true, size: 'xxl'});
    modalRef.componentInstance.allowedTypes = [MediaType.IMAGE];
    modalRef.componentInstance.selected = this.page.media ? [this.page.media] : null;
    modalRef.result.then((media: Media) => {
      this.page.media = media;
      this.imageChange.emit(this.page.media);
    }).catch(() => {
      if (this.page.media) {
        const media = this.store.selectSnapshot(MediaState.results).find(r => r.id === this.page.media.id);
        this.page.media = media;
      }
    });
  }


  public deleteImage() {
    this.page.media = null;
    this.imageChange.emit(this.page.media);
  }
}
