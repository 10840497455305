<div class="mb-3 d-flex justify-content-between">
  <span (click)="openQuestionDialog()" class="question-type">{{ ((question.type) | translate) + '...' }}</span>
</div>
<form class="question-form">
  <div class="content-container mb-3">
    <medsurf-editor *ngIf="!!question.type"
                    [(content)]="question.content" (contentChange)="onChange()"
                    [placeholder]="('question_text' | translate) + '...'" #questionText
                    [autogrow]="true"
                    [minHeight]="150">
    </medsurf-editor>
  </div>

  <div *ngIf="question.type === 'SingleChoiceQuestion' || question.type === 'LongListQuestion'">
    <div cdkDropList ngbRadioGroup name="choices" [(ngModel)]="question.solution" (change)="onChange()"
         (cdkDropListDropped)="orderChanged($event)">
      <div cdkDrag [cdkDragDisabled]="question.choices.length <= 1" class="input-group mb-3"
           *ngFor="let choice of question.choices; index as i; trackBy:trackById">
        <div class="drag-placeholder mb-3" *cdkDragPlaceholder></div>
        <div class="input-group-prepend">
          <button class="btn btn-secondary" type="button" tabindex="-1" cdkDragHandle
                  [disabled]="question.choices.length <= 1">
            <i class="fa fa-grip-dots-vertical"></i>
          </button>
        </div>
        <medsurf-editor
          [(content)]="choice.label" (contentChange)="onChange()"
          [placeholder]="('answer' | translate) + '...'" #choiceLabel
          [autogrow]="true"
          [minHeight]="100">
        </medsurf-editor>
        <div class="input-group-prepend btn-group-toggle">
          <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" tabindex="-1" [value]="i">
            <i class="fas fa-check"></i>
          </label>
        </div>
        <div class="input-group-append" *ngIf="question.type === 'LongListQuestion'">
          <button class="btn btn-primary" type="button" tabindex="-1" (click)="removeChoiceAndSolution(i)">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="question.type === 'FreeTextQuestion'">
    <div class="input-group mb-3">
      <medsurf-editor
        [(content)]="question.solutionText" (contentChange)="onChange()"
        [placeholder]="('answer' | translate) + '...'"
        [autogrow]="true"
        [minHeight]="150">
      </medsurf-editor>
    </div>
  </div>

  <div *ngIf="question.type === 'IndicationQuestion'">
    <div class="input-group-prepend btn-group-toggle mb-3" ngbRadioGroup name="choice" [(ngModel)]="question.solution"
         (change)="onChange()">
      <label ngbButtonLabel class="btn-primary">
        <input ngbButton type="radio" tabindex="-1" [value]="1">
        <i class="fas fa-check"></i>
      </label>
      <label ngbButtonLabel class="btn-primary">
        <input ngbButton type="radio" tabindex="-1" [value]="0">
        <i class="fas fa-times"></i>
      </label>
    </div>
  </div>

  <div *ngIf="question.type === 'MappingQuestion'">
    <label>{{ 'possible_choices' | translate }}</label>
    <div cdkDropList (cdkDropListDropped)="orderChanged($event)">
      <div cdkDrag [cdkDragDisabled]="question.choices.length <= 1" class="input-group mb-3"
           *ngFor="let choice of question.choices | decodeChoices; index as i; trackBy:trackById">
        <div class="drag-placeholder mb-3" *cdkDragPlaceholder></div>
        <div class="input-group-prepend">
          <button class="btn btn-secondary" type="button" tabindex="-1" cdkDragHandle
                  [disabled]="question.choices.length <= 1">
            <i class="fa fa-grip-dots-vertical"></i>
          </button>
        </div>
        <input [(ngModel)]="choice.label" type="text" name="label{{i}}" class="form-control" [placeholder]="('answer' | translate) + '...'"
               (change)="onChange()">
        <div class="input-group-append">
          <button class="btn btn-primary" type="button" tabindex="-1" (click)="removeChoice(i)">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div cdkDropList *ngIf="question.type === 'KprimeQuestion' || question.type === 'TrueFalseQuestion'"
       (cdkDropListDropped)="orderChanged($event)">
    <div cdkDrag [cdkDragDisabled]="question.choices.length <= 1" class="input-group mb-3"
         *ngFor="let choice of question.choices; index as i; trackBy:trackById">
      <div class="drag-placeholder mb-3" *cdkDragPlaceholder></div>
      <div class="input-group-prepend">
        <button class="btn btn-secondary" type="button" tabindex="-1" cdkDragHandle
                [disabled]="question.choices.length <= 1">
          <i class="fa fa-grip-dots-vertical"></i>
        </button>
      </div>
      <medsurf-editor
        [(content)]="choice.label" (contentChange)="onChange()"
        [placeholder]="('statement' | translate) + '...'"
        [autogrow]="true"
        [minHeight]="100">
      </medsurf-editor>
      <div class="input-group-prepend btn-group-toggle" ngbRadioGroup name="choices{{i}}"
           [(ngModel)]="question.solutions[i]" (change)="onChange()">
        <label ngbButtonLabel class="btn-primary">
          <input ngbButton type="radio" tabindex="-1" [value]="'1'">
          <i class="fas fa-check"></i>
        </label>
        <label ngbButtonLabel class="btn-primary">
          <input ngbButton type="radio" tabindex="-1" [value]="'0'">
          <i class="fas fa-times"></i>
        </label>
      </div>
      <div class="input-group-append" *ngIf="question.type === 'TrueFalseQuestion'">
        <button class="btn btn-primary" type="button" tabindex="-1" (click)="removeChoiceAndSolution(i)">
          <i class="fas fa-trash"></i>
        </button>
      </div>
    </div>
  </div>

  <div
    class="mb-3"
    *ngIf="question.type === 'LongListQuestion' || question.type === 'MappingQuestion' || question.type === 'TrueFalseQuestion'">
    <button type="button" class="btn btn-secondary" (click)="addChoice()">
      <i class="fas fa-plus"></i>
      <span class="ml-1">{{ 'add' | translate }}</span>
    </button>
  </div>

  <div *ngIf="question.type === 'MappingQuestion'" class="mt-3">
    <label>{{ 'solutions' | translate }}</label>
    <div cdkDropList (cdkDropListDropped)="solutionOrderChanged($event)">
      <div cdkDrag [cdkDragDisabled]="question.solutions <= 1" class="input-group mb-3"
           *ngFor="let solution of question.solutions; let i = index; trackBy:trackById">
        <div class="drag-placeholder mb-3" *cdkDragPlaceholder></div>
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn btn-secondary" type="button" tabindex="-1" cdkDragHandle
                    [disabled]="question.choices.length <= 1">
              <i class="fa fa-grip-dots-vertical"></i>
            </button>
            <label class="input-group-text">{{ i + 1 }}.</label>
          </div>
          <select [name]="'solution' + i"
                  [(ngModel)]="question.solutions[i]"
                  (change)="onChange()"
                  class="form-control">
            <option value="" disabled selected hidden>{{ 'choose_answer' | translate }}</option>
            <option *ngFor="let choice of question.choices; let k = index; trackBy:trackById" [value]="k">{{ choice.label }}</option>
          </select>
          <div class="input-group-append">
            <button class="btn btn-primary" type="button" tabindex="-1" (click)="removeSolution(i)">
              <i class="fas fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <button type="button" class="btn btn-secondary" (click)="addSolution()">
        <i class="fas fa-plus"></i>
        <span class="ml-1">{{ 'add' | translate }}</span>
      </button>
    </div>
  </div>

  <div class="content-container mb-3">
    <medsurf-editor *ngIf="!!question.type"
                    [(content)]="question.explanation" (contentChange)="onChange()"
                    [autogrow]="true"
                    [minHeight]="150"
                    [placeholder]="('explanation_text' | translate) + '...'" #explanationText>
    </medsurf-editor>
  </div>
</form>
