<fieldset>

  <div class='row'>
    <div class='col-sm-12'>
      <div class='form-group'>
        <label for="group-name" translate="title"></label>
        <input
          id="group-name"
          name="group-name"
          type="text"
          class="form-control input-md"
          [(ngModel)]="group.name"
          (ngModelChange)="changeEmitter.emit()"/>
      </div>
    </div>
  </div>

  <div class='row'>
    <div class='col-sm-12'>
      <div class='form-group'>
        <label for="group-color" translate="label_color"></label>
        <medsurf-color-palette
          id="group-color"
          name="group-color"
          [colorList]="defaultValues.colors"
          [(ngModel)]="group.color"
          (ngModelChange)="changeEmitter.emit()">
        </medsurf-color-palette>
      </div>
    </div>
  </div>


</fieldset>
