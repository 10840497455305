import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MentionModule } from 'angular-mentions';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MomentModule } from 'ngx-moment';
import { RestrictDirective } from './auth/restrict.directive';
import { NavigationComponent } from './navigation/navigation.component';
import { MicroscopeComponent } from './microscope/microscope.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { UploadDropzoneComponent } from './image/upload/upload-dropzone.component';
import { SequenceImageComponent } from './sequence/sequence-image/sequence-image.component';
import { SequenceLayerComponent } from './sequence/sequence-layer/sequence-layer.component';
import { SequenceNavigationComponent } from './sequence/sequence-navigation/sequence-navigation.component';
import { DragulaModule } from 'ng2-dragula';
import { SiblingsSidebarComponent } from './siblings-sidebar/siblings-sidebar.component';
import { SectionComponent } from './section/section.component';
import { WysiwygComponent } from './wysiwyg/wysiwyg.component';
import { MetadataDialogComponent } from './metadata-dialog/metadata-dialog.component';
import { MedsurfLibModule } from '@medsurf/module';
import { QuestionComponent } from './question/question.component';
import { QuestionDialogComponent } from './question-dialog/question-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FileSizePipe } from './pipes/file-size.pipe';
import { EditorComponent } from './editor/editor.component';
import { LinkEditorComponent } from './editor/common/editor/link-editor/link-editor.component';
import { MediaEditorComponent } from './editor/common/editor/media-editor/media-editor.component';
import { MarkerEditorComponent } from './editor/common/editor/marker-editor/marker-editor.component';
import { PermissionDialogComponent } from './permission-dialog/permission-dialog.component';
import { MediaDialogComponent } from './media-dialog/media-dialog.component';
import { MediaSidebarComponent } from './media-library-base/media-sidebar/media-sidebar.component';
import { MediaLibraryBaseComponent } from './media-library-base/media-library-base.component';
import { TreeSelectComponent } from './tree-select/tree-select.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { CommentsComponent } from './comments/comments.component';
import 'tinymce';
import { AutoScrollDirective } from './autoscroll/autoscroll.directive';
import { LinkTypeaheadComponent } from './link-typeahead/link-typeahead.component';
import { DecodeChoicesPipe } from './pipes/decode-choices.pipe';

@NgModule({
  declarations: [
    NavigationComponent,
    MicroscopeComponent,
    DropdownComponent,
    UploadDropzoneComponent,
    SequenceImageComponent,
    SequenceLayerComponent,
    SequenceNavigationComponent,
    RestrictDirective,
    AutoScrollDirective,
    SiblingsSidebarComponent,
    SectionComponent,
    WysiwygComponent,
    MetadataDialogComponent,
    QuestionComponent,
    QuestionDialogComponent,
    FileSizePipe,
    EditorComponent,
    LinkEditorComponent,
    MediaEditorComponent,
    MarkerEditorComponent,
    PermissionDialogComponent,
    MediaDialogComponent,
    MediaSidebarComponent,
    MediaLibraryBaseComponent,
    TreeSelectComponent,
    MultiselectComponent,
    CommentsComponent,
    DecodeChoicesPipe,
    LinkTypeaheadComponent
  ],
  bootstrap: [
    MetadataDialogComponent,
    QuestionDialogComponent,
    PermissionDialogComponent,
    MediaDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule.forChild(),
    MentionModule,
    MomentModule,
    NgxDropzoneModule,
    NgbModule,
    DragulaModule.forRoot(),
    MedsurfLibModule,
    DragDropModule,
    EditorModule
  ],
  exports: [
    NavigationComponent,
    MicroscopeComponent,
    DropdownComponent,
    UploadDropzoneComponent,
    SequenceNavigationComponent,
    RestrictDirective,
    AutoScrollDirective,
    SiblingsSidebarComponent,
    SectionComponent,
    WysiwygComponent,
    FileSizePipe,
    EditorComponent,
    MediaDialogComponent,
    MediaLibraryBaseComponent,
    TreeSelectComponent,
    MultiselectComponent,
    CommentsComponent,
    DecodeChoicesPipe,
    LinkTypeaheadComponent
  ],
})
export class MedsurfCommonModule {
}
