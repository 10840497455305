import { MediaService } from '@medsurf/services';
import { MediaType, Media } from '@medsurf/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Editor } from 'tinymce';
import { ChangeEventCallback, OnAction, OnSetup, ToolbarToggleButtonInstanceApi } from '../plugins';
import { openDialog } from '../media-base';
import { NgZone } from '@angular/core';
import { environment } from '../../../../../environments/environment';

const nodeChangeEventCallback: ChangeEventCallback = (buttonApi: ToolbarToggleButtonInstanceApi, editor: Editor) => async (eventApi) => {
  const element = (<HTMLElement>eventApi.element);
  const active = element?.tagName?.toLowerCase() === 'a' && !!element?.dataset?.id && !element.classList.contains('external') && !element.classList.contains('internal');
  const hasSelectionContent = editor.selection.getContent();

  const enable = active && !hasSelectionContent || hasSelectionContent && !/<(\bvideo\b|\baudio\b|\bimg\b|\ba\b)[\s\S]/i.test(editor.selection.getContent());

  buttonApi.setEnabled(enable || !hasSelectionContent);
  buttonApi.setActive(enable && active);
};

const getOnSetup: OnSetup = (editor: Editor) => (buttonApi: ToolbarToggleButtonInstanceApi) => {
  editor.on('NodeChange', nodeChangeEventCallback(buttonApi, editor));
  return () => {
    editor.off('NodeChange', nodeChangeEventCallback(buttonApi, editor));
  };
};

const getOnAction: OnAction = (editor: Editor, zone: NgZone, modalService: NgbModal, mediaService: MediaService) =>
  async (buttonApi: ToolbarToggleButtonInstanceApi) => {
    zone.run(async () => {
      const selection = editor.selection.getContent();
      const allowedTypes: MediaType[] = [MediaType.DOCUMENT];

      const node = <HTMLElement>editor.selection.getNode();
      const isAnchor = node.tagName.toLowerCase() === 'a';

      const selected: Media[] = isAnchor ? [{
        id: node.dataset.id,
        type: MediaType.DOCUMENT
      }] : [];

      let medium: Media | null = null;
      try{
        medium = await openDialog(allowedTypes, modalService, selected);
      }
      catch(e){
        return;
      }
      
      const url = environment.config.endpointUrl + 'v1/id2url/' + medium.id;

      try {
        if (isAnchor) {
          node.className = '';
          node.innerText = node.innerText.match(/.+?\.pdf$/) ? medium.name : node.innerText;
          editor.dom.setAttribs(node, {
            href: url,
            'data-id': medium.id
          });
          editor.fire('change');
        } else {
          const anchor: HTMLAnchorElement = document.createElement('a');
          anchor.setAttribute('href', url);
          anchor.setAttribute('data-id', medium.id);
          anchor.setAttribute('target', '_blank');
          anchor.innerHTML = selection || medium.name;
          editor.insertContent(anchor.outerHTML);
        }
      } catch (err) {
        return;
      }
    });
  };

export { getOnAction, getOnSetup };
