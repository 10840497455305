import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Actions, Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SlideService } from '../../common/services/slide.service';
import { GetDetachedSlides, DeleteDetachedSlides, AssignSlideIds } from '@medsurf/actions';
import { Slide } from '@medsurf/models';
import { DetachedState } from '@medsurf/state';
import { MediaService } from '@medsurf/services';

@Component({
  selector: 'medsurf-detached-slides',
  templateUrl: './detached-slides.component.html',
  styleUrls: ['./detached-slides.component.scss']
})
export class DetachedSlidesComponent implements OnInit, OnDestroy {

  @Select(DetachedState.slides)
  public detachedSlides$: Observable<Slide[]>;

  public bulkSelectedSlides: Slide[] = [];

  @ViewChild('ConfirmBulkRemoveDialog') confirmBulkRemoveDialog: TemplateRef<any>;

  private _destroyed = new Subject<boolean>();

  constructor(
    private store: Store,
    public mediaService: MediaService,
    public slideService: SlideService,
    public modalService: NgbModal,
    private actions$: Actions,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.store.selectSnapshot(DetachedState.slides).length === 0) {
      this.store.dispatch(new GetDetachedSlides());
    }
  }

  public selectSlide(slide: Slide): void {
    this.router.navigate(['slide', slide.id]);
  }

  isBulkSelected(slide: Slide): boolean {
    return this.bulkSelectedSlides && !!this.bulkSelectedSlides.find(({id}) => slide.id === id);
  }

  toggleBulkSelectAll(event: Event): void {
    const results: Slide[] = this.store.selectSnapshot(DetachedState.slides);
    if ((event.target as any).checked) {
      this.bulkSelectedSlides = [...results];
    } else {
      this.bulkSelectedSlides = [];
    }
  }

  toggleBulkSelect(slide: Slide, event: Event): void {
    event.stopPropagation();
    if (!this.bulkSelectedSlides) {
      this.bulkSelectedSlides = [];
    }

    const index = this.bulkSelectedSlides.findIndex(({id}) => slide.id === id);
    if (index !== -1) {
      this.bulkSelectedSlides.splice(index, 1);
    } else {
      this.bulkSelectedSlides.push(slide);
    }
  }

  bulkDeleteSlides(): void {
    if (this.bulkSelectedSlides?.length > 0) {
      this.modalService.open(this.confirmBulkRemoveDialog, {size: 'xs', scrollable: true}).result.then(() => {
        this.store.dispatch(new DeleteDetachedSlides(this.bulkSelectedSlides));
        this.bulkSelectedSlides = [];
      });
    }
  }

  async bulkAddSlideIds(): Promise<void> {
    if (this.bulkSelectedSlides?.length > 0) {
      this.store.dispatch(new AssignSlideIds(this.bulkSelectedSlides));
      this.bulkSelectedSlides = [];
    }
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
