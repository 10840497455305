export type WindowWithConfig = Window & {
  config?: {
    s3Host?: string;
    s3Port?: string;
    s3Secure?: boolean;
    s3endpointUrl?: string;
    s3Bucket?: string;
    endpointUrl?: string;
    websocketUrl?: string;
    viewerUrl?: string;
    sentryDsn?: string;
    sentryEnvironment?: string;
  }
}

const config = (window as WindowWithConfig)?.config;
config.s3endpointUrl = (config?.s3Secure ? 'https' : 'http') + '://' + config?.s3Host + ':' + config?.s3Port + '/';
config.endpointUrl += config.endpointUrl?.endsWith('/') ? '' : '/';
config.viewerUrl += config.viewerUrl?.endsWith('/') ? '' : '/';

export { config };
