<div class="m-2 mb-3 mt-3 d-flex">
  <span class="badge badge-primary">
    <i class="fa fa-layer-group"></i> {{ layerId + 1  }}
  </span>
  <span class="badge badge-primary ml-2">
    <i class="fa fa-image"></i> {{ sequenceId + 1 }}
  </span>
  <div class="form-check d-inline-block ml-2" *ngIf="{ checked: (showOnlyPoisOfImage$ | async) } as data">
    <input class="form-check-input" type="checkbox" value="" [checked]="data.checked" id="show-only-pois-of-image" (change)="setShowOnlyPoisOfImage(!data.checked)">
    <label class="form-check-label" for="show-only-pois-of-image">
      {{ 'show_only_pois_of_image' | translate }}
    </label>
  </div>
</div>

<form class="form m-2 d-flex">
  <button class="btn btn-primary" (click)="addPoi()" translate="add_poi"></button>
</form>

<hr>

<div *ngIf="{ 
    poiToImageMap: (poiToImageMap$ | async),
    imagePoiSet: (imagePoiSet$ | async),
    displayedPois: (displayedPois$ | async),
    currentImage: (image$ | async)
  } as data">
  <div class="pois-container" dragula="POI" [dragulaModel]="data.displayedPois" (dragulaModelChange)="onSortPois($event)">
    <div 
      class="poi-container tree-node-styled collapsable with-handle"
      *ngFor="let poi of data.displayedPois; trackBy:trackById"
      [class.active]="poi.isExpanded"
      [class.disabled]="!data.imagePoiSet.has(poi.id)">

      <div class="collapsable-header" (click)="poi.isExpanded = !poi.isExpanded">
        <div class="handle"><i class="fa fa-grip-dots-vertical"></i></div>
        <div class="mr-auto">
          <strong>{{ poi.name }}</strong><br>

          <div class="d-inline-block" (click)="$event.stopPropagation()">
            <medsurf-reference-dropdown 
              *ngFor="let layer of slide.layers; trackBy:trackById; let layerIndex = index"
              [list]="layer.images"
              [map]="data.poiToImageMap[poi.id]?.[layer.id] || {}"
              [subject]="poi"
              [dropdownId]="'poi-'+ poi.id +'-poi-dropdown'"
              classes="d-block"
              labelKey="index"
              labelIcon="fa-image"
              [title]="'assign_to_images' | translate"
              [noResultLabel]="'no_images_found' | translate"
              (updateSelected)="updatePoiImage($event.subject, $event.items, $event.add)"
            >
              <div class="d-inline">
                <span *ngIf="slide.layers.length > 1" class="mr-2">
                  <i class="fa fa-layer-group"></i> {{ layerIndex + 1 }} 
                </span>
                <span>
                  <i class="fa fa-image"></i> {{ data.poiToImageMap[poi.id]?.[layer.id]?.count || '0' }}
                </span>
              </div>
            </medsurf-reference-dropdown>
          </div>
          
        </div>
        <div class="d-flex" (click)="$event.stopPropagation()">
          <button *ngIf="data.imagePoiSet.has(poi.id)" class="btn btn-sm btn-secondary ml-2" [title]="'go_to_poi' | translate" (click)="goTo(poi)">
            <i class="fa fa-fw fa-location"></i>
          </button>
          <button *ngIf="!data.imagePoiSet.has(poi.id)" class="btn btn-sm btn-secondary ml-2" [title]="'link_on_current_image' | translate" (click)="updatePoiImage(poi, [data.currentImage], true)">
            <i class="fa fa-fw fa-link"></i>
          </button>
          <button *ngIf="data.imagePoiSet.has(poi.id)" class="btn btn-sm btn-secondary ml-2" [title]="'unlink_on_current_image' | translate" (click)="updatePoiImage(poi, [data.currentImage], false)">
            <i class="fa fa-fw fa-unlink"></i>
          </button>
          <button class="btn btn-sm btn-danger ml-2" [title]="'delete_poi' | translate" (click)="removePoi(poi)">
            <i class="fa fa-fw fa-trash"></i>
          </button>
        </div>
      </div>

      <div class="collapsable-body" *ngIf="poi.isExpanded">
        <medsurf-poi-header
                [poi]="poi"
                [currentBounds]="currentBounds"
                [currentPosition]="currentPosition"
                (goToPoi)="goToPoi.emit($event)"
                (changeEmitter)="onChange()">
        </medsurf-poi-header>

        <hr>

        <medsurf-poi-annotation
                [poi]="poi"
                [slide]="slide"
                [imageAnnotationSet]="imageAnnotationSet$ | async"
                [poiToImageMap]="poiToImageMap$ | async"
                (changeEmitter)="onChange()"
                (setHoverMarker)="setHoverMarker.emit($event)">
        </medsurf-poi-annotation>

        <hr>

        <medsurf-poi-note
                [poi]="poi"
                (changeEmitter)="onChange()">
        </medsurf-poi-note>

      </div>
    </div>
  </div>
</div>
