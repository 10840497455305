import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { MediaState, IndexState  } from '@medsurf/state';
import { Media, Node, MediaType } from '@medsurf/models';
import { MediaService } from '@medsurf/services';
import {
  DeleteMedia,
  DeleteMediaSuccess,
  GetMediaSuccess,
  GetRootNodes,
  SetPage,
  SetSelectedMedia,
  SetUploadedByMe,
  SetUsedInFilter,
  SetTypeFilter,
  SetSearchQuery,
  GetMedia
} from '@medsurf/actions';
import { cloneDeep, debounce } from 'lodash';
import { take, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

enum DisplayMode {
  Grid = 'grid',
  Table = 'table'
}

@Component({
  selector: 'medsurf-media-library-base',
  templateUrl: './media-library-base.component.html',
  styleUrls: ['./media-library-base.component.scss']
})
export class MediaLibraryBaseComponent implements OnInit, OnDestroy {

  @Input() isSelect: boolean;
  @Input() multiple: boolean;
  @Input() selected: Media[];
  @Input() allowedTypes: MediaType[];

  @Input() upload: boolean;
  @Output() uploadChange = new EventEmitter<boolean>();

  @Output() selectEmitter = new EventEmitter<Media[]>();

  @Select(IndexState.index)
  public index$: Observable<Node[]>;

  @Select(MediaState.results)
  public results$: Observable<Media[]>;

  @Select(MediaState.searchQuery)
  public serchQuery$: Observable<string>;

  @Select(MediaState.typeFilter)
  public typeFilter$: Observable<MediaType[]>;

  @Select(MediaState.selectedMedia)
  public selectedMedia$: Observable<Media[]>;

  @Select(MediaState.uploadedByMe)
  public uploadedByMe$: Observable<boolean>;

  @Select(MediaState.page)
  public page$: Observable<number>;

  @Select(MediaState.total)
  public total$: Observable<number>;

  @Select(MediaState.perPage)
  public perPage$: Observable<number>;

  @ViewChild('ConfirmBulkRemoveDialog') confirmBulkRemoveDialog: TemplateRef<any>;

  public page: number;

  public searchQuery: string;
  public typeOptions: object;
  public nodeOptions: Node[];
  public typeFilter: MediaType[];
  public uploadedByMe: boolean;

  public isLoading: boolean;
  public displayMode: DisplayMode = DisplayMode.Grid;

  public editedMedia: Media;
  public selectedMedia: Media[];
  public bulkSelectedMedia: Media[];

  private _destroyed = new Subject<boolean>();
  private debouncedGetMedia = debounce(this.getMedia, 500);

  constructor(
    private store: Store,
    public mediaService: MediaService,
    public actions$: Actions,
    public modalService: NgbModal
  ) {
  }

  private resetFilter(): void {
    this.store.dispatch(new SetPage(1));
    this.store.dispatch(new SetSearchQuery(''));
    this.store.dispatch(new SetSelectedMedia([], true));
    this.store.dispatch(new SetTypeFilter([]));
    this.store.dispatch(new SetUploadedByMe(false));
    this.store.dispatch(new SetUsedInFilter([]));
  }

  ngOnInit(): void {
    this.resetFilter();
    this.typeOptions = MediaType;
    this.isLoading = true;

    this.selectedMedia$.pipe(takeUntil(this._destroyed)).subscribe((selectedMedia: Media[]) => {
      this.selectedMedia = selectedMedia;
      this.selectEmitter.emit(this.selectedMedia);
    });

    this.typeFilter$.pipe(takeUntil(this._destroyed)).subscribe((typeFilter: MediaType[]) => {
      this.typeFilter = typeFilter;
    });

    this.index$.pipe(takeUntil(this._destroyed)).subscribe((index: Node[]) => {
      this.nodeOptions = recPrep(index);

      function recPrep(nodes: Node[]) {
        return nodes.map((node) => {
          return {
            key: node.id,
            value: node.page?.title,
            children: recPrep(node.children || [])
          };
        });
      }
    });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionCompleted(DeleteMediaSuccess)).subscribe(async ({action}) => {
      this.deselectMedia({id: action.mediaId});
      this.editedMedia = null;
    });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionCompleted(GetMediaSuccess)).subscribe(async () => {
      this.isLoading = false;
    });

    this.results$.pipe(takeUntil(this._destroyed)).subscribe((results: Media[]) => {
      if (this.editedMedia) {
        const prevId = this.editedMedia.id;
        const media = results.find(({id}) => {
          return id === prevId;
        });
        this.editedMedia = cloneDeep(media);
      }

      this.selectedMedia.forEach((media) => {
        const updatedMedia = results.find(r => r.id === media.id);
        if (updatedMedia) {
          this.selectMedia(updatedMedia);
        }
      });

      if (this.bulkSelectedMedia) {
        this.bulkSelectedMedia = [];
      }
    });

    if (this.isSelect && this.selected?.[0]) {
      this.editedMedia = cloneDeep(this.selected[0]);
      for (const media of this.selected) {
        this.selectMedia(media, true);
      }
    }

    if (this.isSelect && this.allowedTypes) {
      this.store.dispatch(new SetTypeFilter(this.allowedTypes));
    }

    this.store.dispatch(new GetMedia());

    if (this.nodeOptions.length === 0) {
      this.store.dispatch(new GetRootNodes());
    }
  }

  pageChange(page: number) {
    this.store.dispatch(new SetPage(page));
    this.getMedia(false);
  }

  getMedia(reset: boolean = true) {
    this.isLoading = true;
    if (reset) {
      this.store.dispatch(new SetPage(1));
    }
    this.store.dispatch(new GetMedia());
  }

  selectMedia(media: Media, initalValue = false): void {
    this.editedMedia = media;

    if (!this.isSelect || (this.allowedTypes && !this.allowedTypes.includes(media.type))) {
      return;
    }
    if (this.multiple) {
      this.store.dispatch(new SetSelectedMedia([...this.selectedMedia, media], initalValue));
    } else {
      this.store.dispatch(new SetSelectedMedia([media], initalValue));
    }
  }

  deselectMedia(media: Media, event: MouseEvent = null): void {
    if (event) {
      event.stopPropagation();
    }
    this.store.dispatch(new SetSelectedMedia(this.selectedMedia.filter((m) => m.id !== media.id)));
  }

  isSelected(media: Media): boolean {
    return this.isSelect && !!this.selectedMedia.find(({id}) => media.id === id);
  }

  isEdited(media: Media): boolean {
    return media.id === this.editedMedia?.id;
  }

  isBulkSelected(media: Media): boolean {
    return this.bulkSelectedMedia && !!this.bulkSelectedMedia.find(({id}) => media.id === id);
  }

  isAllowed(media: Media): boolean {
    return !this.isSelect || !this.allowedTypes || !!this.allowedTypes.includes(media.type);
  }

  toggleBulkSelectAll(event: Event): void {
    const results = this.store.selectSnapshot(MediaState.results);
    if ((event.target as any).checked) {
      this.bulkSelectedMedia = [...results];
    } else {
      this.bulkSelectedMedia = [];
    }
  }

  toggleBulkSelect(media: Media, event: Event): void {
    event.stopPropagation();
    if (!this.bulkSelectedMedia) {
      this.bulkSelectedMedia = [];
    }

    const index = this.bulkSelectedMedia.findIndex(({id}) => media.id === id);
    if (index !== -1) {
      this.bulkSelectedMedia.splice(index, 1);
    } else {
      this.bulkSelectedMedia.push(media);
    }
  }

  bulkDeleteMedia() {
    if (this.bulkSelectedMedia && this.bulkSelectedMedia.length > 0) {
      this.modalService.open(this.confirmBulkRemoveDialog, {size: 'xs', scrollable: true}).result.then(() => {
        this.store.dispatch(new DeleteMedia(this.bulkSelectedMedia));
      });
    }
  }

  setDisplayMode(mode: DisplayMode) {
    this.displayMode = mode;
  }

  onSearchQueryChange(value) {
    if (value.length === 0 || value.length >= 3) {
      this.store.dispatch(new SetSearchQuery(value));
      this.debouncedGetMedia();
    }
  }

  onNodeSelectChange(items: any[]) {
    const nodes: Node[] = items.map((item) => {
      return {id: item.key};
    });
    this.store.dispatch(new SetUsedInFilter(nodes));
    this.getMedia();
  }

  onUploadedByMeChange(value) {
    this.store.dispatch(new SetUploadedByMe(value));
    this.getMedia();
  }

  onTypeFilterChange(value) {
    let typeFilter;
    if (this.typeFilter.includes(value)) {
      typeFilter = this.typeFilter.filter((type) => type !== value);
    } else {
      typeFilter = [...this.typeFilter, value];
    }
    this.store.dispatch(new SetTypeFilter(typeFilter));
    this.getMedia();
  }

  onUpload(media: Media) {
    this.store.dispatch(new GetMedia());
    this.actions$.pipe(ofActionCompleted(GetMediaSuccess), take(1)).subscribe(() => {
      if (media) {
        this.selectMedia(media);
        this.upload = false;
        this.uploadChange.emit(this.upload);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
