import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Media, MediaType } from '@medsurf/models';
@Component({
  selector: 'medsurf-media-dialog',
  templateUrl: './media-dialog.component.html',
  styleUrls: ['./media-dialog.component.scss']
})
export class MediaDialogComponent implements OnInit, OnDestroy {

  @Input() multiple: boolean;
  @Input() selected: Media[];
  @Input() allowedTypes: MediaType[];

  public selectedMedia: Media[];
  public isUploading: boolean;

  private _destroyed = new Subject<boolean>();

  constructor(
    public modal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.selectedMedia = [];
    this.isUploading = false;
  }

  onSelect(media: Media[]): void {
    this.selectedMedia = media;
  }

  toggleUploader(): void {
    this.isUploading = !this.isUploading;
  }

  onSave(): void {
    if (this.multiple) {
      this.modal.close(this.selectedMedia);
    } else {
      this.modal.close(this.selectedMedia[0]);
    }
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
