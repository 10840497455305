import paper from 'paper';
import { Annotation } from '@medsurf/models';

class Dot {
  private _path: any;
  private _element: any;

  constructor(private _format, private _target, private _marker) {
    this._path = null;
    this._element = null;
    this._init();
  }

  _init() {
    this.draw();
  }

  draw() {
    const radius = (this._marker && this._marker._model.source && this._marker._model.source.zoom && this._marker._imageScale === 1 ?
        this._marker._container.getStrokeWidth(this._marker._model.source.zoom) || this._format.strokeWidth :
        this._format.strokeWidth)
        / this._marker._imageScale * 2;
    this._element = new paper.Group();
    this._path = new paper.Path.Circle(new paper.Point(this._target.x, this._target.y), radius);
    this._path.strokeColor = this._format.strokeColor;
    this._path.fillColor =
      this._marker && this._marker._model && this._marker._model.style && this._marker._model.style.color ?
        this._marker._model.style.color :
        this._format.strokeColor;
    this._path.strokeWidth = 0;
    this._element.addChild(this._path);
  }

  get element() {
    return this._element;
  }

  get path() {
    return this._path;
  }

  setScale() {
    if (this._path) {
      this._path.remove();
    }
    const radius = (this._marker && this._marker._model.source && this._marker._model.source.zoom && this._marker._imageScale === 1 ?
        this._marker._container.getStrokeWidth(this._marker._model.source.zoom) || this._format.strokeWidth :
        this._format.strokeWidth)
        / this._marker._imageScale * 2;
    this._path = new paper.Path.Circle(new paper.Point(this._target.x, this._target.y), radius);
    this._path.strokeColor = this._format.strokeColor;
    this._path.fillColor = this._format.strokeColor;
    this._path.strokeWidth = 0;
    this._element.addChild(this._path);
  }

  cleanUp() {
    if (this._element) {
      this._element.remove();
      this._element = null;
    }
  }
}

export default Dot;
