import End from './end/end';
import Line from './line';

class Pointer {
  private _end: any;
  private _line: any;

  constructor (private _endType, private _format, private _source, private _target, private _marker) {
    this._end = null;
    this._line = null;
    this._init();
  }

  _init () {
    this.draw();
  }

  draw() {
    if (this._end && this._line) {
      this.cleanUp();
    }
    this._end = new End(this._endType, this._format, this._target, this._marker);
    this._line = new Line(this._format, this._source, this._target, this._marker);
    this.arrange();
  }

  arrange() {
    if (this._endType === 'circle') {
      const intersections = this._line.element.segments[0].path.getIntersections(this._end.element.element);
      if (intersections.length) {
        this._line.element.segments[1].point = intersections[0].point;
      }
    } else if (this._endType === 'arrow') {
      if (this._end.element.path) {
        this._end.element.path.rotation = 90 + this._end.element.angle(
            this._line.element.segments[0].point.x,
            this._line.element.segments[0].point.y,
            this._line.element.segments[1].point.x,
            this._line.element.segments[1].point.y
        );
      }
    }
  }

  setScale(scale) {
    this._line.setScale(scale);
    this._end.setScale(scale);
    this.arrange();
  }

  get end() {
    return this._end;
  }

  get line() {
    return this._line;
  }

  cleanUp() {
    if (this._end) {
      this._end.cleanUp();
    }
    if (this._line) {
      this._line.cleanUp();
    }
  }
}

export default Pointer;
