import Marker from './marker/marker';
import Keymap from './marker/keymap';
import Bitmap from './bitmap/bitmap';
import Freeform from './freeform/freeform';
import KeymapSelftest from './marker/keymapSelftest';

class Container {
  private _elements: any[];
  private _selection: any[];
  private _dirty: boolean;
  private _minZoom: any;
  private width: any;
  private height: any;
  private _offset: any;

  /**
   * Constructor
   *
   * @param localPaper
   * @param format
   * @param isMicroscopeContainer
   * @param isMicroscope
   */
  constructor(private _localPaper, private _format, private _isMicroscopeContainer?, private _isMicroscope?) {
    this._elements = [];
    this._selection = [];
    this._dirty = true;
    this._minZoom = null;
  }

  get allSelected() {
    return (this._selection.length > 0 && this._selection.length === this._elements.length - 1);
  }

  get scale() {
    return this.getElementById('image').scale || 1;
  }

  get offset() {
    return this._offset || 0;
  }

  get elements() {
    return this._elements;
  }

  get selection() {
    return this._selection;
  }

  get minZoom() {
    return this._minZoom;
  }

  /**
   * Round
   *
   * @param value
   * @returns {number}
   */
  static round(value) {
    return Math.round(value);
  }

  /**
   * Add element to container
   *
   * @param element
   */
  addElement(element) {
    this._elements.push(element);
    this.setHierarchy();
    this._dirty = true;
  }

  /**
   * Remove element
   *
   * @param element
   */
  removeElement(element) {
    this._elements = this._elements.filter((e) => {
      if (e === element) {
        e.cleanUp();
        return false;
      }
      return true;
    });
    this._dirty = true;
  }

  /**
   * Remove bitmaps
   */
  removeBitmaps() {
    this._elements = this._elements.filter((element) => {
      if (element instanceof Bitmap) {
        element.cleanup();
        return false;
      }
      return true;
    });
    this._dirty = true;
  }

  /**
   * Resize container
   */
  resize() {
    const width = this.getViewportSize().width,
      height = this.getViewportSize().height;
    for (const element of this._elements) {
      element.resize(width, height);
      element.arrange();
    }
  }

  /**
   * Call element arrange function
   */
  arrange() {
    for (const element of this._elements) {
      if (element instanceof Marker || element instanceof Keymap || element instanceof Freeform) {
        element.element.bringToFront();
        element.draw();
      } else {
        element.arrange();
      }
    }
    this._dirty = true;
  }

  /**
   * Update canvas
   */
  update(force?) {
    if (this._dirty || force) {
      this._localPaper.view.draw();
      this._dirty = false;
    }
  }

  /**
   * Show marker
   *
   * @param marker
   */
  showMarker(marker) {
    this.showMarkers(false);
  }

  /**
   * Show markers
   *
   * @param markers
   */
  showMarkers(markers) {
    // this.setElementState(markers ? 'visible' : 'hidden');
  }

  setCanvasSize(width, height) {
    this.width = width;
    this.height = height;
  }

  setOffset(offset) {
    this._offset = offset;
  }

  setMinZoom(value) {
    this._minZoom = value;
  }

  setHierarchy() {
    const markers = this._elements.filter(element => element instanceof Marker);
    const freeforms = this._elements.filter(element => element instanceof Freeform);
    const rest = this._elements.filter(element => !(element instanceof Freeform || element instanceof Marker));
    this._elements = [...rest, ...freeforms, ...markers];
  }

  select(item, add) {
    if (!item) {
      return;
    }
    if (!add) {
      this.deselectAll();
    }
    this._selection.push(item);
    item.select();
    this._dirty = true;
    this.arrange();
    this.update();
  }

  selectAll() {
    for (const element of this._elements) {
      if (element instanceof Marker || element instanceof Bitmap || element instanceof Keymap || element instanceof Freeform) {
        this._selection.push(element);
        element.select();
      }
    }
    this._dirty = true;
    this.arrange();
    this.update();
  }

  isInSelection(item) {
    return this._selection.filter(element => element.id === item.id).length > 0;
  }

  deselectAll() {
    for (const element of this._elements) {
      if (element instanceof Marker || element instanceof Bitmap || element instanceof Keymap || element instanceof Freeform) {
        element.unselect();
      }
    }
    this._selection = [];
    this._dirty = true;
    this.arrange();
    this.update();
  }

  calculateFixDimensions(size) {
    const zoom = this._localPaper.view.zoom * 100;
    return Math.floor(size * (100 / zoom));
  }

  getViewportSize() {
    return this._localPaper.view.viewSize;
  }

  getCanvasSize() {
    return {
      width: this.width,
      height: this.height
    };
  }

  getCanvasDimensions() {
    const element = this.getElementById('image');
    if (element && element.scale) {
      return {
        width: this._format.canvas.width * element.scale,
        height: this._format.canvas.height * element.scale
      };
    } else {
      return {
        width: this._format.canvas.width,
        height: this._format.canvas.height
      };
    }
  }

  getElementById(id) {
    return this._elements.filter(element => element.id === id)[0];
  }

  getMarkerElementById(id) {
    return this._elements
      .filter((element) => element instanceof Marker || element instanceof Keymap || element instanceof Freeform)
      .filter(element => element.model.id === id)[0];
  }

  getSelftestMarkerElementById(id) {
    return this._elements
      .filter(function (element) {
        return element instanceof KeymapSelftest;
      })
      .filter(element => element.model.id === id)[0];
  }

  getMarkerElements() {
    return this._elements.filter(element => element instanceof Marker);
  }

  /**
   * Get relative coords
   *
   * @param x
   * @param y
   * @param offset
   * @param scale
   * @returns {{x: number, y: number}}
   */
  getRelativeCoords(x, y, offset, scale) {
    if (this._isMicroscopeContainer) {
      return {
        x: x / this.getCanvasSize().width,
        y: y / this.getCanvasSize().height
      };
    } else {
      return {
        x: (x / this.getCanvasDimensions().width) - this.offset.x,
        y: (y / this.getCanvasDimensions().height) - this.offset.y
      };
    }
  }

  /**
   * Get relative delta
   *
   * @param x
   * @param y
   * @returns {*}
   */
  getRelativeDelta(x, y) {
    return this._isMicroscopeContainer ? {x, y} : {
      x: 1 / (this.getCanvasDimensions().width * this.scale) * x,
      y: 1 / (this.getCanvasDimensions().height * this.scale) * y
    };
  }

  /**
   * Get absolute delta
   *
   * @param x
   * @param y
   * @returns {{x: number, y: number}}
   */
  getAbsoluteDelta(x, y) {
    return this._isMicroscopeContainer ? {x: x * this.getCanvasSize().width, y: y * this.getCanvasSize().height} : {
      x: 1 / (this.getCanvasDimensions().width * this.scale) * x,
      y: 1 / (this.getCanvasDimensions().height * this.scale) * y
    };
  }

  /**
   * Get absolute coords
   *
   * @param x
   * @param y
   * @param offset
   * @param scale
   * @returns {{x: number, y: number}}
   */
  getAbsoluteCoords(x, y, offset, scale) {
    if (this._isMicroscopeContainer) {
      return {
          x: Container.round(x * this.getCanvasSize().width),
          y: Container.round(y * this.getCanvasSize().height)
      };
    } else {
      return {
        x: Container.round((x * this.getCanvasDimensions().width) + this.offset.x),
        y: Container.round((y * this.getCanvasDimensions().height) + this.offset.y)
      };
    }
  }

  getStrokeWidth(zoom: number) {
    if (!zoom) {
      return;
    }
    return Math.round((this.getCanvasSize().width / zoom) * (0.02 / 12));
  }

  /**
   * Get absolute coords marker tool
   *
   * @param x
   * @param y
   * @returns {{x: number, y: number}}
   */
  getAbsoluteCoordsMarkerTool(x, y) {
    return this._isMicroscopeContainer ? {x: x * this.getCanvasSize().width, y: y * this.getCanvasSize().height} : {
      x: Container.round((x * this.getCanvasDimensions().width) + this.offset.x),
      y: Container.round((y * this.getCanvasDimensions().height) + this.offset.y)
    };
  }

  destroyElements() {
    for (let i = this._elements.length; i--;) {
      let element = this._elements[i];
      if (element instanceof Marker || element instanceof Keymap || element instanceof Freeform) {
        element.cleanUp();
        this._elements.splice(i, 1);
        element = null;
      }
    }
  }

  cleanUp() {
    for (let i = this._elements.length; i--;) {
      let element = this._elements[i];
      element.cleanUp();
      element = null;
    }
    this._elements = [];
  }
}

export default Container;
