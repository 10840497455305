<div class="images-container" *ngIf="layer && layer.images" dragula="IMAGE" [(dragulaModel)]="layer.images" (dragulaModelChange)="onSequenceChanged()">
  <div class="tree-node-styled with-handle" *ngFor="let image of layer.images; let index = index" [class.active]="(sequenceNumber$ | async) === index" (click)="setSequenceId(index);" title="{{image.media?.title}}">
    <div class="handle">
      <i class="fa fa-grip-dots-vertical"></i>
    </div>
    <span class="flex-shrink-0"># {{ index + 1 }}</span>
    <img *ngIf="image.media?.thumbnail" [src]="mediaService.getUrl(image.media, 'thumbnail')" class="image-thumbnail mr-3 ml-3">
    <span *ngIf="!image.media?.thumbnail" class="image-placeholder fa fa-image ml-3 mr-3"></span>
    <span *ngIf="image.media?.dimensions?.depth">{{image.media?.dimensions?.depth}} μm</span><br>
    <button class="btn btn-primary ml-auto"  [class]="{'bg-secondary' : image.default}"
      (click)="$event.stopImmediatePropagation(); setDefault(index);" title="{{ 'set_as_start_image' | translate }}">
      <i class="fa fa-fw" [class]="image.default ? 'fas fa-star' : 'far fa-star'" title="{{ 'set_as_start_image' | translate }}"></i>
    </button>
    <button class="btn btn-danger ml-1"
      (click)="$event.stopImmediatePropagation(); deleteImage(index, this);">
      <i class="fa fa-fw fa-times" title="{{ 'delete_image_description' | translate }}"></i>
    </button>
  </div>
</div>

<div class="images-footer">
  <button class="btn btn-secondary" *ngIf="layer" (click)="addImage()">
    <i class="fa fa-fw fa-plus" title="{{ 'new_image_description' | translate }}"></i>
    {{ 'new_image_description' | translate }}
  </button>
</div>
