import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PointOfInterest, Note } from '@medsurf/models';
import { DragulaService } from 'ng2-dragula';

type NoteExpandable = Note & { isExpanded?: boolean } 

@Component({
  selector: 'medsurf-poi-note',
  templateUrl: './poi-note.component.html',
  styleUrls: ['./poi-note.component.scss']
})
export class PoiNoteComponent implements OnInit{

  @Input() poi: PointOfInterest;

  @Output() changeEmitter = new EventEmitter();

  constructor(
    private dragulaService: DragulaService
  ) {}

  ngOnInit(): void {
    this.dragulaService.destroy("POI_NOTE_" + this.poi.id);
    this.dragulaService.createGroup("POI_NOTE_" + this.poi.id, {
      moves: (el, container, handle) => {
        const h = el.querySelector('.handle');
        return handle.classList.contains('handle') || h.contains(handle);
      }
    });
  }

  public removeNoteFromPoi(note: Note): void {
    const index = this.poi.notes.findIndex(n => note === n);
    if (index !== -1) {
      this.poi.notes.splice(index, 1);
      this.changeEmitter.emit();
    }
  }

  public addNoteToPoi(): void {
    const order = this.poi.notes.length ? Math.max(...this.poi.notes.map((note: Note) => note.order)) + 1 : 0;
    const newNote: NoteExpandable = {name: '', title: '', dirty: true, order, isExpanded: true};
    this.poi.notes.push(newNote);
    this.changeEmitter.emit();
  }

  public onSortNotes() {
    this.poi.notes.forEach((note, i) => {
      note.order = i;
    })
    this.changeEmitter.emit();
  }

}
