import { Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MediaService } from '@medsurf/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Editor, TinyMCE } from 'tinymce';
import { ConfigSettings, getConfig } from './config/config';
import wordFilter from '@policyco/tinymce-word-paste-filter';
import {
  AudioMediaPlugin,
  ExtLinkPlugin,
  ImageMediaPlugin,
  IntLinkPlugin,
  MarkerLinkPlugin,
  PDFMediaPlugin,
  VideoMediaPlugin,
  BoxTypePlugin,
  BoxWidthPlugin,
  BoxAlignmentPlugin
} from './plugins/plugins';

@Component({
  selector: 'medsurf-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, OnChanges {
  @Output()
  public contentChange = new EventEmitter<string>();
  @Output()
  public blurEmitter = new EventEmitter<InputEvent>();

  @Input()
  public toolbar: string[][];
  @Input()
  public toolbarExcludes: string[];
  @Input()
  public placeholder: string;
  @Input()
  public content: string;
  @Input()
  public minHeight = 500;
  @Input()
  public fullHeight = false;
  @Input()
  public autogrow = false;



  @ViewChild('editor')
  public editorElement: ElementRef;

  public init: Record<string, any>;
  private editor: Editor;
  private ready = false;
  private debounce: NodeJS.Timeout;
  public hidden = false;

  constructor(
    private modalService: NgbModal,
    private mediaService: MediaService,
    private _ngZone: NgZone
  ) {
  }

  ngOnInit(): void {
    this.initEditor();
  }

  initEditor() {
    this.ready = false;
    const options: ConfigSettings = {
      placeholder: this.placeholder, 
      toolbar: this.toolbar, 
      toolbarExcludes: this.toolbarExcludes
    }
    if (this.fullHeight) {
      options.height = '100%';
    } else {
      options.minHeight = this.minHeight;
    }

    if (this.autogrow) {
      options.autogrow = this.autogrow;
    }

    this.init = {
      paste_preprocess: (editor, args) => {
        const c = document.createElement('div');
        c.innerHTML = args.content;

        const eles = c.querySelectorAll('*');
        eles.forEach((el) => {
          const attributes = Array.from(el.attributes);
          attributes.forEach((attr) => { 
            if (attr.name.startsWith('data-') || attr.name.startsWith('aria-')) {
              el.removeAttribute(attr.name);
            }
          })
        })

        args.content = c.innerHTML;
        args.content = wordFilter(args.content)
      },
      ...getConfig(options),
      content_css: "/assets/styles/format.css",
      setup: this.setup(),
    };
  }

  onEditorInit() {
    this.editor.setContent(this.content || '');
    this.ready = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.ready && !!this.editor.getBody() && this.editor.getContent() !== changes.content?.currentValue) {
      this.editor.resetContent(this.content || '');
    }

    if (changes.content) {
      // Reinitialize tinymce when iframe was reloaded (after drag and drop)
      if (this.editor?.contentDocument !== this.editor?.iframeElement?.contentDocument) {
        this.reinitialize();
      }
    }
  }

  public reinitialize() {
    this.hidden = true;
    setTimeout(() => {
      this.hidden = false;
    }, 10);
  }

  public change() {
    if (this.debounce) {
      clearTimeout(this.debounce);
    }
    this.debounce = setTimeout(() => {
      const content = this.editor.getContent();
      if (this.content !== content) {
        this.contentChange.emit(content);
      }
    }, 500);
  }

  private setup() {
    return (editor: Editor) => {
      this.editor = editor;

      editor.ui.registry.addContextToolbar('infoblock', {
        predicate: (node) => node.classList.contains('box-format'),
        items: 'box-type-attention box-type-note | aligncenter alignright | box-alignment box-width',
        position: 'node',
        scope: 'node'
      });

      editor.ui.registry.addToggleButton('box-type-attention', {
        icon: 'attention',
        tooltip: 'Warn',
        onAction: (api) => BoxTypePlugin.onAction(editor, api, 'attention'),
        onSetup: (api) => BoxTypePlugin.onSetup(editor, api, 'attention')
      });
      editor.ui.registry.addToggleButton('box-type-note', {
        icon: 'note',
        tooltip: 'Ok',
        onAction: (api) => BoxTypePlugin.onAction(editor, api, 'note'),
        onSetup: (api) => BoxTypePlugin.onSetup(editor, api, 'note')
      });

      editor.ui.registry.addMenuButton('box-alignment', {
        icon: 'objects-align-left',
        tooltip: 'Alignment',
        fetch: (cb) => BoxAlignmentPlugin.fetch(editor, cb)
      })

      editor.ui.registry.addMenuButton('box-width', {
        icon: 'width',
        tooltip: 'Width',
        fetch: (cb) => BoxWidthPlugin.fetch(editor, cb)
      })

      editor.ui.registry.addToggleButton('marker', {
        icon: 'marker',
        tooltip: 'Insert/edit annotation link',
        onAction: MarkerLinkPlugin.getOnAction(editor, this._ngZone, this.modalService, this.mediaService),
        onSetup: MarkerLinkPlugin.getOnSetup(editor)
      });
    
      editor.ui.registry.addToggleButton('intlink', {
        icon: 'link',
        tooltip: 'Insert/edit internal link',
        onAction: IntLinkPlugin.getOnAction(editor, this._ngZone, this.modalService, this.mediaService),
        onSetup: IntLinkPlugin.getOnSetup(editor)
      });

      editor.ui.registry.addToggleButton('extlink', {
        icon: 'link-external',
        tooltip: 'Insert/edit external link',
        onAction: ExtLinkPlugin.getOnAction(editor, this._ngZone, this.modalService, this.mediaService),
        onSetup: ExtLinkPlugin.getOnSetup(editor)
      });

      editor.ui.registry.addButton('unlink', {
        icon: 'unlink',
        tooltip: 'Remove link',
        onAction: () => {
          editor.execCommand('Unlink');
        }
      });

      editor.ui.registry.addToggleButton('mediaimage', {
        icon: 'image',
        tooltip: 'Insert/edit image',
        onAction: ImageMediaPlugin.getOnAction(editor, this._ngZone, this.modalService, this.mediaService),
        onSetup: ImageMediaPlugin.getOnSetup(editor)
      });

      editor.ui.registry.addToggleButton('mediapdf', {
        icon: 'pdf',
        tooltip: 'Insert/edit pdf',
        onAction: PDFMediaPlugin.getOnAction(editor, this._ngZone, this.modalService, this.mediaService),
        onSetup: PDFMediaPlugin.getOnSetup(editor)
      });

      editor.ui.registry.addToggleButton('mediaaudio', {
        icon: 'audio',
        tooltip: 'Insert/edit audio',
        onAction: AudioMediaPlugin.getOnAction(editor, this._ngZone, this.modalService, this.mediaService),
        onSetup: AudioMediaPlugin.getOnSetup(editor)
      });

      editor.ui.registry.addToggleButton('mediavideo', {
        icon: 'video',
        tooltip: 'Insert/edit video',
        onAction: VideoMediaPlugin.getOnAction(editor, this._ngZone, this.modalService, this.mediaService),
        onSetup: VideoMediaPlugin.getOnSetup(editor)
      });

      editor.ui.registry.addIcon('attention', '<i class="fa fa-exclamation-circle" style="color: #dc3545;"></i>');
      editor.ui.registry.addIcon('note', '<i class="fa fa-check-circle" style="color: #28a745;"></i>');
      editor.ui.registry.addIcon('width', '<i class="fa fa-arrows-h"></i>');
      editor.ui.registry.addIcon('objects-align-left', '<i class="fa fa-objects-align-left"></i>');
      editor.ui.registry.addIcon('objects-align-center', '<i class="fa fa-objects-align-center-horizontal"></i>');
      editor.ui.registry.addIcon('objects-align-right', '<i class="fa fa-objects-align-right"></i>');
      editor.ui.registry.addIcon('bold', '<i class="fa fa-bold"></i>');
      editor.ui.registry.addIcon('italic', '<i class="fa fa-italic"></i>');
      editor.ui.registry.addIcon('underline', '<i class="fa fa-underline"></i>')
      editor.ui.registry.addIcon('color-picker', '<i class="fa fa-palette"></i>');
      editor.ui.registry.addIcon('marker', '<i class="fa fa-map-marker"></i>');
      editor.ui.registry.addIcon('color-swatch-remove-color', '<i class="fa fa-ban"></i>');
      editor.ui.registry.addIcon('subscript', '<i class="fa fa-subscript"></i>');
      editor.ui.registry.addIcon('superscript', '<i class="fa fa-superscript"></i>');
      editor.ui.registry.addIcon('link', '<i class="fa fa-link"></i>');
      editor.ui.registry.addIcon('link-external', '<i class="fa fa-external-link"></i>');
      editor.ui.registry.addIcon('unlink', '<i class="fa fa-unlink"></i>');
      editor.ui.registry.addIcon('image', '<i class="fa fa-image"></i>');
      editor.ui.registry.addIcon('pdf', '<i class="fa fa-file-pdf"></i>');
      editor.ui.registry.addIcon('audio', '<i class="fa fa-volume"></i>');
      editor.ui.registry.addIcon('video', '<i class="fa fa-video"></i>');
      editor.ui.registry.addIcon('image-options', '<i class="fa fa-ellipsis"></i>');
      editor.ui.registry.addIcon('ordered-list', '<i class="fa fa-list-ol"></i>');
      editor.ui.registry.addIcon('unordered-list', '<i class="fa fa-list-ul"></i>');
      editor.ui.registry.addIcon('indent', '<i class="fa fa-indent"></i>');
      editor.ui.registry.addIcon('outdent', '<i class="fa fa-outdent"></i>');
      editor.ui.registry.addIcon('table', '<i class="fa fa-table"></i>');
      editor.ui.registry.addIcon('insert-character', '<i class="fa fa-omega"></i>');
      editor.ui.registry.addIcon('remove-formatting', '<i class="fa fa-remove-format"></i>');
      editor.ui.registry.addIcon('sourcecode', '<i class="fa fa-code"></i>');
      editor.ui.registry.addIcon('fullscreen', '<i class="fa fa-expand"></i>');
      editor.ui.registry.addIcon('help', '<i class="far fa-question-circle"></i>');
    };
  }
}
