<medsurf-navigation></medsurf-navigation>

<div class="detail-container">
  <div class="mb-3">
    <a routerLink="/users">
      <i class="fal fa-fw fa-long-arrow-left"></i> {{ 'back_to_overview' | translate }}
    </a>
  </div>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title mb-0" translate="user_account"></h3>
    </div>
    <div class="card-body">
      <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
        <div class="form-group row">
          <label for="user_firstname" class="col-lg-4 col-form-label" translate="user_firstname"></label>
          <div class="col-lg-8">
            <input class="form-control" id="user_firstname" type="text" name="firstname" formControlName="firstName" [class.is-invalid]="f.firstName.invalid && f.firstName.touched">
            <div class="invalid-feedback" *ngFor="let err of f.firstName.errors | keyvalue">{{ err.value | translate }}</div>
          </div>
        </div>
        <div class="form-group row">
          <label for="user_lastname" class="col-lg-4 col-form-label" translate="user_lastname"></label>
          <div class="col-lg-8">
            <input class="form-control" id="user_lastname" type="text" name="lastname" formControlName="lastName" [class.is-invalid]="f.lastName.invalid && f.lastName.touched">
            <div class="invalid-feedback" *ngFor="let err of f.lastName.errors | keyvalue">{{ err.value | translate }}</div>
          </div>
        </div>
        <div class="form-group row">
          <label for="user_email" class="col-lg-4 col-form-label" translate="user_email"></label>
          <div class="col-lg-8">
            <input class="form-control" id="user_email" type="email" name="email" formControlName="email" [class.is-invalid]="f.email.invalid && f.email.touched">
            <div class="invalid-feedback" *ngFor="let err of f.email.errors | keyvalue">{{ err.value | translate }}</div>
          </div>
        </div>
        <div class="form-group row">
          <label for="user_role" class="col-lg-4 col-form-label" translate="user_role"></label>
          <div class="col-lg-6">
            <select class="form-control" id="user_role" name="role" formControlName="role" [class.is-invalid]="f.role.invalid && f.role.touched">
              <option *ngFor="let role of defaultValues.roles" [value]="role.value">{{ role.name }}</option>
            </select>
            <div class="invalid-feedback" *ngFor="let err of f.role.errors | keyvalue">{{ err.value | translate }}</div>
          </div>
        </div>
        <div class="form-group row" *ngIf="isNewUser">
          <div class="col-lg-12">
            <div class="alert alert-info" role="alert">
              {{ 'new_user_get_message_via_email' | translate }}
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-lg-8">
            <button type="submit" class="btn btn-secondary" translate="save_user" [disabled]="userForm.invalid"></button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="card mt-3" *ngIf="!isNewUser">
    <div class="card-header">
      <h3 class="card-title mb-0" translate="reset_password"></h3>
    </div>
    <div class="card-body">
      <button type="button" (click)="resetPassword()" class="btn btn-primary" translate="reset_password"></button>
    </div>
  </div>

  <div class="card mt-3 border border-danger" *ngIf="canDelete(user)">
    <div class="card-header">
      <h3 class="card-title mb-0" translate="delete_account"></h3>
    </div>
    <div class="card-body">
      <button (click)="deleteUser()" type="submit" class="btn btn-danger" translate="delete"></button>
    </div>
  </div>

</div>
