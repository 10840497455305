import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Select, Store } from '@ngxs/store';
import { GetUsers } from '@medsurf/actions';
import { UserState } from '@medsurf/state';
import { Settings, SETTINGS } from '@medsurf/services';
import { User } from '@medsurf/models';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'medsurf-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  @Select(UserState.users)
  public users$: Observable<User[]>;
  public users: User[];

  constructor(private router: Router,
              private store: Store,
              private actions$: Actions,
              @Inject(SETTINGS) private settings: Settings) {
  }

  ngOnInit() {
    this.store.dispatch(new GetUsers());
    this.users$
      .pipe(filter((users: User[]) => users?.length > 0))
      .subscribe((users: User[]) => {
        this.users = users.sort((a: User, b: User): number => {
          const lastNameA = a?.lastName?.toUpperCase();
          const lastNameB = b?.lastName?.toUpperCase();
          if (lastNameA < lastNameB) {
            return -1;
          }
          if (lastNameA > lastNameB) {
            return 1;
          }
          return 0;
        });
      });
  }

  gotoUserDetail(user: User) {
    this.router.navigate(['user', user.id]);
  }

  addNewUser() {
    this.router.navigate(['user', 'new']);
  }
}
