import { NgZone } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Editor } from 'tinymce';
import { LinkEditorComponent } from '../../common/editor/link-editor/link-editor.component';
import { LinkFormat } from '../../common/format';
import { ChangeEventCallback, OnAction, OnSetup, ToolbarToggleButtonInstanceApi } from '../plugins';

const nodeChangeEventCallback: ChangeEventCallback = (buttonApi: ToolbarToggleButtonInstanceApi, editor: Editor) => async (eventApi) => {
  const element = (<HTMLElement>eventApi.element);
  const isAnchor = element?.tagName?.toLowerCase() === 'a';
  const isExternalLink = !element?.classList?.contains('internal') && !element?.classList?.contains('marker') && !element?.dataset?.id;
  const hasSelectionContent = !!editor.selection.getContent();

  const enable = isAnchor && isExternalLink && !hasSelectionContent || hasSelectionContent && !/<(\bvideo\b|\baudio\b|\bimg\b|\ba\b)[\s\S]/i.test(editor.selection.getContent()) && (!isAnchor || isExternalLink);
  buttonApi.setEnabled(enable);
  buttonApi.setActive(enable && isExternalLink && isAnchor);
};

const getOnSetup: OnSetup = (editor: Editor) => (buttonApi: ToolbarToggleButtonInstanceApi) => {
  editor.on('NodeChange', nodeChangeEventCallback(buttonApi, editor));
  return () => {
    editor.off('NodeChange', nodeChangeEventCallback(buttonApi, editor));
  };
};

const getOnAction: OnAction = (editor: Editor, zone: NgZone, modalService: NgbModal) =>
  async (buttonApi: ToolbarToggleButtonInstanceApi) => {
    zone.run(async () => {
      const node: HTMLAnchorElement = <HTMLAnchorElement>editor.selection.getNode();
      const isExternalLinkNode = !!Array.from(node.classList).find((element: string) => element === 'external');
      if (isExternalLinkNode) {
        editor.selection.select(node);
      }
      const selection: string = editor.selection.getContent();
      let innerText: string = selection === node.innerText ? node.innerText : selection;
      if (!innerText) {
        innerText = node.innerText;
      }

      const modal: NgbModalRef = modalService.open(LinkEditorComponent);
      modal.componentInstance.format = LinkFormat.EXTERNAL;
      modal.componentInstance.linkText = node?.href;
      modal.componentInstance.target = node?.target;

      let result: { linkText: string, target: string } | null = null; 
      try {
        result = await modal.result;
      } catch (e) {
        return;
      }

      if (!result?.linkText?.match(/^http|mailto|https/)) {
        result.linkText = 'https://' + result.linkText;
      }

      if (node?.target === result?.target && node?.href === result.linkText) {
        return;
      }

      if (isExternalLinkNode) {
        editor.dom.setAttribs(node, {
          target: result.target ?? '_blank',
          href: result.linkText
        });
        editor.fire('Change');
      } else {
        const element: HTMLAnchorElement = document.createElement('a');
        element.target = result.target ?? '_blank';
        element.href = result.linkText;
        element.className = 'external';
        element.innerHTML = innerText;
        editor.insertContent(element.outerHTML);
      }
    });
  };

export { getOnAction, getOnSetup };
