import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GenerateChapterPDF, IndexChanged, AddAlert, GenerateChapterPDFSuccess } from '@medsurf/actions';
import { MediaService } from '@medsurf/services';
import { BlockNode, AlertType } from '@medsurf/models';
import { ActionCompletion, Actions, ofActionCompleted, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'medsurf-chapters-main-settings-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent implements OnInit, OnDestroy {

  @Input()
  public selectedBlock: BlockNode;
  public generating = false;
  private _destroyed = new Subject<boolean>();

  constructor(
    public mediaService: MediaService,
    private store: Store,
    private actions$: Actions
  ) {}

  ngOnInit(): void {
    this.actions$.pipe(
      takeUntil(this._destroyed),
      ofActionCompleted(GenerateChapterPDFSuccess)
    ).subscribe(async (completion: ActionCompletion<GenerateChapterPDFSuccess>) => {
      if (completion.action.forAuthor) {
        this.selectedBlock.page.pdfMediaAuthor = completion.action.media;
      } else {
        this.selectedBlock.page.pdfMedia = completion.action.media;
      }
      this.store.dispatch(new AddAlert({
        message: 'chapter_pdf_generated',
        duration: 3,
        dismissable: true,
        type: AlertType.SUCCESS
      }));
      this.generating = false;
    });
  }

  public generatePdf(forceGeneration: boolean = false) {
    this.generating = true;
    this.store.dispatch(new GenerateChapterPDF(this.selectedBlock, forceGeneration, false));
  }

  public generateAuthorPdf(forceGeneration: boolean = false) {
    this.generating = true;
    this.store.dispatch(new GenerateChapterPDF(this.selectedBlock, forceGeneration, true));
  }

  public onChange(): void {
    this.store.dispatch(new IndexChanged([this.selectedBlock]));
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
