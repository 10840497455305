import { NgZone } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Editor } from 'tinymce';
import { MarkerEditorComponent } from '../../common/editor/marker-editor/marker-editor.component';
import { LinkFormat } from '../../common/format';
import { ChangeEventCallback, OnAction, OnSetup, ToolbarToggleButtonInstanceApi } from '../plugins';

const nodeChangeEventCallback: ChangeEventCallback = (buttonApi: ToolbarToggleButtonInstanceApi, editor: Editor) => async (eventApi) => {
  const element = (<HTMLElement>eventApi.element);
  const isAnchor = element?.tagName?.toLowerCase() === 'a';
  const isMarkerLink = element?.classList?.contains('marker');
  const hasSelectionContent = !!editor.selection.getContent();
  buttonApi.setEnabled(hasSelectionContent || isAnchor);
  buttonApi.setActive(isMarkerLink && isAnchor);
};

const getOnSetup: OnSetup = (editor: Editor) => (buttonApi: ToolbarToggleButtonInstanceApi) => {
  editor.on('NodeChange', nodeChangeEventCallback(buttonApi, editor));
  return () => {
    editor.off('NodeChange', nodeChangeEventCallback(buttonApi, editor));
  };
};

const getOnAction: OnAction = (editor: Editor, zone: NgZone, modalService: NgbModal) =>
  async (buttonApi: ToolbarToggleButtonInstanceApi) => {
    zone.run(async () => {
      const node: HTMLAnchorElement = <HTMLAnchorElement>editor.selection.getNode();
      const linkText: string = node?.pathname?.replace('/', '');
      const selection: string = editor.selection.getContent();
      let innerText: string = selection === node.innerText ? node.innerText : selection;

      if (!innerText) {
        innerText = node.innerText;
      }
      const format: string = LinkFormat.MARKER;
      const modal: NgbModalRef = modalService.open(MarkerEditorComponent);
      modal.componentInstance.format = format;
      modal.componentInstance.linkText = linkText;

      const result: string = await modal.result;
      editor.insertContent(`<a class="marker" href="${result}">${innerText}</a>`);
    });
  };

export { getOnAction, getOnSetup };
