import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PanelService } from '../../../panel.service';
import { Store } from '@ngxs/store';
import { Annotation, Image, PointOfInterest, Slide } from '@medsurf/models';
import { DragulaService } from 'ng2-dragula';
import { SlideState } from '@medsurf/state';

@Component({
  selector: 'medsurf-poi-annotation',
  templateUrl: './poi-annotation.component.html',
  styleUrls: ['./poi-annotation.component.scss']
})
export class PoiAnnotationComponent implements OnInit, OnChanges {

  @Input() poi: PointOfInterest;
  @Input() slide: Slide;
  @Input() imageAnnotationSet: Set<string>;
  @Input() poiToImageMap: ReturnType<typeof SlideState.poiToImageMap>;

  @Output() changeEmitter = new EventEmitter();
  @Output() setHoverMarker = new EventEmitter<Annotation>();

  public annotationsListExpanded = true;
  public poiAnnotationSet = new Set<string>();

  constructor(
    private store: Store,
    public panelService: PanelService,
    private dragulaService: DragulaService
  ) {
  }

  ngOnInit() {
    this.dragulaService.destroy("POI_ANNOTATION_" + this.poi.id);
    this.dragulaService.createGroup("POI_ANNOTATION_" + this.poi.id, {
      moves: (el, container, handle) => {
        const h = el.querySelector('.handle');
        return handle.classList.contains('handle') || h.contains(handle);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.slide) {
      this.poiAnnotationSet = new Set(this.poi.annotations.map(a => a.id));
    }
  }

  public assignAnnotationsToPoiImages(removeOnOtherImages = false) {
    const map = this.poiToImageMap[this.poi.id];

    this.slide.layers.forEach(layer => {
      layer.images.forEach(image => {
        
        const tmpAnnotationSet = new Set(image.annotations.map(a => a.id));
        this.poi.annotations.forEach(annotation => {
          if (map[layer.id]?.items?.[image.id]) {
            if (!tmpAnnotationSet.has(annotation.id)) {
              image.annotations.push({ id: annotation.id });
            }
          } else if (removeOnOtherImages) {
            if (tmpAnnotationSet.has(annotation.id)) {
              const index = image.annotations.findIndex(a => annotation.id === a.id);
              if (index !== -1) {
                image.annotations.splice(index, 1);
              }
            }
          }
        })
      })
    })
    this.changeEmitter.emit();
  }

  public goToAnnotation(annotation: Annotation) {
    this.setHoverMarker.emit(null);
    setTimeout(() => {
      this.setHoverMarker.emit(annotation);
    })
  }

  public addMarkerToPoi(annotation: Annotation): void {
    if (!this.poi.annotations) {
      this.poi.annotations = [];
    }
    if (this.poi.annotations.findIndex(a => a.id === annotation.id) === - 1) {
      this.poi.annotations.push({ id: annotation.id });
      this.changeEmitter.emit();
    }
  }

  public removeMarkerFromPoi(annotation: Annotation): void {
    const index = this.poi.annotations.findIndex(a => a === annotation);
    if (index !== -1) {
      this.poi.annotations.splice(index, 1);
      this.changeEmitter.emit();
    }
  }
}
