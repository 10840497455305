<h3 class="mt-4 mb-4 text-secondary" translate="cover_picture"></h3>
<p translate="cover_picture_info"></p>
<div class="position-relative">
  <div class="btn-toolbar position-absolute m-2" *ngIf="page.media">
    <div class="btn-group mr-2">
      <button (click)="selectImage()" class="btn btn-secondary">{{ 'exchange_image' | translate }}</button>
    </div>
    <div class="btn-group">
      <button (click)="deleteImage()" class="btn btn-primary">{{ 'image' | translate }} {{ 'remove' | translate }}</button>
    </div>
  </div>
  <img class="cover-image mb-2" *ngIf="page.media" [src]="mediaService.getUrl(page.media)" [alt]="page.media.altText">
  <div *ngIf="!page.media">
    <label class="mr-3">{{"choose_an_image_file" | translate}}</label>
    <button class="btn btn-secondary" (click)="selectImage()" translate="choose"></button>
  </div>
</div>
