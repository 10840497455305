import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivationStart, Params, Router } from '@angular/router';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { Actions, ofActionCompleted, ofActionDispatched, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import {
  AddAlert,
  SetNewPassword,
  SetNewPasswordSuccess,
  ValidateResetToken,
  ValidateResetTokenSuccess,
  SetNewPasswordInvalid
} from '@medsurf/actions';
import { SetNewPasswordValidator } from '@medsurf/validators';
import { AlertType } from '@medsurf/models';
import { ClassValidatorFormBuilderService, ClassValidatorFormGroup } from 'ngx-reactive-form-class-validator';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'medsurf-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  /**
   * Members
   */
  private queryParams: Params;
  public isLoading: boolean;
  public isValid: boolean;
  public resetForm: ClassValidatorFormGroup;
  public isSubmitted = false;

  private _destroyed = new Subject<boolean>();

  /**
   * Constructor
   * @param router
   * @param store
   * @param actions$
   * @param translate
   * @param fb
   */
  constructor(
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private fb: ClassValidatorFormBuilderService
  ) {
    this.isLoading = true;
    this.router.events
      .pipe(filter((event: ActivationStart) => !!event.snapshot), take(1), map(event => event.snapshot))
      .subscribe((event: any) => {
        this.queryParams = event.queryParams;
        if (this.queryParams.id && this.queryParams.token) {
          this.actions$.pipe(ofActionCompleted(ValidateResetTokenSuccess), take(1)).subscribe(({ action }) => {
            this.isValid = action.valid;
            this.isLoading = false;
          });
          this.store.dispatch(new ValidateResetToken(this.queryParams.id, this.queryParams.token));
        } else {
          this.isValid = false;
          this.isLoading = false;
        }
      });
  }

  /**
   * NgOnInit
   */
  public async ngOnInit(): Promise<void> {
    this.resetForm = this.fb.group(SetNewPasswordValidator, {
      password: [''],
      password_confirm: ['']
    }, {
      validators: this.passwordConfirming,
    });

    this.actions$.pipe(ofActionCompleted(SetNewPasswordSuccess), takeUntil(this._destroyed)).subscribe(async () => {
      this.store.dispatch(new AddAlert({
        message: 'new_password_set',
        type: AlertType.SUCCESS,
        duration: 5
      }));
      await this.router.navigate(['/login']);
    });

    this.actions$.pipe(ofActionDispatched(SetNewPasswordInvalid), takeUntil(this._destroyed)).subscribe((action: SetNewPasswordInvalid) => {
      action.errors.forEach((err) => {
        this.resetForm.get(err.property).setErrors(err.constraints);
      });
      this.isSubmitted = false;
    });
  }

  get f() { return this.resetForm.controls; }

  private passwordConfirming(c: AbstractControl) {
    if (c.get('password').value !== c.get('password_confirm').value) {
      c.get('password_confirm').setErrors({ mustMatch: 'passwords_do_not_match' });
    } else {
      c.get('password_confirm').setErrors(null);
    }
  }

  public async onSubmit(): Promise<void> {
    if (this.isSubmitted || this.resetForm.invalid) {
      return;
    }
    this.isSubmitted = true;

    const { password } = this.resetForm.value;
    this.store.dispatch(new SetNewPassword(this.queryParams.id, this.queryParams.token, password));
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
