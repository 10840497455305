<div class="d-flex flex-col h-100 scrollable">
  <medsurf-navigation></medsurf-navigation>
  <ng-container *ngIf="!isSearching && !(results?.length > 0)">
    <div class="alert alert-warning" role="alert">
      <h2>{{ 'no_results' | translate }}</h2>
    </div>
  </ng-container>

  <ng-container *ngIf="isSearching">
    <h2 class="mt-4 ml-4">{{ 'loading' | translate }}</h2>
  </ng-container>
  <ng-container *ngIf="results?.length > 0">
    <table class="table table-hover table-vcenter mt-2" #table>
      <thead class="bg-primary text-white">
      <tr>
        <th><span></span></th>
        <th><span translate="slide_id"></span></th>
        <th><span translate="title"></span></th>
        <th><span translate="breadcrumbs"></span></th>
        <th><span translate="search_source"></span></th>
        <th><span translate="resource_type"></span></th>
      </tr>
      </thead>
      <tbody id="table-body">
        <tr *ngFor="let slideNode of results"
            [class.d-none]="slideNode.deleted"
            (click)="selectSlide(slideNode.completeRoute)"
            [id]="slideNode.id">
          <td>
            <img class="slides"
                 [src]="slideNode.page.thumbnailMedia ? mediaService.getUrl(slideNode.page.thumbnailMedia, 'thumbnail') : '/assets/images/no-image.png'"
            />
          </td>
          <td>{{slideNode.page.slideId}}</td>
          <td>{{slideNode.page.title}}</td>
          <td>{{ slideNode.breadcrumbs }}</td>
          <td>{{ slideNode.source | translate }}</td>
          <td>{{mediaService.getMediaTypeLabel({type: slideNode.page.mediaType}) | translate}}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
