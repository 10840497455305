import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PointOfInterest, Bounds, ImagePosition } from '@medsurf/models';
import { round } from 'lodash';

@Component({
  selector: 'medsurf-poi-header',
  templateUrl: './poi-header.component.html',
  styleUrls: ['./poi-header.component.scss']
})
export class PoiHeaderComponent {

  @Input() poi: PointOfInterest;
  @Input() currentBounds: Bounds;
  @Input() currentPosition: ImagePosition;

  @Output() goToPoi = new EventEmitter();
  @Output() changeEmitter = new EventEmitter();

  public updatePoiPosition(): void {
    this.poi.bounds = { 
      x: this.currentBounds.x, 
      y: this.currentBounds.y, 
      width: this.currentBounds.width, 
      height: this.currentBounds.height 
    };
    this.poi.position = { 
      x: this.currentPosition.x,
      y: this.currentPosition.y
    };
    this.poi.zoom = this.currentPosition.zoom;
    this.changeEmitter.emit();
  }

  public boundsString() {
    if (this.poi.bounds?.x) {
      const { x, y, width, height } = this.poi.bounds;
      return `(${round(x, 4)}, ${round(y, 4)}) (${round(width, 4)} × ${round(height, 4)})`;
    } else {
      const { x, y } = this.poi.position;
      const { zoom } = this.poi;
      return `(${round(x, 4)}, ${round(y, 4)}) @ ${round(zoom, 2)}x`;
    }
  }
}
