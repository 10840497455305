<div class="upload">
  <ngx-dropzone [multiple]="true" accept="*" (change)="onFileDrop($event)" [disableClick]="uploading" class="px-4 py-5">
    <ngx-dropzone-label>

      <div class="mb-4">
        <ng-container *ngIf="!uploading">
          <span class="fal fa-3x fa-file-upload"></span>
          <div class="mt-3">
            {{ 'dropzone_media' | translate }}
          </div>
        </ng-container>

        <ng-container *ngIf="uploading">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="mt-3">
            {{ 'dropzone_media_uploading' | translate }}
          </div>
        </ng-container>
      </div>

      <div *ngFor="let upload of uploads" class="upload-item border border-primary"
        [class.text-success]="upload.completed" [class.text-danger]="upload.error"
        [class.border-success]="upload.completed" [class.border-danger]="upload.error"
      >
        <div class="upload-item__container">
          <div class="upload-item__title">
            {{ upload.file.name }}
          </div>
          <div class="upload-item__size">
            ({{upload.file.size | fileSize}})
          </div>
          <div class="upload-item__progress progress" *ngIf="upload.started && !upload.completed && !upload.error">
            <div class="progress-bar" role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              [aria-valuenow]="upload.chunksReturned / upload.chunksQuantity * 100"
              [style.width]="upload.chunksReturned / upload.chunksQuantity * 100 + '%'"
            ></div>
          </div>
          <i *ngIf="!upload.started" class="upload-item__check fal fa-pause-circle"></i>
          <div *ngIf="upload.started && !upload.completed && !upload.error" class="upload-item__spinner spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <i *ngIf="upload.completed" class="upload-item__check fa fa-check-circle"></i>
          <i *ngIf="upload.error" class="upload-item__check fa fa-times-circle"></i>
        </div>
        <div class="upload-item__error" *ngIf="upload.error">
          {{ upload.error.message | translate }}
        </div>
      </div>
      <div class="upload-item border border-primary" *ngIf="uploads?.length > 0">
        <div class="upload-item__container">
          <div class="upload-item__title">
            {{ 'total' | translate }}
          </div>
          <div class="upload-item__size">
            ({{totalFileSize | fileSize}})
          </div>
        </div>
      </div>
    </ngx-dropzone-label>
  </ngx-dropzone>
</div>

