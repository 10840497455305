import { Component, Input, OnInit } from '@angular/core';
import { Actions, Select, Store } from '@ngxs/store';
import {
  BlockNode,
  ChapterNode,
  Node,
  PageType,
  SlideNode,
  SubjectNode
} from '@medsurf/models';
import { NodeService } from '@medsurf/services';
import { TrainingState } from '@medsurf/state';
import { GetRootTrainingNode, IndexChanged } from '@medsurf/actions';
import { cloneDeep, omit } from 'lodash';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'medsurf-quiz-start',
  templateUrl: './quiz-start.component.html',
  styleUrls: ['./quiz-start.component.scss']
})
export class QuizStartComponent implements OnInit {

  @Input()
  public selectedBlock: SubjectNode;

  @Select(TrainingState.rootTrainingNode)
  public rootTrainingNode$: Observable<SubjectNode>;

  constructor(private store: Store,
              private router: Router,
              private route: ActivatedRoute,
              private nodeService: NodeService,
              private actions$: Actions) {
  }

  ngOnInit(): void {
    this.store.dispatch(new GetRootTrainingNode());
  }

  public goTo(chapter, block) {
    this.router.navigate(['../', chapter.route, block.route], {relativeTo: this.route, replaceUrl: true});
  }

  public generateQuizRun(rootTrainingNode: SubjectNode) {
    this.selectedBlock.children = [];
    const quizRunNode: Node = {
      id: uuid(),
      children: [],
      page: null,
      shares: [],
    };
    quizRunNode.page = {type: PageType.QUIZ_RUN, title: 'Run'};
    this.selectedBlock.children = [quizRunNode];
    const clonedNode: Node = omit<Node>(cloneDeep<Node>(this.selectedBlock), 'children');
    this.store.dispatch(new IndexChanged([clonedNode]));
    const trainingsSlides: SlideNode[] = rootTrainingNode.children
      .map((chapterNode: ChapterNode) => chapterNode.children
        .map((node: BlockNode) => node.children)
        .reduce((acc: SlideNode[], val: SlideNode[]) => acc.concat(val), []))
      .reduce((acc: SlideNode[], val: SlideNode[]) => acc.concat(val), []);
    quizRunNode.children = trainingsSlides.map((trainingSlide: SlideNode) => {
      return {
        id: uuid(),
        children: [],
        page: trainingSlide.page,
      };
    });
    this.store.dispatch(new IndexChanged([quizRunNode]));
    this.nodeService.childrenMoved(quizRunNode);
  }
}
