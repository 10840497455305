import { EventEmitter } from 'events';
import paper from 'paper';

class Tool extends EventEmitter {
  protected _tool: paper.Tool;

    constructor(protected _id, protected _localPaper, protected _element, protected _canvas, protected _layout, protected _container) {
        super();
    }

    _init() {
        this._tool = new paper.Tool();
        this._tool.minDistance = 10;
    }

    cleanUp() {
        if (this._tool) {
            this._tool.remove();
            this._tool = null;
        }
    }

    get tool() {
        return this._tool;
    }

    get id() {
        return this._id;
    }
}

export default Tool;
