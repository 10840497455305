import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AnnotationType, Slide, FreeForm, Annotation, Keymap, Image, PointOfInterest, ImagePosition, MediaType, Group, Layer } from '@medsurf/models';
import { PanelService } from '../../panel.service';
import { AnnotationService } from '@medsurf/services';
import { Select, Store } from '@ngxs/store';
import { AddMarker, DeleteMarker, SetShowOnlyAnnotationsOfImage, SlideChanged } from '@medsurf/actions';
import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';
import { SlideState } from '@medsurf/state';

type AnnotationExpandable = Annotation & { isExpanded?: boolean } 

@Component({
  selector: 'medsurf-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnChanges, OnInit {
  @Output() setSelftestKeymap = new EventEmitter<Annotation>();
  @Output() activateFreeTool = new EventEmitter<FreeForm>();
  @Output() setHoverMarker = new EventEmitter<Annotation>();
  @Output() setMarker = new EventEmitter<Annotation>();

  @Input() marker: Annotation;
  @Input() slide: Slide;
  @Input() sequenceId: number;
  @Input() layerId: number;
  @Input() currentPosition: ImagePosition;

  @Select(SlideState.image)
  public currentImage$: typeof SlideState.image;

  @Select(SlideState.annotationToImageMap)
  public annotationToImageMap$: typeof SlideState.annotationToImageMap;

  @Select(SlideState.annotationToPoiMap)
  public annotationToPoiMap$: typeof SlideState.annotationToPoiMap;

  @Select(SlideState.annotationToGroupMap)
  public annotationToGroupMap$: typeof SlideState.annotationToGroupMap;

  @Select(SlideState.imageAnnotationSet)
  public imageAnnotationSet$: typeof SlideState.imageAnnotationSet;

  @Select(SlideState.displayedAnnotations)
  public displayedAnnotations$: typeof SlideState.displayedAnnotations;

  @Select(SlideState.showOnlyAnnotationsOfImage)
  public showOnlyAnnotationsOfImage$: typeof SlideState.showOnlyAnnotationsOfImage;

  public AnnotationType = AnnotationType;
  public bulkActionMap: { [key: string]: Annotation } = {};

  constructor(
    public panelService: PanelService, 
    private annotationService: AnnotationService,
    private store: Store,
    public elRef: ElementRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.marker && this.marker?.id) {
      setTimeout(() => {
        this.scrollAnnotationIntoView();
      }, 100);
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.scrollAnnotationIntoView();
    }, 500);
  }

  private scrollAnnotationIntoView() {
    if (!this.marker?.id) return;

    const el = this.elRef.nativeElement.querySelector('#annotation-list-item-' + this.marker?.id);
    if (el) el.scrollIntoView({
      behavior: 'smooth',
    });
  }

  public trackById(index: number, entity: { id: string}) {
    return entity.id || index;
  }

  public get hasSelectedBulk() {
    return Object.keys(this.bulkActionMap).length !== 0;
  }


  public setShowOnlyAnnotationsOfImage(value: boolean) {
    this.store.dispatch(new SetShowOnlyAnnotationsOfImage(value));
  }

  public editMarker(annotation: AnnotationExpandable) {
    const isLastMarker = this.marker?.id === annotation.id;
    annotation.isExpanded = isLastMarker ? !annotation.isExpanded : true;

    if (annotation.isExpanded || !isLastMarker) {
      this.setHoverMarker.emit(null);
      this.setMarker.emit(null);

      setTimeout(() => {
        this.setMarker.emit(annotation);
        this.setHoverMarker.emit(annotation);
      })
    }
  }

  public goToAnnotation(annotation: Annotation) {
    this.setHoverMarker.emit(null);

    setTimeout(() => {
      this.setHoverMarker.emit(annotation);
    })
  }

  public updatePosition(annotation: Annotation) {
    const isDeepZoom = this.slide.layers[this.layerId].images[this.sequenceId].media?.type === MediaType.DEEPZOOM;
    annotation.dirty = true;
    this.annotationService.adjustPosition(annotation, this.currentPosition, isDeepZoom);
    this.store.dispatch(new SlideChanged());
  }

  private addAnnotation(marker: Annotation) {
    marker.dirty = true;
    this.store.dispatch(new AddMarker(marker));
  } 

  public addMarker(endType, text?) {
    const marker = this.annotationService.createMarker(endType, text);
    this.addAnnotation(marker);
  }

  public addKeymap() {
    const keymap: Keymap = this.annotationService.createKeymap();
    keymap.columns.map(column => column.labels.map(row => {
      this.store.dispatch(new AddMarker(row.annotation));
    }));
    this.addAnnotation(keymap);
  }

  public addFreeform(shape) {
    const freeForm = this.annotationService.createFreeForm(shape);
    this.addAnnotation(freeForm);
  }

  public addText() {
    const marker = this.annotationService.createAnnotation();
    this.addAnnotation(marker);
  }

  public updateAnnotationImage(annotation: Annotation, images: Image[], add: boolean) {
    images.forEach(image => {
      const index = image.annotations.findIndex((a) => a.id === annotation.id);
      if (add && index === -1) {
        image.annotations.push({ id: annotation.id });
      } else if (!add && index !== -1) {
        image.annotations.splice(index, 1);
      }
    })
    this.store.dispatch(new SlideChanged());
  }

  public updateAnnotationPoi(annotation: Annotation, pois: PointOfInterest[], add: boolean) {
    pois.forEach(poi => {
      const index = poi.annotations.findIndex((a) => a.id === annotation.id);
      if (add && index === -1) {
        poi.annotations.push({ id: annotation.id });
      } else if (!add && index !== -1) {
        poi.annotations.splice(index, 1);
      }
    })
    this.store.dispatch(new SlideChanged());
  }

  public updateAnnotationGroup(annotation: Annotation, group: Group) {
    const index = group.annotations.findIndex((a) => a.id === annotation.id);
    if (index === -1) {
      group.annotations.push({ id: annotation.id });
    } else {
      group.annotations.splice(index, 1);
    }
    this.store.dispatch(new SlideChanged());
  }

  public deleteAnnotation(annotation: Annotation) {
    this.store.dispatch(new DeleteMarker(annotation));
  }

  public addAnnotationToBulkAction(annotation) {
    if (this.bulkActionMap[annotation.id]) {
      delete this.bulkActionMap[annotation.id];
    } else {
      this.bulkActionMap[annotation.id] = annotation;
    }
  }

  public get annotationsOnClipboard() {
    try {
      const text = localStorage.getItem('clipboard.annotation');
      const annotations = JSON.parse(text);
      return annotations?.length || 0;
    } catch (err) {
      console.log(err);
    }
    return 0;
  }

  public async pasteAnnotations(originalPosition) {
    try {
      const text =  localStorage.getItem('clipboard.annotation');
      const annotations = JSON.parse(text) as Annotation[];
      const image = this.slide.layers[this.layerId].images[this.sequenceId];
      const isDeepZoom = image.media?.type === MediaType.DEEPZOOM;

      const annotationIdMap = new Map(annotations.map(annotation => [annotation.id, v4() ]))

      annotations.forEach((annotation) => {
        annotation.id = annotationIdMap.get(annotation.id);
        annotation.dirty = true;
        if (annotation.source) annotation.source.id = v4();
        if (annotation.label) {
          annotation.label.id = v4();
        }
        if (annotation.targets) {
          annotation.targets.forEach((target) => {
            target.id = v4();
          })
        }
        if ((annotation as FreeForm).freeFormStyle) {
          (annotation as FreeForm).freeFormStyle.id = v4();
        }
        if ((annotation as Keymap).keymapStyle) {
          (annotation as Keymap).keymapStyle.id = v4();
        }
        if ((annotation as Keymap).columns) {
          (annotation as Keymap).columns.forEach((col) => {
            col.id = v4();
            col.labels = col.labels.filter(label => label.annotation?.id && annotationIdMap.has(label.annotation.id));
            col.labels.forEach((label) => {
              label.id = v4();
              label.annotation = { id: annotationIdMap.get(label.annotation.id) };
            })
          })
        }

        if (!originalPosition) this.annotationService.adjustPosition(annotation, this.currentPosition, isDeepZoom);
        image.annotations.push({ id: annotation.id });
        this.slide.annotations.push(annotation);
      });
      this.store.dispatch(new SlideChanged());
    } catch (err) {
      console.log(err);
    }
  }

  public bulkDeleteAnnotations() {
    for (const key in this.bulkActionMap) {
      const annotation = this.bulkActionMap[key];
      this.store.dispatch(new DeleteMarker(annotation));
    }
    this.bulkActionMap = {};
    this.store.dispatch(new SlideChanged());
  }

  public bulkCopyAnnotations() {
    const bulkActionItems = Object.values(this.bulkActionMap).map((item) => {
      return cloneDeep(item);
    });

    localStorage.setItem('clipboard.annotation', JSON.stringify(bulkActionItems));
    this.bulkActionMap = {};
  }
}
