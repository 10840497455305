import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core'
import { NGXS_PLUGINS } from '@ngxs/store'
import { NgxsHistoryPlugin } from './ngxs-history.plugin'

export const NGXS_HISTORY_PLUGIN_OPTIONS = new InjectionToken('NGXS_HISTORY_PLUGIN_OPTIONS');

@NgModule()
export class NgxsHistoryPluginModule {
  static forRoot(config?: any/*PluginOptions*/): ModuleWithProviders<NgxsHistoryPluginModule> {
    return {
      ngModule: NgxsHistoryPluginModule,
      providers: [
        {
          provide: NGXS_PLUGINS,
          useClass: NgxsHistoryPlugin,
          multi: true,
        },
        {
          provide: NGXS_HISTORY_PLUGIN_OPTIONS,
          useValue: config,
        }
      ],
    }
  }
}
