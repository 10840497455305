import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { v4 as uuid } from 'uuid';
import { Slide, Annotation, Group } from '@medsurf/models';
import { SlideChanged } from '@medsurf/actions';
import { PanelService } from '../../panel.service';
import { DragulaService } from 'ng2-dragula';
import { SlideState } from '@medsurf/state';

@Component({
  selector: 'medsurf-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
  @Output() goToGroup = new EventEmitter();
  @Output() setHoverMarker = new EventEmitter<Annotation>();

  @Input() marker: Annotation;
  @Input() slide: Slide;
  @Input() currentPosition;
  @Input() sequenceId: number;
  @Input() layerId: number;

  @Select(SlideState.displayedGroups)
  public displayedGroups$: typeof SlideState.displayedGroups;

  @Select(SlideState.imageAnnotationSet)
  public imageAnnotationSet$: typeof SlideState.imageAnnotationSet;

  constructor(
    private store: Store,
    public panelService: PanelService,
    private dragulaService: DragulaService
  ) {}

  ngOnInit() {
    this.dragulaService.destroy("GROUPS");
    this.dragulaService.createGroup("GROUPS", {
      moves: (el, container, handle) => {
        const h = el.querySelector('.handle');
        return handle.classList.contains('handle') || h.contains(handle);
      }
    });
  }

  public onSortGroups() {
    this.slide.groups.forEach((group, i) => {
      group.order = i;
    })
    this.onChange();
  }

  onChange() {
    this.store.dispatch(new SlideChanged());
  }

  public removeGroup(group: Group) {
    const index = this.slide.groups?.findIndex(g => g?.id === group?.id);
    if (index !== -1) {
      this.slide.groups[index].deleted = true;
      this.onChange();
    }
  }

  public addGroup() {
    if (this.slide.groups === undefined) {
      this.slide.groups = [];
    }
    const group: Group = {};
    group.id = uuid();
    group.name = 'Neue Gruppe';
    group.annotations = [];
    group.order = this.slide.groups.length;
    this.slide.groups.push(group);
    this.onChange();
  }
}
