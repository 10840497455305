<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ 'metadata' | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Button')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="form">
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" translate="modality"></label>
      <div class="col-sm-10">
        <select id="modality" name="modality"
                class="form-control input-md"
                [(ngModel)]="slide.modality" (change)="onChange()" [compareWith]="compareConstant">
          <option [ngValue]="null"></option>
          <option *ngFor="let modality of (modalities$ | async)" [ngValue]="modality">{{modality.name}}</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 col-form-label" translate="thumbnail"></label>
      <div class="d-flex col-sm-10 d-flex align-items-start">
        <img class="thumbnail-image mb-2 mr-2" *ngIf="slide.thumbnailMedia" [src]="mediaService.getUrl(slide.thumbnailMedia, 'thumbnail')" [alt]="slide.thumbnailMedia.altText">
        <div class="btn-toolbar" *ngIf="slide.thumbnailMedia">
          <div class="btn-group mr-2">
            <button (click)="selectMedium('thumbnailMedia')" class="btn btn-secondary">{{ 'exchange_image' | translate }}</button>
          </div>
          <div class="btn-group">
            <button (click)="deleteMedium('thumbnailMedia')" class="btn btn-primary">{{ 'image' | translate }} {{ 'remove' | translate }}</button>
          </div>
        </div>
        <div *ngIf="!slide.thumbnailMedia">
          <button class="btn btn-secondary" (click)="selectMedium('thumbnailMedia')" translate="choose"></button>
        </div>
      </div>
    </div>
  </form>
</div>
