import { MediaType, Media } from '@medsurf/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaDialogComponent } from '../../media-dialog/media-dialog.component';

const openDialog = async (allowedTypes: MediaType[], modalService: NgbModal, selected?: Media[]): Promise<Media> => {
  const modalRef = modalService.open(MediaDialogComponent, {scrollable: true, size: 'xxl'});
  modalRef.componentInstance.allowedTypes = allowedTypes;
  if (selected) {
    modalRef.componentInstance.selected = selected;
  }

  const medium: Media = await modalRef.result;
  return medium;
};

export { openDialog };
