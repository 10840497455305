import Element from '../element';
import paper from 'paper';
import InteractiveArea from '../freeform/interactiveArea';
import { TipType } from '@medsurf/models';

class Selection extends Element {
  private _circle: any;
  private _segments: any[];
  private _rectangle: any;
  private _targets: any[];

  constructor(model, private _format, private _localPaper, private _container, private _marker) {
    super(model);
    this._circle = null;
    this._segments = [];
    this._rectangle = null;
    this._targets = [];
    this._init();
  }

  _init() {
    this._element = new this._localPaper.Group();
    this.draw();
  }

  draw() {
    this.drawSegments();
    this.drawLabel();
    if (this._model.targets && this._model.targets.length > 0) {
      this.drawFork();
      this.drawTargets();
    } else if (this._marker instanceof InteractiveArea) {
      this.drawFork();
    }
  }

  drawSegments() {
    if (this._model.path && this._model.path.length > 0) {
      if (this._segments) {
        for (const segment of this._segments) {
          segment.remove();
        }
      }
      let index = 0;
      this._model.path.forEach(() => {
        this._segments.push(this.drawSegment(index, this._model.path[index]));
        index++;
      });
    }
  }

  drawSegment(index, element) {
    const factor = Math.max(1 / (this._localPaper.view.zoom /2), 1);
    const radius = factor * this._format.selection.radius;

    const segment = new paper.Path.Circle(
      new paper.Point(this._container.getAbsoluteCoords(
        element.x,
        element.y,
        this._marker._marker._imageOffset,
        this._marker._marker._imageScale
      )),
      radius
    );
    segment.strokeColor = this._format.selection.color;
    segment.fillColor = this._format.selection.color;
    segment.fillColor.alpha = this._format.selection.opacity;
    segment.strokeWidth = radius / 10;
    segment.freeform = this._marker;
    segment.positionInPath = index;
    segment.data = {
      'selectionType': 'segment'
    };
    this._element.addChild(segment);
    return segment;
  }

  drawLabel() {
    if (this._rectangle) {
      this._rectangle.remove();
    }
    const label = this._marker._labels[0];
    if (!label) {
      return;
    }
    this._rectangle = new this._localPaper.Path.Rectangle(
      new this._localPaper.Rectangle(label.element.bounds)
    );
    this._rectangle.strokeColor = this._format.selection.color;
    this._rectangle.strokeWidth = this._container.calculateFixDimensions(this._format.selection.strokeWidth);
    this._rectangle.pivot = label.element.pivot;
    this._rectangle.type = 'selection';
    this._rectangle.fillColor = this._format.selection.color;
    this._rectangle.fillColor.alpha = this._format.selection.opacity;
    this._rectangle.data = {
      'selectionType': 'label'
    };
    this._rectangle.marker = this._marker;
    this._element.addChild(this._rectangle);
  }

  drawFork() {
    const factor = Math.max(1 / (this._localPaper.view.zoom /2), 1);
    const radius = factor * this._format.selection.radius;

    this._circle = new paper.Path.Circle({
      radius,
      center: new paper.Point(
        this._container.calculateFixDimensions(radius / 2),
        this._container.calculateFixDimensions(radius / 2)
      ),
      fillColor: this._format.selection.color,
      strokeColor: this._format.selection.color,
      strokeWidth: radius / 10
    });
    this._circle.fillColor.alpha = this._format.selection.opacity;
    this._circle.type = 'selection';
    this._circle.data = {
      'selectionType': 'fork'
    };
    this._circle.marker = this._marker;
    this._element.addChild(this._circle);
  }

  drawTargets() {
    this._model.targets.forEach((target, index) => {
      this._targets.push(this.drawTarget(index, target));
    });
  }

  drawTarget(index, element) {

    const factor = Math.max(1 / (this._localPaper.view.zoom /2), 1);
    const radius = factor * this._format.selection.radius;

    const target = new paper.Path.Circle({
      radius,
      center: new paper.Point(
        radius / 2,
        radius / 2
      ),
      fillColor: this._format.selection.color,
      strokeColor: this._format.selection.color,
      strokeWidth: radius / 10
    });
    target.fillColor.alpha = this._format.selection.opacity;
    target.type = 'selection';
    target.data = {
      'index': index,
      'selectionType': 'target'
    };
    target.marker = this._marker;
    this._element.addChild(target);
    return target;
  }

  arrange() {
    this.drawLabel();
    this.drawSegments();

    let index = 0;
    for (const target of this._targets) {
      const source = this._container.getAbsoluteCoords(
        this._model.targets[index].position.x,
        this._model.targets[index].position.y,
        this._marker._imageOffset,
        this._marker._imageScale
      );
      target.position = source;
      target.bringToFront();
      index++;
    }
  }

  setScale() {
    this.arrange();
  }

  setPosition(source, fork) {
    this._circle.position = fork;
    this._circle.bringToFront();

    this._rectangle.position = source;
    this._rectangle.bringToFront();
  }

  get targets() {
    return this._targets;
  }

  get rectangle() {
    return this._rectangle;
  }

  get circle() {
    return this._circle;
  }

  cleanUp() {
    for (const target of this._targets) {
      target.remove();
    }
    for (const segment of this._segments) {
      segment.remove();
    }
    if (this._rectangle) {
      this._rectangle.remove();
    }
    if (this._circle) {
      this._circle.remove();
    }
    if (this._element) {
      this._element.remove();
    }
  }
}

export default Selection;
