<div class="input-group color-palette" >
  <div class="input-group-prepend">
    <button class="btn btn-outline-dark" *ngIf="supportsEyeDropper()" (click)="startEyeDropper()" [title]="'pick_color_from_screen' | translate">
      <i class="fa fa-eye-dropper"></i>
    </button>
  </div>
  <input id="color-picker" name="color-picker" class="form-control input-md" autocomplete="off"
         [value]="color ? color : ''"
         [colorPicker]="color"
         (colorPickerChange)="onColorChange($event)"
         cpPosition="bottom"/>
  <div class="input-group-append" ngbDropdown placement="bottom-right">
    <button type="button" class="btn btn-outline-dark" ngbDropdownToggle>
      <span class="caret"></span>
    </button>
    <div ngbDropdownMenu>
      <button ngbDropdownItem *ngFor="let color of colorList" (click)="onColorSelect(color)">{{color.name}}</button>
    </div>
  </div>
</div>
