import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Choice,
  FreeTextQuestion,
  KprimeQuestion,
  LongListQuestion,
  MappingQuestion,
  Question,
  QuestionType,
  SingleChoiceQuestion
} from '@medsurf/models';
import { SlideChanged } from '@medsurf/actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'medsurf-question-dialog',
  templateUrl: './question-dialog.component.html',
  styleUrls: ['./question-dialog.component.scss']
})
export class QuestionDialogComponent {

  @Input() question: SingleChoiceQuestion & LongListQuestion & MappingQuestion & KprimeQuestion & FreeTextQuestion;

  public alert: string;

  constructor(private store: Store,
              public modal: NgbActiveModal,
              private translate: TranslateService) { }

  onChange() {
    this.store.dispatch(new SlideChanged());
  }

  private canConvertQuestion(question: Question, numberOfChoices: number) {
    return question.choices.length <= numberOfChoices ||
        (question.choices.length > numberOfChoices &&
            question.choices.filter((choice) => !!choice.label).length <= numberOfChoices);

  }

  private convertQuestionChoices(question: Question, numberOfChoices: number): Choice[] {
    const choices = question.choices.filter((choice) => !!choice.label);
    while (choices.length < numberOfChoices) {
      choices.push({
        label: '',
        order: choices.length
      });
    }
    return choices;
  }

  questionTypeChanged(questionType: string) {
    this.closeAlert();
    switch (questionType) {
      case QuestionType.TRUE_FALSE:
        this.question.solutions = [];
        if (!this.question.choices) {
          this.question.choices = [{
            label: '',
            order: 0
          }];
        }
        break;
      case QuestionType.FREE_TEXT:
        break;
      case QuestionType.INDICATION:
        break;
      case QuestionType.KPRIME:
        this.question.solutions = [];
        if (this.canConvertQuestion(this.question, 4)) {
          this.question.choices = this.convertQuestionChoices(this.question, 4);
        } else {
          this.translate.get('change_question_type_alert', {numberOfChoices: 4}).subscribe((translation) => {
            this.alert = translation;
          });
          return;
        }
        break;
      case QuestionType.LONG_LIST:
        delete this.question.solution;
        if (!this.question.choices) {
          this.question.choices = [{
            label: '',
            order: 0
          }];
        }
        break;
      case QuestionType.SINGLE_CHOICE:
        delete this.question.solution;
        if (this.canConvertQuestion(this.question, 5)) {
          this.question.choices = this.convertQuestionChoices(this.question, 5);
        } else {
          this.translate.get('change_question_type_alert', {numberOfChoices: 5}).subscribe((translation) => {
            this.alert = translation;
          });
          return;
        }
        break;
      case QuestionType.MAPPING:
        this.question.solutions = ['0'];
        if (!this.question.choices) {
          this.question.choices = [{
            label: '',
            order: 0
          }];
        } else {
          this.question.choices.forEach((choice) => {
            choice.label = choice.label.replace(/<.*?>/g, '');
          });
        }
        break;
      default:
        return;
    }
    this.question.type = questionType;
    this.onChange();
  }

  closeAlert() {
    this.alert = '';
  }

  closeModal() {
    this.closeAlert();
    this.modal.dismiss('Button');
  }
}
