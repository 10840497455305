import { Media, MediaType } from './media.model';
import { Base } from './base.model';
import { Group } from './group.model';
import { Image } from './image.model';
import { Page } from './page.model';
import { Question } from './training.model';
import { Annotation } from './annotation.model';
import { PointOfInterest } from './point-of-interest.model';

export enum SlideStatus {
  EMPTY = 'empty',
  DRAFT = 'draft',
  IN_REVIEW = 'in_review',
  REVIEW_NOK = 'review_not_ok',
  FINAL = 'final'
}

// Translate to english???
export enum Window {
  KNOCHENFENSTER = 'KF',
  LUNGENFENSTER = 'LF',
  WEICHTEILFENSTER = 'WF',
  HIRNFENSTER = 'HF',
  MAGNETRESONANZTOMOGRAPHIE_T1 = 'MRT T1',
  MAGNETRESONANZTOMOGRAPHIE_T2 = 'MRT T2',
  ANGIOGRAPHIE = 'AG',
  DIFFUSIONSGEWICHTETE_SEQUENZ = 'DGS',
  CORONARSCHNITT_1 = 'Coronarschnitt 1',
  CORONARSCHNITT_2 = 'Coronarschnitt 2',
  CORONARSCHNITT_3 = 'Coronarschnitt 3',
  SAGGITALSCHNITT_1 = 'Saggitalschnitt 1',
  SAGGITALSCHNITT_2 = 'Saggitalschnitt 2',
  SAGGITALSCHNITT_3 = 'Saggitalschnitt 3',
  LICHTMIKROSKOPIE = 'LM',
  ELEKTRONENMIKROSKOPIE = 'EM',
  FLUORENZENZMIKROSKOPIE = 'FM'
}

export interface Slide extends Page {
  slideId?: number;
  header?: string;
  body?: string;
  status?: SlideStatus;
  mediaType?: MediaType;
  modality?: Modality;
  sideBarSize?: number;
  groups?: Group[];
  annotations?: Annotation[];
  pois?: PointOfInterest[];
  thumbnailMedia?: Media;
  pdfMedia?: Media;
  pdfMediaAuthor?: Media;
  layers?: Layer[];
  questions?: Question[];
  defaults?: Defaults;
  editedAt?: Date;
  hidden?: boolean;
  restricted?: boolean;
}

export interface Modality extends Base {
  name?: string;
  shortcut?: string;
}

export interface Layer extends Base {
  slide?: Slide;
  window?: Window;
  default?: boolean;
  position: number;
  images?: Image[];
}

export interface Defaults {
  labelColor?: string;
  pointerColor?: string;
  labelFontSize?: number;
}

