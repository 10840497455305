import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Group,
  Image,
  Layer,
  Slide,
} from '@medsurf/models';
import { SlideState } from '@medsurf/state';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { LinkFormat } from '../../format';

@Component({
  selector: 'medsurf-code-editor-marker',
  templateUrl: './marker-editor.component.html',
  styleUrls: ['./marker-editor.component.scss']
})
export class MarkerEditorComponent implements OnInit, OnDestroy {

  @Select(SlideState.slide)
  public slide$: Observable<Slide>;

  @Input()
  public format: LinkFormat | string;

  @Input()
  public linkText: string;

  public linkGroup: string;
  public linkLayer: string;
  public linkSequence: string;

  public layers: Layer[];
  public groups: Group[];
  public sequences: Image[];

  private _destroyed = new Subject<boolean>();


  constructor(public modal: NgbActiveModal) {
  }

  public ngOnInit(): void {
    this.slide$.pipe(takeUntil(this._destroyed)).subscribe((slide: Slide) => {
      this.groups = slide.groups;
      this.layers = slide.layers;
      this.sequences = ((slide.layers?.length > 0 && slide.layers[0] !== undefined) ? slide.layers[0]?.images : []);

      if (this.linkText?.includes(',')) {
          [this.linkLayer, this.linkSequence, this.linkGroup] = this.linkText.split(',');
      } else {
        this.linkGroup = this.linkText;
      }
    });
  }

  public save() {
    if (this.layers?.length > 1 || this.sequences?.length > 1) {
      this.modal.close(`${this.linkLayer ?? 'layer-0'},${this.linkSequence ?? 'sequence-0'},${this.linkGroup}`);
      return;
    }
    this.modal.close(this.linkGroup);
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
