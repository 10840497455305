<div ngbDropdown (openChange)="isOpen = $event" container="body" autoClose="outside" [class]="classes" [title]="title">
  <div class="badge badge-secondary" [id]="dropdownId" ngbDropdownToggle>
    <ng-content></ng-content>
  </div>
  <div ngbDropdownMenu>
    <ng-container *ngIf="isOpen">
      <ng-container *ngIf="list.length !== 0">
        <button ngbDropdownItem (click)="update(list, list.length !== map.count)">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [indeterminate]="map.count && list.length !== map.count" [checked]="list.length === map.count">
            <label class="form-check-label">{{ 'all' | translate }}</label>
          </div>
        </button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem  *ngFor="let item of list; trackBy:trackById; let itemIndex = index" (click)="update([item], !map.items?.[item.id])">
          <div class="form-check form-check-sm">
            <input class="form-check-input" type="checkbox" [checked]="map.items?.[item.id]">
            <label class="form-check-label" *ngIf="(item[labelKey] ? item[labelKey] : '#' + (itemIndex + 1)) as labelText" [title]="labelText">
              <i *ngIf="labelIcon" [class]="'fa ' + labelIcon"></i>
              {{ labelText }}
            </label>
          </div>
        </button>
      </ng-container>
      <button disabled ngbDropdownItem *ngIf="list.length === 0">{{ noResultLabel }}</button>
    </ng-container>
  </div>
</div>