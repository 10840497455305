<div class="mb-3">{{'notes' | translate}}</div>
<div class="note-container"  [dragula]="'POI_NOTE_' + poi.id" [(dragulaModel)]="poi.notes" (dragulaModelChange)="onSortNotes()">
  <div class="tree-node-styled dark collapsable with-handle" *ngFor="let note of poi.notes" [class.active]="note.isExpanded">
    <div class="collapsable-header" (click)="note.isExpanded = !note.isExpanded">
      <div class="handle"><i class="fa fa-grip-dots-vertical"></i></div>
      <span>{{ note.name || '...' }}</span>
      <button class="btn btn-sm btn-danger ml-auto" [title]="'delete_note' | translate" (click)="removeNoteFromPoi(note)">
        <i class="fa fa-fw fa-trash"></i>
      </button>
    </div>
    <div class="collapsable-body" *ngIf="note.isExpanded">
      <div class="form-group">
        <label class="control-label" translate="title"></label>
        <input type="text" class="form-control input-md" [(ngModel)]="note.name" (ngModelChange)="changeEmitter.emit()"/>
      </div>
      <div class="form-group mb-0">
        <label class="control-label" translate="text"></label>
        <medsurf-editor 
          [(content)]="note.text" 
          (contentChange)="changeEmitter.emit()" 
          [toolbarExcludes]="editorToolbarExcludes"
          [autogrow]="true"
          [minHeight]="150">
        </medsurf-editor>
      </div>
    </div>
  </div>
</div>

<div class="form-row">
  <button class="btn btn-secondary ml-3" (click)="addNoteToPoi()">
    {{ (poi.notes.length === 0 ? 'add_note' : 'add_new_note') | translate }}
  </button>
</div>


