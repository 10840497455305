import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { DEFAULT_VALUES } from '../../../../common/constants/default-values';
import {Slide, } from '@medsurf/models';
import { SlideChanged } from '@medsurf/actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'medsurf-defaults',
  templateUrl: './defaults.component.html',
  styleUrls: ['./defaults.component.scss']
})
export class DefaultsComponent implements OnInit {
  @ViewChild('defaultForm', { static: true }) defaultForm: NgForm;

  @Input() slide: Slide;

  constructor(private store: Store,
              @Inject(DEFAULT_VALUES) public defaultValues) { }

  ngOnInit() {
    if (!this.slide.defaults) {
      this.resetToDefaults();
    }
  }

  onChange() {
    this.store.dispatch(new SlideChanged());
  }

  public resetToDefaults() {
    this.slide.defaults = {
      labelColor: null,
      pointerColor: null,
    };
  }
}
