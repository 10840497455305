import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Group } from '@medsurf/models';
import { DEFAULT_VALUES } from '../../../../../common/constants/default-values';

@Component({
  selector: 'medsurf-group-header',
  templateUrl: './group-header.component.html',
  styleUrls: ['./group-header.component.scss']
})
export class GroupHeaderComponent {

  @Input()
  public group: Group;

  @Output()
  public changeEmitter = new EventEmitter();

  constructor(
    @Inject(DEFAULT_VALUES) public defaultValues
  ) {
  }
}
