import { Component, Inject, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { DEFAULT_VALUES } from '../../common/constants/default-values';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaDialogComponent } from '../../common/media-dialog/media-dialog.component';
import { GetMetadata, SaveMetadata } from '@medsurf/actions';
import { Media, MediaType, Metadata } from '@medsurf/models';
import { MediaService, SETTINGS, Settings } from '@medsurf/services';
import { MetadataState } from '@medsurf/state';

@Component({
  selector: 'medsurf-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss']
})
export class MetadataComponent implements OnInit {
  @Select(MetadataState.metadata)
  public metadata$: Observable<Metadata>;

  public startPageExpanded = true;
  public htmlMetadataExpanded = true;


  constructor(
    @Inject(SETTINGS) public settings: Settings,
    private store: Store,
    public mediaService: MediaService,
    private modalService: NgbModal,
    @Inject(DEFAULT_VALUES) public defaultValues
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(new GetMetadata());
  }

  public updateMetadata(metadata): void {
    this.store.dispatch(new SaveMetadata(metadata));
  }

  public selectMedia(metadata): void {
    const modalRef = this.modalService.open(MediaDialogComponent, {scrollable: true, size: 'xxl'});
    modalRef.componentInstance.allowedTypes = [MediaType.IMAGE];
    modalRef.componentInstance.selected = metadata.carouselMedia ? [metadata.carouselMedia] : null;
    modalRef.result.then((media: Media) => {
      metadata.carouselMedia = media;
    });
  }

  public removeMedia(metadata): void {
    metadata.carouselMedia = null;
  }
}
