<editor
 *ngIf="!hidden"
  class="medsurf-editor"
  [class.full]="fullHeight"
  #editor
  apiKey="your-api-key"
  outputFormat="html"
  modelEvents="change input nodechange undo redo"
  (onKeyUp)="change()"
  (onChange)="change()"
  (onInit)="onEditorInit()"
  (onBlur)="blurEmitter.emit($event)"
  [init]="init"
></editor>
