<div class="nav-tab-content">
  <form class="form" #defaultForm="ngForm">
    <div class="form-group">
      <label class="control-label" for="label-color" translate="default_label_color"></label>
      <div>
        <medsurf-color-palette id="label-color" name="color" [colorList]="defaultValues.colors" [(ngModel)]="slide.defaults.labelColor" (ngModelChange)="onChange()"></medsurf-color-palette>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label" for="pointer-color" translate="default_pointer_color"></label>
      <div>
        <medsurf-color-palette id="pointer-color" name="color" [colorList]="defaultValues.colors" [(ngModel)]="slide.defaults.pointerColor" (ngModelChange)="onChange()"></medsurf-color-palette>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label" for="fontSize" translate="default_font_size"></label>
      <div>
        <select id="fontSize" name="fontSize" class="form-control input-md" [(ngModel)]="slide.defaults.labelFontSize" (ngModelChange)="onChange()">
          <option *ngFor="let fontSize of defaultValues.fontSizes" [ngValue]="fontSize.value">{{fontSize.name}}</option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <div>
        <button class="btn btn-secondary" (click)="resetToDefaults()" translate="reset_defaults"></button>
      </div>
    </div>
    <hr/>
  </form>
</div>
