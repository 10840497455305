import { NgZone } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Editor } from 'tinymce';
import { LinkEditorComponent } from '../../common/editor/link-editor/link-editor.component';
import { LinkFormat } from '../../common/format';
import { ChangeEventCallback, OnAction, OnSetup, ToolbarToggleButtonInstanceApi } from '../plugins';


const nodeChangeEventCallback: ChangeEventCallback = (buttonApi: ToolbarToggleButtonInstanceApi, editor: Editor) => async (eventApi) => {
  const element = (<HTMLElement>eventApi.element);
  const isAnchor = element?.tagName?.toLowerCase() === 'a';
  const isInternalLink = element?.classList?.contains('internal');
  const hasSelectionContent = !!editor.selection.getContent();

  const enable = isAnchor && isInternalLink && !hasSelectionContent || hasSelectionContent && !/<(\bvideo\b|\baudio\b|\bimg\b|\ba\b)[\s\S]/i.test(editor.selection.getContent()) && (!isAnchor || isInternalLink);

  buttonApi.setEnabled(enable);
  buttonApi.setActive(enable && isInternalLink && isAnchor);
};

const getOnSetup: OnSetup = (editor: Editor) => (buttonApi: ToolbarToggleButtonInstanceApi) => {
  editor.on('NodeChange', nodeChangeEventCallback(buttonApi, editor));
  return () => {
    editor.off('NodeChange', nodeChangeEventCallback(buttonApi, editor));
  };
};

const getOnAction: OnAction = (editor: Editor, zone: NgZone, modalService: NgbModal) =>
  async (buttonApi: ToolbarToggleButtonInstanceApi) => {
    zone.run(async () => {
      const node: HTMLAnchorElement = <HTMLAnchorElement>editor.selection.getNode();
      const isInternalLinkNode = !!Array.from(node.classList).find((element: string) => element === 'internal');
      if (isInternalLinkNode) {
        editor.selection.select(node);
      }
      const selection: string = editor.selection.getContent();
      let innerText: string = selection === node.innerText ? node.innerText : selection;
      if (!innerText) {
        innerText = node.innerText;
      }

      const modal: NgbModalRef = modalService.open(LinkEditorComponent);
      modal.componentInstance.format = LinkFormat.INTERNAL;
      modal.componentInstance.linkText = node?.dataset?.linkText ?? '';
      modal.componentInstance.target = node?.target;

      let result: { linkText: string, target: string, id: string } | null = null;
      try{
       result = await modal.result;
      }
      catch(e){
        return;
      }

      if (node?.target === result?.target && !result?.id) {
        return;
      }

      if (isInternalLinkNode) {
        editor.dom.setAttribs(node,{
          ...(result?.id && {href: `/node/${result.id}`}),
          target: result.target ?? '_blank',
          'data-link-text': result.linkText ?? node.dataset.linkText,
        });
        editor.fire('Change');
      } else {
        const element: HTMLAnchorElement = document.createElement('a');
        element.target = result.target ?? '_blank';
        element.href = `/node/${result.id}`;
        element.className = 'internal';
        element.innerHTML = innerText;
        element.dataset.linkText=result.linkText;
        editor.insertContent(element.outerHTML);
      }
    });
  };

export { getOnAction, getOnSetup };
