import { Component, Input } from '@angular/core';
import { IndexChanged } from '@medsurf/actions';
import {
  SubjectNode,
  Vocabulary,
  VocabularyGroup,
  VocabularyWord
} from '@medsurf/models';
import { Store } from '@ngxs/store';

@Component({
  selector: 'medsurf-vocabulary',
  templateUrl: './vocabulary.component.html',
  styleUrls: ['./vocabulary.component.scss']
})
export class VocabularyComponent {

  @Input()
  public selectedBlock: SubjectNode;

  constructor(private store: Store) {
  }


  public get vocabulary(): Vocabulary {
    return (this.selectedBlock?.page as Vocabulary) || null;
  }

  public set vocabulary(value) {
    (this.selectedBlock.page as Vocabulary) = value;
  }


  public onChange() {
    this.store.dispatch(new IndexChanged([this.selectedBlock]));
  }

  public addVocabularyWord(vocabularyGroup: VocabularyGroup) {
    const vocabularyWord: VocabularyWord = {
      key: 'Schlüssel',
      value: 'Wert',
    };
    vocabularyGroup.vocabularyWords.push(vocabularyWord);
    this.onChange();
  }

  public addVocabularyGroup() {
    const order = this.vocabulary.vocabularyGroups.reduce((max, v) =>  Math.max(v.order, max), -1) + 1;
    this.vocabulary.vocabularyGroups.push({name: 'Neue Gruppe', vocabularyWords: [], order: order });
    this.onChange();
  }

}
