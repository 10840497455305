import paper from 'paper';
import MarkerElement from '../../markerElement';

class Label extends MarkerElement {
  _label: any;
  private _rectangle: any;
  private _background: any;
  private _symbol: any;
  private _originalPoint: any;

  constructor(private _text, private _format, marker) {
    super(_text, marker);
    this._element = null;
    this._label = null;
    this._rectangle = null;
    this._background = null;
    this._symbol = null;
    this._originalPoint = null;
    this._init();
  }

  _init() {
    this._element = new paper.Group();
    this.draw();
  }

  draw() {
    this._element.removeChildren();
    this._drawLabel();
    this._label.bringToFront();
  }

  _drawLabel() {
    this._label = new paper.PointText(new paper.Point(0, 0));
    this._label.fillColor = this._format.fontColor || this._marker._model.label.fontColor;
    this._label.fontFamily = this._format.fontFamily;
    this._label.fontWeight = this._format.fontWeight;
    this._label.fontSize = (this._marker._model.label.fontSize || this._format.fontSize) / this._marker._imageScale;
    this._label.content = this._text;
    this._label.justification = this._format.justification;
    this._label.marker = this._marker;
    this._label.leading = this._format.leading * this._label.fontSize;
    this._element.addChild(this._label);
    if (this._format.justification === 'right') {
      this._element.pivot = this._element.bounds.rightCenter;
    } else {
      this._element.pivot = this._element.bounds.leftCenter;
    }

    /**
     * Setup background object
     * TODO check for keymap -> +1 depth
     */
    if (this._marker._model.label &&
      (typeof (this._marker._model.label.background) === 'boolean' ||
        typeof (this._marker._model.label.background) === 'undefined')
    ) {
      if (this._marker._model.label.background) {
        this._marker._model.label.background = {opacity: 0.4};
      } else {
        this._marker._model.label.background = {opacity: 0};
      }
    }

    if (this._marker._model.label.background.opacity !== 0 || this._marker._model.label.border) {
      const factor = this._marker && this._marker._model.style && this._marker._model.style.strokeWidth ?
        this._marker._model.style.strokeWidth :
        1;
      const path = new paper.Path(),
        width = this._label.bounds.width + factor * this._format.border.strokeWidth,
        height = this._label.bounds.height + factor * this._format.border.strokeWidth;
      path.add(new paper.Point(0, 0));
      path.add(new paper.Point(width, 0));
      path.add(new paper.Point(width, height));
      path.add(new paper.Point(0, height));
      this._rectangle = this._roundCorners(
        path,
        null /*Zoom.getZoomedValue(this.marker._localPaper, this._format.border.cornerRadius)*/
      );
      this._rectangle.position.x = this._label.bounds.x - 0 + (this._label.bounds.width / 2);
      this._rectangle.position.y = this._label.bounds.y - 0 + (this._label.bounds.height / 2);
      if (this._marker._model.label.background.opacity !== 0) {
        this._rectangle.fillColor = this._marker._model.label.background.opacity !== 0 ?
          this._marker._model.label.backgroundInBorderColor ?
            (this._marker._model.label.strokeColor || this._marker._model.label.background.color || '#ffffff') :
            this._marker._model.label.background.color || '#ffffff' :
          undefined;
        this._rectangle.fillColor.alpha = this._marker._model.label.background.opacity;
      }
      this._rectangle.strokeColor = this._marker._model.label.strokeColor || this._format.fontColor;
      this._rectangle.strokeWidth = (this._marker._model.label.border) ?
        this.marker._model.label.strokeWidth || this._format.border.strokeWidth :
        0;
      this._element.dashArray = [1, 0];
      if (this._model?.freeFormStyle?.dash) {
        const [dashLength, gapLength]: string[] = this._model.freeFormStyle.dash.split(',');
        if (dashLength && gapLength) {
          const dashFactor =  this._marker?._model?.freeFormStyle?.strokeWidth ? this._marker._model.freeFormStyle.strokeWidth : 1;
          this._element.dashArray = [
            Number.parseInt(dashLength, 10) * dashFactor,
            Number.parseInt(gapLength, 10) * dashFactor
          ];
        }
      }
      this._element.addChild(this._rectangle);
    }
  }

  calculateFontSize(size) {
    const minZoom = this._marker._container.minZoom * 100;
    return Math.floor(size * (100 / minZoom));
  }

  arrange() {
    this.draw();
  }

  setScale() {
    this.draw();
  }

  setPosition(x, y) {
    const factor = this._marker && this._marker._model.style && this._marker._model.style.strokeWidth ?
      this._marker._model.style.strokeWidth :
      1;

    this._element.pivot = this._element.bounds[this._format.pivot];
    if (!this._rectangle) {
      switch (this._format.pivot) {
        case 'bottomCenter':
          y -= factor * 5;
          break;

        case 'leftCenter':
          x += factor * (1 + 5);
          break;

        case 'topCenter':
          y += factor * 5;
          break;

        case 'rightCenter':
          x -= factor * (2 + 5);
          break;
      }
    }
    this._element.position = new paper.Point(x, y);
  }

  get originalPoint() {
    return this._originalPoint;
  }

  get element() {
    return this._element;
  }

  get label() {
    return this._label;
  }

  get rectangle() {
    return this._rectangle;
  }

  cleanUp() {
    this._element.removeChildren();
    super.cleanUp();
  }
}

export default Label;
