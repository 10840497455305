import { Component, Inject, Input } from '@angular/core';
import { DEFAULT_VALUES } from '../../common/constants/default-values';
import { ConstantState } from '@medsurf/state';
import { Modality, Slide, Base, Media, MediaType } from '@medsurf/models';
import { MediaService, Settings, SETTINGS } from '@medsurf/services';
import { SlideChanged } from '@medsurf/actions';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaDialogComponent } from '../media-dialog/media-dialog.component';

@Component({
  selector: 'medsurf-metadata-dialog',
  templateUrl: './metadata-dialog.component.html',
  styleUrls: ['./metadata-dialog.component.scss']
})
export class MetadataDialogComponent {

  @Select(ConstantState.modalities)
  public modalities$: Observable<Modality[]>;

  @Input() slide: Slide;

  private _destroyed = new Subject<boolean>();

  public generating = false;

  constructor(
    private store: Store,
    @Inject(DEFAULT_VALUES) public defaultValues,
    @Inject(SETTINGS) private settings: Settings,
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    public mediaService: MediaService
  ) {
  }

  onChange() {
    this.store.dispatch(new SlideChanged());
  }

  public async selectMedium(mediaType: 'thumbnailMedia' | 'pdfMedia'): Promise<void> {
    const modalRef = this.modalService.open(MediaDialogComponent, {scrollable: true, size: 'xxl'});
    modalRef.componentInstance.allowedTypes = [MediaType.DEEPZOOM, MediaType.IMAGE, MediaType.VIDEO];
    modalRef.componentInstance.selected = this.slide[mediaType] ? [this.slide[mediaType]] : null;
    const media: Media = await modalRef.result;
    this.slide[mediaType] = media;
    this.onChange();
  }

  public deleteMedium(mediaType: 'thumbnailMedia' | 'pdfMedia'): void {
    this.slide[mediaType] = null;
    this.onChange();
  }

  public downloadMedium(mediaType: 'thumbnailMedia' | 'pdfMedia'): void {
    const fileType: 'file' | 'thumbnail' = mediaType === 'thumbnailMedia' ? 'thumbnail' : 'file';
    const url = this.mediaService.getUrl(this.slide[mediaType], fileType);
    window.open(url, '_blank');
  }

  public compareConstant(a: Base, b: Base): boolean {
    return a && b ? a.id === b.id : a === b;
  }
}
