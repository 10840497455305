<div class="container-fluid thumbnail-container" dragula="CARD_GRID" [(dragulaModel)]="selectedBlock.children" (dragulaModelChange)="nodeMoved()" #grid>
  <div 
    class="card panel-grid"
    [id]="slide.id"
    *ngFor="let slide of selectedBlock.children"
    [class.d-none]="slide.deleted"
  >
    <div class="card-image-container" (dblclick)="selectSlide.emit({slide: slide})">
      <img
        class="card-image"
        [src]="slide.page.thumbnailMedia ? mediaService.getUrl(slide.page.thumbnailMedia, 'thumbnail') : '/assets/images/no-image.png'"
      />
      <div class="bulk-select-checkbox">
        <input type="checkbox" [value]="slide.id" [checked]="isBulkSelected(slide)" (click)="selectBulk.emit({slide: slide})">
      </div>

      <div class="number-container">
        <span class="badge badge-light">#{{ slide.position + 1 }}</span>
        <span class="badge badge-light">{{ slide.page.slideId }}</span>
      </div>

      <div class="label-container">
        <span class="badge badge-light text-info" [title]="slide.page.status | translate" *ngIf="slide.page.status !== 'empty'">
          <span [class]="slideService.getSlideStatusIcon(slide.page)"></span>
        </span>
        <span class="badge badge-light" [title]="mediaService.getMediaTypeLabel({ type: slide.page.mediaType }) | translate">
          <span [class]="'fa ' + mediaService.getMediaTypeIcon({ type: slide.page.mediaType })"></span>
        </span>
        <span *ngIf="slide.page.type === 'Training'" class="badge badge-light" [title]="'quiz' | translate">
          <span class="fas fa-question-square"></span>
        </span>
      </div>

      <button class="btn bookmark" (click)="bookmarkService.toggleSlide(slide)">
        <span *ngIf="bookmarkService.isSlideBookmarked(slide)" class="fas fa-bookmark text-warning"></span>
        <span *ngIf="!bookmarkService.isSlideBookmarked(slide)" class="far fa-bookmark"></span>
      </button>
    </div>
    <div class="card-body" (click)="selectSlide.emit({slide: slide})">
      <div class="card-title truncate">{{ slide.page.title }}</div>
    </div>
  </div>
</div>
