import Element from '../element';

class Grid extends Element {
  private cellSize: number;
  private strokeWidth: number;
  private gridColor: string;
  private _view: any;

  constructor(model, private _localPaper, container) {
    super(model);
    this.cellSize = Math.floor(20 * (100 / (container.minZoom * 100)));
    this.strokeWidth = 1;
    this.gridColor = '#000000';
    this.init();
  }

  init() {
    this.removeGrid();
    this.drawGrid();
  }

  drawGrid() {
    const rectanglesX = this._localPaper.view.bounds.width / this.cellSize;
    let rectanglesY = (this._localPaper.view.bounds.height / this.cellSize) + 1;
    if (this._view) {
      rectanglesY = (this._localPaper.view.bounds.height - 10) / this.cellSize;
    }
    this._element = new this._localPaper.Group();
    this._element.locked = true;

    const rectangle = new this._localPaper.Rectangle(this._localPaper.view.bounds);
    for (let i = 0; i <= rectanglesX; i++) {
      const correctedLeftBounds = Math.ceil(rectangle.left / this.cellSize) * this.cellSize;
      const xPos = correctedLeftBounds + i * this.cellSize;
      const topPoint = new this._localPaper.Point(xPos, rectangle.top);
      const bottomPoint = new this._localPaper.Point(xPos, rectangle.bottom);
      const gridLine = new this._localPaper.Path.Line(topPoint, bottomPoint);
      gridLine.strokeColor = this.gridColor;
      gridLine.opacity = 0.2;
      gridLine.strokeWidth = this.strokeWidth / this._localPaper.view.zoom;
      this._element.addChild(gridLine);
    }
    for (let i = 0; i <= rectanglesY; i++) {
      const correctedTopBounds = Math.ceil(rectangle.top / this.cellSize) * this.cellSize;
      const yPos = correctedTopBounds + i * this.cellSize;
      const gridLineLeftPoint = new this._localPaper.Point(rectangle.left, yPos);
      const gridLineRightPoint = new this._localPaper.Point(rectangle.right, yPos);
      const gridLine = new this._localPaper.Path.Line(gridLineLeftPoint, gridLineRightPoint);
      gridLine.strokeColor = this.gridColor;
      gridLine.opacity = 0.2;
      gridLine.strokeWidth = this.strokeWidth / this._localPaper.view.zoom;
      this._element.addChild(gridLine);
    }

    let leftPoint = new this._localPaper.Point(rectangle.left, rectangle.height / 2);
    let rightPoint = new this._localPaper.Point(rectangle.right, rectangle.height / 2);
    const gridLineH = new this._localPaper.Path.Line(leftPoint, rightPoint);
    gridLineH.strokeColor = this.gridColor;
    gridLineH.opacity = 0.2;
    gridLineH.strokeWidth = this.strokeWidth / this._localPaper.view.zoom;
    this._element.addChild(gridLineH);

    gridLineH.bounds.x = -gridLineH.bounds.width / 2;
    gridLineH.bounds.y = -gridLineH.bounds.height / 2;

    leftPoint = new this._localPaper.Point(rectangle.width / 2, rectangle.top);
    rightPoint = new this._localPaper.Point(rectangle.width / 2, rectangle.bottom);
    const gridLineV = new this._localPaper.Path.Line(leftPoint, rightPoint);
    gridLineV.strokeColor = this.gridColor;
    gridLineV.opacity = 0.2;
    gridLineV.strokeWidth = this.strokeWidth / this._localPaper.view.zoom;
    this._element.addChild(gridLineV);

    gridLineV.bounds.x = -gridLineV.bounds.width / 2;
    gridLineV.bounds.y = -gridLineV.bounds.height / 2;

    this._element.sendToBack();

    if (this.state === 'visible') {
      this._element.visible = true;
    } else {
      this._element.visible = false;
    }

    this._localPaper.view.draw();
  }

  arrange() {
    this.init();
  }

  removeGrid() {
    if (this._element) {
      for (let i = 0; i < this._element.children.length - 1; i++) {
        this._element.children[i].remove();
      }
      this._element.remove();
    }
  }
}

export default Grid;
