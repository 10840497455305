<div class="container">
  <div class="row">
    <div class="offset-md-4 col-md-4 login-panel">
      <div class="card bg-light">
        <div class="card-header">
          <h3 class="card-title m-0">{{ 'medsurf_author' | translate }} {{ 'sign_in' | translate }}</h3>
        </div>
        <div class="card-body">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <fieldset>
              <div class="form-group">
                <input class="form-control" type="email" name="email" formControlName="email" placeholder="{{ 'user_email' | translate }}" 
                  [class.is-invalid]="f.email.invalid && f.email.touched">
                <div class="invalid-feedback" *ngFor="let err of f.email.errors | keyvalue">{{ err.value | translate }}</div>
              </div>
              <div class="form-group">
                <input class="form-control" type="password" name="password" formControlName="password" placeholder="{{ 'password' | translate }}" 
                  [class.is-invalid]="f.password.invalid && f.password.touched">
                <div class="invalid-feedback" *ngFor="let err of f.password.errors | keyvalue">{{ err.value | translate }}</div>
              </div>
              <button class="btn btn-secondary btn-block mb-2" type="submit" [disabled]="loginForm.invalid">{{ 'sign_in' | translate }}</button>
              <a routerLink="/forgot_password">{{ 'password_forgotten' | translate }}</a>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
