<div class="mb-3 d-flex">
  <span>
    {{'annotations' | translate}}
  </span>

  <div ngbDropdown container="body" class="d-block ml-auto">
    <button class="btn btn-sm btn-secondary" ngbDropdownToggle [id]="'poi-'+ poi.id +'-sync'">
      {{ 'sync_image_assignment' | translate }}
    </button>
    <div ngbDropdownMenu>
      <button ngbDropdownItem (click)="assignAnnotationsToPoiImages(true)">{{ 'sync_image_assignment_add_and_remove' | translate }}</button>
      <button ngbDropdownItem (click)="assignAnnotationsToPoiImages()">{{ 'sync_image_assignment_add_only' | translate }}</button>
    </div>
  </div>

</div>
<div class="annotaiton-container" [dragula]="'POI_ANNOTATION_' + poi.id" [(dragulaModel)]="poi.annotations" (dragulaModelChange)="changeEmitter.emit()">
  <div class="tree-node-styled dark align-items-center with-handle" *ngFor="let annotation of poi.annotations">
    <div class="handle"><i class="fa fa-grip-dots-vertical"></i></div>
    <span class="mr-auto" *ngIf="panelService.getAnnotationInfo(annotation) as info">
      <small>{{ info.type }}</small><br>
      <strong>{{ info.title }}</strong>
    </span>

    <button class="btn btn-sm btn-secondary ml-2" *ngIf="imageAnnotationSet.has(annotation.id)" (click)="goToAnnotation(annotation)">
      <span class="fa fa-fw fa-location"></span>
    </button>

    <button class="btn btn-sm btn-danger ml-2" (click)="removeMarkerFromPoi(annotation)">
      <span class="fa fa-fw fa-times" title="{{ 'delete_entry_description' | translate }}"></span>
    </button>
  </div>
</div>

<div class="form-row">
  <div class="input-group mb-3 mx-3">
    <div ngbDropdown container="body" class="d-block">
      <button class="btn btn-secondary" ngbDropdownToggle [id]="'poi-'+ poi.id +'-add'">
        {{ 'add_to_poi' | translate }}
      </button>
      <div ngbDropdownMenu>
        <button ngbDropdownItem *ngFor="let annotation of slide.annotations" [disabled]="poiAnnotationSet.has(annotation.id)" (click)="addMarkerToPoi(annotation)">
          <ng-container *ngIf="panelService.getAnnotationInfo(annotation) as info">
            <span>{{ info.title }}</span>
          </ng-container>
        </button>
      </div>
    </div>
  </div>
</div>


