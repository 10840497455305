<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ 'media_library' | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Button')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <medsurf-media-library-base 
    [isSelect]="true"
    [(upload)]="isUploading"
    [multiple]="multiple"
    [allowedTypes]="allowedTypes"
    [selected]="selected"
    (selectEmitter)="onSelect($event)">
  </medsurf-media-library-base>
</div>
<div class="modal-footer" *ngIf="!isUploading">
  <button class="btn btn-outline-secondary mr-auto" type="button" (click)="toggleUploader()">{{ 'upload_new_media' | translate }}</button>
  <button
    type="button"
    class="btn btn-secondary"
    [disabled]="selectedMedia.length === 0"
    (click)="onSave()">{{ 'choose' | translate }}</button>
</div>
<div class="modal-footer" *ngIf="isUploading">
  <button class="btn btn-secondary mr-auto" type="button" (click)="toggleUploader()">{{ 'cancel_upload' | translate }}</button>
</div>
  