<h3 class="mt-4 mb-2 text-secondary">{{ 'type' | translate}}</h3>

<div class="btn-toolbar mb-4 mt-2" role="toolbar">
  <div class="btn-group mr-2" role="group">
    <button *ngFor="let pageType of pageTypes"
            type="button"
            [class.btn-secondary]="pageType === selectedBlock.page.type"
            [class.btn-primary]="pageType !== selectedBlock.page.type"
            class="btn"
            (click)="onChange(pageType)">{{ pageType | translate }}</button>
  </div>
</div>
