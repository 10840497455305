<div class="container">
    <div class="row">
      <div class="offset-md-4 col-md-4 login-panel">
        <div class="card bg-light">
          <div class="card-header">
            <h3 class="card-title m-0">{{ 'set_new_password' | translate }}</h3>
          </div>
          <div class="card-body" *ngIf="!isLoading">
            <form *ngIf="isValid" [formGroup]="resetForm" (ngSubmit)="onSubmit()">
              <fieldset>
                <div class="form-group">
                  <input class="form-control" id="new_password" type="password" name="new_password" placeholder="{{ 'new_password' | translate }}"
                    formControlName="password" [class.is-invalid]="f.password.invalid && f.password.touched">
                  <div class="invalid-feedback" *ngFor="let err of f.password.errors | keyvalue">{{ err.value | translate }}</div>
                </div>
                <div class="form-group">
                  <input class="form-control" id="new_password_confirm" type="password" name="new_password_confirm" placeholder="{{ 'new_password_confirm' | translate }}"
                    formControlName="password_confirm" [class.is-invalid]="f.password_confirm.invalid && f.password_confirm.touched">
                  <div class="invalid-feedback" *ngFor="let err of f.password_confirm.errors | keyvalue">{{ err.value | translate }}</div>
                </div>
                <button class="btn btn-secondary btn-block" type="submit" [disabled]="resetForm.invalid">{{ 'send' | translate }}</button>
              </fieldset>
            </form>
            <div *ngIf="!isValid">
              {{ 'reset_link_not_valid' | translate }}
            </div>
          </div>
          <div class="card-body d-flex justify-content-center align-items-center" *ngIf="isLoading">
            <span class="spinner-border spinner-border-sm"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  