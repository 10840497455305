import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'medsurf-media-library',
  templateUrl: './media-library.component.html',
  styleUrls: ['./media-library.component.scss']
})
export class MediaLibraryComponent implements OnInit {
  public isUploading: boolean;

  ngOnInit(): void {
    this.isUploading = false;
  }

  toggleUploader() {
    this.isUploading = !this.isUploading;
  }
}
