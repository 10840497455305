<div class="media-sidebar" *ngIf="mediaCopy">
    <div class="d-flex action-bar justify-content-end w-100">
        <div class="d-flex align-items-center" *ngIf="isSaving">
            <span class="spinner-border spinner-border-sm"></span>
            <span class="ml-2" translate="saving"></span>
        </div>
        <div class="d-flex align-items-center" *ngIf="showSaved">
            <span class="fa fa-check"></span>
            <span class="ml-2" translate="saved"></span>
        </div>

        <div ngbDropdown class="d-inline-block ml-auto" placement="bottom-right">
            <button class="btn btn-secondary" ngbDropdownToggle translate="slide_actions"></button>
            <div class="dropdown-menu" ngbDropdownMenu>
                <a ngbDropdownItem *ngIf="mediaService.isImage(mediaCopy)" [disabled]="!mediaCopy.originalFile" [href]="mediaService.getUrl(mediaCopy, 'originalFile')" target="_blank">{{ 'download_original_file' | translate }}</a>
                <a ngbDropdownItem *ngIf="!mediaService.isImage(mediaCopy)" [disabled]="mediaService.isDeepzoom(mediaCopy)" [href]="mediaService.getUrl(mediaCopy)" target="_blank">{{ 'download_original_file' | translate }}</a>
                <button ngbDropdownItem [disabled]="mediaService.isDeepzoom(mediaCopy)" (click)="replace()">{{ 'exchange' | translate }}</button>
                <div class="dropdown-divider"></div>
                <button ngbDropdownItem (click)="remove()">{{ 'delete' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="media-preview bg-secondary">
        <ng-container *ngIf="mediaCopy.file">
            <img
                *ngIf="mediaService.isImage(mediaCopy) || mediaService.isDeepzoom(mediaCopy)"
                [src]="mediaService.getUrl(media)"
                [alt]="mediaCopy.altText"
            >
            <video
                #videoPlayer
                *ngIf="mediaService.isVideo(mediaCopy)"
                [src]="mediaService.getUrl(media, 'file', true)"
                crossorigin="anonymous"
                controls
                [alt]="mediaCopy.altText"
            ></video>
            <audio
                *ngIf="mediaService.isAudio(mediaCopy)"
                [src]="mediaService.getUrl(media)"
                controls
                [alt]="mediaCopy.altText"
            ></audio>
        </ng-container>
    </div>

    <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li [ngbNavItem]="1">
            <a ngbNavLink>{{ 'settings' | translate }}</a>
            <ng-template ngbNavContent>
                <dl>
                    <ng-container *ngIf="mediaService.isVideo(mediaCopy)">
                        <dt><label>{{ "poster" | translate }}</label></dt>
                        <dd>
                            <div *ngIf="mediaCopy.poster" class="position-relative mb-2">
                                <img class="video-poster d-block" [src]="mediaService.getUrl(mediaCopy, 'poster')">
                                <div class="remove-poster position-absolute m-2">
                                    <button class="btn btn-danger" (click)="removePoster()">{{ 'remove_poster' | translate }}</button>
                                </div>
                            </div>
                            <div>
                                <button class="btn btn-secondary mr-2 mb-2" (click)="uploadVideoFrame()">{{ 'select_poster_from_video' | translate }}</button>
                                <button class="btn btn-primary mb-2" (click)="uploadCustomFrame()">{{ 'upload_custom_poster' | translate }}</button>
                            </div>
                        </dd>
                    </ng-container>

                    <dt><label for="media_name">{{ "name" | translate }}</label></dt>
                    <dd><input type="text" class="form-control" id="media_name" [(ngModel)]="mediaCopy.name" (change)="onChange()"></dd>

                    <dt><label for="media_alt">{{ "alt_text" | translate }}</label></dt>
                    <dd><input type="text" class="form-control" id="media_alt" [(ngModel)]="mediaCopy.altText" (change)="onChange()"></dd>

                    <dt><label for="media_access">{{ "access" | translate }}</label></dt>
                    <dd>
                        <select class="form-control" id="media_access" [(ngModel)]="mediaCopy.access" (change)="onChange()" [disabled]="mediaService.isDeepzoom(mediaCopy)">
                            <option *ngFor="let option of accessOptions | keyvalue" [value]="option.value">{{ option.value | translate }}</option>
                        </select>
                    </dd>

                    <dt><label for="compound_number">{{ "compound_number" | translate }}</label></dt>
                    <dd><input type="text" class="form-control" id="compound_number" [(ngModel)]="mediaCopy.compoundNumber" (change)="onChange()"></dd>

                    <dt><label for="stain">{{ "stain" | translate }}</label></dt>
                    <dd>
                        <select class="form-control" id="stain" [(ngModel)]="mediaCopy.stain" [compareWith]="compareConstant" (change)="onChange()">
                            <option [ngValue]="null"></option>
                            <option *ngFor="let option of (stains$ | async)" [ngValue]="option">{{option.name}}</option>
                        </select>
                    </dd>

                    <ng-container *ngIf="mediaService.isDeepzoom(mediaCopy)">
                      <dt><label for="pixel_per_millimeter">{{ "pixel_per_millimeter" | translate }}</label></dt>
                      <dd><input type="text" class="form-control" id="pixel_per_millimeter" [(ngModel)]="mediaCopy.dimensions.pixelPerMillimeter" (change)="onChange()"></dd>
                    </ng-container>

                    <ng-container *ngIf="mediaService.isDeepzoom(mediaCopy)">
                    <dt><label for="depth">{{ "depth" | translate }}</label></dt>
                    <dd><input type="text" class="form-control" id="depth" [(ngModel)]="mediaCopy.dimensions.depth" (change)="onChange()"></dd>
                    </ng-container>

                    <dt><label for="media_alt">{{ "copyright" | translate }}</label></dt>
                    <dd>
                        <div *ngFor="let copyright of mediaCopy.copyrights; let i = index" class="d-flex mb-2">
                            <select [id]="'copyright' + i" [name]="'copyright' + i" class="form-control mr-2" [(ngModel)]="mediaCopy.copyrights[i]" (change)="onChange()" [compareWith]="compareConstant">
                                <option *ngFor="let option of (copyrights$ | async)" [ngValue]="option">{{option.text}}</option>
                            </select>
                            <button type="button" class="btn btn-danger" (click)="removeCopyright(i)"><i class="fa fa-trash"></i></button>
                        </div>
                        <button class="btn btn-secondary" type="button" (click)="addCopyright()">{{ 'add_copyright' | translate }}</button>
                    </dd>

                    <ng-container *ngIf="mediaService.isImage(mediaCopy)">
                        <dt><label>{{ "watermark" | translate }}</label></dt>
                        <dd class="btn-toolbar">
                            <div class="btn-group mr-2">
                                <button type="button" class="btn btn-primary" [title]="'add_watermark' | translate"
                                    [disabled]="mediaCopy.copyrights?.length === 0"
                                    (click)="addWatermark('top_left')">
                                    <i class="fas fa-arrow-left fa-rotate-45"></i>
                                </button>
                                <button type="button" class="btn btn-primary" [title]="'add_watermark' | translate"
                                    [disabled]="mediaCopy.copyrights?.length === 0"
                                    (click)="addWatermark('top_right')">
                                    <i class="fas fa-arrow-up fa-rotate-45"></i>
                                </button>
                                <button type="button" class="btn btn-primary" [title]="'add_watermark' | translate"
                                    [disabled]="mediaCopy.copyrights?.length === 0"
                                    (click)="addWatermark('bot_right')">
                                    <i class="fas fa-arrow-right fa-rotate-45"></i>
                                </button>
                                <button type="button" class="btn btn-primary" [title]="'add_watermark' | translate"
                                    [disabled]="mediaCopy.copyrights?.length === 0"
                                    (click)="addWatermark('bot_left')">
                                    <i class="fas fa-arrow-down fa-rotate-45"></i>
                                </button>
                            </div>
                            <div class="btn-group">
                                <button type="button" class="btn btn-secondary" [title]="'remove_watermark' | translate"
                                    (click)="removeWatermark()">
                                    <i class="fa fa-eraser"></i>
                                </button>
                            </div>
                        </dd>
                    </ng-container>
                </dl>
            </ng-template>
        </li>
        <li [ngbNavItem]="2">
            <a ngbNavLink>{{'metadata' | translate }}</a>
            <ng-template ngbNavContent>
                <dl>
                    <dt>{{ "file_type" | translate }}</dt>
                    <dd>{{ mediaCopy.mime }}</dd>

                    <dt>{{ "size" | translate }}</dt>
                    <dd>{{ mediaCopy.size * 1024 * 1024 | fileSize }}</dd>

                    <ng-container *ngIf="mediaService.isVideo(mediaCopy) || mediaService.isImage(mediaCopy) || mediaService.isDeepzoom(mediaCopy)">
                        <dt>{{ "resolution" | translate }}</dt>
                        <dd>
                            {{ mediaCopy.dimensions?.width }} x {{ mediaCopy.dimensions?.height }} px
                            <span *ngIf="mediaService.isDeepzoom(mediaCopy) && mediaCopy.dimensions?.pixelPerMillimeter">@ {{ mediaCopy.dimensions?.pixelPerMillimeter }} px/mm</span>
                        </dd>
                    </ng-container>

                    <ng-container *ngIf="mediaService.isVideo(mediaCopy) || mediaService.isAudio(mediaCopy)">
                        <dt>{{ "duration" | translate }}</dt>
                        <dd>{{ mediaCopy.duration }} s</dd>
                    </ng-container>

                    <dt>{{ "uploaded_at" | translate }}</dt>
                    <dd>{{ mediaCopy.uploadedAt | date:'dd.MM.yyyy HH:mm' }}</dd>

                    <dt>{{ "used_in" | translate }}</dt>
                    <dd>
                        <ng-container *ngFor="let node of relatedNodes; let isLast=last">
                            <a target="_blank" [href]="'/node/' + node.id">{{ node.page.title }}</a>{{ isLast ? '' : ', ' }}
                        </ng-container>
                    </dd>
                </dl>
            </ng-template>
        </li>
        <li [ngbNavItem]="3">
            <a ngbNavLink>{{'comments' | translate }}</a>
            <ng-template ngbNavContent>
                <medsurf-comments
                    [item]="mediaCopy"
                    (changeEmitter)="onChange()"></medsurf-comments>
            </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>


</div>

<!-- Media remove confirmation modal -->
<ng-template #ConfirmRemoveDialog let-modal>
    <header class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">{{ 'remove_media' | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Button')">
            <span aria-hidden="true">&times;</span>
        </button>
    </header>
    <div class="modal-body">
        <p>{{ 'media_remove_confirm' | translate }}</p>
        <ng-container *ngIf="relatedNodes.length > 0">
            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel>
                  <ng-template ngbPanelTitle>
                    <img *ngIf="media.thumbnail" [src]="mediaService.getUrl(media, 'thumbnail')" class="thumbnail-sm mr-3" [alt]="media.altText">
                    <div class="thumbnail-sm border bg-primary text-dark mr-3" *ngIf="!media.thumbnail">
                      <span [class]="'fa ' + mediaService.getMediaTypeIcon(media)"></span>
                    </div>
                    <span>{{ media.name }}</span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p>{{ 'media_remove_confirm_with_links' | translate }}</p>
                    <ul class="ml-4">
                      <li *ngFor="let node of relatedNodes">
                          <a target="_blank" [href]="'/node/' + node.id">{{ node.page.title }}</a>
                      </li>
                    </ul>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
        </ng-container>
    </div>
    <footer class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">{{ 'cancel' | translate }}</button>
        <button type="button" class="btn btn-danger" (click)="modal.close()">{{ 'delete' | translate }}</button>
    </footer>
</ng-template>
