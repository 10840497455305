<div class="m-2 mb-3 mt-3 d-flex">
  <span class="badge badge-primary">
    <i class="fa fa-layer-group"></i> {{ layerId + 1  }}
  </span>
  <span class="badge badge-primary ml-2">
    <i class="fa fa-image"></i> {{ sequenceId + 1 }}
  </span>
  <div class="form-check d-inline-block ml-2" *ngIf="{ checked: (showOnlyAnnotationsOfImage$ | async) } as data">
    <input class="form-check-input" type="checkbox" value="" [checked]="data.checked" id="show-only-annotations-of-image" (change)="setShowOnlyAnnotationsOfImage(!data.checked)">
    <label class="form-check-label" for="show-only-annotations-of-image">
      {{ 'show_only_annotations_of_image' | translate }}
    </label>
  </div>
</div>

<form class="form m-2 d-flex">
  <div ngbDropdown class="d-inline-block mr-auto">
    <button class="btn btn-primary" id="add-annotation-dropdown" ngbDropdownToggle>{{ 'add_annotation' | translate }}</button>
    <div ngbDropdownMenu aria-labelledby="add-annotation-dropdown">
      <button ngbDropdownItem (click)="addMarker('dot')">{{ 'add_new_marker' | translate }}</button>
      <button ngbDropdownItem (click)="addFreeform('triangle')">{{ 'add_new_wedge' | translate }}</button>
      <button ngbDropdownItem (click)="addFreeform('arrow')">{{ 'add_new_arrow' | translate }}</button>
      <button ngbDropdownItem (click)="addFreeform('line')">{{ 'add_new_line' | translate }}</button>
      <div class="dropdown-divider"></div>
      <button ngbDropdownItem (click)="addFreeform('rectangle')">{{ 'add_new_rectangle' | translate }}</button>
      <button ngbDropdownItem (click)="addFreeform('ellipse')">{{ 'add_new_ellipse' | translate }}</button>
      <button ngbDropdownItem (click)="addFreeform('free')">{{ 'add_new_draw_form' | translate }}</button>
      <button ngbDropdownItem (click)="addFreeform('interactiveArea')">{{ 'add_new_interactive_area' | translate }}</button>
      <div class="dropdown-divider"></div>
      <button ngbDropdownItem (click)="addText()">{{ 'add_new_text' | translate }}</button>
      <button ngbDropdownItem (click)="addKeymap()">{{ 'add_new_legend' | translate }}</button>
    </div>
  </div>

  <div ngbDropdown class="d-inline-block ml-2">
    <button class="btn btn-primary" [title]="'paste_annotation' | translate" id="paste-annotation-dropdown" [disabled]="annotationsOnClipboard === 0" ngbDropdownToggle>
      <i class="fa fa-paste"></i>
      <span> ({{ annotationsOnClipboard }})</span>
    </button>
    <div ngbDropdownMenu aria-labelledby="add-annotation-dropdown">
      <button ngbDropdownItem (click)="pasteAnnotations(true)">{{ 'paste_at_original_position' | translate }}</button>
      <button ngbDropdownItem (click)="pasteAnnotations(false)">{{ 'paste_at_current_position' | translate }}</button>
    </div>
  </div>
  <button class="btn btn-primary ml-2" [title]="'copy_annotation' | translate" [disabled]="!hasSelectedBulk" (click)="bulkCopyAnnotations()">
    <i class="fa fa-copy"></i>
  </button>
  <button class="btn btn-danger ml-2" [disabled]="!hasSelectedBulk" [title]="'delete_annotation' | translate" (click)="bulkDeleteAnnotations()">
    <i class="fa fa-fw fa-trash"></i>
  </button>

</form>

<hr>

<div class="annotations-container pb-5" *ngIf="{ 
    imageToAnnotationMap: (annotationToImageMap$ | async), 
    annotationToPoiMap: (annotationToPoiMap$ | async), 
    annotationToGroupMap: (annotationToGroupMap$ | async),
    displayedAnnotations: (displayedAnnotations$ | async),
    imageAnnotationSet: (imageAnnotationSet$ | async),
    currentImage: (currentImage$ | async)
  } as data">
  <div 
    class="annotation-container tree-node-styled collapsable m-2"
    *ngFor="let annotation of data.displayedAnnotations; trackBy:trackById"
    [class.active]="annotation.id === marker?.id"
    [id]="'annotation-list-item-' + annotation.id"
    [class.disabled]="!data.imageAnnotationSet.has(annotation.id)"
  >
    <div class="collapsable-header" (click)="editMarker(annotation)">
      <div class="mr-auto">
        <ng-container *ngIf="panelService.getAnnotationInfo(annotation) as info">
          <small>{{ info.type }}</small><br>
          <strong>{{ info.title }}</strong>
        </ng-container>

        <div class="d-flex" (click)="$event.stopPropagation()">

          <div class="d-inline-block">
            <medsurf-reference-dropdown 
              *ngFor="let layer of slide.layers; trackBy:trackById; let layerIndex = index"
              [list]="layer.images"
              [map]="data.imageToAnnotationMap[annotation.id]?.[layer.id] || {}"
              [subject]="annotation"
              [dropdownId]="'annotation-'+ annotation.id +'-poi-dropdown'"
              classes="d-block"
              labelKey="index"
              labelIcon="fa-image"
              [title]="'assign_to_images' | translate"
              [noResultLabel]="'no_images_found' | translate"
              (updateSelected)="updateAnnotationImage($event.subject, $event.items, $event.add)"
            >
              <div class="d-inline">
                <span *ngIf="slide.layers.length > 1" class="mr-2">
                  <i class="fa fa-layer-group"></i> {{ layerIndex + 1 }} 
                </span>
                <span>
                  <i class="fa fa-image"></i> {{ data.imageToAnnotationMap[annotation.id]?.[layer.id]?.count || '0' }}
                </span>
              </div>
            </medsurf-reference-dropdown>
          </div>
          

          <medsurf-reference-dropdown 
            [list]="slide.pois"
            [map]="data.annotationToPoiMap[annotation.id] || {}"
            [subject]="annotation"
            [dropdownId]="'annotation-'+ annotation.id +'-poi-dropdown'"
            classes="d-inline-block ml-2"
            labelKey="name"
            [title]="'assign_to_pois' | translate"
            [noResultLabel]="'no_pois_found' | translate"
            (updateSelected)="updateAnnotationPoi($event.subject, $event.items, $event.add)"
          >
            <i class="fa fa-square-dashed"></i> {{ data.annotationToPoiMap[annotation.id]?.count || '0' }}
          </medsurf-reference-dropdown>

          <medsurf-reference-dropdown 
            [list]="slide.groups"
            [map]="data.annotationToGroupMap[annotation.id] || {}"
            [subject]="annotation"
            [dropdownId]="'annotation-'+ annotation.id +'-group-dropdown'"
            classes="d-inline-block ml-2"
            labelKey="name"
            [title]="'assign_to_groups' | translate"
            [noResultLabel]="'no_groups_found' | translate"
            (updateSelected)="updateAnnotationGroup($event.subject, $event.items, $event.add)"
          >
            <i class="fa fa-object-group"></i> {{ data.annotationToGroupMap[annotation.id]?.count || '0' }}
          </medsurf-reference-dropdown>
        </div>
      </div>

      <div class="d-flex" (click)="$event.stopPropagation()">
        <button *ngIf="data.imageAnnotationSet.has(annotation.id)" class="btn btn-sm btn-secondary ml-2" [title]="'go_to_annotation' | translate" (click)="goToAnnotation(annotation)">
          <i class="fa fa-fw fa-location"></i>
        </button>
        <button *ngIf="!data.imageAnnotationSet.has(annotation.id)" class="btn btn-sm btn-secondary ml-2" [title]="'link_on_current_image' | translate" (click)="updateAnnotationImage(annotation, [data.currentImage], true)">
          <i class="fa fa-fw fa-link"></i>
        </button>
        <button *ngIf="data.imageAnnotationSet.has(annotation.id)" class="btn btn-sm btn-secondary ml-2" [title]="'unlink_on_current_image' | translate" (click)="updateAnnotationImage(annotation, [data.currentImage], false)">
          <i class="fa fa-fw fa-unlink"></i>
        </button>
        <button *ngIf="data.imageAnnotationSet.has(annotation.id)" class="btn btn-sm btn-secondary ml-2" [title]="'move_to_current_view' | translate" (click)="updatePosition(annotation)">
          <i class="fa fa-fw fa-arrows-to-circle"></i>
        </button>
        <button class="btn btn-sm btn-danger ml-2" [title]="'delete_annotation' | translate" (click)="deleteAnnotation(annotation)">
          <i class="fa fa-fw fa-trash"></i>
        </button>
        <div class="ml-2">
          <input type="checkbox" value="" [checked]="bulkActionMap[annotation.id]" [id]="'annotation-bulk-action-'+ annotation.id" (change)="addAnnotationToBulkAction(annotation)">
        </div>
      </div>
    </div>

    <div class="collapsable-body" *ngIf="annotation.isExpanded && annotation.id === marker?.id">
      <medsurf-marker
        *ngIf="annotation?.type === AnnotationType.MARKER"
        [marker]="annotation"
        [currentPosition]="currentPosition"
      ></medsurf-marker>
      <medsurf-keymap
        *ngIf="annotation?.type === AnnotationType.KEYMAP"
        [keymap]="annotation"
        [slide]="slide"
        (setSelftestKeymap)="setSelftestKeymap.emit($event)"
      ></medsurf-keymap>
      <medsurf-freeform 
        *ngIf="annotation?.type === AnnotationType.FREE_FORM"
        [marker]="annotation"
        (activateFreeTool)="activateFreeTool.emit($event)"
      ></medsurf-freeform>
    </div>
  </div>
</div>