import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DEFAULT_VALUES } from '../../../../common/constants/default-values';
import { FreeForm, Slide, PointOfInterest, LinkType, Shape, AnnotationType } from '@medsurf/models';
import { SlideChanged } from '@medsurf/actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'medsurf-freeform',
  templateUrl: './freeform.component.html',
  styleUrls: ['./freeform.component.scss']
})
export class FreeformComponent {
  @Output() localDeleteMarker = new EventEmitter<FreeForm>();
  @Output() activateFreeTool = new EventEmitter<FreeForm>();
  @Output() addMarkerToPoi = new EventEmitter<FreeForm>();

  @Input() slide: Slide;
  @Input() marker: FreeForm;
  @Input() sequenceId: number;
  @Input() layerId: number;

  public linkTypes: LinkType[] = [LinkType.SITE, LinkType.SLIDE];

  public editorToolbarExcludes = [
    'mediaimage',
    'mediapdf',
    'mediaaudio',
    'mediavideo',
    'marker'
  ];

  constructor(private store: Store,
              @Inject(DEFAULT_VALUES) public defaultValues) {
  }

  onChange() {
    if (this.marker.shape === Shape.INTERACTIVE_AREA && !!this.marker.targets) {
      delete this.marker.targets;
    }
    this.marker.dirty = true;
    this.store.dispatch(new SlideChanged());
  }

  public onToggleLabelBackground() {
    if (this.marker.label.background.opacity === 0) {
      this.marker.label.background.opacity = 0.4;
    } else {
      this.marker.label.background.opacity = 0;
    }
    this.onChange();
  }

  public checkMarkerInPoi() {
    return this.slide.layers[this.layerId].images[this.sequenceId].pois.filter((poi: PointOfInterest) => {
      if (!poi.annotations) {
        poi.annotations = [];
      }
      return !!(this.marker && poi.annotations.indexOf(this.marker) > -1);
    });
  }

  public checkSelectMarkerInPoi() {
    return this.slide.layers[this.layerId].images[this.sequenceId].pois.filter((poi: PointOfInterest) => {
      if (!poi.annotations) {
        poi.annotations = [];
      }
      return !(this.marker && poi.annotations && poi.annotations.indexOf(this.marker) > -1);
    });
  }

  public removeAnnotationFromPoi(poi: PointOfInterest) {
    const index = poi.annotations.findIndex(a => a === this.marker);
    if (index !== -1) {
      poi.annotations.splice(index, 1);
      this.onChange();
    }
  }

  public selectLinkType(linkType: LinkType) {
    if (!this.marker.link) {
      this.marker.link = {};
    }
    this.marker.link.type = linkType;
    this.onChange();
  }

  public isFreeform(marker: FreeForm): boolean {
    return marker.type === AnnotationType.FREE_FORM;
  }

  onSelfTestChange($event: any) {
    this.marker.selfTest.ignore = !$event.target.checked;
    this.onChange();
  }

  get typeOptions () {
    const withPath = [Shape.FREE, Shape.INTERACTIVE_AREA];
    const free = withPath.includes(this.marker.shape);
    return this.defaultValues.freeform.filter(({value}) => {
      const hasPath = withPath.includes(value);
      return free ? hasPath : !hasPath;
    })
  }
}
