import paper from 'paper';

class Symbol {
  protected _element: any;

  constructor (protected _format, protected _target, protected _marker) {
    this._element = new paper.Group();
  }

  angle (cx, cy, ex, ey) {
    const dy = ey - cy;
    const dx = ex - cx;
    let theta = Math.atan2(dy, dx);
    theta *= 180 / Math.PI;
    return theta;
  }

  get element() {
    return this._element;
  }

  cleanUp() {
    if (this._element) {
      this._element.remove();
      this._element = null;
    }
  }
}

export default Symbol;
