import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'medsurf-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent implements OnDestroy {

  @Input()
  public selectedItems: string[] = [];

  @Output()
  selectedItemsChange = new EventEmitter<string[]>();

  @Input()
  regexFilter: string;

  @Input()
  placeholder: string;

  public input = '';

  @ViewChild('inputElement') inputElement;
  @ViewChild('container') container;

  private _destroyed = new Subject<boolean>();

  public addItem(): void {
    if (this.input && this.checkInput(this.input) && !this.selectedItems.includes(this.input)) {
      this.selectedItems.push(this.input);
      this.input = '';
    }
  }

  public deleteItem(): void {
    if (!this.input) {
      this.selectedItems.pop();
    }
  }

  public checkInput(string: string) {
    const regex = new RegExp(this.regexFilter);
    for (const char of string) {
      if (!char.match(regex)) {
        return false;
      }
    }
    return true;
  }

  public validate(event): void {
    const regex = new RegExp(this.regexFilter);
    if (this.regexFilter) {
      if (!event.key.match(regex) && !['Enter', 'Backspace'].includes(event.key) && !event.ctrlKey) {
        event.preventDefault();
      }
    }
  }

  public focusInput(): void {
    this.inputElement.nativeElement.focus();
  }

  public scrollBot(): void {
    this.container.nativeElement.scrollTop = 999999;
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
