import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GetCopyrights,
  GetModalities,
  GetStains,
} from '@medsurf/actions';
import {
  Base,
  Copyright,
  Modality,
  Stain
} from '@medsurf/models';
import { ConstantState } from '@medsurf/state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

export enum ConstantType {
  MODALITY = 'Modality',
  STAIN = 'Stain',
  COPYRIGHT = 'Copyright'
}

@Component({
  selector: 'medsurf-constant-list',
  templateUrl: './constant-list.component.html',
  styleUrls: ['./constant-list.component.scss']
})
export class ConstantListComponent implements OnInit {

  @Select(ConstantState.modalities)
  public modalities$: Observable<Modality[]>;
  @Select(ConstantState.stains)
  public stains$: Observable<Stain[]>;
  @Select(ConstantState.copyrights)
  public copyrights$: Observable<Copyright[]>;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private store: Store) { }

  ngOnInit(): void {
    this.route.data.pipe(map(data => data.constantType))
        .subscribe((constantType) => {
          switch (constantType) {
            case ConstantType.COPYRIGHT:
              this.store.dispatch(new GetCopyrights());
              break;
            case ConstantType.MODALITY:
              this.store.dispatch(new GetModalities());
              break;
            case ConstantType.STAIN:
              this.store.dispatch(new GetStains());
              break;
          }
        });
  }

  public get constantType$(): Observable<ConstantType> {
    return this.route.data.pipe(map(data => data.constantType));
  }

  newConstant() {
    this.router.navigate(['./detail'], {relativeTo: this.route});
  }

  goToDetail(constant: Base) {
    this.router.navigate(['./detail', { id: constant.id}], {relativeTo: this.route});
  }
}
