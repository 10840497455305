import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedsurfLibModule } from '@medsurf/module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DragulaModule } from 'ng2-dragula';
import { MedsurfCommonModule } from '../common/medsurf-common.module';
import { FormsModule } from '@angular/forms';
import { PanelModule } from './panel/panel.module';

@NgModule({
  imports: [
    CommonModule,
    MedsurfCommonModule,
    TranslateModule,
    MedsurfLibModule,
    NgbModule,
    DragulaModule,
    FormsModule,
    PanelModule,
  ]
})
export class SlideDetailModule {
}
