<div class="container">
  <div class="row">
    <div class="offset-md-4 col-md-4 login-panel">
      <div class="card bg-light">
        <div class="card-header">
          <h3 class="card-title m-0">{{ 'reset_password' | translate }}</h3>
        </div>
        <div class="card-body">
          <form role="form" [formGroup]="forgotPasswordForm" (submit)="onSubmit()">
            <fieldset>
              <div class="form-group">
                <input class="form-control" autocomplete="off" id="user_email" type="email" name="email" formControlName="email" placeholder="{{ 'user_email' | translate }}"
                  [class.is-invalid]="f.email.invalid && f.email.touched">
                <div class="invalid-feedback" *ngFor="let err of f.email.errors | keyvalue">{{ err.value | translate }}</div> 
              </div>
              <button class="btn btn-secondary btn-block mb-2" type="submit" [disabled]="forgotPasswordForm.invalid">{{ 'reset' | translate }}</button>
              <a routerLink="/login">
                {{ 'back_to_sign_in' | translate }}
              </a>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
