<div class="layers-container" dragula="LAYER" [(dragulaModel)]="slide.layers" (dragulaModelChange)="onLayersChanged()">
  <div class="tree-node-styled with-handle" *ngFor="let layer of slide.layers; let index = index" [class.active]="(layerNumber$ | async) === index" (click)="setLayerId(index)">
    <div class="handle">
      <i class="fa fa-grip-dots-vertical"></i>
    </div>
    <span class="flex-shrink-0"># {{ index + 1 }}</span>
    <div class="mr-3 ml-3">
      <select id="status" name="status"
              class="form-control input-md"
              [(ngModel)]="layer.window" (ngModelChange)="onChange()">
        <option [value]=""></option>
        <option *ngFor="let window of defaultValues.window" [value]="window.value">{{window.name}}</option>
      </select>
    </div>
    <button class="btn btn-danger ml-auto"
        [disabled]="slide.layers.length === 1"
        (click)="$event.stopImmediatePropagation(); deleteLayer(index, this);">
      <i class="fa fa-fw fa-times" title="{{ 'delete_layer_description' | translate }}"></i>
    </button>
  </div>
</div>

<div class="layers-footer">
  <button class="btn btn-secondary" (click)="addLayer()">
    <i class="fa fa-fw fa-plus" title="{{ 'new_layer_description' | translate }}"></i>
    {{ 'new_layer_description' | translate }}
  </button>
</div>
