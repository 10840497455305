<medsurf-navigation></medsurf-navigation>

<div class="detail-container">
    <div class="card">
        <div class="card-header bg-primary">
            <h3 class="card-title">{{ constantType$ | async | lowercase | translate}}</h3>
        </div>
        <div class="card-body" *ngIf="constant">
            <form>
                <ng-container [ngSwitch]="constantType$ | async">
                    <ng-container *ngSwitchCase="'Modality'">
                        <div class="form-group row">
                            <label class="col-lg-3 col-form-label" translate="name"></label>
                            <div class="col-lg-9">
                                <input class="form-control" type="text" name="name" [(ngModel)]="constant.name">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-3 col-form-label" translate="shortcut"></label>
                            <div class="col-lg-9">
                                <input class="form-control" type="text" name="shortcut" [(ngModel)]="constant.shortcut">
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Stain'">
                        <div class="form-group row">
                            <label class="col-lg-3 col-form-label" translate="name"></label>
                            <div class="col-lg-9">
                                <input class="form-control" type="text" name="name" [(ngModel)]="constant.name">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-3 col-form-label" translate="shortcut"></label>
                            <div class="col-lg-9">
                                <input class="form-control" type="text" name="shortcut" [(ngModel)]="constant.shortcut">
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Copyright'">
                        <div class="form-group row">
                            <label class="col-lg-3 col-form-label" translate="text"></label>
                            <div class="col-lg-9">
                                <input class="form-control" type="text" name="text" required [(ngModel)]="constant.text">
                            </div>
                        </div>
                    </ng-container>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <button (click)="discardChanges()" class="btn btn-primary mr-1 mb-2"
                                    translate="{{ 'discard_changes' | translate }}"></button>
                            <ng-container *ngSwitchCase="'Modality'">
                                <button (click)="updateModality(constant)" class="btn btn-secondary" translate="{{ 'save_changes' | translate }}"
                                        [disabled]="!constant.shortcut || !constant.name"></button>
                            </ng-container>
                            <ng-container *ngSwitchCase="'Stain'">
                                <button (click)="updateStain(constant)" class="btn btn-secondary" translate="{{ 'save_changes' | translate }}"
                                        [disabled]="!constant.name || !constant.shortcut"></button>
                            </ng-container>
                            <ng-container *ngSwitchCase="'Copyright'">
                                <button (click)="updateCopyright(constant)" class="btn btn-secondary" translate="{{ 'save_changes' | translate }}"
                                        [disabled]="!constant.text"></button>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </form>
        </div>
    </div>

    <div class="card mt-3 border border-danger" *ngIf="constant?.id">
        <div class="card-header bg-primary">
            <h3 class="card-title">{{ constantType$ | async | lowercase | translate}} {{ 'delete' | translate }}</h3>
        </div>
        <div class="card-body">
            <ng-container [ngSwitch]="constantType$ | async">
                <ng-container *ngSwitchCase="'Modality'">
                    <button type="submit" class="btn btn-danger" translate="delete" (click)="deleteModality(constant)"></button>
                </ng-container>
                <ng-container *ngSwitchCase="'Stain'">
                    <button type="submit" class="btn btn-danger" translate="delete" (click)="deleteStain(constant)"></button>
                </ng-container>
                <ng-container *ngSwitchCase="'Copyright'">
                    <button type="submit" class="btn btn-danger" translate="delete" (click)="deleteCopyright(constant)"></button>
                </ng-container>
            </ng-container>
        </div>
    </div>

</div>
