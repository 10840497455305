import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Annotation, Group, Slide } from '@medsurf/models';
import { Store } from '@ngxs/store';
import { PanelService } from '../../../panel.service';


@Component({
  selector: 'medsurf-group-annotation',
  templateUrl: './group-annotation.component.html',
  styleUrls: ['./group-annotation.component.scss']
})
export class GroupAnnotationComponent implements OnChanges {

  @Input() group: Group;
  @Input() slide: Slide;
  @Input() imageAnnotationSet: Set<string>;

  @Output() changeEmitter = new EventEmitter();
  @Output() setHoverMarker = new EventEmitter<Annotation>();

  public groupAnnotationSet = new Set<string>();  
  
  constructor(
    public panelService: PanelService,
    private store: Store
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.slide) {
      this.groupAnnotationSet = new Set(this.group.annotations.map(a => a.id))
    }
  }

  public goToAnnotation(annotation: Annotation) {
    this.setHoverMarker.emit(null);
    setTimeout(() => {
      this.setHoverMarker.emit(annotation);
    })
  }

  public addMarkerToGroup(annotation: Annotation) {
    if (!this.group.annotations) {
      this.group.annotations = [];
    }
    if (this.group.annotations.findIndex(a => a.id === annotation.id) === - 1) {
      this.group.annotations.push({ id: annotation.id });
      this.changeEmitter.emit();
    }
  }

  public removeMarkerFromGroup(annotation: Annotation) {
    const index = this.group?.annotations?.findIndex(a => a?.id === annotation?.id);
    if (index !== -1) {
      this.group.annotations.splice(index, 1);
      this.changeEmitter.emit();
    }
  }
}
