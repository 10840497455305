import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Slide, DisplayMode, AnnotationMode, ImageMode, Media, Annotation } from '@medsurf/models';
import { SetDisplayMode, SetAnnotationMode, ToggleShowHeader, ToggleShowModality, AddQuestion, SlideChanged,
  ToggleShowBody, ToggleShowQuiz, ToggleShowInteractiveArea, SetImageMode } from '@medsurf/actions';
import { MenuState, SlideState } from '@medsurf/state';
import { MediaService } from '@medsurf/services';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'medsurf-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
  @Select(MenuState.displayMode)
  public displayMode$: Observable<DisplayMode>;

  @Select(MenuState.annotationMode)
  public annotationMode$: Observable<AnnotationMode>;

  @Select(MenuState.imageMode)
  public imageMode$: Observable<ImageMode>;

  @Select(MenuState.showHeader)
  public showHeader$: Observable<boolean>;

  @Select(MenuState.showBody)
  public showBody$: Observable<boolean>;

  @Select(MenuState.showQuiz)
  public showQuiz$: Observable<boolean>;

  @Select(MenuState.showInteractiveArea)
  public showInteractiveArea$: Observable<boolean>;

  @Select(MenuState.showModality)
  public showModality$: Observable<boolean>;

  @Select(SlideState.media)
  public media$: Observable<Media>;

  @Select(SlideState.copyStack)
  public copyStack$: Observable<Annotation>;

  @Output() addMarker = new EventEmitter<string>();
  @Output() addKeymap = new EventEmitter();
  @Output() addFreeform = new EventEmitter();
  @Output() addText = new EventEmitter();
  @Output() setZoom = new EventEmitter<string>();
  @Output() handleUndo = new EventEmitter();
  @Output() handleRedo = new EventEmitter();
  @Output() togglePreview = new EventEmitter();
  @Output() gotoSlideDetail = new EventEmitter();
  @Output() layoutChange = new EventEmitter<boolean>();
  @Output() gridChange = new EventEmitter<boolean>();
  @Output() copyMarker = new EventEmitter<Annotation>();
  @Output() pasteMarker = new EventEmitter();

  @Input() slide: Slide;
  @Input() marker: Annotation;
  @Input() layout: boolean;
  @Input() grid: boolean;
  @Input() preview;
  @Input() zoom;
  @Input() canUndo;
  @Input() canRedo;
  @Input() isSaving: boolean;

  constructor(
    public mediaService: MediaService,
    private store: Store
  ) {}

  displayModeChanged(displayMode: DisplayMode) {
    this.store.dispatch(new SetDisplayMode(displayMode));
  }

  annotationModeChanged(annotationMode: AnnotationMode) {
    this.store.dispatch(new SetAnnotationMode(annotationMode));
  }

  showHeaderChanged() {
    this.store.dispatch(new ToggleShowHeader());
  }

  showBodyChanged() {
    this.store.dispatch(new ToggleShowBody());
  }

  showQuizChanged() {
    this.store.dispatch(new ToggleShowQuiz());
  }

  showInteractiveChanged() {
    this.store.dispatch(new ToggleShowInteractiveArea());
  }

  showModalityChanged() {
    this.store.dispatch(new ToggleShowModality());
  }

  addQuestion() {
    this.store.dispatch(new AddQuestion());
    this.store.dispatch(new SlideChanged());
  }

  imageModeChanged(imageMode: ImageMode) {
    this.store.dispatch(new SetImageMode(imageMode));
  }
}
