import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import {
  GetUserById,
  CreateNewUser,
  UpdateUser,
  UpdateUserSuccess,
  AddUser,
  AddUserSuccess,
  DeleteUserSuccess,
  DeleteUser,
  AddAlert,
  ResetPassword,
  ResetPasswordSuccess,
  UpdateUserInvalid,
  GetMetadata,
} from '@medsurf/actions';
import { Metadata, AlertType, User } from '@medsurf/models';
import { UpdateUserValidator } from '@medsurf/validators';
import { AuthState, MetadataState, UserState } from '@medsurf/state';
import { Settings, SETTINGS } from '@medsurf/services';

import { DEFAULT_VALUES } from '../../common/constants/default-values';
import { Observable, Subject } from 'rxjs';
import { ClassValidatorFormBuilderService, ClassValidatorFormGroup } from 'ngx-reactive-form-class-validator';
import { assign, cloneDeep } from 'lodash';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'medsurf-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {
  @Select(UserState.user)
  public user$: Observable<User>;

  @Select(AuthState.user)
  public authenticatedUser$: Observable<User>;

  @Select(MetadataState.metadata)
  public metadata$: Observable<Metadata>;

  public metadata: Metadata;
  public user: User;

  public isNewUser: boolean;
  public userForm: ClassValidatorFormGroup;

  private _destroyed = new Subject<boolean>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private fb: ClassValidatorFormBuilderService,
    private actions$: Actions,
    @Inject(DEFAULT_VALUES) public defaultValues,
    @Inject(SETTINGS) public settings: Settings) {
  }

  ngOnInit() {
    this.store.dispatch(new GetMetadata());
    this.userForm = this.fb.group(UpdateUserValidator, {
      firstName: [''],
      lastName: [''],
      email: [''],
      role: ['']
    });

    this.metadata$.pipe(takeUntil(this._destroyed)).subscribe((metadata: Metadata) => {
      if (metadata) {
        this.metadata = metadata;
      }
    });

    this.route.paramMap.pipe(takeUntil(this._destroyed)).subscribe((params: ParamMap) => {

      const id = params.get('userSlug');
      if (id !== 'new') {
        this._getUserDetail(id);
        this.isNewUser = false;
      } else {
        this._newUser();
        this.isNewUser = true;
      }
    });

    this.user$.pipe(takeUntil(this._destroyed)).subscribe((user: User) => {
      if (user) {
        this.userForm.patchValue(user);
        this.user = user;
      }
    });

    this.actions$.pipe(ofActionDispatched(UpdateUserInvalid), takeUntil(this._destroyed)).subscribe((action: UpdateUserInvalid) => {
      action.errors.forEach((err) => {
        this.userForm.get(err.property).setErrors(err.constraints);
      });
    });

    this.actions$.pipe(ofActionDispatched(ResetPasswordSuccess), takeUntil(this._destroyed)).subscribe(() => {
      const { supportEmail } = this.metadata;
      this.store.dispatch(new AddAlert({
        message: 'reset_password_success_message',
        params: { emailSent: this.user.email, emailSupport: supportEmail },
        type: AlertType.SUCCESS
      }));
    });

    this.actions$.pipe(ofActionDispatched(AddUserSuccess, UpdateUserSuccess, DeleteUserSuccess), takeUntil(this._destroyed))
      .subscribe(() => {
        this.store.dispatch(new AddAlert({
          message: 'updated',
          type: AlertType.SUCCESS,
          duration: 5
        }));
        this.router.navigate(['/users']);
      });
  }

  get f() {
    return this.userForm.controls;
  }

  public onSubmit() {
    if (this.userForm.invalid) {
      return;
    }

    const formUser = cloneDeep(this.userForm.value);
    assign(this.user, formUser);
    if (this.isNewUser) {
      this.store.dispatch(new AddUser());
    } else {
      this.store.dispatch(new UpdateUser());
    }
  }

  private _getUserDetail(id) {
    this.store.dispatch(new GetUserById(id));
  }

  private _newUser() {
    this.store.dispatch(new CreateNewUser());
  }

  public deleteUser() {
    this.store.dispatch(new DeleteUser());
  }

  public resetPassword() {
    const {email} = this.user;
    this.store.dispatch(new ResetPassword(email));
  }

  canDelete(user: User) {
    return user && user.id && user.role !== 'admin';
  }

  /**
   * OnDestroy method
   */
  public ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
