import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, NgZone, OnChanges, OnDestroy, Output,
  SimpleChanges, ViewChild } from '@angular/core';
import { Annotation, AnnotationType, Dimensions, ImagePosition, PointOfInterest, Shape, Slide, ImageMode, Image, Layer, Bounds } from '@medsurf/models';
import { SlideChanged, SetPosition, SetSequenceNumber, CopyMarker, PasteMarker, SetBounds } from '@medsurf/actions';
import { MenuState, SlideState } from '@medsurf/state';
import { SETTINGS, Settings, MediaService } from '@medsurf/services';
import Container from '../image/elements/container';
import Freeform from '../image/elements/freeform/freeform';
import Grid from '../image/elements/grid/grid';
import Keymap from '../image/elements/marker/keymap';
import KeymapSelftest from '../image/elements/marker/keymapSelftest';
import Marker from '../image/elements/marker/marker';
import Text from '../image/elements/marker/text';
import MarkerFormat from '../models/format';
import MarkerTool from '../tools/markerTool';
import OpenSeadragon from './osd/osd.prototype';
import Overlay from './osd/overlay';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MediaDialogComponent } from '../media-dialog/media-dialog.component';
import { Media, MediaDeepzoom, MediaImage, MediaType } from '@medsurf/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Scalebar } from './osd/scalebar';
import { ActivatedRoute } from '@angular/router';
import { onFontLoad } from '@medsurf/helpers';
import { ZipZoomify } from "./osd/ZipZoomify";

@Component({
  selector: 'medsurf-microscope',
  templateUrl: './microscope.component.html',
  styleUrls: ['./microscope.component.scss']
})
export class MicroscopeComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Select(MenuState.imageMode)
  public imageMode$: Observable<ImageMode>;

  @Select(SlideState.slide)
  public slide$: Observable<Slide>;

  @Select(SlideState.layer)
  public layer$: Observable<Layer>;

  @Select(SlideState.layerNumber)
  public layerNumber$: Observable<number>;

  @Select(SlideState.sequenceNumber)
  public sequenceNumber$: Observable<number>;

  @Select(SlideState.imageAnnotations)
  public imageAnnotations$: Observable<Annotation[]>;

  @Select(SlideState.image)
  public image$: Observable<Image>;

  @Select(SlideState.media)
  public media$: Observable<Media>;

  @ViewChild('microscope')
  public microscopeRef: ElementRef;

  @Output() microscopeChange = new EventEmitter();
  @Output() markerChange = new EventEmitter();
  @Output() zoomChange = new EventEmitter();

  @Input() state: any;
  @Input() zoom: number;
  @Input() zoomPreset: any;
  @Input() grid: boolean;
  @Input() layout: boolean;
  @Input() preview: any;
  @Input() marker: Annotation;
  @Input() hoverMarker: Annotation;
  @Input() selftestMarker: any;
  @Input() error: any;
  @Input() props: any;
  @Input() command: any;
  @Input() selectedPoi: any;

  public sequenceNumber = 0;
  public layerNumber = 0;
  public imageAnnotations = [];
  public slide: Slide;
  public media: MediaImage & MediaDeepzoom;
  public toastMessage = '';
  private container: Container;
  private overlay: Overlay;
  private viewer: OpenSeadragon.Viewer;
  private minZoom: number;
  private maxZoom = null;
  private hasMoved = false;
  private isDeepZoom: boolean;
  private _destroyed = new Subject<boolean>();
  public hasImage = true;
  private scalebar: Scalebar;
  private layer: Layer;
  private boundsBeforeSave: OpenSeadragon.Rect = null;
  private preloadTimers: NodeJS.Timeout[] = [];


  constructor(
    @Inject(SETTINGS) private settings: Settings,
    private zone: NgZone,
    private modalService: NgbModal,
    public mediaService: MediaService,
    private store: Store,
    private route: ActivatedRoute
  ) {
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.imageAnnotations$
        .pipe(takeUntil(this._destroyed))
        .subscribe((annotations) => {
          this.imageAnnotations = annotations;
          this.handleImageProperties();
        });
      
      this.layerNumber$
        .pipe(takeUntil(this._destroyed))
        .subscribe(this.setLayerId.bind(this));

      this.sequenceNumber$
        .pipe(takeUntil(this._destroyed))
        .subscribe(this.setSequenceId.bind(this));

      this.slide$.pipe(takeUntil(this._destroyed)).subscribe((slide: Slide) => {
        this.slide = slide;
      });

      this.media$.pipe(takeUntil(this._destroyed)).subscribe((media: Media) => {
        if (media) {
          this.isDeepZoom = this.mediaService.isDeepzoom(media);
          const hadMedia = !!this.media;
          const forceReload = !this.mediaService.isImage(this.media) && !this.mediaService.isDeepzoom(this.media);
          this.media = media;
          if (forceReload || !hadMedia) {
            setTimeout(() => {
              this.instantiateVirtualMicroscope();
            });
          }
        } else {
          this.media = null;
        }
      });
      this.layer$.pipe(takeUntil(this._destroyed)).subscribe((layer: Layer) => {
        if (!layer) {
          return;
        }
        const oldId = this.layer?.id;
        this.layer = layer;
        setTimeout(() => {
          if (oldId === this.layer?.id && this.viewer) {
            this.boundsBeforeSave = this.viewer.viewport.getBounds(true);
          } else {
            this.boundsBeforeSave = null;
            const defaultImage = layer.images?.find(i => i.default === true);
            if (defaultImage?.sequenceNumber) {
              this.store.dispatch(new SetSequenceNumber(defaultImage.sequenceNumber));
            }
          }
          this.instantiateVirtualMicroscope();
        });

      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.grid) {
      this.handleToggleGrid();
    }
    if (changes.marker && this.viewer && this.container) {
      this.handleSelectMarker();
    }
    if (changes.hoverMarker && this.viewer && this.container) {
      this.handleHoverMarker();
    }
    if (changes.selftestMarker && this.overlay && this.container) {
      this.handleSelftestMarker();
    }
    if (changes.selectedPoi && this.viewer) {
      this.goToPOI(this.viewer, this.selectedPoi);
    }
    if (changes.zoomPreset) {
      this.handleZoomPreset();
    }
  }

  public onDeleteImage() {
    this.handleDeleteImage();
  }

  public onChangeImage() {
    if (this.media !== this.slide?.layers?.[this.layerNumber]?.images?.[this.sequenceNumber]?.media) {
      this.media = this.slide?.layers?.[this.layerNumber]?.images?.[this.sequenceNumber]?.media;
    }
  }

  /**
   * @function
   * @param {OpenSeadragon.Viewer} viewer - OSD viewer object
   */
  private async changeImage(viewer, newSequenceNumber, oldSequenceNumber) {
    if (newSequenceNumber === undefined) {
      return;
    }

    this.viewer?._cancelPendingImages();
    this.updateLayerPreload(newSequenceNumber, true);

    if (!this.scalebar) {
      return;
    }
    setTimeout(() => {
      this.scalebar.refresh({
        pixelsPerMeter: this.media?.dimensions?.pixelPerMillimeter * 1000,
      });
    });
  }

  private updateLayerPreload(sequenceNumber: number, preload: boolean) {
    if (this.preloadTimers.length > 0) {
      this.preloadTimers.forEach(timer => clearTimeout(timer));
    }
    if (sequenceNumber === undefined || !this.viewer) {
      return;
    }

    const length: number = this.viewer.world.getItemCount();
    for (let i = 0; i < length; i++) {
      const item = this.viewer.world.getItemAt(i);
      if (i === sequenceNumber) {
        item.setOpacity(1);
        item.immediateRender = false;
      } else if (preload && (i === sequenceNumber + 1 || i === sequenceNumber - 1)) {
        this.preloadTimers.push(setTimeout(() => {
          item.setOpacity(0.001); // setting opacity to not zero enables preloading of the viewport
          item.immediateRender = true;
        }, 100));
      } else {
        if (item.getOpacity() !== 0) {
          item.setOpacity(0);
          item.immediateRender = true;
        }
      }
    }
  }

  /**
   * Sets the Viewport to the selected POI
   *

   * @param {OpenSeadragon.Viewer} viewer - OSD viewer object
   * @param {Object} poi - POI object
   */
  public goToPOI(viewer, poi: PointOfInterest) {
    const currentImage = viewer?.world.getItemAt(this.sequenceNumber ?? 0);
    const { width, height } = this.getDimensions();
    if (!poi || !currentImage || !viewer || !width || !height) {
      return;
    }
    let bounds = null;

    if (poi.bounds?.x) {
      bounds = currentImage.imageToViewportRectangle(
        new OpenSeadragon.Rect(
          poi.bounds.x * width,
          poi.bounds.y * height, 
          poi.bounds.width * width,
          poi.bounds.height * height
        )
      )
    } else {
      const zoom = poi.zoom ?? 1;
      bounds = currentImage.imageToViewportRectangle(
        new OpenSeadragon.Rect(
          poi.position.x * width - (width / zoom / 2),
          poi.position.y * height - (width / zoom / 2),
          width / zoom,
          width / zoom
        )
      );
    }
    
    viewer?.viewport.fitBounds(bounds, false);
  }

  /**
   * Sets the Viewport to the selected Marker
   *

   * @param {OpenSeadragon.Viewer} viewer - OSD viewer object
   * @param {OpenSeadragon.Viewport} viewer.viewport - OSD viewport object
   * @param {Object} marker - Marker object
   * @param {Object} marker.source - Source object of Marker
   * @param {number} marker.source.x - Horizontal position of Marker object
   * @param {number} marker.source.y - Vertical position of Marker object
   * @param {number} marker.source.zoom - Zoom of Marker object
   */
  public goToMarker(viewer, marker: Annotation) {
    const currentImage = viewer.world.getItemAt(this.sequenceNumber ?? 0);
    const obj = this.container.elements.find(e => e.id === marker.id);

    if (!marker || !currentImage || !obj?.element?.handleBounds) return;

    const padding = { x: 0.4, y: 0.2 };
    const bounds = obj.element.handleBounds;
    const viewportBounds = currentImage.imageToViewportRectangle(
      new OpenSeadragon.Rect(
        bounds.x - (bounds.width * padding.x), 
        bounds.y - (bounds.height * padding.y), 
        bounds.width + (bounds.width * padding.x * 2), 
        bounds.height + (bounds.height * padding.y * 2), 
      )
    );

    viewer?.viewport.fitBoundsWithConstraints(viewportBounds, false);
  }

  /**
   * Grid toggle
   */
  private handleToggleGrid() {
    if (this.container) {
      const element = this.container.getElementById('grid');
      if (element) {
        element.state = (this.grid) ? 'visible' : 'hidden';
        this.container.update();
        this.overlay.localPaper.view.update();
      }
    }
  }

  public selectMedia() {
    const modalRef = this.modalService.open(MediaDialogComponent, {scrollable: true, size: 'xxl'});
    modalRef.componentInstance.allowedTypes = [MediaType.IMAGE, MediaType.DEEPZOOM, MediaType.VIDEO, MediaType.AUDIO];
    modalRef.result.then((media: Media) => {
      this.slide.layers[this.layerNumber].images[this.sequenceNumber].media = media;

      setTimeout(() => this.handleUploadImage());
      if (
        this.sequenceNumber === 0 &&
        this.layerNumber === 0 &&
        [MediaType.IMAGE, MediaType.VIDEO, MediaType.DEEPZOOM].includes(media.type)
      ) {
        this.slide.thumbnailMedia = media;
      }
      this.store.dispatch(new SlideChanged());
    });
  }

  /**
   * New Image uploaded
   *
   */
  private handleUploadImage() {
    this.instantiateVirtualMicroscope();
    this.handleToggleGrid();
  }

  /**
   * Image deleted
   *
   */
  private handleDeleteImage() {
    this.instantiateVirtualMicroscope();
    this.handleToggleGrid();
  }

  /**
   * Fetches ImagesProperties and AnnotationsXML
   *
   * @param {number} slideId - Slide identifier
   * @param {boolean} differentImage - Force redrawing of Image
   */
  public instantiateVirtualMicroscope() {
    if (!this.mediaService.isDeepzoom(this.media) && !this.mediaService.isImage(this.media)) {
      return;
    }
    if (this.viewer) {
      this.viewer.destroy();
    }
    this.zone.runOutsideAngular(() => {
      const config = this.getOpenSeadragonConfig();
      this.viewer = new OpenSeadragon.Viewer(config);

      this.viewer.addHandler('animation-start', () => {
        this.updateLayerPreload(this.sequenceNumber, false);
      })

      this.viewer.addHandler('animation-finish', () => {
        this.updateLayerPreload(this.sequenceNumber, true);
      })

      this.viewer.addHandler('animation-finish', () => {
        this.container.selection.forEach((el) => {
          this.container.select(el, false);
        })
      })

      this.viewer.addHandler('canvas-key', (e) => {
        if (this.marker) {
          e.preventDefaultAction = true;
          e.preventVerticalPan = true;
          e.preventHorizontalPan = true;
        }
      })

      this.overlay = this.viewer.paperjsOverlay();

      if (this.media?.dimensions?.pixelPerMillimeter) {
        this.scalebar = new Scalebar({
          viewer: this.viewer,
          pixelsPerMeter: this.media?.dimensions?.pixelPerMillimeter * 1000,
          backgroundColor: 'white',
          xOffset: 10,
          yOffset: 10,
          barThickness: 4
        });
      }
      this.container = this.setupContainer(this.overlay.localPaper);
    });

    this.minZoom = this.viewer.viewport.getZoom(true);

    if (!this.isDeepZoom) {
      this.drawGrid(this.overlay, this.container);
    }
    this.drawMarkers(this.overlay, this.container);
    this.addHandler(this.viewer, this.overlay, this.container);
  }

  /**

   * @param {OpenSeadragon.Overlay} overlay
   * @param {Container} container
   */
  private drawGrid(overlay, container) {
    const {localPaper} = overlay;

    const grid = new Grid(
      this.slide.layers[this.layerNumber]?.images[this.sequenceNumber],
      localPaper,
      container
    );
    grid.id = 'grid';
    container.addElement(grid);

    overlay.resize();
    overlay.resizeCanvas();
  }

  /**
   * @param {OpenSeadragon.Overlay} overlay
   * @param {paper} overlay.localPaper
   * @param {paper.Layer} overlay.mainLayer
   * @param {Container} container
   */
  private drawMarkers(overlay, container) {
    const {localPaper, mainLayer} = overlay;

    const scale = this.isDeepZoom ? 1 : 600 / this.getDimensions().width;

    this.imageAnnotations.forEach((marker) => {
      const markerFormat = new MarkerFormat(this.slide.defaults);
      let element;
      switch (marker.type) {
        case AnnotationType.TEXT:
          element = new Text(marker, markerFormat, localPaper, mainLayer, container, scale, {x: 0, y: 0});
          break;
        case AnnotationType.KEYMAP:
          element = new Keymap(<Keymap>marker, markerFormat, localPaper, mainLayer, container, scale, {x: 0, y: 0});
          break;
        case AnnotationType.FREE_FORM:
          markerFormat.setMarkerFormat(marker);
          element = new Freeform(marker, markerFormat, localPaper, mainLayer, container, scale, {x: 0, y: 0});
          break;
        default:
          markerFormat.setMarkerFormat(marker);
          element = new Marker(marker, markerFormat, localPaper, mainLayer, container, scale, {x: 0, y: 0});
          break;
      }
      element.id = marker.id;
      container.selection.forEach((selectedItem) => {
        if (selectedItem.model && selectedItem.model.id === marker.id && element) {
          element.select();
        }
      });
      if (element) {
        const existingElement = container.getMarkerElementById(element.id);
        if (existingElement) {
          container.removeElement(existingElement);
        }
        container.addElement(element);
      }
    });

    overlay.resize();
    overlay.resizeCanvas();
  }

  /**

   * @param {PaperScope} localPaper
   * @returns {Container}
   */
  private setupContainer(localPaper) {
    const container = new Container(localPaper,
      new MarkerFormat(),
      true,
      this.isDeepZoom);
    container.setCanvasSize(
      this.getDimensions().width,
      this.getDimensions().height
    );
    container.setMinZoom(1);

    return container;
  }

  /**

   * @param {OpenSeadragon.Viewer} viewer - OSD viewer object
   * @param {OpenSeadragon.Viewport} viewer.viewport - OSD viewport object
   * @param {Object} event - OSD event object
   * @param {OpenSeadragon.Point} event.position - The position of the event relative to the tracked element.
   * @param {PointerEvent} event.originalEvent - PointerEvent instance with infos about pressed keys
   * @param {MarkerTool} markerTool - Paper marker tool
   */
  private clickHandler(viewer, event, markerTool) {
    const point = new OpenSeadragon.Point(event.position.x, event.position.y);
    const viewportPoint = viewer.viewport.pointFromPixel(point);

    const currentImage = viewer.world.getItemAt(this.sequenceNumber ?? 0);
    const imagePoint = currentImage?.viewportToImageCoordinates(viewportPoint);
    event.point = {};
    ({x: event.point.x, y: event.point.y} = imagePoint);
    event.event = event.originalEvent;

    markerTool._handleMouseDown.call(markerTool._tool, event);
  }

  /**

   * @param {OpenSeadragon.Viewer} viewer - OSD viewer object
   * @param {OpenSeadragon.Viewport} viewer.viewport - OSD viewport object
   * @param {Object} event - OSD event object
   * @param {OpenSeadragon.Point} event.delta - Difference between the current position and the last drag event position.
   * @param {PointerEvent} event.originalEvent - PointerEvent instance with infos about pressed keys
   * @param {MarkerTool} markerTool - Paper marker tool
   */
  private dragHandler(viewer, event, markerTool) {
    if (!this.marker) {
      return;
    }
    const delta = new OpenSeadragon.Point(event.delta.x, event.delta.y);
    const viewportPoint = viewer.viewport.deltaPointsFromPixels(delta);
    viewportPoint.y *= viewer.viewport._contentAspectRatio || 1;
    ({x: event.delta.x, y: event.delta.y} = viewportPoint);
    event.event = event.originalEvent;

    markerTool._handleMouseDrag.call(markerTool._tool, event);
  }

  /**

   * @param viewer
   * @param event
   * @param markerTool
   */
  private releaseHandler(viewer, event, markerTool) {
    if (!this.marker) {
      return;
    }
    markerTool._handleMouseUp.call(markerTool._tool);
  }


  /**
   * @function
   * @param viewer
   * @param event
   */
  private keyHandler(viewer, event) {
    const getSequenceLength = () => this.layer?.images.filter((image: Image) => image.media).length;
    if (!event.ctrl && !event.alt && !event.meta) {
      let sequenceNumber: number;
      switch (event.keyCode) {
        case 106: // j
          sequenceNumber = this.sequenceNumber - 1;
          if (0 <= sequenceNumber && sequenceNumber < getSequenceLength()) {
            this.store.dispatch(new SetSequenceNumber(sequenceNumber));
          }
          event.preventDefault = true;
          break;
        case 107: // k
          sequenceNumber = this.sequenceNumber + 1;
          if (0 <= sequenceNumber && sequenceNumber < getSequenceLength()) {
            this.store.dispatch(new SetSequenceNumber(sequenceNumber));
          }
          event.preventDefault = true;
          break;
        case 99: // c
          if (this.marker) {
            this.store.dispatch(new CopyMarker(this.marker));
          }
          event.preventDefault = true;
          break;
        case 118: // v
          this.store.dispatch(new PasteMarker());
          event.preventDefault = true;
          break;
        default:
          event.preventDefault = false;
          break;
      }
    } else if (event.ctrl) {
      switch (event.keyCode) {
        case 106: // j
          event.preventDefault = true;
          break;
        case 107: // k
          event.preventDefault = true;
          break;
        default:
          event.preventDefault = false;
          break;
      }
    } else {
      event.preventDefault = false;
    }
  }

  /**
   * @param {Object} object
   */
  public setFreeTool(object) {
    if (object.id === this.marker.id) {
      const element = this.container.getMarkerElementById(object.id);
      const shape = element._shape || element;
      if (shape._model.shape === Shape.FREE || shape._model.shape === Shape.INTERACTIVE_AREA) {
        this.toastMessage = 'freeform_drawing_tool_toast';
        shape.drawTool(this.viewer, this.sequenceNumber).then(() => {
          this.toastMessage = '';
          this.marker = object;
          this.markerChange.emit(this.marker);
          this.microscopeChange.emit();
        });
      }
    }
  }

  /**

   * @param {OpenSeadragon.Viewer} viewer - OSD viewer object
   * @param {OpenSeadragon.Viewport} viewer.viewport - OSD viewport object
   * @param {OpenSeadragon.Overlay} overlay
   * @param {PaperScope} overlay.localPaper
   * @param {Container} container
   */
  private addHandler(viewer, overlay, container) {
    const {localPaper} = overlay;

    const scale = (this.isDeepZoom) ? 1 : 600 / this.getDimensions().width;
    // const offset = (isVM) ? undefined : this.slide.images[this.sequenceId].offset;
    const markerTool = new MarkerTool(localPaper, container, this.slide, this.command, scale);
    const trackerConfig = {
      element: viewer.canvas,
      pressHandler: (event) => this.clickHandler(viewer, event, markerTool),
      dragHandler: (event) => this.dragHandler(viewer, event, markerTool),
      releaseHandler: (event) => this.releaseHandler(viewer, event, markerTool),
      keyHandler: (event) => this.keyHandler(this.viewer, event),

    };

    const mouseTracker = new OpenSeadragon.MouseTracker(trackerConfig);
    mouseTracker.setTracking(true);

    markerTool.removeAllListeners();

    markerTool.on('selectMarker', (event) => this.handleSelectContainerElement(event));
    markerTool.on('selectKeymap', (event) => this.handleSelectContainerElement(event));
    markerTool.on('selectFreeform', (event) => this.handleSelectContainerElement(event));

    markerTool.on('moveItem', (event) => this.handleMove(viewer, container, event));
    markerTool.on('endMoveItem', () => this.handleEndMove(viewer));

    markerTool.on('unselectMarker', () => {
      this.handleUnSelectMarker();
    });
    markerTool.on('updateMarker', () => {
      container.arrange();
      container.update();
    });
    markerTool.on('selectAllMarkers', () => {
      container.selectAll();
    });

    onFontLoad ('Figtree', { weight: '600' }, () => {
      container.arrange();
      container.update();
    });

    viewer.addHandler('animation', () => this.updateLayoutAndGrid());
    viewer.addHandler('animation-finish', () => this.getCurrentPositionAndZoom(container, viewer, true));
    viewer.addHandler('open', () => {
      setTimeout(() => {
        if (this.boundsBeforeSave) {
          this.viewer.viewport.fitBounds(this.boundsBeforeSave, true);
        }
        this.getCurrentPositionAndZoom(container, viewer, false);
      });
      // this.handleUnSelectMarker();
    });
  }

  private handleMove(viewer, container, event) {
    this.hasMoved = true;
    viewer.setMouseNavEnabled(false);
    if (event.item._model.type !== AnnotationType.KEYMAP) {
      const sX = event.item._model.source.position.x;
      const sY = event.item._model.source.position.y;
      const currentImage = viewer.world.getItemAt(this.sequenceNumber ?? 0);
      const point = currentImage?.imageToViewportCoordinates(
        sX * this.getDimensions().width,
        sY * this.getDimensions().height
      );

      // if (event.item._element.bounds._width !== 0 && event.item._element.bounds._height !== 0) {
      //   const bW = event.item._element.bounds._width;
      //   const bH = event.item._element.bounds._height;
      //   const fW = event.item._localPaper.view.bounds.width / bW;
      //   const fH = event.item._localPaper.view.bounds.height / bH;
      //   event.item._model.source.zoom = (fW > fH ? fH : fW) * (point.x > point.y ? point.x : point.y);
      // }
    } else {
      viewer.viewport.goHome();
    }
  }

  private handleEndMove(viewer) {
    viewer.setMouseNavEnabled(true);
    if (this.hasMoved) {
      this.microscopeChange.emit();
    }
    this.hasMoved = false;
  }

  private updateLayoutAndGrid() {
    const element = this.container.getElementById('grid');
    if (element) {
      element.arrange();
    }
  }

  /**

   * @param {OpenSeadragon.Viewer} viewer - OSD viewer object
   * @param {Container} container
   * @param {Object} event
   * @param {Model} event.item
   */
  private handleSelectContainerElement(event) {
    if (this.container) {
      if (!this.selftestMarker && event.item) {
        this.marker = event.item.model;
        this.markerChange.emit(this.marker);
        const element = this.container.getMarkerElementById(event.item._model.id);
        if (element) {
          this.container.select(element, false);
        }
      }
    }
  }

  /**

   * @param {OpenSeadragon.Viewer} viewer - OSD viewer object
   * @param {Container} container
   */
  private handleSelectMarker() {
    if (this.container) {
      if (this.selftestMarker) {
        const element = this.container.getSelftestMarkerElementById(this.selftestMarker.id);
        if (element) {
          this.container.removeElement(element);
        }
        this.selftestMarker = undefined;
      }
      if (this.marker) {
        const element = this.container.getMarkerElementById(this.marker.id);
        if (element) {
          this.container.select(element, false);
        }
      }
    }
  }

  /**

   * @param {OpenSeadragon.Viewer} viewer - OSD viewer object
   * @param {Container} container
   */
  private handleHoverMarker() {
    if (this.container) {
      if (this.selftestMarker) {
        const element = this.container.getSelftestMarkerElementById(this.selftestMarker.id);
        if (element) {
          this.container.removeElement(element);
        }
        this.selftestMarker = null;
      }
      if (this.hoverMarker) {
        const element = this.container.getMarkerElementById(this.hoverMarker.id);
        if (element) {
          this.container.select(element, false);
          this.goToMarker(this.viewer, this.hoverMarker);
        }
      } else if (this.marker) {
        const element = this.container.getMarkerElementById(this.marker.id);
        if (element) {
          this.container.select(element, false);
          this.goToMarker(this.viewer, this.marker);
        }
      }
    }
  }

  /**

   * @param {OpenSeadragon.Overlay} overlay
   * @param {Container} container
   */
  private handleSelftestMarker() {
    const {localPaper, mainLayer} = this.overlay;

    if (this.container) {
      if (this.selftestMarker) {
        const scale = (this.isDeepZoom) ? 1 : 600 / this.getDimensions().width;
        // const offset = (isVM) ? undefined : this.slide.images[this.sequenceId].offset;
        const markerFormat = new MarkerFormat(this.slide.defaults);
        const element = new KeymapSelftest(this.selftestMarker, markerFormat, localPaper, mainLayer, this.container, scale);
        if (element) {
          element.id = 'marker_selftest';
          this.container.addElement(element);
          this.container.select(element, false);
          element._element.bringToFront();
        }
      } else if (this.marker) {
        const element = this.container.getElementById(this.marker.id);
        if (element) {
          this.container.select(element, false);
        }
      }
    } else {
      this.container.deselectAll();
    }
  }

  /**

   * @param {OpenSeadragon.Viewer} viewer - OSD viewer object
   * @param {Container} container
   */
  private handleUnSelectMarker() {
    this.viewer.setMouseNavEnabled(true);
    if (this.selftestMarker) {
      const element = this.container.getSelftestMarkerElementById(this.selftestMarker.id);
      if (element) {
        this.container.removeElement(element);
      }
      this.selftestMarker = null;
    } else {
      this.container.deselectAll();
      this.marker = null;
      this.markerChange.emit(this.marker);
      this.props.imageIsSelected = false;
    }
  }

  /**

   * @param {OpenSeadragon.Overlay} overlay
   * @param {Container} container
   */
  private handleImageProperties(dimensions?: Dimensions) {
    if (!this.container) {
      return;
    }
    this.container.destroyElements();
    this.drawMarkers(this.overlay, this.container);
    this.container.setCanvasSize(
      dimensions?.width ?? this.getDimensions().width,
      dimensions?.height ?? this.getDimensions().height
    );
    this.container.resize();
    this.container.arrange();
    this.container.update();
  }

  public viewerBackgroundColor(slide: Slide) {
    const c: string = slide?.layers?.[this.layerNumber ?? 0]?.images?.[this.sequenceNumber ?? 0]?.background?.color || '';
    const color = c.startsWith('#') || c.startsWith('rgb') ? c : '#ffffff';
    if (this.viewer?.navigator?.element) {
      this.viewer.navigator.element.style.background = color;
    }
    return color;
  }

  private handleZoomPreset() {
    switch (this.zoomPreset) {
      case 'maxZoom':
        this.viewer.viewport.zoomTo(this.maxZoom);
        break;
      case 'fit':
        // eslint-disable-next-line no-case-declarations
        const bounds = this.viewer.viewport.getHomeBounds();
        this.viewer.viewport.fitBounds(bounds, false);
        break;
      case 'minZoom':
        this.viewer.viewport.zoomTo(1);
        break;
      default:
        break;
    }
  }

  /**

   * @param {Container} container
   * @param {OpenSeadragon.Viewer} viewer - OSD viewer object
   * @param {OpenSeadragon.Viewport} viewer.viewport - OSD viewport object
   * @param {boolean} handler
   * @returns {{x: number, y: number, zoom: number, height: number, width: number}}
   */
  private getCurrentPositionAndZoom(container, viewer, handler): ImagePosition {
    const currentImage = viewer.world.getItemAt(this.sequenceNumber ?? 0);
    const point = currentImage?.viewportToImageCoordinates(viewer.viewport.getCenter(true));
    const {height, width} = this.getDimensions();

    if (!height || !width) {
      return;
    }
    const viewportBounds = this.viewer.viewport.getBounds();
    const imageBounds = currentImage.viewportToImageRectangle(viewportBounds);
    const currentBounds: Bounds = {
      x: imageBounds.x / width,
      y: imageBounds.y / height,
      width: imageBounds.width / width,
      height: imageBounds.height / height
    };
    this.store.dispatch(new SetBounds(currentBounds));

    const currentPosition: ImagePosition = {
      x: point.x / width,
      y: point.y / height,
      zoom: viewer.viewport.getZoom(true),
      height,
      width
    };
    this.store.dispatch(new SetPosition(currentPosition));
    this.zoom = Math.floor(viewer.viewport.getZoom(true) * 100);
    this.zoomChange.emit(this.zoom);
    this.updateLayoutAndGrid();
    return currentPosition;
  }

  /**
   * Creates a placeholder blank image
   *
   * @param width: image width
   * @param height: image height
   */
  public getPlaceholderImageUrl(width: number, height: number) {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const placeholderUrl = canvas.toDataURL();
    return placeholderUrl;
  }

  /**
   * @function
   * @returns {Object}
   */
  private getOpenSeadragonConfig(): OpenSeadragon.Options {
    const media = this.layer.images.find(image => image?.media?.type === MediaType.IMAGE || image?.media?.type === MediaType.DEEPZOOM)?.media as MediaImage;
    const placeholderUrl = this.getPlaceholderImageUrl(media.dimensions.width / 10, media.dimensions.height / 10);

    return {
      element: this.microscopeRef.nativeElement,
      showNavigationControl: false,
      showNavigator: true,
      navigatorPosition: 'ABSOLUTE',
      navigatorBackground: this.viewerBackgroundColor(this.slide),
      navigatorHeight: 105,
      navigatorWidth: 155,
      navigatorTop: 20,
      navigatorLeft: 'calc(100% - 175px)',
      navigatorAutoResize: true,
      autoHideControls: false,
      maxZoomPixelRatio: 1,
      animationTime: 0.7,
      gestureSettingsMouse: {
        flickEnabled: false,
        clickToZoom: false,
        dblClickToZoom: true
      },
      gestureSettingsPen: {
        flickEnabled: false
      },
      gestureSettingsTouch: {
        flickEnabled: false
      },
      gestureSettingsUnknown: {
        flickEnabled: false
      },
      sequenceMode: false,
      imageLoaderLimit: 10,
      showSequenceControl: false,
      preserveViewport: true,
      tileSources: this.layer.images.filter(image => image?.media).map((image, index) => {
        const media: MediaDeepzoom = image.media;
        const isActive = index === this.sequenceNumber;
        const isPreload = index === (this.sequenceNumber - 1) || index === (this.sequenceNumber + 1);

        let tileSource;
        let loadTilesWithAjax = false;
        if (media.type === MediaType.DEEPZOOM) {
          const tilesUrl = this.mediaService.getUrl(media, 'tileFolder');
          if (tilesUrl.endsWith('.zip')) {
            loadTilesWithAjax = true;
            tileSource = new ZipZoomify({
              tilesUrl,
              width: media.dimensions.width,
              height: media.dimensions.height,
            })
          } else {
            tileSource = {
              type: 'zoomifytileservice',
              tilesUrl,
              width: media.dimensions.width,
              height: media.dimensions.height,
              tileSize: media.dimensions.tileSize
            };
          }
        } else if (media.type === MediaType.IMAGE) {
          tileSource = {
            type: 'image',
            url: this.mediaService.getUrl(media)
          };
        } else {
          tileSource = {
            type: 'image',
            url: placeholderUrl
          }
        }
        return {
          x: 0,
          y: 0,
          loadTilesWithAjax,
          opacity: isActive ? 1 : (isPreload ? 0.001 : 0),
          tileSource
        };
      })
    };
  }

  /**
   * @returns {{width: number, height: number, tileSize: number}}
   */
  private getDimensions(): Dimensions {
    if (this.media) {
      return (this.media as MediaImage & MediaDeepzoom).dimensions;
    } else {
      return {width: undefined, height: undefined};
    }
  }

  public async setSequenceId(sequenceNumber?: number): Promise<void> {
    if (!this.viewer) {
      return;
    }
    await this.changeImage(this.viewer, sequenceNumber, this.sequenceNumber);
    this.sequenceNumber = sequenceNumber ?? 0;
    this.updateLayerPreload(this.sequenceNumber, true);
  }

  public setLayerId(layerNumber): void {
    if (!this.viewer) {
      return;
    }
    this.layerNumber = layerNumber ?? 0;
  }
}
