import { ConfigSettings } from './config';
import { EditorOptions } from 'tinymce';

export const getFormatConfig = (settings: ConfigSettings): Partial<EditorOptions> => {
    return {
      block_formats: 'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Box=box-format;',
      formats: {
        'box-format': {
          block: 'div', 
          classes: 'box-format',
          wrapper: true
        },
        'box-format-attention': {
          selector: 'div.box-format',
          classes: 'attention'
        },
        'box-format-note': {
          selector: 'div.box-format',
          classes: 'note'
        }
      },
      style_formats_merge: true
    }
};
