import { Component, Input } from '@angular/core';
import { IndexChanged } from '@medsurf/actions';
import { SubjectNode } from '@medsurf/models';
import { Store } from '@ngxs/store';
import { ThemeColor, ThemeColorService } from './theme-color.service';

@Component({
  selector: 'medsurf-theme-color',
  templateUrl: './theme-color.component.html',
  styleUrls: ['./theme-color.component.scss']
})
export class ThemeColorComponent {

  @Input()
  public selectedBlock: SubjectNode;

  public themeColors: ThemeColor[];

  constructor(private store: Store, public themeColorService: ThemeColorService) {
    this.themeColors = themeColorService.themeColors;
  }

  public onChange(event) {
    if (this.selectedBlock.page.themeColor === event) {
      return;
    }

    this.selectedBlock.page.themeColor = event;
    this.store.dispatch(new IndexChanged([this.selectedBlock]));
  }
}
