import Element from './element';

import paper from 'paper';

class MarkerElement extends Element {

  constructor(model, protected _marker?) {
    super(model);
  }

  _roundCorners(path, radius, excludes?) {
    const segments = path.segments.slice(0);
    path.removeSegments();
    for (let i = 0, l = segments.length; i < l; i++) {
      const curPoint = segments[i].point,
          nextPoint = segments[i + 1 === l ? 0 : i + 1].point,
          prevPoint = segments[i - 1 < 0 ? segments.length - 1 : i - 1].point,
          nextDelta = curPoint.subtract(nextPoint),
          prevDelta = curPoint.subtract(prevPoint);
      nextDelta.length = radius;
      prevDelta.length = radius;
      if (excludes && excludes.indexOf(i) === -1) {
        path.add(
          new paper.Segment(
            curPoint.subtract(prevDelta),
            null,
            prevDelta.divide(2)
          )
        );
        path.add(
          new paper.Segment(
            curPoint.subtract(nextDelta),
            nextDelta.divide(2),
            null
          )
        );
      } else {
        path.add(curPoint);
      }
    }
    path.closed = true;
    return path;
  }

  get marker() {
    return this._marker;
  }
}

export default MarkerElement;
