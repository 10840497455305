import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Slide } from '@medsurf/models';

@Component({
  selector: 'medsurf-sequence-navigation',
  templateUrl: './sequence-navigation.component.html',
  styleUrls: ['./sequence-navigation.component.scss']
})
export class SequenceNavigationComponent {
  @Output() navigationChange = new EventEmitter();

  @Input() slide: Slide;
}
