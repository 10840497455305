import { Component, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
  Choice,
  FreeTextQuestion,
  KprimeQuestion,
  LongListQuestion,
  MappingQuestion,
  QuestionType,
  SingleChoiceQuestion
} from '@medsurf/models';
import { SlideChanged } from '@medsurf/actions';
import { QuestionDialogComponent } from '../question-dialog/question-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'medsurf-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent {
  @Input() question: SingleChoiceQuestion & LongListQuestion & MappingQuestion & KprimeQuestion & FreeTextQuestion;

  @ViewChild('questionText') questionText: HTMLDivElement;
  @ViewChildren('choiceLabel') choiceLabels: QueryList<HTMLDivElement>;


  public editorToolbarExcludesFreeText = [
    'mediapdf',
    'mediaaudio',
    'mediavideo'
  ];

  public editorToolbarExcludes = [
    'mediaimage',
    ...this.editorToolbarExcludesFreeText
  ];

  constructor(
    private store: Store,
    private modalService: NgbModal
  ) {}

  public focus() {
    // this.questionText.focus();
  }

  openQuestionDialog() {
    const modalRef = this.modalService.open(QuestionDialogComponent, {size: 'xl'});
    modalRef.componentInstance.question = this.question;
  }

  onChange() {
    if (this.question.choices) {
      this.question.choices = this.question.choices.map((choice, idx) => {
        return {...choice, order: idx}
      });
    }
    if (this.question.solutions) {
      this.question.solutions = this.question.solutions.map((solution) => solution);
    }
    this.store.dispatch(new SlideChanged());
  }

  trackById(obj: { id: string}) {
    return obj.id || obj;
  }

  addChoice() {
    this.question.choices.push({
      label: '',
      order: this.question.choices.length
    });
    setTimeout(() => {
      this.choiceLabels.last?.focus();
    }, 0);
    this.onChange();
  }

  removeChoiceAndSolution(i: number) {
    this.question.choices?.splice(i, 1);
    this.question.solutions?.splice(i, 1);
    this.onChange();
  }

  removeChoice(i: number) {
    this.question.choices.splice(i, 1);
    this.onChange();
  }

  removeSolution(i: number) {
    this.question.solutions.splice(i, 1);
    this.onChange();
  }

  orderChanged($event: CdkDragDrop<Choice, any>) {
    // Order choices
    moveItemInArray(this.question.choices, $event.previousIndex, $event.currentIndex);
    // Order solutions for Kprime and TrueFalse
    switch (this.question.type) {
      case QuestionType.KPRIME:
      case QuestionType.TRUE_FALSE:
        moveItemInArray(this.question.solutions, $event.previousIndex, $event.currentIndex);
        break;
      case QuestionType.MAPPING:
        this.question.solutions = this.question.solutions.map(s => this.question.choices.findIndex(c => `${c.order}` === s).toString());
        break;
      case QuestionType.SINGLE_CHOICE:
      case QuestionType.LONG_LIST:
        this.question.solution = this.question.choices.findIndex(c => c.order === this.question.solution);
        break;
    }

    // Set order property
    this.question.choices = this.question.choices.map((choice, idx) => {
      return {...choice, order: idx}
    });
    this.onChange();
  }

  solutionOrderChanged($event: CdkDragDrop<any, any>) {
    // Order solutions
    moveItemInArray(this.question.solutions, $event.previousIndex, $event.currentIndex);
    this.onChange();
  }

  addSolution() {
    this.question.solutions.push(null);
    this.onChange();
  }
}
