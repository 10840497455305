import paper from 'paper';
import Label from '../marker/label/label';
import { FreeForm } from '@medsurf/models';

class Path {
  private _element: any;
  private _center: any;

  constructor(private _format, private _marker, private _model: FreeForm, private _path?) {
    this._element = null;
    this._center = null;
    this._init();
  }

  _init() {
    this.draw();
    this.drawLabel();
  }

  draw() {
    if (this._element) {
      this.cleanUp();
    }

    if (this._model.deleted) {
      return;
    }

    this._element = this.getElement();

    this._element.freeform = this._marker;

    this._center = new paper.Point(this._marker._container.getAbsoluteCoords(
      this._model.source.position.x,
      this._model.source.position.y,
      this._marker._imageOffset,
      this._marker._imageScale
    ));

    this._element.strokeColor = this._model.freeFormStyle.strokeColor || this._model.freeFormStyle.color || this._format.border.strokeColor;
    this._element.strokeWidth = this._model.freeFormStyle.strokeWidth || this._format.border.strokeWidth;
    this._element.fillColor = this._model.freeFormStyle.background ?
      (this._model.freeFormStyle.backgroundInBorderColor ?
        this._model.freeFormStyle.strokeColor || this._model.freeFormStyle.color || this._format.border.strokeColor :
        this._model.freeFormStyle.color || this._format.border.fillColor) :
      undefined;
    if (this._element.fillColor) {
      this._element.fillColor.alpha = this._model.freeFormStyle.opacity;
    }
    this._element.dashArray = [1, 0];
    if (this._model?.freeFormStyle?.dash) {
      const [dashLength, gapLength]: string[] = this._model.freeFormStyle.dash.split(',');
      if (dashLength && gapLength) {
        const factor =  this._marker?._model?.freeFormStyle?.strokeWidth ? this._marker._model.freeFormStyle.strokeWidth : 1;
        this._element.dashArray = [
          Number.parseInt(dashLength, 10) * factor,
          Number.parseInt(gapLength, 10) * factor
        ];
      }
    }

    const rotationPoint = this._center;
    this._element.rotate(this._model.freeFormStyle.angle || 0, rotationPoint || null);
    this._element.skew(this._model.freeFormStyle.skewAngle || 0);
    this._marker._element.addChild(this._element);

    if (this._marker._isSelected) {
      this.drawSelection();
    }
  }

  getElement() {
    const rectangle = new paper.Rectangle(
      new paper.Point(this._marker._container.getAbsoluteCoords(
        this._model.source.position.x - 0.5 * this._model.freeFormStyle.width,
        this._model.source.position.y - 0.5 * this._model.freeFormStyle.height,
        this._marker._imageOffset,
        this._marker._imageScale
      )),
      new paper.Point(this._marker._container.getAbsoluteCoords(
        this._model.source.position.x + 0.5 * this._model.freeFormStyle.width,
        this._model.source.position.y + 0.5 * this._model.freeFormStyle.height,
        this._marker._imageOffset,
        this._marker._imageScale
      ))
    );

    switch (this._path) {
      case 'rectangle':
        return new paper.Path.Rectangle(rectangle);
      case 'ellipse':
        return new paper.Path.Ellipse(rectangle);
      case 'arrow':
        // eslint-disable-next-line no-case-declarations
        const path = new paper.Path(this.getArrowSegments());
        path.closed = true;
        return path;
      case 'triangle':
        // eslint-disable-next-line no-case-declarations
        const trianglePath = new paper.Path(this.getTriangleSegments());
        trianglePath.closed = true;
        return trianglePath;
    }
  }

  drawSelection() {
    const selectionCenters = [];

    switch (this._path) {
      case 'rectangle':
        selectionCenters.push(this._element.getNearestPoint(
          new paper.Point(this._marker._container.getAbsoluteCoords(
            this._model.source.position.x + 0.5 * this._model.freeFormStyle.width,
            this._model.source.position.y + 0.5 * this._model.freeFormStyle.height,
            this._marker._imageOffset,
            this._marker._imageScale
          ))
        ));
        break;
      case 'ellipse':
        selectionCenters.push(this._element.getNearestPoint(
          new paper.Point(this._marker._container.getAbsoluteCoords(
            this._model.source.position.x,
            this._model.source.position.y + 0.5 * this._model.freeFormStyle.height,
            this._marker._imageOffset,
            this._marker._imageScale
          ))
        ));
        selectionCenters.push(this._element.getNearestPoint(
          new paper.Point(this._marker._container.getAbsoluteCoords(
            this._model.source.position.x + 0.5 * this._model.freeFormStyle.width,
            this._model.source.position.y,
            this._marker._imageOffset,
            this._marker._imageScale
          ))
        ));
        break;
      case 'arrow':
      case 'triangle':
        // eslint-disable-next-line no-case-declarations
        const {lineRatio} = this.getArrowRatios();
        selectionCenters.push(this._element.getNearestPoint(
          new paper.Point(this._marker._container.getAbsoluteCoords(
            this._model.source.position.x + this._model.freeFormStyle.width,
            this._model.source.position.y + 0.5 * this._model.freeFormStyle.height * lineRatio,
            this._marker._imageOffset,
            this._marker._imageScale
          ))
        ));
        break;
    }

    for (const center of selectionCenters) {
      const factor = Math.max(1 / (this._marker._container._localPaper.view.zoom / 2), 1);
      const radius = factor * this._format.selection.radius;

      const selection = new paper.Path.Circle(center, radius);
      const rotationPoint = this._center;
      selection.strokeColor = this._format.selection.color;
      selection.fillColor = this._format.selection.color;
      selection.fillColor.alpha = this._format.selection.opacity;
      selection.strokeWidth = radius / 10;
      selection.rotate(this._model.freeFormStyle.angle || 0, rotationPoint);
      selection.freeform = this._marker;
      selection.data = {
        'selectionType': 'resize'
      };
      selection.bringToFront();
      this._marker._element.appendTop(selection);
    }
  }

  drawLabel() {
    if (!this._model.label || !this._model.label.text || this._model.label.text === '' || !this._center) {
      return;
    }

    const label = new Label(this._model.label.text, this._format, this._marker);
    const position: any = {};
    ({x: position.x, y: position.y} = this._center);
    if (this._path === 'arrow' || this._path === 'triangle') {
      const angle = this._model.freeFormStyle.angle || 0;
      // When angle range is in [90, 270], arrow points right
      const arrowPointsRight = angle > 90 && angle < 270;
      const arrowPointsDown = angle === 270;
      const arrowPointsUp = angle === 90;

      if (arrowPointsDown || arrowPointsUp) {
        const distanceRatio = 1.2;
        position.y -= (arrowPointsUp ? -1 : 1) * (this._element.bounds.height + distanceRatio * this._format.fontSize);
        position.x -= 0.5 * label._element.bounds.width;
      } else {
        const width = new paper.Point(
          this._marker._container.getAbsoluteCoords(
            this._model.source.position.x + this._model.freeFormStyle.width,
            1,
            this._marker._imageOffset,
            this._marker._imageScale
          )
        ).x - position.x;
        position.y += (this._format.fontSize + width) * Math.sin(angle * Math.PI / 180);
        position.x += (this._format.fontSize + width) * Math.cos(angle * Math.PI / 180) -
          (arrowPointsRight ? label._element.bounds.width : 0);
      }
    } else {
      const distanceRatio = 1.5;
      position.y += 0.5 * this._element.bounds.height + distanceRatio * this._format.fontSize;
      position.x -= 0.5 * label._label.bounds.width;
    }
    label.element.position = new paper.Point(position.x, position.y);
    this._marker._element.addChild(label.element);
  }

  get element() {
    return this._element;
  }

  setScale() {
    this.element.strokeWidth = this._format.strokeWidth;
  }

  cleanUp() {
    if (this._element) {
      this._element.remove();
      this._element = null;
    }
  }

  getArrowSegments() {
    const ratios = this.getArrowRatios();

    const segments = [];

    const position = new paper.Point(this._marker._container.getAbsoluteCoords(
      this._model.source.position.x,
      this._model.source.position.y,
      this._marker._imageOffset,
      this._marker._imageScale
    ));
    const dimension = new paper.Point(this._marker._container.getAbsoluteCoords(
      this._model.source.position.x + this._model.freeFormStyle.width,
      this._model.source.position.y + this._model.freeFormStyle.height,
      this._marker._imageOffset,
      this._marker._imageScale
    ));

    dimension.x -= position.x;
    dimension.y -= position.y;

    // 1
    segments.push(new paper.Point(
      position.x,
      position.y
    ));

    // 2
    segments.push(new paper.Point(
      position.x + ratios.arrowHeadLengthRatio * dimension.x,
      position.y + 0.5 * (ratios.arrowHeadWidthRation - 1) * dimension.y
    ));

    // 3
    segments.push(new paper.Point(
      position.x + ratios.arrowHeadLengthRatio * dimension.x - ratios.arrowHeadDepthRatio * dimension.x,
      position.y - 0.5 * dimension.y * ratios.lineRatio
    ));

    // 4
    segments.push(new paper.Point(
      position.x + dimension.x,
      position.y - 0.5 * dimension.y * ratios.lineRatio
    ));

    // 5
    segments.push(new paper.Point(
      position.x + dimension.x,
      position.y + 0.5 * dimension.y * ratios.lineRatio
    ));

    // 6
    segments.push(new paper.Point(
      position.x + ratios.arrowHeadLengthRatio * dimension.x - ratios.arrowHeadDepthRatio * dimension.x,
      position.y + 0.5 * dimension.y * ratios.lineRatio
    ));

    // 7
    segments.push(new paper.Point(
      position.x + ratios.arrowHeadLengthRatio * dimension.x,
      position.y + 0.5 * (1 - ratios.arrowHeadWidthRation) * dimension.y
    ));

    return segments;
  }

  getTriangleSegments() {
    const triangleHeadWidthRatio = 96 / 186;
    const triangleHeadDepthRatio = 16 / 186;

    const segments = [];

    const position = new paper.Point(this._marker._container.getAbsoluteCoords(
      this._model.source.position.x,
      this._model.source.position.y,
      this._marker._imageOffset,
      this._marker._imageScale
    ));
    const dimension = new paper.Point(this._marker._container.getAbsoluteCoords(
      this._model.source.position.x + this._model.freeFormStyle.width,
      this._model.source.position.y + this._model.freeFormStyle.height,
      this._marker._imageOffset,
      this._marker._imageScale
    ));

    dimension.x -= position.x;
    dimension.y -= position.y;

    // 1
    segments.push(new paper.Point(
      position.x,
      position.y
    ));

    // 2
    segments.push(new paper.Point(
      position.x + dimension.x,
      position.y + 0.5 * (triangleHeadWidthRatio - 1) * dimension.y
    ));

    // 3
    segments.push(new paper.Point(
      position.x + dimension.x - triangleHeadDepthRatio * dimension.x,
      position.y
    ));

    // 4
    segments.push(new paper.Point(
      position.x + dimension.x,
      position.y + 0.5 * (1 - triangleHeadWidthRatio) * dimension.y
    ));

    return segments;
  }

  getArrowRatios() {
    const rectangleSize = 186;
    const arrowHeadLengthRatio = 127 / rectangleSize;
    const arrowHeadWidthRation = 96 / rectangleSize;
    const arrowHeadDepthRatio = 16 / rectangleSize;
    const lineRatio = 30 / rectangleSize;

    return {arrowHeadLengthRatio, arrowHeadWidthRation, arrowHeadDepthRatio, lineRatio};
  }
}

export default Path;
