<h3 class="mt-4 mb-2 text-secondary">{{ 'theme_color' | translate}}</h3>

<div class="btn-toolbar mb-4 mt-2" role="toolbar">
  <div class="btn-group mr-2" role="group">
    <button *ngFor="let color of themeColors"
            type="button"
            [class.btn-secondary]="color.value === selectedBlock.page.themeColor"
            [class.btn-primary]="color.value !== selectedBlock.page.themeColor"
            class="btn"
            (click)="onChange(color.value)">
      <span class="badge ml-1"
            [style.background-color]="themeColorService.getHexValue(color.value)"> &nbsp;
      </span>
      {{ color.name }}
    </button>
  </div>
</div>


