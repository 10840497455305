export enum MediaFormat {
  PDF = 'pdf',
  IMAGE = 'image',
  AUDIO = 'audio',
  VIDEO = 'video'
}

export enum LinkFormat {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  MARKER = 'marker',
}

export enum InlineFormat {
  BOLD = 'bold',
  H1 = 'header-1',
  H2 = 'header-2',
  H3 = 'header-3',
  ITALIC = 'italic',
  SUB = 'script-sub',
  SUP = 'script-super'
}

export enum BlockFormat {
  ORDERED = 'list-ordered',
  BULLET = 'list-bullet',
  TABLE = 'table'
}
