import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import 'moment/locale/de';
import { Settings, SETTINGS } from '@medsurf/services';
import { IndexState, SlideState } from '@medsurf/state';
import { SetLoadParameters, Me, LogoutSuccess } from '@medsurf/actions';
import { ofActionCompleted, Store, Actions } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'medsurf-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private _destroyed = new Subject<boolean>();
  private lastSessionCheck: number = Date.now();

  constructor(private translate: TranslateService,
              @Inject(SETTINGS) public settings: Settings,
              private router: Router,
              private store: Store,
              private actions$: Actions) {
    translate.setDefaultLang('de');
    translate.use('de');

    moment.locale('de');
  }

  ngOnInit(): void {
    this.store.dispatch(new SetLoadParameters(true, true));

    this.actions$.pipe(takeUntil(this._destroyed), ofActionCompleted(LogoutSuccess)).subscribe(async () => {
      const queryParams: Params = {};
      const url = this.router.routerState.snapshot.url;
      if (url && !url.includes('login')) {
        queryParams.r = url;
      }
      await this.router.navigate(['login'], {queryParams});
    });
  }

  @HostListener('window:focus')
  @HostListener('window:mousemove')
  private checkSession() {
    if (this.lastSessionCheck + 1000 * 60 * 10 < Date.now()) {
      this.lastSessionCheck = Date.now();
      this.store.dispatch(new Me());
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  public onLeave(e: BeforeUnloadEvent) {
    const isSlidesDirty: boolean = this.store.selectSnapshot(SlideState.isDirty);
    const isIndexDirty: boolean = this.store.selectSnapshot(IndexState.isDirty);
    if (isSlidesDirty || isIndexDirty) {
      e.returnValue = isSlidesDirty || isIndexDirty;
    }
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
