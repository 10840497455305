import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthState } from '@medsurf/state';
import { AddAlert, Logout } from '@medsurf/actions';
import { AlertType, Role, User } from '@medsurf/models';
import { BaseChainGuard } from '@medsurf/module';

@Injectable({
  providedIn: 'root'
})
export class ViewerGuard extends BaseChainGuard {
  /**
   * Check if user viewer
   *
   * @param next: ActivatedRouteSnapshot
   * @param state: RouterStateSnapshot
   */
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const user: User = this.store.selectSnapshot(AuthState.user);
    if (user?.role === Role.VIEWER) {
      this.store.dispatch(new Logout());
      this.store.dispatch(new AddAlert({
        message: 'no_access_rights_for_author',
        type: AlertType.ERROR,
        code: 500,
        dismissable: true,
        duration: 10
      }));
      return false;
    }
    return true;
  }
}
