import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AnnotationType, FreeForm, Node, Question, Shape, Slide } from '@medsurf/models';
import { AddQuestion, SlideChanged, IndexChanged, PathModel } from '@medsurf/actions';
import { IndexState, MenuState, NavigationState, SlideState } from '@medsurf/state';
import { NodeService } from '@medsurf/services';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MetadataDialogComponent } from '../metadata-dialog/metadata-dialog.component';
import Container from '../image/elements/container';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { takeUntil } from 'rxjs/operators';
import { QuestionComponent } from '../question/question.component';
import { cloneDeep, omit } from 'lodash';

@Component({
  selector: 'medsurf-wysiwyg',
  templateUrl: './wysiwyg.component.html',
  styleUrls: ['./wysiwyg.component.scss']
})
export class WysiwygComponent implements OnInit, OnDestroy {
  @Select(MenuState.showImage)
  public showImage$: Observable<boolean>;

  @Select(MenuState.showText)
  public showText$: Observable<boolean>;

  @Select(MenuState.showHeader)
  public showHeader$: Observable<boolean>;

  @Select(MenuState.showBody)
  public showBody$: Observable<boolean>;

  @Select(MenuState.showQuiz)
  public showQuiz$: Observable<boolean>;

  @Select(MenuState.showInteractiveArea)
  public showInteractiveArea$: Observable<boolean>;

  @Select(MenuState.showModality)
  public showModality$: Observable<boolean>;

  @Select(SlideState.imagePois)
  public imagePois$: typeof SlideState.imagePois;

  @Select(SlideState.virtualGroups)
  public virtualGroups$: typeof SlideState.virtualGroups;

  @Select(SlideState.annotationsMap)
  public annotationsMap$: typeof SlideState.annotationsMap;

  @ViewChildren('questionComponent') questions: QueryList<QuestionComponent>;
  @ViewChildren('questionContainer') questionContainers: QueryList<ElementRef<HTMLDivElement>>;

  @Input() slide: Slide;
  @Input() sequenceId: number;
  @Input() layerId: number;
  @Input() container: Container;

  public expandedQuestions = {};

  private _destroyed = new Subject<boolean>();

  constructor(private store: Store,
              private actions$: Actions,
              private modalService: NgbModal,
              private nodeService: NodeService) {
  }

  ngOnInit(): void {
    this.actions$.pipe(takeUntil(this._destroyed), ofActionCompleted(AddQuestion))
      .subscribe(() => {
        this.toggleQuestion(this.slide.questions[this.slide.questions.length - 1]);
        setTimeout(() => {
          this.questionContainers.last.nativeElement.scrollIntoView();
          this.questions.last.focus();
        });
      });
  }

  trackById(obj: { id: string}) {
    return obj.id || obj;
  }

  toggleQuestion(question: Question) {
    if (this.expandedQuestions[question.id]) {
      delete this.expandedQuestions[question.id];
    } else {
      this.expandedQuestions[question.id] = true;
    }
  }

  deleteQuestion(question: Question) {
    const index = this.slide.questions.findIndex(q => q.id === question.id);
    this.slide.questions[index].deleted = true;
    this.onChange();
  }

  onChange(indexChange = false) {
    this.store.dispatch(new SlideChanged());

    if (indexChange) {
      const nodes: Node[] = this.store.selectSnapshot(IndexState.index);
      const currentPath: PathModel = this.store.selectSnapshot(NavigationState.currentPath);

      if (currentPath.slide && currentPath.slide.route.startsWith('neuer-titel')) {
        const node: Node = this.nodeService.findNode(currentPath.slide.id, nodes);
        const clonedNode: Node = omit<Node>(cloneDeep<Node>(node), 'children');
        clonedNode.route = currentPath.slide.page.title;
        clonedNode.dirty = true;

        this.store.dispatch(new IndexChanged([clonedNode]));
      }
    }
  }

  public get interactiveAreas() {
    if (this.slide && this.slide.layers &&
      this.slide.layers[this.layerId] && this.slide.layers[this.layerId].images &&
      this.slide.layers[this.layerId].images[this.sequenceId]) {
      return this.slide.layers[this.layerId].images[this.sequenceId].annotations
        .filter(marker => marker.type === AnnotationType.FREE_FORM)
        .filter((marker: FreeForm) => marker.shape === Shape.INTERACTIVE_AREA);
    }
    return [];
  }

  openMetadataDialog() {
    const modalRef = this.modalService.open(MetadataDialogComponent, {size: 'xl'});
    modalRef.componentInstance.slide = this.slide;
  }

  orderChanged($event: CdkDragDrop<Question, any>) {
    moveItemInArray(this.slide.questions, $event.previousIndex, $event.currentIndex);
    // Set order property
    this.slide.questions.forEach((question, idx) => {
      if (idx !== question.order || idx === $event.currentIndex) {
        this.slide.questions[idx] = {
          ...question,
          order: idx
        }
      }
      question.order = idx;
    });
    this.onChange();
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
