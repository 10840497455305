import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { SlideNode, Node } from '@medsurf/models';
import { Search, SetSearchString, GetRootNodes, SearchSuccess } from '@medsurf/actions';
import { IndexState } from '@medsurf/state';
import { MediaService } from '@medsurf/services';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface SlideNodeWithSource extends SlideNode {
  source: string;
  completeRoute: string;
  breadcrumbs: string;
}

@Component({
  selector: 'medsurf-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  @Select(IndexState.indexMap)
  public indexMap$: Observable<Map<string, Node>>;

  public indexMap: Map<string, Node>;
  public results: SlideNodeWithSource[] = [];
  public isSearching = true;
  private _destroyed = new Subject<boolean>();

  constructor(
    private store: Store,
    public mediaService: MediaService,
    private router: Router,
    private route: ActivatedRoute,
    private actions$: Actions,
  ) {
  }

  ngOnInit(): void {

    const getSlideNodeWithSource = (source: string) => {
      return (results: SlideNode[]): SlideNodeWithSource[] => results
        .map((newResult: SlideNodeWithSource): SlideNodeWithSource => {
          newResult.source = source;
          newResult.breadcrumbs = this.getBreadcrumbsForNode(newResult, this.indexMap).text;
          newResult.completeRoute = this.getBreadcrumbsForNode(newResult, this.indexMap).route;
          return newResult;
        });
    };

    this.actions$
      .pipe(takeUntil(this._destroyed), ofActionSuccessful(SearchSuccess))
      .subscribe(({resultSlides, resultMarkers}: SearchSuccess) => {
        if (!this.indexMap) {
          return;
        }
        this.isSearching = false;
        this.results = [
          ...getSlideNodeWithSource('page')(resultSlides) ?? [],
          ...getSlideNodeWithSource('annotations')(resultMarkers) ?? []
        ].sort((a: SlideNodeWithSource, b: SlideNodeWithSource) => a?.page?.title?.localeCompare(b?.page?.title));
      });

    this.indexMap$
      .pipe(takeUntil(this._destroyed))
      .subscribe((indexMap: Map<string, Node>) => {
        this.isSearching = true;
        if (!indexMap?.size) {
          this.store.dispatch(new GetRootNodes());
          return;
        }
        this.indexMap = indexMap;
        this.route.queryParams
          .pipe(takeUntil(this._destroyed))
          .subscribe((params: Params) => {
            this.isSearching = true;
            this.results = [];
            const searchString = params.searchString;
            if (!searchString) {
              return;
            }
            this.store.dispatch(new SetSearchString(decodeURI(searchString)));
            this.store.dispatch(new Search());
          });
      });
  }

  public getBreadcrumbsForNode(node: Node, map: Map<string, Node>) {
    if (!map) {
      return;
    }
    const nodes = [];
    let n = node;
    while (n) {
      n = map.get(n.parentId);
      if (n) {
        nodes.unshift(n);
      }
    }
    const titles = nodes.map(nodeElement => nodeElement.page.title);
    const routes: string[] = nodes.map(nodeElement => nodeElement.route);
    routes.push(node.route);
    return {
      text: titles.join(' | '),
      route: routes.join('/')
    };
  }

  public selectSlide(route) {
    this.router.navigate([route]);
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
