<h3 class="mt-4 mb-2 text-secondary" translate="text_export"></h3>

<ng-container *ngIf="!startedContentLoad">
  <button class="btn btn-primary" role="button" (click)="load()">{{ 'load_content' | translate }}</button>
</ng-container>

<ng-container *ngIf="startedContentLoad">
  <div class="btn-toolbar mb-2 mt-2" role="toolbar">
    <div class="btn-group mr-2" role="group">
      <button *ngFor="let displayType of displayTypes"
              type="button"
              [class.btn-secondary]="displayType === selectedDisplayType"
              [class.btn-primary]="displayType !== selectedDisplayType"
              class="btn"
              (click)="onSelectDisplayType(displayType)">{{ 'text_' + displayType | translate }}</button>
    </div>
  </div>

  <div class="form-check mb-4">
    <input type="checkbox" class="form-check-input" id="replaceHTMLTags" [checked]="keepHTMLTags"
           (click)="onSelectKeepHTMLTags()">
    <label class="form-check-label" for="replaceHTMLTags">
      {{ 'keep_html_tags' | translate}}
    </label>
  </div>

  <ng-container *ngIf="!loading && textExports?.length > 0; else exportLoadingSpinner;">
    <button class="btn btn-primary"  role="button" type="button" (click)="download()">{{'download' | translate}}</button>
    <button class="btn btn-primary ml-2"  role="button" type="button" (click)="copyToClipboard()">{{'copy_to_clipboard' | translate}}</button>
    <div  class="cutoff-text mt-2">
      <textarea name="export" id="export" class="cutoff-textarea" [(ngModel)]="text" style="resize: none"></textarea>
    </div>
  </ng-container>
</ng-container>

<ng-template #exportLoadingSpinner>
  <div *ngIf="loading; else nothingToExport;" class="spinner-border inline-block" role="status">
    <span class="sr-only"></span>
  </div>
  <ng-template #nothingToExport>
    <p>{{'nothing_to_export' | translate}}</p>
  </ng-template>
</ng-template>





