<div class="d-flex flex-col h-100 scrollable">
  <medsurf-navigation></medsurf-navigation>
  <div class="panel panel-default scrollable d-flex flex-col">
    <div class="panel-heading">
      <button id="btn-append-to-to-body" [title]="('new_user' | translate)" type="button" class="btn btn-secondary" (click)="addNewUser()">
        <span class="fa fa-fw fa-plus"></span> <span class="ng-binding">{{ 'new_user' | translate }}</span>
      </button>
    </div>
    <div class="panel-body panel-body-full-margin">
      <div class="list">
        <div class="card">
          <table class="table table-hover table-vcenter">
            <thead>
            <tr>
              <th><span translate="user_lastname"></span></th>
              <th><span translate="user_firstname"></span></th>
              <th><span translate="user_email"></span></th>
              <th><span translate="user_role"></span></th>
              <th><span translate="user_shares"></span></th>
              <th><span translate="user_last_login"></span></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of users"
                (click)="gotoUserDetail(user)">
              <td>{{user.lastName}}</td>
              <td>{{user.firstName}}</td>
              <td>{{user.email}}</td>
              <td><span class="label label-default">{{user.role}}</span></td>
              <td><span *ngFor="let share of user.shares"><span class="label label-default">{{share.title}}</span>&nbsp;</span></td>
              <td>{{ user.lastLogin ? (user.lastLogin | amTimeAgo) : '-' }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
