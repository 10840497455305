import paper from 'paper';

class Circle {
  private _element: any;

  constructor(private _format, private _target, private _marker) {
    this._element = null;
    this._init();
  }

  _init() {
    this.draw();
  }

  draw() {
    const factor = this._marker && this._marker._model.style && this._marker._model.style.strokeWidth ?
      this._marker._model.style.strokeWidth / this._marker._imageScale :
      this._format.strokeWidth / this._marker._imageScale;
    this._element = new paper.Path.Circle(
      new paper.Point(this._target.x, this._target.y), factor * this._format.circleRadius
    );
    this._element.fillColor = this._format.strokeColor;
    // When alpha channel is set to 0, the circle element is not selectable!
    this._element.fillColor.alpha = 0.1;
    this._element.strokeColor =
      this._marker && this._marker._model && this._marker._model.style && this._marker._model.style.color ?
        this._marker._model.style.color :
        this._format.strokeColor;
    this._element.strokeWidth = factor * this._format.strokeWidth;
  }

  get element() {
    return this._element;
  }

  setScale() {
    const factor = (this._marker && this._marker._model.source && this._marker._model.source.zoom ?
        this._marker._container.getStrokeWidth(this._marker._model.source.zoom) || this._format.strokeWidth :
        this._format.strokeWidth)
        / this._marker._imageScale;
    this.element.strokeWidth = factor * this._format.strokeWidth;
  }


  cleanUp() {
    if (this._element) {
      this._element.remove();
      this._element = null;
    }
  }
}

export default Circle;
