import { version } from './version';
import { config } from './config';

export const environment = {
  production: true,
  config: {
    s3endpointUrl: config?.s3endpointUrl,
    s3Bucket: config?.s3Bucket,
    endpointUrl: config?.endpointUrl,
    websocketUrl: config?.websocketUrl,
    viewerUrl: config?.viewerUrl,
    version: version.version,
    timestamp: version.timestamp,
    revision: version.revision,
    branch: version.branch,
    sentry: {
      dsn: config?.sentryDsn,
      environment: config?.sentryEnvironment,
    }
  }
};