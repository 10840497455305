<form class="form m-2 mt-3">
  <div class='button-group'>
    <button class="btn btn-secondary" (click)="addGroup()" translate="add_group"></button>
  </div>
</form>

<hr>

<div *ngIf="slide.groups && (slide.groups).length !== 0">  
  <div class="groups-container" dragula="GROUPS" [(dragulaModel)]="slide.groups" (dragulaModelChange)="onSortGroups()">
    <ng-container *ngFor="let group of (displayedGroups$ | async)">
      <div
        *ngIf="group.deleted !== true"
        class="group-container tree-node-styled collapsable with-handle"
        [class.active]="group.isExpanded">

        <div class="collapsable-header" (click)="group.isExpanded = !group.isExpanded">
          <div class="handle"><i class="fa fa-grip-dots-vertical"></i></div>
          <button class="btn btn-primary btn-sm mr-2" [style.background-color]="group.color">
            <span class="fa" title="{{ 'theme_color' | translate }}"></span>
          </button>
          <strong>{{ group.name }}</strong>
          <button class="btn btn-sm btn-danger ml-auto" [title]="'delete_group' | translate" (click)="removeGroup(group, $event)">
            <i class="fa fa-fw fa-trash"></i>
          </button>
        </div>

        <div class="collapsable-body" *ngIf="group.isExpanded">

          <medsurf-group-header
            [group]="group"
            (changeEmitter)="onChange()">
          </medsurf-group-header>

          <hr>

          <medsurf-group-annotation
            [group]="group"
            [slide]="slide"
            [imageAnnotationSet]="imageAnnotationSet$ | async"
            (setHoverMarker)="setHoverMarker.emit($event)"
            (changeEmitter)="onChange()">
          </medsurf-group-annotation>

        </div>
      </div>
    </ng-container>
  </div>
</div>
