import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { AuthState } from '@medsurf/state';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  @Select(AuthState.token)
  public token$: Observable<string>;

  constructor(private store: Store) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // TODO remove: const authReq =
    //  !!token ? req.clone({ withCredentials: true, setHeaders: { 'x-access-token': token, Authorization: 'Bearer ' + token } }) : req;

    const token = this.store.selectSnapshot(AuthState.token);
    const user = this.store.selectSnapshot(AuthState.user);
    // eslint-disable-next-line no-extra-boolean-cast
    const authReq = !!user ? req.clone({ setHeaders: { 'x-access-user': user.email, 'x-access-token': token } }) : req;
    return next.handle(authReq);
  }
}
