import { Injectable } from '@angular/core'
import { v4 as uuid } from 'uuid';
import {
  getActionTypeFromInstance,
  getValue,
  InitState,
  NgxsNextPluginFn,
  NgxsPlugin,
  setValue,
  StateToken
} from '@ngxs/store';
import { HistoryControlActions } from '@medsurf/flat-actions';
import { actionsToHandle } from '@medsurf/flat-decorators';
import { ErrorControlModels, HistoryControlEntityStatesModels, HistoryControlModels } from '@medsurf/flat-models';
import { DefaultHistorySnapshot, HISTORY_CONTROL_TOKEN, HistoryControlStateModel } from '@medsurf/flat-states';

/**
 * State Date
 */
export interface StateDate {
  stateToken: StateToken<any>;
  type: HistoryControlModels.HistorySnapshotItemModifierType;
}

@Injectable()
export class NgxsHistoryPlugin implements NgxsPlugin {
  /**
   * Members
   */
  private readonly ACTIONS_TO_IGNORE = new Set([InitState.type]);

  /**
   * Handle
   *
   * @param state: any
   * @param action: any
   * @param next: NgxsNextPluginFn
   */
  handle(state: any,
         action: any,
         next: NgxsNextPluginFn) {
    const actionType = getActionTypeFromInstance(action);
    if (actionType) {
      const ignoreAction = this.ACTIONS_TO_IGNORE.has(actionType);
      if (!ignoreAction) {
        switch (actionType) {
          case HistoryControlActions.UndoSnapshot.type:
            state = this.handleUndoSnapshot(state);
            break;
          case HistoryControlActions.RedoSnapshot.type:
            state = this.handleRedoSnapshot(state);
            break;
          default:
            state = this.handleSnapshotItem(state, action, actionType);
            break;
        }
      }
    }
    return next(state, action)
  }

  //<editor-fold desc="Undo">

  /**
   * Handle Undo Snapshot
   *
   * @param state
   * @protected
   */
  protected handleUndoSnapshot(state: any): any {
    // Get History State
    const _historyState = state[HISTORY_CONTROL_TOKEN.getName()] as HistoryControlStateModel;
    if (_historyState.past.length < 1) {
      return;
    }

    // Undo snapshot in history state
    let future = _historyState.present;
    let present = _historyState.past[_historyState.past.length - 1];
    let removeIndex = 1;

    // Check if it's a locked snapshot; when not get snapshot from past
    if (!future.locked) {
      if (_historyState.past.length < 2) {
        return;
      }
      future = present;
      present = _historyState.past[_historyState.past.length - 2];
      removeIndex = 2;
    }

    // Update history
    state = setValue(state, 'historyControl.future', [..._historyState.future, future]);
    state = setValue(state, 'historyControl.present', present);
    state = setValue(state, 'historyControl.past', _historyState.past.slice(0, _historyState.past.length - removeIndex));

    // Undo snapshot in entities
    for (let i = future.snapshotItems.length - 1; i >= 0; i--) {
      const snapshotItem = future.snapshotItems[i];

      // Get entity state
      const entityState = this._getState(state, snapshotItem.stateToken);

      // Revert changes
      switch (snapshotItem.type) {
        case HistoryControlModels.HistorySnapshotItemModifierType.ADD:
          this._undoSnapshotAdd(state, entityState, snapshotItem);
          break;
        case HistoryControlModels.HistorySnapshotItemModifierType.UPDATE:
          this._undoSnapshotUpdate(state, entityState, snapshotItem);
          break;
        case HistoryControlModels.HistorySnapshotItemModifierType.REMOVE:
          // Uses the same logic as snapshot update
          this._undoSnapshotUpdate(state, entityState, snapshotItem);
          break;
        default:
          throw new ErrorControlModels.MedsurfError(
            "history_type_not_supported",
            ErrorControlModels.ErrorLevel.error,
            ErrorControlModels.ErrorInstance.AUTHOR,
            "PLUGINS.HistoryPlugin.handle"
          );
      }
    }

    return state;
  }

  /**
   * Undo Snapshot Add
   *
   * @param state: any
   * @param entityState: any
   * @param snapshotItem: HistoryControlModels.HistorySnapshotItem
   * @protected
   */
  protected _undoSnapshotAdd(state: any,
                             entityState: any,
                             snapshotItem: HistoryControlModels.HistorySnapshotItem): void {
    // Get entities and revert snapshot
    const entities = entityState.entities;
    delete entities[snapshotItem.entityId];

    // Save changes
    state = setValue(state, snapshotItem.stateToken.getName() + '.entities', entities);

    return state;
  }

  /**
   * Undo Snapshot Update
   *
   * @param state: any
   * @param entityState: any
   * @param snapshotItem: HistoryControlModels.HistorySnapshotItem
   * @protected
   */
  protected _undoSnapshotUpdate(state: any,
                                entityState: any,
                                snapshotItem: HistoryControlModels.HistorySnapshotItem): any {
    if (!snapshotItem.previousSnapshotId) {
      throw new ErrorControlModels.MedsurfError(
        "no_previous_snapshot_found",
        ErrorControlModels.ErrorLevel.error,
        ErrorControlModels.ErrorInstance.AUTHOR,
        "PLUGINS.HistoryPlugin.handle"
      );
    }

    // Get snapshot
    const snapshot = this._getSnapshot(entityState, snapshotItem.previousSnapshotId);

    // Get entities and revert snapshot
    const entities = entityState.entities;
    entities[snapshot.id] = snapshot;

    // Save changes
    state = setValue(state, snapshotItem.stateToken.getName() + '.entities', entities);

    return state;
  }

  //</editor-fold>

  //<editor-fold desc="Redo">

  /**
   * Handle Redo Snapshot
   * @param state
   * @protected
   */
  protected handleRedoSnapshot(state: any): any {
    // Get History State
    const _historyState = state[HISTORY_CONTROL_TOKEN.getName()] as HistoryControlStateModel;
    if (_historyState.future.length < 1) {
      return;
    }

    // Redo snapshot in history state
    const present = _historyState.future[_historyState.future.length - 1];

    // Update history
    state = setValue(state, 'historyControl.future', _historyState.future.slice(0, _historyState.future.length - 1));
    state = setValue(state, 'historyControl.present', _historyState.future[_historyState.future.length - 1]);
    state = setValue(state, 'historyControl.past', [..._historyState.past, _historyState.present]);

    // Undo snapshot in entities
    for (let i = 0; i <= present.snapshotItems.length - 1; i++) {
      const snapshotItem = present.snapshotItems[i];

      // Get entity state
      const entityState = this._getState(state, snapshotItem.stateToken );

      // Revert changes
      switch (snapshotItem.type) {
        case HistoryControlModels.HistorySnapshotItemModifierType.ADD:
          // Uses the same logic as snapshot update
          this._redoSnapshotUpdate(state, entityState, snapshotItem);
          break;
        case HistoryControlModels.HistorySnapshotItemModifierType.UPDATE:
          this._redoSnapshotUpdate(state, entityState, snapshotItem);
          break;
        case HistoryControlModels.HistorySnapshotItemModifierType.REMOVE:
          this._redoSnapshotRemove(state, entityState, snapshotItem);
          break;
        default:
          throw new ErrorControlModels.MedsurfError(
            "history_type_not_supported",
            ErrorControlModels.ErrorLevel.error,
            ErrorControlModels.ErrorInstance.AUTHOR,
            "PLUGINS.HistoryPlugin.handle"
          );
      }
    }

    return state;
  }

  /**
   * Redo Snapshot Update
   *
   * @param state: any
   * @param entityState: any
   * @param snapshotItem: HistoryControlModels.HistorySnapshotItem
   * @protected
   */
  protected _redoSnapshotUpdate(state: any,
                                entityState: any,
                                snapshotItem: HistoryControlModels.HistorySnapshotItem): any {
    if (!snapshotItem.followingSnapshotId) {
      throw new ErrorControlModels.MedsurfError(
        "no_following_snapshot_found",
        ErrorControlModels.ErrorLevel.error,
        ErrorControlModels.ErrorInstance.AUTHOR,
        "PLUGINS.HistoryPlugin.handle"
      );
    }

    // Get snapshot
    const snapshot = this._getSnapshot(entityState, snapshotItem.followingSnapshotId);

    // Get entities and revert snapshot
    const entities = entityState.entities;
    entities[snapshot.id] = snapshot;

    // Save changes
    state = setValue(state, snapshotItem.stateToken.getName() + '.entities', entities);

    return state;
  }

  /**
   * Redo Snapshot Remove
   *
   * @param state: any
   * @param entityState: any
   * @param snapshotItem: HistoryControlModels.HistorySnapshotItem
   * @protected
   */
  protected _redoSnapshotRemove(state: any,
                                entityState: any,
                                snapshotItem: HistoryControlModels.HistorySnapshotItem): void {
    // Get entities and revert snapshot
    const entities = entityState.entities;
    delete entities[snapshotItem.entityId];

    // Save changes
    state = setValue(state, snapshotItem.stateToken.getName() + '.entities', entities);

    return state;
  }
  //</editor-fold>

  //<editor-fold desc="Snapshot Item">
  /**
   * Handle Snapshot Item
   *
   * @param state: any
   * @param action: any
   * @param actionType: string
   * @protected
   */
  protected handleSnapshotItem(state: any,
                               action: any,
                               actionType: string): any {
    const stateData = this._getStateData(actionType)

    // Check if action is undoable
    if (stateData && stateData.stateToken) {
      // Get entity state
      const entityState = this._getState(state, stateData.stateToken);

      // Create previous snapshot
      let previousSnapshotId: string | null = null;
      if (stateData.type !== HistoryControlModels.HistorySnapshotItemModifierType.ADD) {
        // Get entity
        const entity = this._getEntity(entityState, action.entity.id);

        // Check for duplicate entries
        const entityJson = JSON.stringify(entity);
        const snapshots = entityState.snapshots;
        for (const key in snapshots) {
          // Return id when snapshot already exists
          if (snapshots[key].itemJson === entityJson) {
            previousSnapshotId = key;
            break;
          }
        }

        // Create new snapshot
        if (previousSnapshotId === null) {
          previousSnapshotId = uuid();
          snapshots[previousSnapshotId] = {
            itemJson: entityJson
          };
          state = setValue(state, stateData.stateToken.getName() + '.snapshots', snapshots);
        }
      }

      // Create following snapshot
      let followingSnapshotId: string | null = null;
      if (stateData.type !== HistoryControlModels.HistorySnapshotItemModifierType.REMOVE) {
        // Check for duplicate entries
        const entityJson = JSON.stringify(action.entity);
        const snapshots = entityState.snapshots;
        for (const key in snapshots) {
          // Return id when snapshot already exists
          if (snapshots[key].itemJson === entityJson) {
            followingSnapshotId = key;
            break;
          }
        }
        // Create new snapshot
        if (followingSnapshotId === null) {
          followingSnapshotId = uuid();
          snapshots[followingSnapshotId] = {
            itemJson: entityJson
          };
          state = setValue(state, stateData.stateToken.getName() + '.snapshots', snapshots);
        }
      }

      // Get snapshot item
      const snapshotItem = this._getSnapshotItem(stateData, action.entity.id, previousSnapshotId, followingSnapshotId);

      // Get history
      const _historyState = getValue(state, HISTORY_CONTROL_TOKEN.getName()) as HistoryControlStateModel;

      // Prevent updating of a locked snapshot, instead we push the snapshot to past and create a new default snapshot
      if (_historyState.present.locked) {
        state = setValue(state, 'historyControl.past', [..._historyState.past, _historyState.present]);
        state = setValue(state, 'historyControl.present', new DefaultHistorySnapshot());
      }

      // Add snapshot to history state
      state = setValue(state, 'historyControl.present.snapshotItems', [..._historyState.present.snapshotItems, snapshotItem]);

      // Clean up future snapshots in entities
      if (_historyState.future.length > 0) {
        // Get history snapshots
        const historySnapshots: HistoryControlModels.HistorySnapshot[] = [
          ...getValue(state, 'historyControl.past'),
          getValue(state, 'historyControl.present'),
          ...getValue(state, 'historyControl.future')
        ];

        // Get all snapshot ids
        const historySnapshotIds: string[] = [];
        historySnapshots
          .map((s) => s.snapshotItems.filter(si => si.stateToken === stateData.stateToken))
          .flat()
          .forEach((si) => {
            if (si.previousSnapshotId) {
              historySnapshotIds.push(si.previousSnapshotId);
            }
            if (si.followingSnapshotId) {
              historySnapshotIds.push(si.followingSnapshotId);
            }
          });

        // Get duplicate history snapshot ids
        const duplicateHistorySnapshotIds = historySnapshotIds.filter((c, index) => {
          return historySnapshotIds.indexOf(c) !== index;
        });

        // Remove duplicate history snapshot ids; we can keep them for sure
        const removeableHistorySnapshotIds = historySnapshotIds.filter((si) => !duplicateHistorySnapshotIds.includes(si));

        // Remove all non-linked snapshots from entity
        _historyState.future
          .map((s: HistoryControlModels.HistorySnapshot) => s.snapshotItems)
          .flat()
          .forEach((si: HistoryControlModels.HistorySnapshotItem) => {
            if (si.previousSnapshotId && removeableHistorySnapshotIds.includes(si.previousSnapshotId)) {
              // Remove snapshot
              const snapshots = entityState.snapshots;
              delete snapshots[si.previousSnapshotId];

              // Save changes
              state = setValue(state, snapshotItem.stateToken.getName() + '.snapshots', snapshots);
            }
            if (si.followingSnapshotId && removeableHistorySnapshotIds.includes(si.followingSnapshotId)) {
              // Remove Snapshot
              const snapshots = entityState.snapshots;
              delete snapshots[si.followingSnapshotId];

              // Save changes
              state = setValue(state, snapshotItem.stateToken.getName() + '.snapshots', snapshots);
            }
          });

        // Clear future snapshots
        state = setValue(state, 'historyControl.future', []);
      }
    }

    return state;
  }

  /**
   * Get State Data
   *
   * @param actionType: string
   * @protected
   */
  protected _getStateData(actionType: string): StateDate | null {
    const currentActionMetadata = Object.values(actionsToHandle).find((it) => it.actions.includes(actionType));

    if (!currentActionMetadata) {
      return null;
    }

    const index = currentActionMetadata.actions.indexOf(actionType);
    const type = currentActionMetadata.types[index];
    const stateToken = currentActionMetadata.stateTokens[index];

    return { stateToken, type};
  }

  /**
   * Get State
   *
   * @param state: any
   * @param stateToken: StateToken<any>
   * @protected
   */
  protected _getState(state: any,
                      stateToken: StateToken<any>): HistoryControlEntityStatesModels.HistoryControlEntityStateModel<any> {
    const _state = state[stateToken.getName()] as HistoryControlEntityStatesModels.HistoryControlEntityStateModel<any>;

    if (!_state) {
      throw new ErrorControlModels.MedsurfError(
        "not_a_history_entity",
        ErrorControlModels.ErrorLevel.error,
        ErrorControlModels.ErrorInstance.AUTHOR,
        "PLUGINS.HistoryPlugin.handle"
      );
    }
    if (!_state.entities) {
      throw new ErrorControlModels.MedsurfError(
        "no_entities_in_entity",
        ErrorControlModels.ErrorLevel.error,
        ErrorControlModels.ErrorInstance.AUTHOR,
        "PLUGINS.HistoryPlugin.handle"
      );
    }

    return _state;
  }

  /**
   * Get Snapshot Item
   *
   * @param stateData: StateData
   * @param entityId: string
   * @param previousSnapshotId: string | null
   * @param followingSnapshotId: string | null
   * @protected
   */
  protected _getSnapshotItem(stateData: StateDate,
                             entityId: string,
                             previousSnapshotId: string | null,
                             followingSnapshotId: string | null): HistoryControlModels.HistorySnapshotItem {
    // Check if all snapshot id are provided
    switch (stateData.type) {
      case HistoryControlModels.HistorySnapshotItemModifierType.ADD:
        if (followingSnapshotId === null) {
          throw new ErrorControlModels.MedsurfError(
            "no_following_snapshot_found",
            ErrorControlModels.ErrorLevel.critical,
            ErrorControlModels.ErrorInstance.AUTHOR,
            "PLUGINS.HistoryPlugin.handle"
          );
        }
        break;
      case HistoryControlModels.HistorySnapshotItemModifierType.UPDATE:
        if (previousSnapshotId === null) {
          throw new ErrorControlModels.MedsurfError(
            "no_previous_snapshot_found",
            ErrorControlModels.ErrorLevel.critical,
            ErrorControlModels.ErrorInstance.AUTHOR,
            "PLUGINS.HistoryPlugin.handle"
          );
        }
        if (followingSnapshotId === null) {
          throw new ErrorControlModels.MedsurfError(
            "no_following_snapshot_found",
            ErrorControlModels.ErrorLevel.critical,
            ErrorControlModels.ErrorInstance.AUTHOR,
            "PLUGINS.HistoryPlugin.handle"
          );
        }
        break;
      case HistoryControlModels.HistorySnapshotItemModifierType.REMOVE:
        if (previousSnapshotId === null) {
          throw new ErrorControlModels.MedsurfError(
            "no_previous_snapshot_found",
            ErrorControlModels.ErrorLevel.critical,
            ErrorControlModels.ErrorInstance.AUTHOR,
            "PLUGINS.HistoryPlugin.handle"
          );
        }
        break;
      default:
        throw new ErrorControlModels.MedsurfError(
          "undefined_history_modifier",
          ErrorControlModels.ErrorLevel.critical,
          ErrorControlModels.ErrorInstance.AUTHOR,
          "PLUGINS.HistoryPlugin.handle"
        );
    }

    // Return new history snapshot item
    return new HistoryControlModels.HistorySnapshotItem(stateData.stateToken, stateData.type, entityId, previousSnapshotId, followingSnapshotId, null)
  }

  /**
   * Get Entity
   *
   * @param state: any
   * @param entityId: string
   * @protected
   */
  protected _getEntity(state: any,
                       entityId: string): any {
    const entity = state.entities[entityId];

    if (!entity) {
      throw new ErrorControlModels.MedsurfError(
        "no_entity_found",
        ErrorControlModels.ErrorLevel.error,
        ErrorControlModels.ErrorInstance.AUTHOR,
        "PLUGINS.HistoryPlugin.handle"
      );
    }

    return entity;
  }

  /**
   * Get Snapshot
   *
   * @param state: any
   * @param snapshotId: string
   * @protected
   */
  protected _getSnapshot(state: any,
                         snapshotId: string): any {
    const snapshot = state.snapshots[snapshotId];

    if (!snapshot) {
      throw new ErrorControlModels.MedsurfError(
        "no_snapshot_found",
        ErrorControlModels.ErrorLevel.error,
        ErrorControlModels.ErrorInstance.AUTHOR,
        "PLUGINS.HistoryPlugin.handle"
      );
    }

    return JSON.parse(snapshot.itemJson);
  }
  //</editor-fold>
}