import { MediaService } from '@medsurf/services';
import { MediaType, Media } from '@medsurf/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Editor } from 'tinymce';
import { ChangeEventCallback, OnAction, OnSetup, ToolbarToggleButtonInstanceApi } from '../plugins';
import { openDialog } from '../media-base';
import { NgZone } from '@angular/core';
import { environment } from '../../../../../environments/environment';

const nodeChangeEventCallback: ChangeEventCallback = (buttonApi: ToolbarToggleButtonInstanceApi, editor: Editor) => async (eventApi) => {
  const element = (<HTMLElement>eventApi.element);
  const isVideo = element?.tagName?.toLowerCase() === 'video' || element?.dataset?.mceObject === 'video';
  
  buttonApi.setEnabled(isVideo || editor.selection.getContent() === '');
  buttonApi.setActive(isVideo);
};

const getOnSetup: OnSetup = (editor: Editor) => (buttonApi: ToolbarToggleButtonInstanceApi) => {
  editor.on('NodeChange', nodeChangeEventCallback(buttonApi, editor));
  return () => {
    editor.off('NodeChange', nodeChangeEventCallback(buttonApi, editor));
  };
};

const getOnAction: OnAction = (editor: Editor, zone: NgZone, modalService: NgbModal, mediaService: MediaService) =>
  async (buttonApi: ToolbarToggleButtonInstanceApi) => {
    zone.run(async () => {
      const allowedTypes: MediaType[] = [MediaType.VIDEO];

      const node = <HTMLElement>editor.selection.getNode();
      const isVideo = node?.tagName?.toLowerCase() === 'video';

      const selection: Media[] = isVideo ? [{
        id: node.dataset.id,
        type: MediaType.VIDEO
      }] : [];

      try {
        const medium: Media = await openDialog(allowedTypes, modalService, selection);
        const url = environment.config.endpointUrl + 'v1/id2url/' + medium.id;

        if (isVideo) {
          editor.dom.setAttribs(node, {
            src: url,
            'data-id': medium.id,
            'data-mce-src': url
          });
          editor.fire('change');
        } else {
          const node: HTMLVideoElement = document.createElement('video');
          node.setAttribute('src', url);
          node.setAttribute('data-id', medium.id);
          node.setAttribute('controls', '');
          node.setAttribute('controlsList', 'nodownload');
          editor.insertContent(node.outerHTML);
        }
      } catch (err) {
        return;
      }
    });
  };

export { getOnAction, getOnSetup };
