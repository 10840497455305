import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { IndexChanged } from '@medsurf/actions';
import { SubjectNode } from '@medsurf/models';
import { Store } from '@ngxs/store';

@Component({
  selector: 'medsurf-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.scss']
})
export class TypeComponent implements OnChanges {

  @Input()
  public selectedBlock: SubjectNode;

  @Input()
  public isBlockSelected: boolean;

  public pageTypes: string[];

  constructor(private store: Store) {
  }

  public onChange(event) {
    this.selectedBlock.page.type = event;
    this.store.dispatch(new IndexChanged([this.selectedBlock]));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.pageTypes = !this.isBlockSelected ? ['Folder', 'Vocabulary', 'QuizStart'] : ['Grid', 'Case'];
  }
}
