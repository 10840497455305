import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { BlockNode, SlideNode } from '@medsurf/models';
import { NodeService, SETTINGS, Settings, MediaService } from '@medsurf/services';
import { BookmarkService } from '../../../common/services/bookmark.service';
import { SlideService } from '../../../common/services/slide.service';

export interface SlideEvent {
  slide: SlideNode;
}

@Component({
  selector: 'medsurf-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent {
  @Output() selectSlide = new EventEmitter<SlideEvent>();
  @Output() selectBulk = new EventEmitter<SlideEvent>();

  @Input() items: any[];
  @Input() selectedBlock: BlockNode;
  @Input() bulkSelectedSlideIds: string[];

  @ViewChild('grid')
  private grid: ElementRef;

  constructor(
    @Inject(SETTINGS) private settings: Settings,
    public nodeService: NodeService,
    public mediaService: MediaService,
    public slideService: SlideService,
    public bookmarkService: BookmarkService
  ) {}

  nodeMoved() {
    this.nodeService.childrenMoved(this.selectedBlock);
  }

  public scrollToId(id: string): void {
    const htmlElement: Element = (this.grid.nativeElement as HTMLDivElement).children.namedItem(id);
    htmlElement?.scrollIntoView();
  }

  isBulkSelected(slide: any): boolean {
    if (slide?.page?.slideId) {
      const slideId = slide.page.slideId.toString();
      return this.bulkSelectedSlideIds && !!this.bulkSelectedSlideIds.includes(slideId);
    } else {
      return false;
    }
  }
}
