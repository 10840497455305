<div ngbDropdown class="mr-3" autoClose="outside">
    <button class="dropdown-button btn btn-outline-primary btn-white" id="dropdownBasic1" ngbDropdownToggle>
        {{ label | translate }}: 
        <ng-container *ngIf="selected.length === 0">
            <span>{{ 'everywhere' | translate }}</span>
        </ng-container>
        <ng-container *ngIf="selected.length !== 0">
            <span *ngFor="let item of selected; let isLast=last">
                {{item.value}}{{isLast ? '' : ', '}}
            </span>
        </ng-container>
    </button>
    <div ngbDropdownMenu class="dropdown-menu overflow-auto" aria-labelledby="dropdownBasic1">
        <ng-template #TreeView let-items>
        <div *ngFor="let item of items">
            <div class="d-flex">
                <button class="expander" (click)="expand(item)" *ngIf="item.children?.length > 0">
                    <span *ngIf="!item.expanded" class class="fa fa-caret-right"></span>
                    <span *ngIf="item.expanded" class class="fa fa-caret-down"></span>
                </button>
                <div class="expander" *ngIf="item.children?.length === 0"></div>
                <button ngbDropdownItem (click)="select(item)" [class.active]="isSelected(item)">
                    {{ item.value }}
                </button>
            </div>
            <div class="ml-3" *ngIf="item.expanded && item.children?.length > 0">
                <ng-container *ngTemplateOutlet="TreeView; context:{ $implicit: item.children }"></ng-container>
            </div>
        </div>
        </ng-template>
        <ng-container *ngTemplateOutlet="TreeView; context:{ $implicit: options }"></ng-container>
    </div>
</div>