import { ConfigSettings } from './config';

type ToolbarGroup = string[];

const toolbar: ToolbarGroup[] = [
  ['blocks'],
  [
    'bold',
    'italic',
    'underline',
    'forecolor',
  ],
  [
    'subscript',
    'superscript',
    'strikethrough'
  ],
  [
    'extlink',
    'intlink ',
    'marker',
    'unlink'
  ],
  [
    'mediaimage',
    'mediapdf',
    'mediaaudio',
    'mediavideo'
  ],
  [
    'bullist',
    'numlist',
    'outdent',
    'indent'
  ],
  [
    'table',
    'tabledelete'
  ],
  ['charmap'],
  ['removeformat'],
  [
    'code',
    'fullscreen',
    'example',
    'help'
  ]
];


export const getToolbarConfig = (settings: ConfigSettings) => {
  return {
    toolbar: settings.toolbar ? settings.toolbar : toolbar.map((toolbarGroup: ToolbarGroup) =>
      toolbarGroup
        .filter((toolbarElement: string) => settings.toolbarExcludes ? !settings.toolbarExcludes.includes(toolbarElement) : true)
        .join(' ')).join(' | ')
  };
};
