<div class="modal-body" tabindex="0">

  <div class="row form-group">
    <label class="col-sm-2 col-form-label" translate="link"></label>
    <div class="col-sm-10">
      <medsurf-link-typeahead
        *ngIf="isInternalLink"
        class="col-sm-9 p-0" 
        (isEditing)="isAddButtonDisabled=$event" 
        (internalLink)="changeNode($event)" 
        [placeholder]="'search_query' | translate" 
        [linkText]="linkText" 
        [linkLabel]="linkLabel"
      ></medsurf-link-typeahead>
      <input 
        *ngIf="isInternalLink === false"
        class="form-control"
        type="url"
        placeholder="https://..."
        [(ngModel)]="linkText"
        [ngModelOptions]="{standalone: true}">

      <div class="form-check mt-2">
        <input type="checkbox" id="link-target-blank" class="form-check-input" [(ngModel)]="isTargetBlank" (ngModelChange)="changeTarget()" [ngModelOptions]="{standalone: true}">
        <label class="form-check-label" for="link-target-blank" translate="target_chk"></label>
      </div>
    </div>
    
  </div>
</div>
<div class="modal-footer">
  <button (click)="modal.dismiss()" class="btn btn-secondary" translate="close"></button>
  <button (click)="close()" [disabled]="isAddButtonDisabled" class="btn btn-primary mr-1" translate="add"></button>
</div>
