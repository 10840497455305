import { ConfigSettings } from './config';

const background = {
  title: 'Background color', menu: [
    {title: 'Primary', value: 'table-primary'},
    {title: 'Secondary', value: 'table-secondary'},
    {title: 'Success', value: 'table-success'},
    {title: 'Danger', value: 'table-danger'},
    {title: 'Warning', value: 'table-warning'},
    {title: 'Info', value: 'table-info'},
    {title: 'Light', value: 'table-light'},
    {title: 'Dark', value: 'table-dark'},
  ]
};

const border = {
  title: 'Border', menu: [
    {title: 'Border-Right-4px-Black', value: 'border-right-black-4'}
  ]
};

export const getTableConfig = (settings: ConfigSettings) => {
  return {
    table_default_attributes: {class: 'table'},
    table_sizing_mode: 'relative',
    table_class_list: [
      {title: 'None', value: 'table '},
      {title: 'Zebra striped', value: 'table table-striped'},
      {title: 'Bordered', value: 'table table-bordered'},
      {title: 'Hover', value: 'table table-hover'},
      {title: 'Small', value: 'table table-sm'}
    ],
    table_row_class_list: [
      {title: 'None', value: ''},
      background,
      border
    ],
    table_cell_class_list: [
      {title: 'None', value: ''},
      background,
      border
    ]
  };
};
