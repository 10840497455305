import { Component, DoCheck, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MicroscopeComponent } from '../common/microscope/microscope.component';
import { AnnotationType, Keymap, Slide, SlideNode, User, BlockNode, AlertType, Annotation } from '@medsurf/models';
import { AuthState, IndexState, NavigationState, SlideState, SharedHistoryState, UserState } from '@medsurf/state';
import { SETTINGS, Settings, AnnotationService, MediaService, NodeService } from '@medsurf/services';
import { AddMarker, DeleteMarker, DeleteSlide, DeleteSlideSuccess, GetCopyrights, GetModalities, GetNextSlideId,
  GetNodeForUrl, GetNodeForUrlFail, GetNodeForUrlSuccess, GetStains, GetUsers, PasteMarker, CopyMarker, AddAlert,
  AddWatermarkSuccess, UpdateSlidesSuccess, SaveIndex, NewMarkerAdded, SlideChanged, PathModel, SaveIndexSuccess,
  SetLayerNumber, SetSequenceNumber, SharedHistory, GetNodesBySlideId, GetNodesBySlideIdSuccess, GetSlide, UpdateSlides } from '@medsurf/actions';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { Actions, ofActionCompleted, ofActionDispatched, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { filter, take, takeUntil } from 'rxjs/operators';
import Command from '../common/command/command';
import { SiblingsSidebarComponent } from '../common/siblings-sidebar/siblings-sidebar.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'medsurf-slide-detail',
  templateUrl: './slide-detail.component.html',
  styleUrls: ['./slide-detail.component.scss']
})
export class SlideDetailComponent implements OnInit, DoCheck, OnDestroy {
  @Select(AuthState.user)
  public user$: Observable<User>;

  @Select(SlideState.slide)
  public slide$: Observable<Slide>;

  @Select(SlideState.layerNumber)
  public layerNumber$: Observable<number>;

  @Select(SlideState.sequenceNumber)
  public sequenceNumber$: Observable<number>;

  @Select(SharedHistoryState.canUndo('slide'))
  public canUndo$: Observable<boolean>;

  @Select(SharedHistoryState.canRedo('slide'))
  public canRedo$: Observable<boolean>;

  @Select(UserState.users)
  public users$: Observable<User[]>;

  @Select(NavigationState.currentPath)
  public path$: Observable<PathModel>;

  @Select(IndexState.isDirty)
  public isIndexDirty$: Observable<boolean>;

  @Select(SlideState.isDirty)
  public isSlidesDirty$: Observable<boolean>;

  @ViewChild('microscope')
  public microscope: MicroscopeComponent;

  @ViewChild('siblingsSidebarComponent')
  public siblingsSidebarComponent: SiblingsSidebarComponent;

  @ViewChild('ConfirmSlideRemoveDialog')
  public ConfirmSlideRemoveDialog: TemplateRef<any>;

  private _oldState: string;
  private _preview: boolean;
  private _views: { layout: boolean; grid: boolean };
  private _command: Command;
  private _destroyed = new Subject<boolean>();
  public siblings: SlideNode[];
  public parent: BlockNode;
  public state: string;
  public marker: any;
  public hoverMarker: any;
  public selftestMarker: any;
  public zoom: any;
  public grid: boolean;
  public layout: boolean;
  public message: string;
  public props: { imageIsSelected: boolean; lockslideid: boolean; lockimage: boolean };
  public timeoutId: any;
  public selectedPoi: any;
  public zoompreset: any;
  public relatedNodes: SlideNode[];
  public isSaving = false;

  constructor(
    @Inject(SETTINGS) private settings: Settings,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private translate: TranslateService,
    private annotationService: AnnotationService,
    private nodeService: NodeService,
    private modalService: NgbModal,
    public mediaService: MediaService,
    private store: Store,
    private actions$: Actions
  ) {
    this.state = 'init';
    this.marker = null;
    this.hoverMarker = null;
    this.selftestMarker = null;
    this.zoom = null;
    this.grid = true;
    this.layout = true;
    this.message = '';
    this.props = {
      lockimage: true,
      lockslideid: true,
      imageIsSelected: false
    };
    this._preview = false;
    this.timeoutId = null;
    this.selectedPoi = null;
    this.store.dispatch(new GetUsers());

    if (this.store.selectSnapshot(AuthState.isAdmin)) {
      this._setAdminPermissions();
    }

    this.store.dispatch(new GetModalities());
    this.store.dispatch(new GetStains());
    this.store.dispatch(new GetCopyrights());
    this.store.dispatch(new SetLayerNumber(0));
    this.store.dispatch(new SetSequenceNumber(0));
  }

  public ngOnInit() {
    this.slide$.pipe(takeUntil(this._destroyed)).subscribe((slide: Slide) => {
      if (slide && !slide?.id && !slide?.slideId) {
        location.reload();
      }
      if (this.marker) {
        this.marker = slide.annotations.find(annotation => annotation.id === this.marker.id)
      }
    });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionDispatched(SlideChanged))
      .subscribe(() => {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!this.microscope) {
          setTimeout(() => {
            this.microscope.onChangeImage();
          })
        }
      });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionDispatched(AddWatermarkSuccess))
      .subscribe(() => {
        this.microscope.instantiateVirtualMicroscope();
      });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionSuccessful(SharedHistory.Undo, SharedHistory.Redo))
      .subscribe(() => {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!this.microscope) {
          this.microscope.onChangeImage();
        }
      });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionSuccessful(GetNodeForUrlSuccess)).subscribe((action: GetNodeForUrlSuccess) => {
      if (action.path.slide) {
        const slide = action.path.slide.page;
        if (!slide.slideId) {
          this.store.dispatch(new GetNextSlideId());
        }
        if (!this.siblings) {
          this.siblings = action.path.block.children;
        }
        if (!this.parent) {
          this.parent = action.path.block;
        }
        this._initCommand(slide);
      }
    });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionDispatched(GetNodeForUrlFail)).subscribe(() => {
      this.route.paramMap.pipe(take(1)).subscribe((map: ParamMap) => {
        const {params} = (map as any);
        this.store.dispatch(new AddAlert({
          message: 'error_slide_not_found',
          params: params,
          type: AlertType.WARNING
        }));
      });
    });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionDispatched(SaveIndexSuccess, UpdateSlidesSuccess))
      .subscribe(() => {
        this.translate.get(['updated']).subscribe((translations) => {
          this.message = translations.updated;
        });
      });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionSuccessful(SaveIndexSuccess))
      .subscribe(() => {
        const currentPath: PathModel = this.store.selectSnapshot(NavigationState.currentPath);
        if (currentPath.slide) {
          this.router.navigate(['../', currentPath.slide.route], {relativeTo: this.route, replaceUrl: true});
        }
      });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionDispatched(GetNodesBySlideIdSuccess))
      .subscribe((action: GetNodesBySlideIdSuccess) => {
        if (action.slide) {
          const nodes: SlideNode[] = action.nodes;
          this.relatedNodes = nodes;
        }
      });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionSuccessful(NewMarkerAdded))
      .subscribe((action: NewMarkerAdded) => {
        const marker = action.marker;
        (marker as any).isExpanded = true;
        this.selectMarker(action.marker);
        this.updateSlide();
        if (marker.type === AnnotationType.FREE_FORM && !((marker as any).path.length > 0)) {
          setTimeout(() => {
            this.activateFreeTool(marker);
          }, 500);
        }
      });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionDispatched(DeleteSlideSuccess))
      .subscribe((action: DeleteSlideSuccess) => {
        const path: PathModel = this.store.selectSnapshot(state => state.navigation.currentPath);
        path.slide.deleted = true;
        this.location.back();
      });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionCompleted(AddMarker, DeleteMarker))
      .subscribe(() => this.updateSlide());

    this.route.paramMap.subscribe((params: ParamMap) => {
      if ((params as any).params.slideId) {
        this.store.dispatch(new GetSlide({id: (params as any).params.slideId}));
      } else {
        this.store.dispatch(new GetNodeForUrl());
      }
    });

    this._oldState = this.state;
  }

  get preview() {
    return this._preview;
  }

  get command() {
    return this._command;
  }

  private _setAdminPermissions() {
    this.props.lockslideid = false;
  }

  public ngDoCheck(): void {
    if (this.state !== this._oldState) {
      this._oldState = this.state;
      if (this.state === 'error') {
        this.translate.get(['error_loading_image']).subscribe((translations) => {
          this.store.dispatch(new AddAlert({message: translations.error_loading_image}));
        });
      }
    }
  }

  private _initCommand(slide: Slide) {
    this._command = new Command(slide);
  }

  public addMarker(endType, text?) {
    const marker = this.annotationService.createMarker(endType, text);
    marker.dirty = true;
    this.store.dispatch(new AddMarker(marker));
  }

  public addKeymap() {
    const keymap: Keymap = this.annotationService.createKeymap();
    keymap.columns.map(column => column.labels.map(row => {
      this.store.dispatch(new AddMarker(row.annotation));
    }));
    keymap.dirty = true;
    this.store.dispatch(new AddMarker(keymap));
  }

  public addFreeForm(shape) {
    const freeForm = this.annotationService.createFreeForm(shape);
    freeForm.dirty = true;
    this.store.dispatch(new AddMarker(freeForm));
  }

  public addText() {
    const marker = this.annotationService.createAnnotation();
    marker.dirty = true;
    this.store.dispatch(new AddMarker(marker));
  }

  public activateFreeTool(mObj) {
    this.microscope.setFreeTool(mObj);
  }

  public selectMarker(marker) {
    this.marker = marker;
  }

  public deleteMarker(marker) {
    marker.dirty = true;
    this.store.dispatch(new DeleteMarker(marker));
    this.marker = undefined;
  }

  public async deleteSlide() {
    this.relatedNodes = [];
    const slide: Slide = this.store.selectSnapshot(SlideState.slide);
    this.store.dispatch(new GetNodesBySlideId(slide.slideId));

    await this.modalService.open(this.ConfirmSlideRemoveDialog, {size: 'xs'}).result;
    this.store.dispatch(new DeleteSlide());
  }

  public changeMedia() {
    this.microscope.instantiateVirtualMicroscope();
  }

  public toogleGrid() {
    this.grid = (!this.grid);
    this.saveViews();
  }

  public toogleLayout() {
    this.layout = (!this.layout);
    this.saveViews();
  }

  public togglePreview() {
    if (this._preview) {
      this._preview = false;
      this.grid = this._views.grid;
      this.layout = this._views.layout;
    } else {
      this._preview = true;
      this.saveViews();
      this.grid = false;
      this.layout = false;
    }
  }

  private openPreview = () => {
    const path: string = this.store.selectSnapshot((state) => state.navigation.currentURL);
    const url = `${this.settings.viewerUrl}${path}`;
    this.isSaving = false;
    open(url, '_medsurf_preview');
  }

  public async gotoSlideDetail() {
    const idxDirty = this.store.selectSnapshot(IndexState.isDirty);
    const slideDirty = this.store.selectSnapshot(SlideState.isDirty);

    if (idxDirty || slideDirty) {
      this.isSaving = true;

      if (slideDirty) {
        this.store.dispatch(new UpdateSlides());
        await firstValueFrom(this.isSlidesDirty$.pipe(filter(v => !v)));
      }
      if (idxDirty) {
        this.store.dispatch(new SaveIndex());
        await firstValueFrom(this.isIndexDirty$.pipe(filter(v => !v)));
      }
    }
    this.openPreview();
  }

  public saveViews() {
    this._views = {
      grid: this.grid,
      layout: this.layout
    };
  }

  handleUndo() {
    this.store.dispatch(new SharedHistory.Undo('slide'));
  }

  handleRedo() {
    this.store.dispatch(new SharedHistory.Redo('slide'));
  }

  public goToPoi(poi) {
    this.selectedPoi = null;
    setTimeout(() => {
      this.selectedPoi = poi;
    })
  }

  public handleSlideProperties() {
    this.store.dispatch(new SlideChanged());
  }

  public setZoom(value) {
    this.zoompreset = value;
  }

  private updateSlide() {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!this.microscope) {
      this.microscope.onChangeImage();
    }
    this.handleSlideProperties();
  }

  public onNavigationChange() {
    this.handleSlideProperties();
  }

  navigateToSlide(slide: SlideNode) {
    this.router.navigate(['..', slide.route], {relativeTo: this.route, replaceUrl: true});
  }

  public copyMarker(event: Annotation) {
    this.store.dispatch(new CopyMarker(event));
  }

  public pasteMarker() {
    this.store.dispatch(new PasteMarker());
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  newSlide() {
    this.translate.get(['new_title']).subscribe((translations) => {
      const newItem = this.nodeService.newNode(3, this.parent, translations.new_title);
      setTimeout(() => this.siblingsSidebarComponent.scrollToId(newItem.id), 500);
      this.store.dispatch(new SaveIndex());
    });
  }
}
