import { Injectable } from '@angular/core';
import { Annotation, FreeForm } from '@medsurf/models';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PanelService {

  constructor(private translate: TranslateService) {}

  public getAnnotationInfo(annotation: Annotation): { type: string, title: string } {
    const info = { 
      type: this.translate.instant(annotation.type?.toLocaleLowerCase()), 
      title: ''
    }
    if (annotation.label?.text?.trim()) {
      info.title = annotation.label.text.trim();
    } else {
      info.title = `${annotation.id.slice(0,4)}-${annotation.id.slice(-4)}`;
    }
    if ((annotation as FreeForm).shape) {
      info.type += ' ' + this.translate.instant((annotation as FreeForm).shape);
    }
    return info;
  }
}
