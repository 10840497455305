<nav class="navbar">
  <ul class="nav nav-pills">
    <li class="nav-item">
      <span class="title">{{ 'medsurf' | translate | uppercase}}</span><small><a
      routerLink="/changelog">Version: {{ settings.version}} ({{ settings.timestamp }}),
      Revision: {{ settings.revision }}</a></small>
    </li>
  </ul>
  <ul class="nav nav-pills">
    <li class="nav-item" *ngIf="!((isIndexDirty$ | async) || (isSlidesDirty$ | async))">
      <span class="message">{{ message }}</span>
    </li>
    <li class="save-button" *ngIf="(isIndexDirty$ | async) || (isSlidesDirty$ | async)">
      <button class="btn btn-danger" [disabled]="isSaving" (click)="onSave()">
        {{'unsaved_changes' | translate }}
        <span class="spinner-border spinner-border-sm" *ngIf="isSaving"></span>
      </button>
    </li>
  </ul>
  <ul class="nav nav-pills">
    <!--    <li class="nav-item">-->
    <!--      <span class="nav-link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [class.active]="(route.data | async)['index']">{{ 'index' | translate }}</span>-->
    <!--    </li>-->
    <li class="nav-item">
      <form (ngSubmit)="search()" action="" class="search-container form-control">
        <input [(ngModel)]="searchString"
               autocomplete="off"
               class="search form-control"
               id="search"
               name="search"
               title="search"
               type="search"
               placeholder="{{ 'search_view' | translate }}">
        <i class="fa fa-search search-icon"></i>

      </form>
    </li>
    <li class="nav-item" ngbDropdown medsurfRestrict access="admin,author">
      <span id="admin-dropdown" class="nav-link" ngbDropdownToggle>{{ 'admin' | translate }} <b
        class="caret"></b></span>
      <div ngbDropdownMenu aria-labelledby="simple-dropdown">
        <a routerLink="/users" translate="user_administration" ngbDropdownItem medsurfRestrict access="admin"></a>
        <a routerLink="/media" translate="media_library" ngbDropdownItem></a>
        <a routerLink="/detached" translate="detached_slides" ngbDropdownItem></a>
        <a routerLink="/metadata" translate="metadata_administration" ngbDropdownItem medsurfRestrict access="admin"></a>
        <a routerLink="/modality" translate="modality_administration" ngbDropdownItem></a>
        <a routerLink="/stain" translate="stain_administration" ngbDropdownItem></a>
        <a routerLink="/copyright" translate="copyright_administration" ngbDropdownItem></a>
      </div>
    </li>
    <li class="nav-item" ngbDropdown>
      <span id="help-dropdown" class="nav-link" ngbDropdownToggle>{{ 'help' | translate }} <b class="caret"></b></span>
      <div ngbDropdownMenu aria-labelledby="simple-dropdown">
        <a routerLink="/dokumentation" translate="documentation" ngbDropdownItem></a>
        <a routerLink="/changelog" translate="changelog" ngbDropdownItem></a>
        <a (click)="openContactModal()" translate="feedback" ngbDropdownItem></a>
      </div>
    </li>
    <li class="nav-item" ngbDropdown *ngIf="(user$ | async)">
      <span id="simple-dropdown" class="nav-link" ngbDropdownToggle>{{ (user$ | async).email }} <b
        class="caret"></b></span>
      <div aria-labelledby="simple-dropdown" ngbDropdownMenu>
        <a routerLink="/profile" translate="user_profile" ngbDropdownItem></a>
        <a (click)="logout()" translate="sign_out" ngbDropdownItem></a>
      </div>
    </li>
    <!--    <li class="nav-item" ngbDropdown placement="bottom-right">-->
    <!--      <span id="language-dropdown" class="nav-link" ngbDropdownToggle>{{ currentLanguage.toUpperCase() }}<b class="caret"></b></span>-->
    <!--      <div ngbDropdownMenu aria-labelledby="simple-dropdown">-->
    <!--        <a *ngFor="let langKey of availableLanguages" (click)="changeLanguage(langKey)" ngbDropdownItem>-->
    <!--          {{ langKey.toUpperCase() }}-->
    <!--        </a>-->
    <!--      </div>-->
    <!--    </li>-->
  </ul>
</nav>
<nav class="navbar route border-bottom">
  <ul class="nav nav-pills">
    <li class="nav-item">
      <span class="nav-link">
        <a [routerLink]="['/']">{{ 'home' | translate}}</a>
      </span>

      <small *ngFor="let breadcrumb of (breadcrumbs$ | async); let i = index">
        <span> / </span>
        <a [routerLink]="breadcrumb.params">{{ breadcrumb.title }}</a>
      </small>
    </li>
  </ul>
  <ul class="nav nav-pills" *ngIf="page">
    <li class="text-primary">
      <small [title]="'last_update' | translate" *ngIf="{ at: page?.editedAt, by: page?.editedBy ? (page.editedBy.firstName + ' ' + page.editedBy.lastName) : '' } as edited">
        <i class="fal fa-clock-rotate-left"></i><span *ngIf="edited.by"> {{ edited.by }},</span> {{ edited.at | date:'dd.MM.yyyy HH:mm' }}
      </small>
    </li>
  </ul>
</nav>
