<div class="w-100 h-100 d-flex position-relative justify-content-center align-items-center" *ngIf="(slide$ | async)">
  <div class="btn-toolbar position-absolute" *ngIf="!media">
    <div class="btn-group">
      <button class="btn btn-primary" (click)="selectMedia()" translate="choose_media"></button>
    </div>
  </div>
  <ng-container *ngIf="media">
    <div id="virtualm" class="microscope" [style.background-color]="viewerBackgroundColor(slide)"
        [class.d-none]="!(mediaService.isImage(media) || mediaService.isDeepzoom(media))" #microscope>
    </div>

    <div class="vm-toast alert alert-dark" *ngIf="toastMessage" [innerHTML]="toastMessage | translate | safeHtml"></div>

    <div class="video-container" *ngIf="mediaService.isVideo(media)">
      <video controls class="w-100" [src]="mediaService.getUrl(media)" [poster]="mediaService.getUrl(media, 'poster')"></video>
    </div>

    <div class="audio-container" *ngIf="mediaService.isAudio(media)">
      <audio controls class="w-100" [src]="mediaService.getUrl(media)"></audio>
    </div>
  </ng-container>
</div>
