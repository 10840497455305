<div class="title-row" *ngIf="showText$ | async">
    <div class="metadata-container" *ngIf="showModality$ | async" (click)="openMetadataDialog()">
        <label>{{'modality' | translate}}</label>
        <div class="metadata-field" *ngIf="slide.modality; else metadataSelect">{{slide.modality.name}}</div>
    </div>
    <div class="title-container">
        <label for="title">{{'title' | translate}}</label>
        <textarea id="title" name="title" class="title-textarea form-control" rows="3" [(ngModel)]="slide.title" (change)="onChange(true)"></textarea>
    </div>
</div>
<div class="main-row">
    <div class="text-column" *ngIf="showText$ | async">
        <div class="header-container" *ngIf="showHeader$ | async">
            <label for="header">{{'caption' | translate}}</label>
            <textarea id="header" name="header" class="caption-textarea form-control" rows="3" [(ngModel)]="slide.header" (change)="onChange()"></textarea>
        </div>
        <div class="text-container" *ngIf="showBody$ | async">
            <label>{{'text' | translate}}</label>
            <medsurf-editor 
                class="text-editor"
                [(content)]="slide.body"
                [autogrow]="true"
                [minHeight]="300"
                (contentChange)="onChange()">
            </medsurf-editor>
        </div>
        <div *ngIf="showQuiz$ | async" cdkDropList (cdkDropListDropped)="orderChanged($event)">
            <div *ngFor="let question of slide.questions; trackBy:trackById" cdkDrag [cdkDragDisabled]="slide.questions.length <= 1" #questionContainer>
                <div class="drag-placeholder" *cdkDragPlaceholder></div>
                <div class="question-container" *ngIf="!question.deleted">
                    <div class="d-flex justify-content-between align-items-start">
                        <button type="button" class="btn btn-secondary quiz-drag-button" tabindex="-1" cdkDragHandle>
                            <i class="fa fa-grip-dots-vertical"></i>
                        </button>
                        <div class="w-100 question-header ml-2" (click)="toggleQuestion(question)">
                            <span class="badge badge-secondary">{{ question.type | translate }}</span>
                            <span class="question-text" [innerHTML]="(question.content | stripHtml) || ('quiz_question' | translate)"></span>
                        </div>
                        <button type="button" class="btn btn-danger ml-2" (click)="deleteQuestion(question)">
                            <i class="fas fa-fw fa-trash"></i>
                        </button>
                    </div>
                    <medsurf-question *ngIf="expandedQuestions[question.id]" [question]="question" #questionComponent>
                    </medsurf-question>
                </div>
            </div>
        </div>
        <ng-container *ngIf="{ pois: (imagePois$ | async), virtualGroups: (virtualGroups$ | async), annotationsMap: (annotationsMap$ | async) } as data">
            <div class="panel-container" *ngIf="data.virtualGroups?.length !== 0">
                <label>{{'interactive_area' | translate}}</label>
                <medsurf-interactive-area-list 
                    [groups]="data.virtualGroups"
                ></medsurf-interactive-area-list>
            </div>
            <div class="panel-container" *ngIf="data.pois?.length !== 0">
                <label>{{'pois' | translate}}</label>
                <medsurf-poi-list 
                    [pois]="data.pois"
                    [annotationsMap]="data.annotationsMap"
                ></medsurf-poi-list>
            </div>
        </ng-container>
    </div>
    <div class="content" [class.d-none]="(showImage$ | async) === false">
        <ng-content></ng-content>
    </div>
</div>

<ng-template #metadataSelect>
    <div class="choose">{{ 'choose...'  | translate }}</div>
</ng-template>
