<h2 class="mt-4 mb-4">{{ 'vocabulary' | translate}}</h2>

<div *ngFor="let vocabularyGroup of vocabulary?.vocabularyGroups" class="mt-4">
  <div class="card">
    <div class="card-header bg-primary text-white">
      <h3 *ngIf="vocabularyGroup.expanded">{{ vocabularyGroup.name }}
        <a (click)="vocabularyGroup.expanded = !vocabularyGroup.expanded">
          <span class="fa fa-minus-square float-right text-secondary"></span>
        </a>
      </h3>
      <h3 *ngIf="!vocabularyGroup.expanded">{{ vocabularyGroup.name }}
        <a (click)="vocabularyGroup.expanded = !vocabularyGroup.expanded">
          <span class="fa fa-plus-square float-right text-secondary"></span>
        </a>
      </h3>
    </div>


    <div class="card-body" [ngbCollapse]="!vocabularyGroup.expanded">
      <h5 class="card-title" translate="group_name"></h5>
      <input type="text" [(ngModel)]="vocabularyGroup.name" (ngModelChange)="onChange()">
    </div>

    <div class="card-body" [ngbCollapse]="!vocabularyGroup.expanded">
      <table class="table table-hover">

        <thead class="bg-primary text-white">
        <tr>
          <th><span translate="Schlüssel"></span></th>
          <th><span translate="Wert"></span></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let vocabularyWord of vocabularyGroup?.vocabularyWords">
          <td><input type="text" [(ngModel)]="vocabularyWord.key"></td>
          <td><input type="text" [(ngModel)]="vocabularyWord.value"></td>
        </tr>
        </tbody>

        <tfoot>
        <tr>
          <th><span>Total: {{ vocabularyGroup?.vocabularyWords?.length}}</span></th>
          <th>
            <button class="btn btn-secondary" (click)="addVocabularyWord(vocabularyGroup)" translate="add_vocabularyword"></button>
          </th>
        </tr>
        </tfoot>

      </table>
    </div>
  </div>
</div>

<button class="btn btn-secondary mt-4" translate="add_vocabularygroup" (click)="addVocabularyGroup()"></button>
