import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { GetTextExport } from '@medsurf/actions';
import { BlockNode, Node, TextExport, } from '@medsurf/models';
import { NavigationState, TextExportState } from '@medsurf/state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { textExportFuncs, TextExportArgType } from '../../../workers/textExport.worker';

@Component({
  selector: 'medsurf-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit, OnChanges, OnDestroy {

  @Select(TextExportState.exports)
  public textExports$: Observable<TextExport[]>;

  @Select(TextExportState.node)
  public node$: Observable<Node>;

  @Input() selectedBlock: BlockNode;

  public textExports: TextExport[];
  get text(): string{
    return this._text.slice(0,10000);
  }
  public _text = '';

  public displayTypes = ['info', 'plain', 'json'];
  public selectedDisplayType = 'info';
  public keepHTMLTags = false;
  private _destroyed = new Subject<boolean>();
  public loading = false;
  public startedContentLoad = false;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.textExports$.pipe(takeUntil(this._destroyed)).subscribe((textExports: TextExport[]) => {
      this.textExports = textExports;
      this.getText('TextStructured');
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes?.selectedBlock?.currentValue) {
      this.startedContentLoad = false;
      this.textExports = [];
    }
  }

  public load() {
    this.startedContentLoad = true;
    this.textExports = [];
    this.loading = true;
    this.store.dispatch(new GetTextExport(this.selectedBlock));
  }

  public onSelectDisplayType(displayType: string) {
    this.selectedDisplayType = displayType;
    this.setText();
  }

  public onSelectKeepHTMLTags() {
    this.keepHTMLTags = !this.keepHTMLTags;
    this.setText();
  }

  private setText() {
    this.loading = true;
    switch (this.selectedDisplayType) {
      case 'plain':
        this.getText('TextWithoutInfo');
        return;
      case 'json':
        this.getText('TextInJson');
        return;
      case 'info':
      default:
        this.getText('TextStructured');
        return;
    }
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this._text);
  }

  download(){
    let fileName = this.store.selectSnapshot(NavigationState.breadcrumbs).map(el => el.title).join('_').replace(/;|,|:|\?|!|\s|\t|-/g, '_');
    if (!fileName?.[0]) {
      fileName = 'text_export';
    }
    
    const element = document.createElement('a');
    element.setAttribute('href', `data:${this.selectedDisplayType === 'json' ? 'application/json' : 'text/plain'};charset=utf-8,` + encodeURIComponent(this._text));
    element.setAttribute('download', `${fileName + (this.selectedDisplayType === 'json' ? '.json' : '.txt')}`);
    element.click();
  }

  private async getText(type: TextExportArgType['type']){
    const argObj: TextExportArgType = {
      type: type, 
      keepHTMLTags: this.keepHTMLTags, 
      textExports: this.textExports
    };

    if (!argObj?.textExports?.[0]) return;

    if (typeof Worker !== 'undefined') {
      const worker = new Worker(new URL('../../../workers/textExport.worker', import.meta.url));
      worker.onmessage = ({ data }) => {
        this.loading = false;
        if(data instanceof Error) return;
        this._text = data;
      };
      worker.postMessage({argObj});
    } else {
      this._text = textExportFuncs[type]?.(argObj);
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }

}
