import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Actions, ofActionCompleted, ofActionDispatched, Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import {
  AddAlert,
  ResetPassword,
  ResetPasswordSuccess,
  ResetPasswordInvalid,
  GetMetadata
} from '@medsurf/actions';
import { Metadata, AlertType } from '@medsurf/models';
import { MetadataState } from '@medsurf/state';
import { ResetPasswordValidator } from '@medsurf/validators';
import { ClassValidatorFormBuilderService, ClassValidatorFormGroup } from 'ngx-reactive-form-class-validator';

@Component({
  selector: 'medsurf-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  /**
   * Members
   */
  public forgotPasswordForm: ClassValidatorFormGroup;
  public isSubmitted = false;

  @Select(MetadataState.metadata)
  public metadata$: Observable<Metadata>;

  public metadata: Metadata;
  private _destroyed = new Subject<boolean>();

  /**
   * Constructor
   * @param router
   * @param store
   * @param actions$
   */
  constructor(
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private fb: ClassValidatorFormBuilderService
  ) {}

  /**
   * NgOnInit
   */
  public async ngOnInit(): Promise<void> {
    this.store.dispatch(new GetMetadata());
    this.forgotPasswordForm = this.fb.group(ResetPasswordValidator, {
      email: ['']
    });

    this.metadata$.pipe(takeUntil(this._destroyed)).subscribe((metadata: Metadata) => {
      if (metadata) {
        this.metadata = metadata;
      }
    });

    this.actions$.pipe(ofActionCompleted(ResetPasswordSuccess), takeUntil(this._destroyed)).subscribe(() => {
      const { supportEmail } = this.metadata;
      const { email } = this.forgotPasswordForm.value;
      this.store.dispatch(new AddAlert({
        message: 'reset_password_success_message',
        params: { emailSent: email, emailSupport: supportEmail },
        type: AlertType.SUCCESS
      }));
      this.router.navigate(['/login']);
    });

    this.actions$.pipe(ofActionDispatched(ResetPasswordInvalid), takeUntil(this._destroyed)).subscribe((action: ResetPasswordInvalid) => {
      this.isSubmitted = false;
      action.errors.forEach((err) => {
        this.forgotPasswordForm.get(err.property).setErrors(err.constraints);
      });
    });
  }

  get f() { return this.forgotPasswordForm.controls; }

  public async onSubmit(): Promise<void> {
    if (this.forgotPasswordForm.invalid || this.isSubmitted) {
      return;
    }
    this.isSubmitted = true;

    const { email } = this.forgotPasswordForm.value;
    this.store.dispatch(new ResetPassword(email));
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
