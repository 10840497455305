<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ selectedNode?.page?.title }} {{ 'user_shares' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="onDismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row mb-3">
        <div class="col-lg-10">
            <select id="user" name="user"
                    class="form-control input-md"
                    [(ngModel)]="selectedUser">
                    >
                <option *ngFor="let user of (users$ | async)" [ngValue]="user">{{user.lastName + ' ' + user.firstName}}</option>
            </select>
        </div>
        <div class="col-lg-2">
            <button class="btn btn-secondary" [disabled]="!selectedUser" (click)="localAddShare()"><span class="fa fa-fw fa-plus"></span></button>
        </div>
    </div>
    
    <div class="row">
        <div class="col">
            <table class="table table-bordered">
            <tbody>
            <tr *ngFor="let sharedUser of selectedNode.shares">
                <td>{{sharedUser.firstName}}</td>
                <td>{{sharedUser.lastName}}</td>
                <td><span class="label label-default">{{sharedUser.role}}</span></td>
                <td><button class="btn btn-secondary btn-danger" (click)="localRemoveShare(sharedUser)"><span class="fa fa-fw fa-minus"></span></button></td>
            </tr>
            </tbody>
            </table>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onDismiss()">{{ 'discard_changes' | translate }}</button>
    <button type="button" class="btn btn-secondary" (click)="onSave()">{{ 'save_changes' | translate }}</button>
</div>
  