import paper from 'paper';

import End from '../marker/pointer/end/end';

class Line {
  private _element: any;
  private _segments: any[];
  private _end: any;
  private _start: any;
  private _endType: any;
  private _startType: any;

  constructor(private _model, private _localPaper, private _format, public _marker) {
    this._element = null;
    this._segments = [];
    this._end = null;
    this._start = null;
    this._endType = this._model.freeFormStyle.endType;
    this._startType = this._model.freeFormStyle.startType;

    this._init();
  }

  _init() {
    this.draw();
  }

  draw() {
    if (this._model.deleted) {
      return;
    }

    const start = new paper.Point(this._marker._container.getAbsoluteCoords(
      this._model.source.position.x,
      this._model.source.position.y,
      this._marker._imageOffset,
      this._marker._imageScale
    ));
    const end = new paper.Point(this._marker._container.getAbsoluteCoords(
      this._model.source.position.x + this._model.freeFormStyle.width,
      this._model.source.position.y + this._model.freeFormStyle.height,
      this._marker._imageOffset,
      this._marker._imageScale
    ));

    this._segments.push(start);
    this._segments.push(end);

    this._element = new paper.Path(this._segments);

    this._element.strokeColor = this._model.freeFormStyle.strokeColor || this._model.freeFormStyle.color || this._format.border.strokeColor;
    this._element.strokeWidth = this._model.freeFormStyle.strokeWidth ||
      this._marker._container.getStrokeWidth(this._model.source.zoom) ||
      this._format.strokeWidth;
    this._element.dashArray = [1, 0];
    if (this._model?.freeFormStyle?.dash) {
      const [dashLength, gapLength]: string[] = this._model.freeFormStyle.dash.split(',');
      if (dashLength && gapLength) {
        const factor =  this._marker?._model?.freeFormStyle?.strokeWidth ? this._marker._model.freeFormStyle.strokeWidth : 1;
        this._element.dashArray = [
          Number.parseInt(dashLength, 10) * factor,
          Number.parseInt(gapLength, 10) * factor
        ];
      }
    }
    this._element._model = this._model;

    this._element.freeform = this._marker;
    this._marker._element.addChild(this._element);

    this._start = this.drawEnding(start, this._startType, this._model.freeFormStyle.startRadius, 1);
    this._end = this.drawEnding(end, this._endType, this._model.freeFormStyle.endRadius, 0);

    if (this._marker._isSelected) {
      this.drawSelection(end);
    }
  }

  drawEnding(position, type, radius, index) {
    this._format.strokeColor = this._element.strokeColor;
    if (radius) {
      this._format.circleRadius = radius;
    }
    const ending = new End(type, this._format, position, this._marker);

    if (type === 'dot' || type === 'arrow') {
      ending.element.element.firstChild.freeform = this._marker;
      this._marker._element.addChild(ending.element.element.firstChild);
    } else {
      ending.element.element.freeform = this._marker;
      this._marker._element.addChild(ending.element.element);
    }

    if (type === 'arrow') {
      ending.element.path.rotation = 90 + ending.element.angle(
        this._element.segments[index].point.x,
        this._element.segments[index].point.y,
        this._element.segments[1 - index].point.x,
        this._element.segments[1 - index].point.y
      );
    } else if (type === 'circle') {
      const intersections = this._element.segments[index].path.getIntersections(ending.element.element);
      if (intersections.length) {
        this._element.segments[1 - index].point = intersections[0].point;
      }
      ending.element.element.strokeWidth = this._model.freeFormStyle.strokeWidth || this._format.border.strokeWidth;
    }

    return ending;
  }

  drawSelection(end) {
    const factor = Math.max(1 / (this._marker._container._localPaper.view.zoom / 2), 1);
    const radius = factor * this._format.selection.radius;

    const selection = new paper.Path.Circle(end, radius);
    selection.strokeColor = this._format.selection.color;

    selection.fillColor = this._format.selection.color;
    selection.fillColor.alpha = this._format.selection.opacity;
    selection.strokeWidth = radius / 10;
    selection.freeform = this._marker;
    selection.positionInPath = this._segments.indexOf(1);
    selection.data = {
      'selectionType': 'resize'
    };

    selection.bringToFront();
    this._marker._element.appendTop(selection);
  }

  cleanUp() {
    if (this._element) {
      this._element.remove();
      this._element = null;
    }
  }
}

export default Line;

