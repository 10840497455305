<div class="section">
    <div [ngClass]="{'panel-heading': !isMinimized, 'minimized': isMinimized}">
        <div [class.rotated]="isMinimized">
            <p class="h2">{{ title }}</p>
        </div>
        <div class="btn-group" ngbDropdown container="body" role="group">
            <button ngbDropdownToggle type="button" class="btn btn-primary">
            </button>
            <div class="dropdown-menu px-4 py-3" ngbDropdownMenu>
                <ng-content select="[dropdownMenu]"></ng-content>
                <button ngbDropdownItem (click)="toggleMinimized()">
                    <ng-container *ngIf="!isMinimized">{{ 'minimize_panel' | translate }}</ng-container>
                    <ng-container *ngIf="isMinimized">{{ 'maximize_panel' | translate }}</ng-container>
                </button>
            </div>
        </div>
    </div>
    <div class="panel-body panel-body-noborders flex-grow" *ngIf="!isMinimized">
        <ng-content></ng-content>
    </div>
</div>
