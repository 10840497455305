<medsurf-navigation></medsurf-navigation>

<div class="detail-container">
  <div class="mb-3">
    <a routerLink="/">
      <i class="fal fa-fw fa-long-arrow-left"></i> {{ 'back_to_overview' | translate }}
    </a>
  </div>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title mb-0" translate="user_profile"></h3>
    </div>
    <div class="card-body">
      <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
        <div class="form-group row">
          <label for="user_firstname" class="col-lg-4 col-form-label" translate="user_firstname"></label>
          <div class="col-lg-8">
            <input class="form-control" id="user_firstname" type="text" name="firstname" formControlName="firstName" [class.is-invalid]="f.firstName.invalid && f.firstName.touched">
            <div class="invalid-feedback" *ngFor="let err of f.firstName.errors | keyvalue">{{ err.value | translate }}</div>
          </div>
        </div>
        <div class="form-group row">
          <label for="user_lastname" class="col-lg-4 col-form-label" translate="user_lastname"></label>
          <div class="col-lg-8">
            <input class="form-control" id="user_lastname" type="text" name="lastname" formControlName="lastName" [class.is-invalid]="f.lastName.invalid && f.lastName.touched">
            <div class="invalid-feedback" *ngFor="let err of f.lastName.errors | keyvalue">{{ err.value | translate }}</div>
          </div>
        </div>
        <div class="form-group row">
          <label for="user_email" class="col-lg-4 col-form-label" translate="user_email"></label>
          <div class="col-lg-8">
            <input class="form-control" id="user_email" type="email" name="email" formControlName="email" [class.is-invalid]="f.email.invalid && f.email.touched">
            <div class="invalid-feedback" *ngFor="let err of f.email.errors | keyvalue">{{ err.value | translate }}</div>
          </div>
        </div>
        <div class="form-group row">
          <label for="use_legacy_text_editor" class="col-lg-4 col-form-label" translate="use_legacy_text_editor"></label>
          <div class="col-lg-8">
            <input class="" type="checkbox" id="use_legacy_text_editor" name="use_legacy_text_editor"
                   [(ngModel)]="useLegacyEditor" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-lg-8">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="change_password" (change)="togglePasswordChange($event)" [checked]="changePassword">
              <label class="form-check-label" for="change_password" translate="change_password"></label>
            </div>
          </div>
        </div>
        <div class="form-group row" *ngIf="changePassword">
          <label for="user_password" class="col-lg-4 col-form-label" translate="user_password"></label>
          <div class="col-lg-8">
            <input class="form-control" id="user_password" type="password" name="password" formControlName="password" [class.is-invalid]="f.password.invalid && f.password.touched">
            <div class="invalid-feedback" *ngFor="let err of f.password.errors | keyvalue">{{ err.value | translate }}</div>
          </div>
        </div>
        <div class="form-group row" *ngIf="changePassword">
          <label for="user_password_confirm" class="col-lg-4 col-form-label" translate="user_password_confirm"></label>
          <div class="col-lg-8">
            <input class="form-control" id="user_password_confirm" type="password" name="password_confirm" formControlName="password_confirm" [class.is-invalid]="f.password_confirm.invalid &&  f.password_confirm.touched">
            <div class="invalid-feedback" *ngFor="let err of f.password_confirm.errors | keyvalue">{{ err.value | translate }}</div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-lg-8">
            <button type="submit" [disabled]="profileForm.invalid" class="btn btn-secondary" translate="save_changes"></button>
          </div>
        </div>
      </form>
    </div>
  </div>

</div>
