<div class="d-flex justify-content-between">
    <div class="self-start">
        <div class="btn-group text-center" *ngIf="!preview">
            <button type="button" class="btn btn-primary" (click)="handleUndo.emit()" [disabled]="!canUndo">
                <span class="fa fa-fw fa-undo"></span>
            </button>
            <button type="button" class="btn btn-primary" (click)="handleRedo.emit()" [disabled]="!canRedo">
                <span class="fa fa-fw fa-redo"></span>
            </button>
        </div>
        <div class="btn-group">
            <button type="button" class="btn btn-primary save-preview" (click)="gotoSlideDetail.emit()">
                <ng-container *ngIf="!isSaving; else spinner">
                    <span *ngIf="canUndo">{{ 'save_and_preview' | translate }}</span>
                    <span *ngIf="!canUndo">{{ 'preview' | translate }}</span>
                </ng-container>
                <ng-template #spinner>
                    <div class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                </ng-template>
            </button>
        </div>
    </div>

    <div class="self-end">
        <div class="btn-group btn-group-toggle" ngbRadioGroup [ngModel]="displayMode$ | async"
            (ngModelChange)="displayModeChanged($event)">
            <label ngbButtonLabel ngbDropdown container="body" class="btn btn-primary">
                <input ngbButton type="radio" value="FULL">
                <span class="far fa-fw fa-lg fa-rectangle-landscape"></span>
                <span class="dropdown-toggle-split" ngbDropdownToggle></span>
                <div class="dropdown-menu px-4 py-3" ngbDropdownMenu>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="showModality" [checked]="showModality$ | async"
                            (change)="showModalityChanged()">
                        <label class="form-check-label" for="showModality">
                            {{ 'show_modality' | translate}}
                        </label>
                    </div>
                </div>
            </label>
            <label ngbButtonLabel ngbDropdown container="body" class="btn btn-primary">
                <input ngbButton type="radio" value="TEXT">
                <span class="fa-stack fa-fw fa-xs">
            <i class="far fa-text fa-stack-1x"></i>
            <i class="far fa-expand-wide fa-stack-2x"></i>
        </span>
                <span class="dropdown-toggle-split" ngbDropdownToggle></span>
                <div class="dropdown-menu px-4 py-3" ngbDropdownMenu>
    <!--                <button ngbDropdownItem>{{ 'add_new_text_block' | translate }}</button>-->
                    <button ngbDropdownItem (click)="addQuestion()">{{ 'add_new_quiz_block' | translate }}</button>
    <!--                <button ngbDropdownItem>{{ 'add_interactive_area_list' | translate }}</button>-->
    <!--                <button ngbDropdownItem>{{ 'add_poi_list' | translate }}</button>-->
                    <div class="dropdown-divider"></div>
                    <button ngbDropdownItem [disabled]="true">{{ 'Auswahl ' }}{{ 'duplicate' | translate }}</button>
                    <button ngbDropdownItem [disabled]="true">{{ 'Auswahl ' }}{{ 'copy' | translate }}</button>
                    <button ngbDropdownItem [disabled]="true">{{ 'Vorhandenes ' }}{{ 'insert_from_clipboard' | translate }}</button>
                    <button ngbDropdownItem [disabled]="true">{{ 'Auswahl ' }}{{ 'remove' | translate }}</button>
                    <div class="dropdown-divider"></div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="showHeader" [checked]="showHeader$ | async"
                            (change)="showHeaderChanged()">
                        <label class="form-check-label" for="showHeader">
                            {{ 'show_header' | translate}}
                        </label>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="showBody" [checked]="showBody$ | async"
                            (change)="showBodyChanged()">
                        <label class="form-check-label" for="showBody">
                            {{ 'show_body' | translate}}
                        </label>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="showQuiz" [checked]="showQuiz$ | async"
                            (change)="showQuizChanged()">
                        <label class="form-check-label" for="showQuiz">
                            {{ 'show_quiz' | translate}}
                        </label>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="showInteractiveArea" [checked]="showInteractiveArea$ | async"
                            (change)="showInteractiveChanged()">
                        <label class="form-check-label" for="showInteractiveArea">
                            {{ 'show_interactive_area' | translate}}
                        </label>
                    </div>
                </div>
            </label>
            <label ngbButtonLabel class="btn btn-primary">
                <input ngbButton type="radio" value="IMAGE">
                <span class="fa-stack fa-fw fa-xs">
            <i class="far fa-image fa-stack-1x"></i>
            <i class="far fa-expand-wide fa-stack-2x"></i>
        </span>
            </label>
        </div>
        <div class="btn-group btn-group-toggle" ngbRadioGroup [ngModel]="annotationMode$ | async"
            (ngModelChange)="annotationModeChanged($event)">
            <label ngbButtonLabel ngbDropdown container="body" class="btn btn-primary">
                <input ngbButton type="radio" value="IMAGE">
                <span class="far fa-fw fa-lg fa-image"></span>
                <span class="dropdown-toggle-split" ngbDropdownToggle></span>
                <div class="dropdown-menu px-4 py-3" ngbDropdownMenu>
                    <button ngbDropdownItem [disabled]="true">{{ 'add_new_image' | translate }}</button>
                    <button ngbDropdownItem [disabled]="true">{{ 'exchange_image' | translate }}</button>
                    <div class="dropdown-divider"></div>
                    <button ngbDropdownItem [disabled]="true">{{ 'image' | translate }}{{ 'duplicate' | translate }}</button>
                    <button ngbDropdownItem [disabled]="true">{{ 'image' | translate }}{{ 'copy' | translate }}</button>
                    <button ngbDropdownItem [disabled]="true">{{ 'image' | translate }}{{ 'insert_from_clipboard' | translate }}</button>
                    <button ngbDropdownItem [disabled]="true">{{ 'image' | translate }}{{ 'remove' | translate }}</button>
                    <div class="dropdown-divider"></div>
                    <button ngbDropdownItem [disabled]="true">{{ 'edit_image' | translate }}</button>
                    <button ngbDropdownItem [disabled]="true">{{ 'edit_image_metadata' | translate }}</button>
                </div>
            </label>
            <label *ngIf="(mediaService.isImage(media$ | async) || mediaService.isDeepzoom(media$ | async))" ngbButtonLabel ngbDropdown container="body" class="btn btn-primary">
                <input ngbButton type="radio" value="ANNOTATION">
                <span class="far fa-fw fa-lg fa-map-marker-alt"></span>
                <span class="dropdown-toggle-split" ngbDropdownToggle></span>
                <div class="dropdown-menu px-4 py-3" ngbDropdownMenu>
                    <button ngbDropdownItem (click)="addMarker.emit('dot')">{{ 'add_new_marker' | translate }}</button>
                    <button ngbDropdownItem (click)="addFreeform.emit('triangle')">{{ 'add_new_wedge' | translate }}</button>
                    <button ngbDropdownItem (click)="addFreeform.emit('arrow')">{{ 'add_new_arrow' | translate }}</button>
                    <button ngbDropdownItem (click)="addFreeform.emit('rectangle')">{{ 'add_new_rectangle' | translate }}</button>
                    <button ngbDropdownItem (click)="addFreeform.emit('ellipse')">{{ 'add_new_ellipse' | translate }}</button>
                    <button ngbDropdownItem (click)="addFreeform.emit('line')">{{ 'add_new_line' | translate }}</button>
                    <button ngbDropdownItem (click)="addFreeform.emit('free')">{{ 'add_new_draw_form' | translate }}</button>
                    <button ngbDropdownItem (click)="addFreeform.emit('interactiveArea')">{{ 'add_new_interactive_area' | translate }}</button>
                    <button ngbDropdownItem (click)="addKeymap.emit()">{{ 'add_new_legend' | translate }}</button>
                    <button ngbDropdownItem (click)="addText.emit()">{{ 'add_new_text' | translate }}</button>
<!--                    <div class="dropdown-divider"></div>-->
<!--                    <button ngbDropdownItem (click)="copyMarker.emit(marker)" [disabled]="!marker">{{ 'Auswahl ' }}{{ 'copy' | translate }}</button>-->
<!--                    <button ngbDropdownItem (click)="pasteMarker.emit()" [disabled]="!(copyStack$ | async)">{{ 'Vorhandenes ' }}{{ 'insert_from_clipboard' | translate }}</button>-->
<!--                    <button ngbDropdownItem [disabled]="true">{{ 'Auswahl ' }}{{ 'remove' | translate }}</button>-->
<!--                    <div class="dropdown-divider"></div>-->
<!--                    <button ngbDropdownItem [disabled]="true">{{ 'Auswahl ' }}{{ 'edit' | translate }}</button>-->
<!--                    <div class="form-check">-->
<!--                        <input [disabled]="true" type="checkbox" class="form-check-input" id="showInSelftest">-->
<!--                        <label class="form-check-label" for="showInSelftest">-->
<!--                            {{ 'Auswahl ' }}{{ 'show_in_selftest' | translate}}-->
<!--                        </label>-->
<!--                    </div>-->
<!--                    <div class="dropdown-divider"></div>-->
<!--                    <button ngbDropdownItem [disabled]="true">{{ 'move_to_foreground' | translate }}</button>-->
<!--                    <button ngbDropdownItem [disabled]="true">{{ 'move_to_background' | translate }}</button>-->
<!--                    <div class="dropdown-divider"></div>-->
<!--                    <button ngbDropdownItem [disabled]="true">{{ 'Auswahl ' }}{{ 'group' | translate }}</button>-->
<!--                    <button ngbDropdownItem [disabled]="true">{{ 'Auswahl ' }}{{ 'remove_group' | translate }}</button>-->
                </div>
            </label>
            <label ngbButtonLabel class="btn btn-primary">
                <input ngbButton type="radio" value="SELF_TEST">
                <span class="fa fa-fw fa-lg fa-check"></span>
            </label>
        </div>
        <div *ngIf="(mediaService.isImage(media$ | async) || mediaService.isDeepzoom(media$ | async))" class="btn-group btn-group-toggle">
            <label class="btn-primary" ngbButtonLabel>
                <input type="checkbox" ngbButton [(ngModel)]="grid"
                    (change)="gridChange.emit(grid)">{{ 'grid' | translate }}
            </label>
        </div>
    </div>
</div>
