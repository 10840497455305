import { MediaService } from '@medsurf/services';
import { MediaType, Media } from '@medsurf/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Editor } from 'tinymce';
import { ChangeEventCallback, OnAction, OnSetup, ToolbarToggleButtonInstanceApi } from '../plugins';
import { openDialog } from '../media-base';
import { NgZone } from '@angular/core';
import { environment } from '../../../../../environments/environment';

const nodeChangeEventCallback: ChangeEventCallback = (buttonApi: ToolbarToggleButtonInstanceApi, editor: Editor) => async (eventApi) => {
  const element: HTMLElement = (<HTMLElement>eventApi.element);
  const isImage: boolean = element.tagName.toLowerCase() === 'img';

  buttonApi.setEnabled(isImage || editor.selection.getContent() === '' );
  buttonApi.setActive(isImage);
};

const getOnSetup: OnSetup = (editor: Editor) => (buttonApi: ToolbarToggleButtonInstanceApi) => {
  editor.on('NodeChange', nodeChangeEventCallback(buttonApi, editor));
  return () => {
    editor.off('NodeChange', nodeChangeEventCallback(buttonApi, editor));
  };
};

const getOnAction: OnAction = (editor: Editor, zone: NgZone, modalService: NgbModal, mediaService: MediaService) =>
  async (buttonApi: ToolbarToggleButtonInstanceApi) => {
    zone.run(async () => {
      const allowedTypes: MediaType[] = [MediaType.IMAGE];
      const node = <HTMLImageElement>editor.selection.getNode();
      const isImage = node?.tagName?.toLowerCase() === 'img';
      
      const media: Media[] = isImage ? [{
        id: node.dataset.id,
        type: MediaType.IMAGE
      }] : [];

      try {
        const medium: Media = await openDialog(allowedTypes, modalService, media);
        const url = environment.config.endpointUrl + 'v1/id2url/' + medium.id;

        if (isImage) {
          editor.dom.setAttribs(node, {
            src: url,
            alt: medium.name,
            'data-id': medium.id
          });
          editor.fire('change');
        } else {
          const element = document.createElement('img');
          element.src = url;
          element.alt = medium.name;
          element.dataset.id = medium.id;
          element.width = 200;
          editor.insertContent(element.outerHTML);
        }
      } catch (e) {
        return;
      }
    });
  };

export { getOnAction, getOnSetup };
