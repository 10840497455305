/// <reference lib="webworker" />

import { TextExport } from '@medsurf/models';
import { cloneDeep, unescape } from 'lodash';

export interface TextExportArgType {
  textExports: TextExport[];
  type: keyof typeof textExportFuncs;
  keepHTMLTags: boolean;
}

export const textExportFuncs = {
  TextStructured: (argObj: TextExportArgType) => {
    return outputObject(argObj.textExports, argObj.keepHTMLTags, true);
  },

  TextWithoutInfo: (argObj: TextExportArgType) => {
    return outputObject(argObj.textExports, argObj.keepHTMLTags, false);
  },

  TextInJson: (argObj: TextExportArgType) => {
    const textExports = cloneDeep(argObj.textExports);
    for (const slide of textExports) {
      if (slide.body) {
        slide.body = cleanText(slide.body, argObj.keepHTMLTags);
      } else {
        slide.description = cleanText(slide.description, argObj.keepHTMLTags);
      }
    }
    return JSON.stringify(textExports, null, 4);
  }
}

const replaceKeyMap = new Set(['header', 'description', 'body', 'text', 'content', 'solutionText', 'explanation', 'label', 'name']);
const infoKeysMap = new Set(['id', 'slideId', 'type', 'nodeId', 'route']);

function outputObject<T extends any[]>(items: T, keepHTML = false, printInfo = false, indent = 0) {
  let output = '';
  items.forEach((item) => {
    output += `\n${''.padStart(indent, '\t')}------------\n`;
    
    let key: keyof typeof item;
    for (key in item) {
      const value = item[key];
      const printKey = key.charAt(0).toUpperCase() + key.slice(1);

      if (!printInfo && infoKeysMap.has(key)) {
        continue;
      }

      if (Array.isArray(value)) {
        if (value.length > 0) {
          output += `${''.padStart(indent, '\t')}${printKey}:\n`;
          output += outputObject(value, keepHTML, printInfo, indent + 1);
        }
        continue;
      }

      if (value) {
        output += `${''.padStart(indent, '\t')}`;
        if (printInfo) {
          output += `${printKey}: `;
        }
        if (typeof value === 'string' && replaceKeyMap.has(key)) {
          output += `${cleanText(value, keepHTML)}\n`;
        } else {
          output += `${value}\n`;
        }
      }
    }
  });
  return output;
}

function cleanText(text: string, keepHTMLTags: boolean) {
  if (!keepHTMLTags) {
    text = text?.replace(/<[^>]+>/ig, ' ');
  }

  return unescape(text || '')
    ?.replace(/&auml;/g, 'ä')
    .replace(/&ouml;/g, 'ö')
    .replace(/&uuml;/g, 'ü')
    .replace(/&Auml;/g, 'Ä')
    .replace(/&Ouml;/g, 'Ö')
    .replace(/&Uuml;/g, 'Ü')
    .replace(/&nbsp;/g, ' ')
    .replace(/  +/g, ' ')
    .trim();
}

addEventListener('message', ({ data }) => {
  if (!data?.argObj) return;
  
  try {
    postMessage(textExportFuncs[data.argObj.type]?.(data.argObj));
  } catch(e){
    postMessage(new Error(e));
  }
});
