<div class="multiselect form-control" #container (click)="focusInput()">
    <div class="multiselect__item badge badge-secondary" *ngFor="let item of selectedItems">
        {{ item }}
    </div>
    <input 
        #inputElement
        class="multiselect__input"
        type="text" 
        autocomplete="off"
        (keydown)="validate($event)"
        (keyup)="scrollBot()"
        (keyup.enter)="addItem()"
        (blur)="addItem()"
        (keydown.backspace)="deleteItem()"
        [(ngModel)]="input"
        [size]="input.length > 4 ? input.length : 4"
        [placeholder]="selectedItems.length === 0 ? placeholder : ''"
    >
</div>