import { Point as OSDPoint, Viewer } from 'openseadragon';

interface Options {
  viewer?: Viewer;
  color?: string;
  fontColor?: string;
  backgroundColor?: string;
  fontSize?: string;
  fontFamily?: string;
  textAddition?: string;
  barThickness?: number;
  pixelsPerMeter?: number;
  referenceItemIdx?: number;
  minWidth?: number;
  location?: number;
  xOffset?: number;
  yOffset?: number;
  stayInsideImage?: boolean;
}


export class Scalebar {
  private viewer: Viewer;
  private divElt: HTMLDivElement;
  private color: string;
  private fontColor: string;
  private backgroundColor: string;
  private fontSize: string;
  private fontFamily: string;
  private barThickness: any;
  private referenceItemIdx: any;
  private pixelsPerMeter: any;
  private location: number;
  private yOffset: any;
  private xOffset: any;
  private minWidth: number;
  private drawScalebar: any;
  private stayInsideImage: boolean;
  private sizeAndTextRenderer: any;
  private textAddition: string;

  constructor(options: Options) {
    options = options || {};
    if (!options.viewer) {
      throw new Error('A viewer must be specified.');
    }
    this.viewer = options.viewer;

    this.divElt = document.createElement('div');
    this.viewer.container.appendChild(this.divElt);
    this.divElt.style.position = 'relative';
    this.divElt.style.margin = '0';
    this.divElt.style.pointerEvents = 'none';

    this.setMinWidth(options.minWidth || '150px');

    this.drawScalebar = this.drawMicroscopyScalebar;
    this.color = options.color || 'black';
    this.fontColor = options.fontColor || 'black';
    this.backgroundColor = options.backgroundColor || 'none';
    this.fontSize = options.fontSize || '';
    this.fontFamily = options.fontFamily || '';
    this.textAddition = options.textAddition || '';
    this.barThickness = options.barThickness || 2;
    this.pixelsPerMeter = options.pixelsPerMeter || null;
    this.referenceItemIdx = options.referenceItemIdx || 0;
    this.location = options.location || 1;
    this.xOffset = options.xOffset || 5;
    this.yOffset = options.yOffset || 5;
    this.stayInsideImage = this.isDefined(options.stayInsideImage) ?
      options.stayInsideImage : true;
    this.sizeAndTextRenderer = function (ppm, minSize) {
      return this.getScalebarSizeAndTextForMetric(ppm, minSize, 'm');
    };

    const handler = () => {
      this.refresh();
    };
    this.viewer.addHandler('open', handler);
    this.viewer.addHandler('animation', handler);
    this.viewer.addHandler('resize', handler);
  }

  public refresh(options?) {
    this.updateOptions(options);

    if (!this.viewer.isOpen() ||
      !this.drawScalebar ||
      !this.pixelsPerMeter ||
      !this.location) {
      this.divElt.style.display = 'none';
      return;
    }
    this.divElt.style.display = '';

    const viewport = this.viewer.viewport;
    const tiledImage = this.viewer.world.getItemAt(this.referenceItemIdx);
    const zoom = this.tiledImageViewportToImageZoom(tiledImage, viewport.getZoom(true));
    const currentPPM = zoom * this.pixelsPerMeter;
    const props = this.sizeAndTextRenderer(currentPPM, this.minWidth);

    this.drawScalebar(props.size, props.text);
    const location = this.getScalebarLocation();
    this.divElt.style.left = location.x + 'px';
    this.divElt.style.top = location.y + 'px';
  }

  public updateOptions(options) {
    if (!options) {
      return;
    }
    if (this.isDefined(options.minWidth)) {
      this.setMinWidth(options.minWidth);
    }
    if (this.isDefined(options.color)) {
      this.color = options.color;
    }
    if (this.isDefined(options.fontColor)) {
      this.fontColor = options.fontColor;
    }
    if (this.isDefined(options.backgroundColor)) {
      this.backgroundColor = options.backgroundColor;
    }
    if (this.isDefined(options.fontSize)) {
      this.fontSize = options.fontSize;
    }
    if (this.isDefined(options.fontFamily)) {
      this.fontFamily = options.fontFamily;
    }
    if (this.isDefined(options.textAddition)) {
      this.textAddition = options.textAddition;
    }
    if (this.isDefined(options.barThickness)) {
      this.barThickness = options.barThickness;
    }
    if (this.isDefined(options.pixelsPerMeter)) {
      this.pixelsPerMeter = options.pixelsPerMeter;
    }
    if (this.isDefined(options.referenceItemIdx)) {
      this.referenceItemIdx = options.referenceItemIdx;
    }
    if (this.isDefined(options.location)) {
      this.location = options.location;
    }
    if (this.isDefined(options.xOffset)) {
      this.xOffset = options.xOffset;
    }
    if (this.isDefined(options.yOffset)) {
      this.yOffset = options.yOffset;
    }
    if (this.isDefined(options.stayInsideImage)) {
      this.stayInsideImage = options.stayInsideImage;
    }
    if (this.isDefined(options.sizeAndTextRenderer)) {
      this.sizeAndTextRenderer = options.sizeAndTextRenderer;
    }
  }

  public setMinWidth(minWidth) {
    this.divElt.style.width = minWidth;
    // Make sure to display the element before getting is width
    this.divElt.style.display = '';
    this.minWidth = this.divElt.offsetWidth;
  }

  public drawMicroscopyScalebar(size, text) {
    this.divElt.style.fontSize = this.fontSize;
    this.divElt.style.fontFamily = this.fontFamily;
    this.divElt.style.textAlign = 'center';
    this.divElt.style.color = this.fontColor;
    this.divElt.style.border = 'none';
    this.divElt.style.borderBottom = this.barThickness + 'px solid ' + this.color;
    this.divElt.style.backgroundColor = this.backgroundColor;
    this.divElt.innerHTML = text;
    this.divElt.style.width = size + 'px';
  }

  public getScalebarLocation(): OSDPoint {
    let x = 0;
    let y = 0;
    if (this.stayInsideImage) {
      const pixel = this.viewer.viewport.pixelFromPoint(
        new OSDPoint(0, 0), true);
      if (!this.viewer.wrapHorizontal) {
        x = Math.max(pixel.x, 0);
      }
      if (!this.viewer.wrapVertical) {
        y = Math.max(pixel.y, 0);
      }
    }
    return new OSDPoint(x + this.xOffset, y + this.yOffset);
  }

  private getScalebarSizeAndTextForMetric(ppm: number, minSize: number, unitSuffix: string): { size: number, text: string } {
    const value: number = this.normalize(ppm, minSize);
    const factor: number = this.roundSignificand(value / ppm * minSize, 3);
    const size: number = value * minSize;
    let text = this.getWithUnit(factor, unitSuffix);
    if (this.textAddition) {
      text += ' ' + this.textAddition;
    }
    return {
      size,
      text
    };
  }


  private normalize(value, minSize): number {
    const significand = this.getSignificand(value);
    const minSizeSign = this.getSignificand(minSize);
    let result = this.getSignificand(significand / minSizeSign);
    if (result >= 5) {
      result /= 5;
    }
    if (result >= 4) {
      result /= 4;
    }
    if (result >= 2) {
      result /= 2;
    }
    return result;
  }

  private getSignificand(x: number) {
    return x * Math.pow(10, Math.ceil(-this.log10(x)));
  }

  private roundSignificand(x: number, decimalPlaces: number): number {
    const exponent = -Math.ceil(-this.log10(x));
    const power = decimalPlaces - exponent;
    const significand = x * Math.pow(10, power);
    // To avoid rounding problems, always work with integers
    if (power < 0) {
      return Math.round(significand) * Math.pow(10, -power);
    }
    return Math.round(significand) / Math.pow(10, power);
  }

  private log10(x: number): number {
    return Math.log(x) / Math.log(10);
  }

  private getWithUnit(value: number, unitSuffix: string): string {
    if (value < 0.000001) {
      return value * 1000000000 + ' n' + unitSuffix;
    }
    if (value < 0.001) {
      return value * 1000000 + ' μ' + unitSuffix;
    }
    if (value < 1) {
      return value * 1000 + ' m' + unitSuffix;
    }
    if (value >= 1000) {
      return value / 1000 + ' k' + unitSuffix;
    }
    return value + ' ' + unitSuffix;
  }

  // Missing TiledImage.viewportToImageZoom function in OSD 2.0.0
  private tiledImageViewportToImageZoom(tiledImage, viewportZoom: number): number {
    const ratio: number = tiledImage._scaleSpring.current.value *
      tiledImage.viewport._containerInnerSize.x /
      tiledImage.source.dimensions.x;
    return ratio * viewportZoom;
  }

  private isDefined(variable) {
    return typeof (variable) !== 'undefined';
  }
}
