<form class="form" *ngIf="marker && isMarker(marker)">

  <!-- Selftest use -->
  <div class="form-group">
    <input name="useForSelftest" type="checkbox" [checked]="!marker.selfTest.ignore" (change)="onSelfTestChange($event)"> {{ 'use_for_selftest' | translate }}
    <hr/>
  </div>

  <!-- Label -->
  <fieldset>

    <div class="form-group">
      <label for="labelText" translate="label_text"></label>
      <textarea id="labelText" name="labelText" class="form-control" object-edit [(ngModel)]="marker.label.text" (keyup)="onChange()" rows="2"></textarea>
    </div>

    <div name="align" class="btn-group btn-group-toggle mb-3" ngbRadioGroup [(ngModel)]="marker.label.align" (ngModelChange)="onChange()">
      <label ngbButtonLabel class="btn-secondary" aria-label="Left Align">
        <input name="labelAlign" ngbButton type="radio" value="left">
        <span class="fa fa-fw fa-align-left" aria-hidden="true"></span>
      </label>
      <label ngbButtonLabel class="btn-secondary" aria-label="Center Align">
        <input name="labelAlign" ngbButton type="radio" value="center">
        <span class="fa fa-fw fa-align-center" aria-hidden="true"></span>
      </label>
      <label ngbButtonLabel class="btn-secondary" aria-label="Right Align">
        <input name="labelAlign" ngbButton type="radio" value="right">
        <span class="fa fa-fw fa-align-right" aria-hidden="true"></span>
      </label>
    </div>

    <div class='row'>
      <div class='col-sm-6'>
        <div class='form-group'>
          <label for="labelColor" translate="label_color"></label>
          <medsurf-color-palette id="labelColor" name="labelColor" [colorList]="defaultValues.colors" [(ngModel)]="marker.label.color" (ngModelChange)="onChange()"></medsurf-color-palette>
        </div>
      </div>
      <div class='col-sm-6'>
          <label for="labelFontSize" translate="label_font_size"></label>
          <input id="labelFontSize" name="labelFontSize" class="form-control input-md" type="number" [(ngModel)]="marker.label.fontSize" (ngModelChange)="onChange()"/>
      </div>
    </div>

    <div class='row'>
      <div class='col-sm-6'>
        <div class='form-group'>
          <input name="border" type="checkbox" [(ngModel)]="marker.label.border" (ngModelChange)="onChange()"> {{ 'with_border' | translate }}
        </div>
      </div>
    </div>

    <div class="row" *ngIf="marker.label.border">
      <div class='col-sm-6'>
        <label for="labelStrokeColor" translate="label_stroke_color"></label>
        <medsurf-color-palette id="labelStrokeColor" name="labelStrokeColor" [colorList]="defaultValues.colors" [(ngModel)]="marker.label.borderColor" (ngModelChange)="onChange()"></medsurf-color-palette>
      </div>
    </div>

    <div class="row" *ngIf="marker.label.border">
      <div class='col-sm-6'>
        <div class='form-group'>
          <label for="labelStrokeDashArray" translate="label_stroke_dash"></label>
          <select id="labelStrokeDashArray" name="labelStrokeDashArray" class="form-control input-md" [(ngModel)]="marker.label.dash" (ngModelChange)="onChange()">
            <option *ngFor="let dash of defaultValues.dashArray" [value]="dash.value">{{dash.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-sm-6">
        <div class='form-group'>
          <label for="labelStrokeWidth" translate="label_stroke_width"></label>
          <input id="labelStrokeWidth" name="strokeWidth" class="form-control input-md" type="number" [(ngModel)]="marker.label.borderWidth" (ngModelChange)="onChange()"/>
        </div>
      </div>
    </div>

    <div class='row'>
      <div class='col-sm-6'>
        <div class='form-group'>
          <input id="labelBackground" name="labelBackground" type="checkbox" [checked]="marker.label.background.opacity > 0" (click)="onToggleLabelBackground()"> {{ 'with_background' | translate }}
        </div>
      </div>
      <div class='col-sm-6'>
        <div class='form-group'>
          <input type="range" class="form-control-range" id="labelBackgroundOpacity" name="labelBackgroundOpacity" [(ngModel)]="marker.label.background.opacity" (ngModelChange)="onChange()" min="0" max="1" step="0.01"> {{ (marker.label.background.opacity * 100 | number) + "%" }}
        </div>
      </div>
    </div>

    <div class='row' *ngIf="marker.label.background.opacity > 0">
      <div class='col-sm-6'>
        <div class='form-group'>
          <label for="labelBackgroundColor" translate="label_background_color"></label>
          <medsurf-color-palette id="labelBackgroundColor" name="labelBackgroundColor" [colorList]="defaultValues.colors" [(ngModel)]="marker.label.background.color" (ngModelChange)="onChange()"></medsurf-color-palette>
        </div>
      </div>
      <div class='col-sm-6'>
        <div class='form-group'>
          <input id="labelBackgroundInBorderColor" name="labelBackgroundInBorderColor" type="checkbox" [(ngModel)]="marker.label.backgroundInBorderColor" (ngModelChange)="onChange()"> {{ 'label_background_bordercolor' | translate }}
        </div>
      </div>
    </div>

    <hr />

  </fieldset>

  <!-- Points -->
  <fieldset *ngIf="marker && marker.targets">

    <div class='row'>
      <div class='col-sm-6'>
        <div class='form-group'>
          <label class="control-label" for="text" translate="pointers"></label>
          <input name="length" type="text" class="form-control input-md" [(ngModel)]="marker.targets.length" (ngModelChange)="onChange()" disabled/>
        </div>
      </div>
      <div class='col-sm-6'>
        <div class='form-group'>
          <label class="control-label" for="text">&nbsp;</label>
          <br>
          <div class="btn-group">
            <button class="btn btn-secondary" (click)="addTarget()">
              <span class="fa fa-fw fa-plus"></span>
            </button>
            <button class="btn btn-secondary" (click)="removeTarget()">
              <span class="fa fa-fw fa-minus"></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class='row' *ngIf="marker.targets.length > 0">
      <div class='col-sm-6'>
        <div class='form-group'>
          <label class="control-label" for="endType" translate="pointer_end"></label>
          <select id="endType" name="endType" class="form-control input-md" [(ngModel)]="marker.targets[0].endType" (ngModelChange)="onChange()" (change)="changeEndType()">
            <option *ngFor="let endType of defaultValues.endTypes" [value]="endType.value">{{endType.name}}</option>
          </select>
        </div>
      </div>
      <div class='col-sm-6'>
        <div class='form-group' *ngIf="(marker.type !== 'text') && marker.targets[0].endType === 'circle'">
          <label class="control-label" for="endTypeRadius"></label>
          <input type="range" class="form-control-range" id="endTypeRadius" name="endTypeRadius" [(ngModel)]="marker.targets[0].endRadius" (ngModelChange)="onChange()" min="4" max="100" step="0.01">
        </div>
      </div>
    </div>

    <div class='row' *ngIf="(marker.type !== 'text') && marker.targets.length > 0">
      <div class='col-sm-6'>
        <div class="form-group">
          <label class="control-label" for="pivot" translate="pointer_pivot"></label>
          <div>
            <label class="radio-inline mr-3">
              <input type="radio" name="pivot" id="pivotBottomRight" value="bottomRight" disabled>
            </label>
            <label class="radio-inline mr-3">
              <input type="radio" name="pivot" id="pivotBottomCenter" value="bottomCenter" [(ngModel)]="marker.label.pivot" (ngModelChange)="onChange()" [checked]="marker.label.pivot === 'bottomCenter'">
            </label>
            <label class="radio-inline mr-3">
              <input type="radio" name="pivot" id="pivotBottomLeft" value="bottomLeft" disabled>
            </label>
          </div>
          <div>
            <label class="radio-inline mr-3">
              <input type="radio" name="pivot" id="pivotRightCenter" value="rightCenter" [(ngModel)]="marker.label.pivot" (ngModelChange)="onChange()" [checked]="marker.label.pivot === 'rightCenter'">
            </label>
            <label class="radio-inline mr-3">
              <input type="radio" name="pivot" id="pivotCenter" value="center" [(ngModel)]="marker.label.pivot" (ngModelChange)="onChange()" [checked]="marker.label.pivot === 'center'">
            </label>
            <label class="radio-inline mr-3">
              <input type="radio" name="pivot" id="pivotLeftCenter" value="leftCenter" [(ngModel)]="marker.label.pivot" (ngModelChange)="onChange()" [checked]="marker.label.pivot === 'leftCenter'">
            </label>
          </div>
          <div>
            <label class="radio-inline mr-3">
              <input type="radio" name="pivot" id="pivotTopRight" value="topRight" disabled>
            </label>
            <label class="radio-inline mr-3">
              <input type="radio" name="pivot" id="pivotTopCenter" value="topCenter" [(ngModel)]="marker.label.pivot" (ngModelChange)="onChange()" [checked]="marker.label.pivot === 'topCenter'">
            </label>
            <label class="radio-inline mr-3">
              <input type="radio" name="pivot" id="pivotTopLeft" value="topLeft" disabled>
            </label>
          </div>
        </div>
        <br/>
      </div>
    </div>

    <div class='row' *ngIf="marker.targets.length > 0">
      <div class='col-sm-6'>
        <div class='form-group'>
          <label class="control-label" for="pointerColor" translate="pointer_color"></label>
          <medsurf-color-palette id="pointerColor" name="pointerColor" [colorList]="defaultValues.colors" [(ngModel)]="marker.targets[0].color" (ngModelChange)="onChange()"></medsurf-color-palette>
        </div>
      </div>
      <div class='col-sm-6'>
        <div class='form-group'>
          <label class="control-label" for="text"></label>
        </div>
      </div>
    </div>

    <hr/>

  </fieldset>

  <!-- Link -->
  <fieldset>
    <div class='row mb-2'>
      <div class='col-sm-6'>
        <label for="linkedSite" translate="label_linked_site_type"></label>
        <div class="btn-group" role="group">
          <button *ngFor="let linkType of linkTypes"
                  type="button"
                  [class.btn-secondary]="linkType === marker?.link?.type"
                  [class.btn-primary]="linkType !== marker?.link?.type"
                  class="btn btn-sm"
                  (click)="selectLinkType(linkType)">
            {{ linkType }}
          </button>
        </div>
      </div>
    </div>
    <div class='row' *ngIf="marker.link">
      <div class='col-sm-6'>
        <div class='form-group'>
          <label for="linkedSite" translate="label_linked_site"></label>
          <input id="linkedSite" name="linkedSite" class="form-control input-md"
                  type="text"
                  [disabled]="!(marker.link.type)"
                  [(ngModel)]="marker.link.link" (ngModelChange)="onChange()"/>
        </div>
      </div>
    </div>
  </fieldset>
</form>
