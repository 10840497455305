import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { cloneDeep, omit } from 'lodash';
import { Observable } from 'rxjs';
import { User, Node  } from '@medsurf/models';
import { UserState } from '@medsurf/state';
import { SaveIndex, IndexChanged, GetUsers } from '@medsurf/actions';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'medsurf-sidebar-permission',
  templateUrl: './permission-dialog.component.html',
  styleUrls: ['./permission-dialog.component.scss']
})
export class PermissionDialogComponent implements OnInit {

  public node: Node;
  /**
   * Selector for all users, list in modal
   * @type {Observable<User[]>}
   */
  @Select(UserState.users)
  public users$: Observable<User[]>;

  /**
   * Selected user in dropdown field
   * @type {User}
   */
  public selectedUser: User;

  /**
   * Selected node, should be updated. Reference in shares modal service
   * @type {Node}
   */
  public selectedNode: Node;

  /**
   * Constructor
   * @param {Store} store
   */
  constructor(
    private store: Store,
    public modal: NgbActiveModal
  ) {}

  /**
   * NgOnInit function
   */
  public ngOnInit(): void {
    this.selectedNode = omit<Node>(cloneDeep<Node>(this.node), 'children');
    this.store.dispatch(new GetUsers());
  }

  /**
   * Add selected user to shares if not already added
   */
  public localAddShare(): void {
    if (this.selectedNode.shares.some((user) => user.id === this.selectedUser.id)) {
      return;
    }
    this.selectedNode.shares.push(this.selectedUser);
  }

  /**
   * Remove already shared user from shared list
   * @param {User} sharedUser
   */
  public localRemoveShare(sharedUser: User): void {
    this.selectedNode.shares = this.selectedNode.shares.filter(user => user.id !== sharedUser.id);
  }

  /**
   * Callback function for save button
   * Updates selected node in store and closes window
   */
  public onSave(): void {
    this.update(this.selectedNode);
    this.modal.close();
  }

  /**
   * Callback function for close button
   */
  public onDismiss(): void {
    this.modal.dismiss();
  }

  /**
   * Deep clone node to update
   * @param {Node} node
   * @private
   */
  private update(node: Node): void {
    const clonedNode: Node = omit<Node>(cloneDeep<Node>(node), 'children');
    this.store.dispatch(new IndexChanged([clonedNode]));
    this.store.dispatch(new SaveIndex());
  }

}
