<ng-container>
  <medsurf-upload-dropzone class="upload-section"
    *ngIf="upload"
    [node]="node"
    (uploaded)="onUpload($event)"
  ></medsurf-upload-dropzone>

  <div class="media-container" *ngIf="!upload">
    <div class="media-grid-container">

      <div class="media-grid-header">
        <div class="btn-group filter-input mr-3" role="group">
          <button type="button" [class]="'btn ' + (displayMode === 'grid' ? 'btn-primary border-0' : 'btn-outline-primary btn-white')" (click)="setDisplayMode('grid')">
            <span class="fas fa-th"></span>
          </button>
          <button type="button" [class]="'btn ' + (displayMode === 'table' ? 'btn-primary border-0' : 'btn-outline-primary btn-white')" (click)="setDisplayMode('table')">
            <span class="fas fa-bars"></span>
          </button>
        </div>
        <input id="search-input" class="filter-input form-control mr-3 w-25" type="text" [placeholder]="'search_query' | translate" [ngModel]="searchQuery" (ngModelChange)="onSearchQueryChange($event)">
        <div ngbDropdown class="filter-input mr-3" autoClose="outside">
          <button class="btn btn-outline-primary btn-white" id="dropdownBasic1" ngbDropdownToggle>
            <span class="media-type-btn">
              <span>{{ 'file_type' | translate }}: </span>
              <span *ngIf="(typeFilter$ | async).length === 0 || (typeFilter$ | async).length === (typeOptions | keyvalue).length">{{ 'all' | translate }}</span>
              <ng-container *ngIf="(typeFilter$ | async).length !== 0 && (typeFilter$ | async).length !== (typeOptions | keyvalue).length">
                <ng-container *ngFor="let type of typeOptions | keyvalue">
                  <span *ngIf="(typeFilter$ | async).indexOf(type.value) !== -1" [class]="'mr-2 fa ' + mediaService.getMediaTypeIcon({type: type.value})"></span> 
                </ng-container>
              </ng-container>
            </span>
          </button>
          <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownBasic1">
            <button ngbDropdownItem *ngFor="let item of typeOptions | keyvalue" (click)="onTypeFilterChange(item.value)" [class.active]="(typeFilter$ | async).indexOf(item.value) !== -1">
              <span [class]="'mr-2 fa ' + mediaService.getMediaTypeIcon({type: item.value})"></span> 
              {{ (item.key | lowercase) | translate }}
            </button>
          </div>
        </div>

        <medsurf-tree-select
          class="filter-input"
          [label]="'used_in'"
          [options]="nodeOptions"
          (selectedChange)="onNodeSelectChange($event)"
        ></medsurf-tree-select>

        <div class="filter-input form-check d-flex align-items-center ml-1 mr-3">
          <input id="uploaded-by-me" class="form-check-input mt-0" type="checkbox" [ngModel]="uploadedByMe" (ngModelChange)="onUploadedByMeChange($event)">
          <label for="uploaded-by-me" class="form-check-label">{{ 'uploaded_by_me' | translate }}</label>
        </div>

        <div class="filter-input btn-group btn-group-toggle" ngbDropdown container="body" *ngIf="!isSelect">
          <label container="body" class="btn btn-outline-primary btn-white" ngbDropdownToggle>
            <span >{{ 'bulk_actions' | translate }} ({{ bulkSelectedMedia?.length || 0 }})</span>
          </label>
          <div class="dropdown-menu px-1 py-2" ngbDropdownMenu>
              <button ngbDropdownItem [disabled]="!bulkSelectedMedia || bulkSelectedMedia.length === 0" (click)="bulkDeleteMedia()">{{ 'delete_selected_media' | translate }}</button>
          </div>
        </div>
      </div>
        
      <div class="media-grid-body">
        <div class="w-100 d-flex justify-content-center mt-lg-5" *ngIf="isLoading">
          <div class="spinner-border"></div>
        </div>
        <div *ngIf="!isLoading && (results$ | async).length === 0" class="d-flex w-100 justify-content-center">
          <p class="text-center mt-lg-5">
            <strong translate="no_media_found"></strong><br />
            <span translate="adjust_your_filters"></span>
          </p>
        </div>

        <ng-container *ngIf="displayMode === 'grid'">
          <div class="media-grid" *ngIf="!isLoading && (results$ | async).length > 0">
            <div class="card" *ngFor="let media of (results$ | async)" (click)="selectMedia(media)" [class.border-info]="isEdited(media)" [class.disabled]="!isAllowed(media)">
              <div class="bulk-select-checkbox" *ngIf="!isSelect">
                <input type="checkbox" [value]="media.id" [checked]="isBulkSelected(media)" (click)="toggleBulkSelect(media, $event)">
              </div>
              <div class="media-type-icon" *ngIf="media.thumbnail">
                <span [class]="'fa ' + mediaService.getMediaTypeIcon(media)"></span>
              </div>
              <button class="is-selected btn btn-info" *ngIf="isSelected(media)" (click)="deselectMedia(media, $event)">
                <i class="fa fa-check"></i>
                <i class="fa fa-minus"></i>
              </button>
              <img *ngIf="media.thumbnail" [src]="mediaService.getUrl(media, 'thumbnail')" class="card-img-top" [alt]="media.altText">
              <div class="thumbnail-placeholder bg-primary text-secondary" *ngIf="!media.thumbnail">
                <span [class]="'fa ' + mediaService.getMediaTypeIcon(media)"></span>
              </div>
              <div class="card-body">
                <p class="card-text" [title]="media.name">{{ media.name }}</p>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="displayMode === 'table'">
          <div>
            <table class="table table-hover table-vcenter" *ngIf="!isLoading && (results$ | async).length > 0">
              <thead class="bg-primary text-white">
                <tr *ngIf="!isSelect">
                  <td class="text-center">
                    <input 
                      type="checkbox" 
                      (click)="toggleBulkSelectAll($event)"
                      [indeterminate]="bulkSelectedMedia && bulkSelectedMedia.length > 0 && bulkSelectedMedia.length < (results$ | async).length"
                      [checked]="bulkSelectedMedia && bulkSelectedMedia.length === (results$ | async).length"
                    >
                  </td>
                  <td></td>
                  <td><strong>{{ 'title' | translate }}</strong></td>
                  <td><strong>{{ 'media_type' | translate }}</strong></td>
                  <td><strong>{{ 'file_type' | translate }}</strong></td>
                  <td><strong>{{ 'size' | translate }}</strong></td>
                  <td><strong>{{ 'uploaded_at' | translate }}</strong></td>
                  <td *ngIf="isSelect"></td>
                </tr>
              </thead>
              <tbody>
                <tr 
                  *ngFor="let media of (results$ | async)" 
                  (click)="selectMedia(media)" 
                  [class.edited]="isEdited(media)" 
                  [class.disabled]="!isAllowed(media)"
                >
                  <td class="text-center" *ngIf="!isSelect">
                    <input type="checkbox" [value]="media.id" [checked]="isBulkSelected(media)" (click)="toggleBulkSelect(media, $event)">
                  </td>
                  <td>
                    <img *ngIf="media.thumbnail" [src]="mediaService.getUrl(media, 'thumbnail')" class="thumbnail-sm" [alt]="media.altText">
                    <div class="thumbnail-sm border bg-primary text-dark" *ngIf="!media.thumbnail">
                      <span [class]="'fa ' + mediaService.getMediaTypeIcon(media)"></span>
                    </div>
                  </td>
                  <td>
                    <span [title]="media.name">{{ media.name }}</span>
                  </td>
                  <td>
                    <span>{{ mediaService.getMediaTypeLabel(media) | translate }}</span>
                  </td>
                  <td>
                    <span>{{ media.mime }}</span>
                  </td>
                  <td>
                    <span class="text-nowrap">{{ media.size * 1024 * 1024 | fileSize }}</span>
                  </td>
                  <td>
                    <span>{{ media.uploadedAt | date:'dd.MM.yyyy HH:mm' }}</span>
                  </td>
                  <td *ngIf="isSelect">
                    <button class="is-selected btn btn-info" *ngIf="isSelected(media)" (click)="deselectMedia(media, $event)">
                      <i class="fa fa-check"></i>
                      <i class="fa fa-minus"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>

        <ngb-pagination 
          *ngIf="(perPage$ | async) < (total$ | async)"
          class="d-flex justify-content-center mt-3"
          maxSize="5"
          [rotate]="true"
          [collectionSize]="(total$ | async)" 
          [page]="(page$ | async)" [directionLinks]="false" 
          [pageSize]="(perPage$ | async)" 
          (pageChange)="pageChange($event)">
        </ngb-pagination>


      </div>
    </div>

    <medsurf-media-sidebar [media]="editedMedia"></medsurf-media-sidebar>

  </div>
</ng-container>


<!-- Media remove confirmation modal -->
<ng-template #ConfirmBulkRemoveDialog let-modal>
  <header class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">{{ 'bulk_remove_media' | translate }}</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Button')">
          <span aria-hidden="true">&times;</span>
      </button>
  </header>
  <div class="modal-body">
    <p>{{ 'bulk_remove_media_confirm' | translate }}</p>
    <ngb-accordion #acc="ngbAccordion">
      <ngb-panel type="light" *ngFor="let media of bulkSelectedMedia">
        <ng-template ngbPanelTitle>
          <img *ngIf="media.thumbnail" [src]="mediaService.getUrl(media, 'thumbnail')" class="thumbnail-sm mr-3" [alt]="media.altText">
          <div class="thumbnail-sm border bg-primary text-dark mr-3" *ngIf="!media.thumbnail">
            <span [class]="'fa ' + mediaService.getMediaTypeIcon(media)"></span>
          </div>
          <span>{{ media.name }}</span>
        </ng-template>
        <ng-template ngbPanelContent *ngIf="mediaService.getRelatedNodes(media); let related">
          <ng-container *ngIf="related.length > 0">
            <p>{{ 'media_remove_confirm_with_links' | translate }}</p>
            <ul class="ml-4">
              <li *ngFor="let node of related">
                  <a target="_blank" [href]="'/node/' + node.id">{{ node.page.title }}</a>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="related.length === 0">
            <p>{{ 'is_not_used' | translate }}</p>
          </ng-container>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <footer class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">{{ 'cancel' | translate }}</button>
      <button type="button" class="btn btn-danger" (click)="modal.close()">{{ 'delete' | translate }}</button>
  </footer>
</ng-template>