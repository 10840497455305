import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DEFAULT_VALUES } from '../../../../common/constants/default-values';
import { takeUntil } from 'rxjs/operators';
import { ActionCompletion, Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { Slide, User, AlertType, populateNodeSettings, NodeSettings } from '@medsurf/models';
import { AddAlert, GenerateSlidePDF, GenerateSlidePDFSuccess, IndexChanged, PathModel, SlideChanged } from '@medsurf/actions';
import { MediaService } from '@medsurf/services';
import { getRouteString } from '@medsurf/helpers';
import { NavigationState } from '@medsurf/state';
import { Observable } from 'tinymce';

@Component({
  selector: 'medsurf-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss']
})
export class MetadataComponent implements OnInit, OnDestroy {
  @Output() localDeleteSlide = new EventEmitter();

  @Input() slide: Slide;
  @Input() user: User;
  @Input() users: User[];
  @Input() path: PathModel;
  @Input() props;
  @Input() sequenceId: number;
  @Input() layerId: number;

  @Select(NavigationState.settingsInherited)
  public settingsInherited$: Observable<any>;

  public settingsDefault: NodeSettings = populateNodeSettings({});

  public generating = false;

  private _destroyed = new Subject<boolean>();

  constructor(
    private store: Store,
    private actions$: Actions,
    public mediaService: MediaService,
    @Inject(DEFAULT_VALUES) public defaultValues
  ) {}

  ngOnInit() {
    this.actions$.pipe(
      takeUntil(this._destroyed),
      ofActionCompleted(GenerateSlidePDFSuccess)
    ).subscribe(async (completion: ActionCompletion<GenerateSlidePDFSuccess>) => {
      if(completion.action.forAuthor){
        this.slide.pdfMediaAuthor = completion.action.media;
      }
      else {
        this.slide.pdfMedia = completion.action.media;
      }
      this.store.dispatch(new AddAlert({
        message: 'slide_pdf_generated',
        duration: 3,
        dismissable: true,
        type: AlertType.SUCCESS
      }));
      this.generating = false;
    });
  }

  onChange() {
    this.store.dispatch(new SlideChanged());
  }

  onRouteChange() {
    this.store.dispatch(new IndexChanged([this.path.slide]));
  }

  setRouteFromTitle() {
    this.path.slide.route = getRouteString(this.slide.title);
    this.onRouteChange();
  }

  public onHideAnnotationsClear() {
    const settings = Object.assign({}, this.path.slide.settings);
    delete settings.hideAnnotations;
    this.path.slide.settings = settings;
    this.store.dispatch(new IndexChanged([this.path.slide]));
  }

  public onHideAnnotationsChange(value) {
    this.path.slide.settings = Object.assign({}, this.path.slide.settings, { hideAnnotations: value });
    this.store.dispatch(new IndexChanged([this.path.slide]));
  }

  public isUndefined(value): boolean {
    return typeof value === 'undefined';
  }

  onBackgroundChange(event) {
    const background = this.slide?.layers?.[this.layerId]?.images?.[this.sequenceId]?.background || {};
    background.color = event;
    for (const layer of this.slide.layers) {
      for (const image of layer.images) {
        image.background = { ...background };
      }
    }
    this.onChange();
  }

  public compareUser(a: User, b) {
    return a && b ? a.email === b.email : a === b;
  }

  public generatePdf() {
    this.generating = true;
    this.store.dispatch(new GenerateSlidePDF(this.slide, false));
  }

  public generateAuthorPdf() {
    this.generating = true;
    this.store.dispatch(new GenerateSlidePDF(this.slide, true));
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
