import Symbol from './symbol';
import paper from 'paper';

class Arrow extends Symbol {
  private _path: any;

  constructor(format, target, marker) {
    super(format, target, marker);
    this._init();
  }

  _init() {
    this.draw();
  }

  draw() {
    if (this._path) {
      this._path.remove();
      this._path = null;
    }
    const factor = (this._marker._model?.freeFormStyle?.strokeWidth || 3) / 3;
    this._path = new paper.Path();
    this._path.add(new paper.Point(6.5 * factor, 2.27 * factor));
    this._path.add(new paper.Point(13 * factor, 16 * factor));
    this._path.add(new paper.Point(6.5 * factor, 11 * factor));
    this._path.add(new paper.Point(0 * factor, 16 * factor));
    this._path.closed = true;
    this._path.style = {
      fillColor: this._marker && this._marker._model && this._marker._model.freeFormStyle && this._marker._model.freeFormStyle.color ?
        this._marker._model.freeFormStyle.color :
        this._format.strokeColor
    };
    this._element.addChild(this._path);
    this._element.position = this._target;
  }

  setScale() {
    this.draw();
    const factor = (this._marker && this._marker._model.source && this._marker._model.source.zoom && this._marker._imageScale === 1 ?
        this._marker._container.getStrokeWidth(this._marker._model.source.zoom) || this._format.strokeWidth :
        this._format.strokeWidth)
        / 2 / this._marker._imageScale;

    this._path.scale(factor);
  }

  get element() {
    return this._element;
  }

  get path() {
    return this._path;
  }
}

export default Arrow;
