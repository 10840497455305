import { MediaService } from '@medsurf/services';
import { MediaType, Media } from '@medsurf/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Editor } from 'tinymce';
import { ChangeEventCallback, OnAction, OnSetup, ToolbarToggleButtonInstanceApi } from '../plugins';
import { openDialog } from '../media-base';
import { NgZone } from '@angular/core';
import { environment } from '../../../../../environments/environment';

const nodeChangeEventCallback: ChangeEventCallback = (buttonApi: ToolbarToggleButtonInstanceApi, editor: Editor) => async (eventApi) => {
  const element = (<HTMLElement>eventApi.element);
  const isAudio = element?.tagName?.toLowerCase() === 'audio' || element?.dataset.mceObject === 'audio';;

  buttonApi.setEnabled(isAudio || editor.selection.getContent() === '');
  buttonApi.setActive(isAudio);
};

const getOnSetup: OnSetup = (editor: Editor) => (buttonApi: ToolbarToggleButtonInstanceApi) => {
  editor.on('NodeChange', nodeChangeEventCallback(buttonApi, editor));
  return () => {
    editor.off('NodeChange', nodeChangeEventCallback(buttonApi, editor));
  };
};

const getOnAction: OnAction = (editor: Editor, zone: NgZone, modalService: NgbModal, mediaService: MediaService) =>
  async (buttonApi: ToolbarToggleButtonInstanceApi) => {
    zone.run(async () => {
      const allowedTypes: MediaType[] = [MediaType.AUDIO];

      const node = <HTMLElement>editor.selection.getNode();
      const isAudio = node?.tagName?.toLowerCase() === 'audio';
 
      const selected: Media[] = isAudio ? [{
        id: node.dataset.id,
        type: MediaType.AUDIO
      }] : [];

      try {
        const medium: Media = await openDialog(allowedTypes, modalService, selected);
        const url = environment.config.endpointUrl + 'v1/id2url/' + medium.id;

        if (isAudio) {
          editor.dom.setAttribs(node, {
            src: url,
            'data-id': medium.id,
            'data-mce-src': url
          });
          editor.fire('change');
        } else {
          const newAudio: HTMLAudioElement = document.createElement('audio');
          newAudio.setAttribute('src', url);
          newAudio.setAttribute('data-id', medium.id);
          newAudio.setAttribute('controls', '');
          newAudio.setAttribute('controlsList', 'nodownload');
          editor.insertContent(newAudio.outerHTML);
        }
      } catch (err) {
        return;
      }
    });
  };

export { getOnAction, getOnSetup };
