import { EventEmitter } from 'events';

class Element extends EventEmitter {
  _element: any;
  protected _state: any;
  protected _isMovable: boolean;
  protected _isSelected: boolean;
  private _id: any;

  constructor(protected _model: any) {
    super();
    this._element = null;
    this._state = undefined;
    this._isMovable = true;
    this._isSelected = null;
  }

  setPosition(x, y) {
    this._element.position.x = x;
    this._element.position.y = y;
  }

  cleanUp() {
    if (this._element) {
        this._element.remove();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resize() {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  arrange() {}

  set state(state) {
    this._state = state;
    switch (state) {
      case 'visible':
        this._element.visible = true;
        break;
      case 'hidden':
        this._element.visible = false;
        break;
      default:
        break;
    }
  }

  get state() {
    return this._state;
  }

  set id(id) {
    this._id = id;
  }

  get id() {
    return this._id;
  }

  set model(model) {
    this._model = model;
  }

  get model() {
    return this._model;
  }

  get element() {
    return this._element;
  }

  set isMovable(id) {
    this._isMovable = id;
  }

  get isMovable() {
    return this._isMovable;
  }

  get isSelected() {
    return this._isSelected;
  }

  select() {
    this._isSelected = true;
  }

  unselect() {
    this._isSelected = false;
  }
}

export default Element;
