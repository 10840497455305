import { Injectable } from '@angular/core';
import { SlideNode } from '@medsurf/models';

interface SlideBookmark {
  id: string;
  title: string;
}

interface BookmarkStore {
  slides: {
    [key: string]: SlideBookmark;
  };
}


@Injectable({
  providedIn: 'root'
})
export class BookmarkService {

  private _key = 'bookmarks';
  private _store: BookmarkStore;

  public isSlideBookmarked(slide: SlideNode): boolean {
    const store = this._load();
    return !!store.slides[slide.id];
  }

  public toggleSlide(slide: SlideNode): void {
    const store = this._load();
    if (this.isSlideBookmarked(slide)) {
      delete store.slides[slide.id];
    } else {
      store.slides[slide.id] = {
        id: slide.id,
        title: slide.page.title
      };
    }
    this._store = store;
    this._save();
  }

  private _init(): void {
    this._store = {
      slides: {}
    };
  }

  private _load(): BookmarkStore {
    if (!this._store) {
      try {
        const storeString = localStorage.getItem(this._key);
        if (storeString) {
          this._store = JSON.parse(storeString);
        } else {
          this._init();
        }
      } catch (err) {
        this._init();
      }
    }
    return this._store;
  }

  private _save(): void {
    localStorage.setItem(this._key, JSON.stringify(this._store));
  }
}
