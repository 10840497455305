import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Media, Page } from '@medsurf/models';
import { Store } from '@ngxs/store';

@Component({
  selector: 'medsurf-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent {
  @Input() item: Page & Media;

  @Output()
  public changeEmitter = new EventEmitter<void>();

  private dirty = false;

  public editorToolbarExcludes = [
    'mediaimage',
    'mediapdf',
    'mediaaudio',
    'mediavideo',
    'marker'
  ];

  constructor(
    private store: Store
  ) {
  }


  localOnChange() {
    this.dirty = true;
  }

  localEmitChange() {
    if (this.dirty) {
      this.dirty = false;
      this.changeEmitter.emit();
    }
  }
}
