import Circle from './circle';
import Dot from './dot';
import Arrow from './symbol/arrow';

class End {
  private _element: any;

  constructor (private _endType, private _format, private _target, private _marker) {
    this._element = null;
    this._init();
  }

  _init () {
    this.draw();
  }

  draw() {
    switch (this._endType) {
      case 'circle':
        this._element = new Circle(this._format, this._target, this._marker);
        this._element.element.marker = this._marker;
        break;
      case 'arrow':
        this._element = new Arrow(this._format, this._target, this._marker);
        break;
      case undefined:
      case 'none':
        this._element = new Dot(this._format, this._target, this._marker);
        this._element.element.visible = false;
        break;
      default:
      case 'dot':
        this._element = new Dot(this._format, this._target, this._marker);
        break;
    }
    this._element.marker = this._marker;
  }

  setScale(scale) {
    if (this._endType === 'none') {
      return;
    }
    this._element.setScale(scale);
  }

  get element() {
    return this._element;
  }

  cleanUp() {
    if (this._element) {
      this._element.cleanUp();
    }
  }
}

export default End;
