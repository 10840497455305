import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { UploadService } from '../../services/upload.service';
import { MediaAccess, CopyrightPosition, Media, AlertType, Base, Copyright, MediaVideo, Stain, Node } from '@medsurf/models';
import { Settings, SETTINGS, MediaService } from '@medsurf/services';
import { ConstantState } from '@medsurf/state';
import { AddAlert, AddWatermark, AddWatermarkSuccess, GetCopyrights, GetStains, UpdateMedia, UpdateMediaSuccess,
  DeleteMedia } from '@medsurf/actions';
import { cloneDeep } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'medsurf-media-sidebar',
  templateUrl: './media-sidebar.component.html',
  styleUrls: ['./media-sidebar.component.scss']
})
export class MediaSidebarComponent implements OnChanges, OnInit, OnDestroy {

  @Input() media: Media;
  public mediaCopy: Media;

  public showMetadata: boolean;

  public relatedNodes: Node[];
  public accessOptions: object;

  public isSaving: boolean;
  public showSaved: boolean;

  private _destroyed = new Subject<boolean>();

  @Select(ConstantState.copyrights)
  public copyrights$: Observable<Copyright[]>;

  @Select(ConstantState.stains)
  public stains$: Observable<Stain[]>;

  @ViewChild('ConfirmRemoveDialog') confirmRemoveDialog: TemplateRef<any>;
  @ViewChild('videoPlayer') videoPlayer;

  constructor(
    @Inject(SETTINGS) private settings: Settings,
    private uploadService: UploadService,
    public mediaService: MediaService,
    private modalService: NgbModal,
    private store: Store,
    private actions$: Actions
  ) {
  }

  ngOnInit(): void {
    this.accessOptions = MediaAccess;

    this.store.dispatch(new GetCopyrights());
    this.store.dispatch(new GetStains());

    this.actions$.pipe(takeUntil(this._destroyed), ofActionCompleted(UpdateMediaSuccess)).subscribe(async () => {
      this.isSaving = false;
      this.showSaved = true;
      setTimeout(() => {
        this.showSaved = false;
      }, 2000);
    });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionCompleted(AddWatermarkSuccess)).subscribe(async () => {
      this.store.dispatch(new AddAlert({
        message: 'watermark_updated',
        duration: 3,
        dismissable: true,
        type: AlertType.SUCCESS
      }));
    });
  }

  ngOnChanges(): void {
    if (this.media) {
      const media = cloneDeep(this.media);
      this.relatedNodes = this.mediaService.getRelatedNodes(media);
      delete media.mediaInImages;
      delete media.mediaInFolders;
      delete media.mediaInSlideThumbs;
      this.mediaCopy = media;
    } else {
      this.mediaCopy = null;
    }
  }

  toggleMetadata(): void {
    this.showMetadata = !this.showMetadata;
  }

  remove(): void {
    this.modalService.open(this.confirmRemoveDialog, {size: 'xs'}).result.then(() => {
      this.store.dispatch(new DeleteMedia([this.mediaCopy]));
    });
  }

  onChange(): void {
    this.isSaving = true;
    setTimeout(() => {
      this.update();
    });
  }

  update(): void {
    this.store.dispatch(new UpdateMedia(this.mediaCopy));
  }

  replace(): void {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = this.mediaCopy.mime;
    input.addEventListener('change', async () => {
      if (input.files.length > 0) {
        const file = input.files[0];
        const media = await this.uploadService.uploadFile(file, [{ name: 'mediaId', value: this.mediaCopy.id }]);
        this.mediaCopy = media;
        this.update();
      }
    });
    input.click();
  }

  async uploadVideoFrame() {
    const video = this.videoPlayer.nativeElement;
    const canvas =  document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const blob: Blob = await new Promise((resolve) => {
      canvas.toBlob(resolve, 'image/jpeg', 80);
    });
    const file = new File([blob], 'video_frame', { type: blob.type });

    const media: MediaVideo = await this.uploadService.uploadFile(file, [
      { name: 'mediaId', value: this.mediaCopy.id }
    ], 'v1/poster');

    this.store.dispatch(new AddAlert({
      message: 'video_poster_updated',
      duration: 3,
      dismissable: true,
      type: AlertType.SUCCESS
    }));
    (this.mediaCopy as MediaVideo) = media;
    this.update();
  }

  async uploadCustomFrame() {
    const input = document.createElement('input');
    input.type = 'file';
    input.addEventListener('change', async () => {
      if (input.files.length > 0) {
        const file = input.files[0];
        const media: MediaVideo = await this.uploadService.uploadFile(file, [
          { name: 'mediaId', value: this.mediaCopy.id }
        ], 'v1/poster');
        this.store.dispatch(new AddAlert({
          message: 'video_poster_updated',
          duration: 3,
          dismissable: true,
          type: AlertType.SUCCESS
        }));
        (this.mediaCopy as MediaVideo) = media;
        this.update();
      }
    });
    input.click();
  }

  addWatermark(position: CopyrightPosition) {
    this.store.dispatch(new AddWatermark(this.mediaCopy, position));
  }

  removeWatermark() {
    this.store.dispatch(new AddWatermark(this.mediaCopy, null));
  }

  addCopyright() {
    this.mediaCopy.copyrights.push({});
  }

  removeCopyright(index) {
    this.mediaCopy.copyrights = this.mediaCopy.copyrights.filter((v, i) => i !== index);
    this.onChange();
  }

  removePoster() {
    (this.mediaCopy as MediaVideo).poster = null;
    this.onChange();
  }

  compareConstant(a: Base, b: Base) {
    return a && b ? a.id === b.id : a === b;
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
