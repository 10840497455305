import paper from 'paper';
import Element from '../element';

class Bitmap extends Element {
  private _selection: any;
  private layerId: number;
  private _image: any;
  private _originalImageWidth: any;
  private _originalImageHeight: any;
  private oldScale: any;

  constructor(public $http, model, private _localPaper, private _mainLayer, public settings, private _container, private _format) {
    super(model);
    this.settings = settings;
    this._isSelected = false;
    this._selection = null;
    this.$http = $http;
    this.layerId = 0;
    this.init();
  }

  init() {
    this._element = new this._localPaper.Group();
    this.state = 'hidden';
    this._mainLayer.addChild(this._element);
  }

  loadFile({layer}) {
    this.layerId = Number.isInteger(layer) ? layer : this.layerId;
    const path = this._model.images[this.layerId].original,
        // eslint-disable-next-line @typescript-eslint/no-this-alias
      self = this;

    if (!path || path.length === 0) {
      return;
    }

    this.$http
      .get(this.settings.endpointUrl + 'v1/file/public' + path, {
        responseType: 'arraybuffer'
      })
      .then(function (data) {
        self._handleData(data.data);
      })
      .catch(function (data, status) {
        self._handleError(status);
      });
  }

  _handleData(response) {
    const uInt8Array = new Uint8Array(response);
    let i = uInt8Array.length;
    const binaryString = new Array(i);

    while (i--) {
      binaryString[i] = String.fromCharCode(uInt8Array[i]);
    }
    const data = binaryString.join(''),
      base64 = window.btoa(data),
      imageData = 'data:image/jpeg;base64,' + base64;
    this.drawImage(imageData);
  }

  _handleError(status) {
    console.log('Request failed with status: ' + status);
    this.emit('error');
    this.drawImage(null);
  }

  drawImage(imageData) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this._image = new this._localPaper.Raster(
      imageData,
      new paper.Point(
        this.model.dimensions.width / 2,
        this.model.dimensions.height / 2
      )
    );
    this._originalImageWidth = this._image.width;
    this._originalImageHeight = this._image.height;
    this._element.addChild(this._image);
    this._image.sendToBack();
    this._image.selected = false;
    this._image.class = this;
    this._image.selectedColor = '#DEDEDEDE';
    this._image.blendMode = this._format.blendMode;
    this._image.scale(this.oldScale = this._model.scale);
    this._localPaper.settings.handleSize = 6;
    setTimeout(function () {
      self.state = 'visible';
      self.emit('created');
    }, 100);
  }

  select() {
    if (!this._isMovable) {
      return;
    }
    this._isSelected = true;
    this.arrange();
  }

  unselect() {
    this._isSelected = false;
    this.arrange();
  }

  drawSelection() {
    this.removeSelection();
    this._selection = new this._localPaper.Path.Rectangle(this._image.bounds);
    this._selection.strokeColor = this._format.selection.color;
    this._selection.strokeWidth = this._container.calculateFixDimensions(this._format.selection.strokeWidth);
    this._selection.fillColor = this._format.selection.color;
    this._selection.fillColor.alpha = this._format.selection.opacity;
    this._selection.locked = true;
    this._element.addChild(this._selection);
  }

  removeSelection() {
    if (this._selection) {
      this._selection.remove();
      this._selection = null;
    }
  }

  resize(width?, height?) {
    width = this._originalImageWidth;
    height = this._originalImageHeight;
  }

  arrange() {
    const coords = this._container.getAbsoluteCoords(this._model.offset.x, this._model.offset.y);
    if (this._isSelected) {
      this.drawSelection();
    } else {
      this.removeSelection();
    }
    coords.x += (this._image.width * this._model.scale) / 2;
    coords.y += (this._image.height * this._model.scale) / 2;

    if (Number.parseFloat(this._model.scale) > 0) {
      this._image.scale(1 / this.oldScale);
      this._image.scale(this.oldScale = this._model.scale);
      this._element.position = coords;
    }
    this._image.blendMode = this._format.blendMode;
  }

  cleanup() {
    this._element.removeChildren();
    super.cleanUp();
  }

  get scale() {
    return this._element.matrix.a;
  }

  set format(format) {
    this._format = format;
  }

  get format() {
    return this._format;
  }
}

export default Bitmap;
