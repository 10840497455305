import { Component, Input } from '@angular/core';
import { SubjectNode } from '@medsurf/models';
import { ThemeColorService } from '../settings/theme-color/theme-color.service';

@Component({
  selector: 'medsurf-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent {

  @Input()
  public currentItem: SubjectNode;

  @Input()
  public isSubject: boolean;

  @Input()
  public isBlockSelected: boolean;

  constructor(public themeColorService: ThemeColorService) {
  }
}
