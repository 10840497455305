import { Annotation, AnnotationType, Defaults, FreeForm } from '@medsurf/models';

class Format {
  public backgroundColor: string;
  private fontColor: string;
  private fontSize: number;
  private fillColor: string;
  private strokeColor: string;
  private blendMode: any;
  private justification: any;
  private pivot: any;
  private circleRadius: number;

  constructor (defaults?: Defaults) {
    this.defaults();
    if (defaults) {
      if (defaults.labelColor) {
        this.fontColor = defaults.labelColor;
      }
      if (defaults.labelFontSize) {
        this.fontSize = defaults.labelFontSize;
      }
      if (defaults.pointerColor) {
        this.fillColor = defaults.pointerColor;
        this.strokeColor = defaults.pointerColor;
      }
    }
  }

  defaults() {
    Object.assign(this, this.getDefaults());
  }

  setBackgroundFormat(image) {
    if (!image.background) { return; }
    if (image.background.color) {
      this.backgroundColor = image.background.color;
    }
    if (image.background.blendMode) {
      this.blendMode = image.background.blendMode;
    }
  }

  setMarkerFormat(marker: Annotation) {
    if (marker.label) {
      if (marker.label.fontSize) {
        this.fontSize = marker.label.fontSize;
      }
      if (marker.label.color) {
        this.fontColor = marker.label.color;
      }
      if (marker.label.align) {
        this.justification = marker.label.align;
      }
      if (marker.label.pivot) {
        this.pivot = marker.label.pivot;
      }
    }
    if (marker.type !== AnnotationType.FREE_FORM && marker.targets.length > 0) {
      if (marker.targets[0].endType && marker.targets[0].endRadius) {
        this.circleRadius = marker.targets[0].endRadius;
      }
      if (marker.targets.some(target => !!target.color)) {
        this.strokeColor = marker.targets.find(target => !!target.color).color;
      }
    }
    if (marker.type === AnnotationType.FREE_FORM && (<FreeForm>marker).target) {
      const freeFrom: FreeForm = marker;
      if (freeFrom.target.endRadius) {
        this.circleRadius = freeFrom.target.endRadius;
      }
      if (freeFrom.target.color) {
        this.strokeColor = freeFrom.target.color;
      }
    }
  }

  getDefaults() {
    return {
      canvas: {
        width: 600, // 630
        height: 600 // 580
      },
      color: '#000000',
      backgroundColor: '#fafafa',
      fontColor: '#000000',
      fontFamily: 'Figtree',
      fontWeight: '600',
      fontSize: 12,
      leading: 1,
      strokeColor: '#000000',
      blendMode: 'normal',
      strokeWidth: 1,
      fillColor: '#fafafa',
      justification: 'left',
      pivot: '',
      selection: {
        radius: 4,
        strokeWidth: 2,
        color: '#4A90E2',
        opacity: 0.4
      },
      border: {
        fillColor: '#ffffff',
        strokeColor: '#000000',
        strokeWidth: 1,
        cornerRadius: 2
      },
      circleRadius: 4,
    };
  }
}

export default Format;
