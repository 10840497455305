import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {DEFAULT_VALUES} from '../../constants/default-values';
import { SlideChanged, SetLayerNumber, SetSequenceNumber } from '@medsurf/actions';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Image, Layer, Slide, Window } from '@medsurf/models';
import { SlideState } from '@medsurf/state';
import { DragulaService } from 'ng2-dragula';
import { v4 } from 'uuid';

@Component({
  selector: 'medsurf-sequence-layer',
  templateUrl: './sequence-layer.component.html',
  styleUrls: ['./sequence-layer.component.scss']
})
export class SequenceLayerComponent {
  @Select(SlideState.layerNumber)
  public layerNumber$: Observable<number>;

  @Output() navigationChange = new EventEmitter();

  @Input() slide: Slide;

  constructor(
    private store: Store,
    private translate: TranslateService,
    private dragulaService: DragulaService,
    @Inject(DEFAULT_VALUES) public defaultValues
  ) {
    dragulaService.destroy("LAYER");
    dragulaService.createGroup("LAYER", {
      moves: (el, container, handle) => {
        const h = el.querySelector('.handle');
        return handle.classList.contains('handle') || h.contains(handle);
      }
    });
  }

  onChange() {
    this.slide.layers.forEach(layer => {
      if (!layer.window) layer.window = null;
    });
    this.store.dispatch(new SlideChanged());
  }

  public onLayersChanged() {
    this.slide.layers.forEach((layer, idx) => {
      layer.position = idx;
    });
    this.navigationChange.emit();
  }

  public setLayerId(layerNumber: number) {
    this.store.dispatch(new SetLayerNumber(layerNumber));
    this.store.dispatch(new SetSequenceNumber(0));
  }

  public editLayer(layerInput) {
    layerInput.readOnly = false;
    layerInput.select();
  }

  public sendLayer(layerInput, layer) {
    layer.label = layerInput.value;
    layerInput.readOnly = true;
    this.navigationChange.emit();
  }

  public editShortLayer(layerShortInput) {
    layerShortInput.readOnly = false;
    layerShortInput.select();
  }

  public sendShortLayer(layerShortInput, layer) {
    layer.shortLabel = layerShortInput.value;
    layerShortInput.readOnly = true;
    this.navigationChange.emit();
  }

  public addLayer() {
    // add layer
    const newImage: Image = {
      id: v4(),
      background: {
        color: 'Standardwert',
      },
      annotations: [],
      pois: [],
      sequenceNumber: 0
    };

    const newLayer: Layer = {
      id: v4(),
      default: false,
      window: Window.WEICHTEILFENSTER,
      images: [newImage],
      position: this.slide.layers.length
    };
    this.slide.layers.push(newLayer);

    this.navigationChange.emit();
  }

  public deleteLayer(layerId, layer) {
    this.translate.get(['confirm_delete_layer']).subscribe((translations) => {
      if (confirm(translations.confirm_delete_layer)) {
        this.setLayerId(0);
        this.slide.layers.splice(layerId, 1);
        if (layer.$parent) {
          layer.$parent.remove(layer);
        }
        this.navigationChange.emit();
      }
    });
  }
}
