import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DEFAULT_VALUES } from '../../../../common/constants/default-values';
import {
  Slide,
  LinkType,
  Media
} from '@medsurf/models';
import { MediaState } from '@medsurf/state';
import { MediaService } from '@medsurf/services';
import { SlideChanged } from '@medsurf/actions';
import { Store } from '@ngxs/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaDialogComponent } from '../../../../common/media-dialog/media-dialog.component';

@Component({
  selector: 'medsurf-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent {
  @Output() changeMedia = new EventEmitter();

  @Input() slide: Slide;
  @Input() sequenceId: number;
  @Input() layerId: number;
  @Input() props: any;

  public linkTypes: LinkType[] = [LinkType.SITE, LinkType.SLIDE];

  constructor(
    private store: Store,
    private modalService: NgbModal,
    public mediaService: MediaService,
    @Inject(DEFAULT_VALUES) public defaultValues
  ) {
  }

  get media() {
    return this.slide?.layers?.[this.layerId]?.images?.[this.sequenceId]?.media || null;
  }

  set media(media: Media) {
    this.slide.layers[this.layerId].images[this.sequenceId].media = media;
    this.store.dispatch(new SlideChanged());
  }

  onChange() {
    this.store.dispatch(new SlideChanged());
  }

  onBackgroundChange(event) {
    this.slide.layers[this.layerId].images[this.sequenceId].background =
      this.slide.layers[this.layerId].images[this.sequenceId].background || {};
    this.slide.layers[this.layerId].images[this.sequenceId].background.color = event;
    this.store.dispatch(new SlideChanged());
  }

  public selectMedia(): void {
    const modalRef = this.modalService.open(MediaDialogComponent, {scrollable: true, size: 'xxl'});
    modalRef.componentInstance.selected = this.media ? [this.media] : null;
    modalRef.result.then((media: Media) => {
      this.media = media;
      this.changeMedia.emit(this.media);
    }).catch(() => {
      if (this.media) {
        const media = this.store.selectSnapshot(MediaState.results).find(r => r.id === this.media.id);
        this.slide.layers[this.layerId].images[this.sequenceId].media = media;
        this.changeMedia.emit(this.media);
      }
    }).finally(() => {
      if (this.media && this.layerId === 0) {
        let defaultIndex = this.slide.layers[this.layerId].images.findIndex((image) => image.default);
        if (defaultIndex === -1) {
          defaultIndex = 0;
        }
        if (this.sequenceId === defaultIndex) {
          this.slide.thumbnailMedia = this.media;
        }
      }
    });
  }


  public removeMedia() {
    this.media = null;
    this.changeMedia.emit(this.media);
  }

  public selectLinkType(linkType: LinkType) {
    this.slide.layers[this.layerId].images[this.sequenceId].link.type = linkType;
    this.onChange();
  }
}
