<div class='row'>
  <div class='col-sm-12'>
    <div class='form-group'>
      <label for="poi-name" translate="title"></label>
      <input id="poi-name" name="poi-name" type="text" class="form-control input-md" [(ngModel)]="poi.name" (ngModelChange)="changeEmitter.emit()"/>
    </div>
  </div>
</div>

<div class="form-row">

  <div class="input-group col-sm-12">
    <input type="text" disabled class="form-control" 
      [value]="boundsString()">

    <div class="input-group-append">
      <button class="btn btn-secondary" (click)="updatePoiPosition()" [title]="'set_poi' | translate">
        <i class="fa fa-fw fa-arrows-to-circle"></i>
      </button>
    </div>
  </div>

</div>

