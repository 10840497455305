<table class="table table-hover table-vcenter mt-2" #table>
  <thead class="bg-primary text-white">
  <tr>
    <th><span></span></th>
    <th>
      <input
        type="checkbox"
        (click)="toggleBulkSelectAll($event)"
        [indeterminate]="bulkSelectedSlideIds && bulkSelectedSlideIds.length > 0 && bulkSelectedSlideIds.length < selectedBlock.children?.length"
        [checked]="bulkSelectedSlideIds && bulkSelectedSlideIds.length === selectedBlock.children?.length"
      >
    </th>
    <th><span></span></th>
    <th><span translate="slide_id"></span></th>
    <th><span translate="title"></span></th>
    <th><span translate="resource_type"></span></th>
    <th><span translate="modality"></span></th>
    <th><span translate="status"></span></th>
  </tr>
  </thead>
  <tbody id="table-body">
    <tr *ngFor="let slide of selectedBlock.children"
        [class.d-none]="slide.deleted"
        (click)="selectSlide.emit({slide: slide})"
        [id]="slide.id">
      <td><b>{{ slide.position + 1 }}</b></td>
      <td>
        <input type="checkbox" [value]="slide.id" [checked]="isBulkSelected(slide)" (click)="bulkSelect($event, slide)">
      </td>
      <td><img class="slides" [src]="slide.page.thumbnailMedia ? mediaService.getUrl(slide.page.thumbnailMedia, 'thumbnail') : '/assets/images/no-image.png'"/>
      </td>
      <td>{{slide.page.slideId}}</td>
      <td>{{slide.page.title}}</td>
      <td>{{mediaService.getMediaTypeLabel({ type: slide.page.mediaType }) | translate}}</td>
      <td>{{slide.page.modality?.name}}</td>
      <td>{{slide.page.status | translate}}</td>
    </tr>
  </tbody>
</table>

