<form class="form" *ngIf="marker && isFreeform(marker)">

  <!-- Type -->
  <div class="form-group">
    <label for="shape" translate="shape_freeform"></label>
    <select id="shape" name="shape" class="form-control input-md" [(ngModel)]="marker.shape" (ngModelChange)="onChange()">
      <option *ngFor="let freeForm of typeOptions" [value]="freeForm.value">{{freeForm.name}}</option>
    </select>
  </div>

  <hr />

  <!-- Selftest use -->
  <fieldset *ngIf="marker.shape === 'interactiveArea'">
    <div class="form-group">
      <input name="useForSelftest" type="checkbox" [checked]="!marker.selfTest.ignore" (change)="onSelfTestChange($event)"> {{ 'use_for_selftest' | translate }}
      <hr/>
    </div>
  </fieldset>

  <!-- Shape Ellipse / Rectangle / Arrow / Triangle -->
  <fieldset *ngIf="marker.shape === 'ellipse' || marker.shape === 'rectangle' || marker.shape === 'arrow' || marker.shape === 'triangle'">

    <div class='row'>
      <div class='col-sm-6'>
        <div class='form-group'>
          <label for="shapeHeight" translate="image_height"></label>
          <input id="shapeHeight" name="shapeHeight" class="form-control input-md" type="number" [(ngModel)]="marker.freeFormStyle.height" (ngModelChange)="onChange()"/>
        </div>
      </div>
      <div class='col-sm-6'>
        <div class='form-group'>
          <label for="shapeWidth" translate="image_width"></label>
          <input id="shapeWidth" name="shapeWidth" class="form-control input-md" type="number"  [(ngModel)]="marker.freeFormStyle.width" (ngModelChange)="onChange()"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class='col-sm-12'>
        <div class='form-group'>
          <label for="shapeAngle" translate="freeform_angle"></label>
          <input type="range" class="form-control-range" id="shapeAngle" name="shapeAngle" [(ngModel)]="marker.freeFormStyle.angle" (ngModelChange)="onChange()" min="0" max="360" step="45">
        </div>
      </div>
    </div>

    <!-- TODO fix skew - angle
    <div class="row">
      <div class='col-sm-12'>
        <div class='form-group'>
          <label for="shapeSkewAngle" translate="freeform_skew"></label>
          <mv-slider id="shapeSkewAngle" name="shapeSkewAngle" [(value)]="marker.freeFormStyle.skewAngle" (valueChange)="onChange()" [min]="-45" [max]="45" [tooltip]="hide" [step]="1" [ticksLabels]="grad" ></mv-slider>
        </div>
      </div>
    </div>
    -->

    <hr />

  </fieldset>

  <!-- Shape Line -->
  <fieldset *ngIf="marker.shape === 'line'">

    <div class='row'>
      <div class='col-sm-6'>
        <div class='form-group'>
          <label for="lineY" translate="start_y"></label>
          <input id="lineY" name="lineY" class="form-control input-md" type="number" [(ngModel)]="marker.source.position.y" (ngModelChange)="onChange()"/>
        </div>
      </div>
      <div class='col-sm-6'>
        <div class='form-group'>
          <label for="lineX" translate="start_x"></label>
          <input id="lineX" name="lineX" class="form-control input-md" type="number" [(ngModel)]="marker.source.position.x" (ngModelChange)="onChange()"/>
        </div>
      </div>
    </div>

    <div class='row'>
      <div class='col-sm-6'>
        <div class='form-group'>
          <label for="lineHeight" translate="vector_y"></label>
          <input id="lineHeight" name="lineHeight" class="form-control input-md" type="number" [(ngModel)]="marker.freeFormStyle.height" (ngModelChange)="onChange()"/>
        </div>
      </div>
      <div class='col-sm-6'>
        <div class='form-group'>
          <label for="lineWidth" translate="vector_x"></label>
          <input id="lineWidth" name="lineWidth" class="form-control input-md" type="number" [(ngModel)]="marker.freeFormStyle.width" (ngModelChange)="onChange()"/>
        </div>
      </div>
    </div>

    <hr />

  </fieldset>

  <!-- Shape Free -->
  <fieldset *ngIf="marker.shape === 'free' || marker.shape === 'interactiveArea'">

    <button type="button" class="btn btn-secondary mb-2" (click)="activateFreeTool.emit(marker)">
      {{ 'draw_freeform' | translate }}
    </button>

    <div class='form-check'>
      <input class="form-check-input" id="freeClosePath" name="freeClosePath" type="checkbox" [(ngModel)]="marker.freeFormStyle.closePath" (ngModelChange)="onChange()">
      <label class="form-check-label" for="freeClosePath">{{ 'close_path' | translate }}</label>
    </div>

    <div class='form-check'>
      <input class="form-check-input" id="freeSmooth" name="freeSmooth" type="checkbox" [(ngModel)]="marker.freeFormStyle.smooth" (ngModelChange)="onChange()">
      <label class="form-check-label" for="freeSmooth">{{ 'smooth_path' | translate }}</label>

      <div class="form-row w-50 mt-1 mb-2" *ngIf="marker.freeFormStyle.smooth">
        <input class="form-control-range" min="0" max="0.5" step="0.05" type="range" name="freeSmoothFactor" id="freeSmoothFactor" [(ngModel)]="marker.freeFormStyle.smoothFactor" (ngModelChange)="onChange()">
        <span>
          {{ marker.freeFormStyle.smoothFactor }}
        </span>
      </div>
    </div>

    <hr />
  </fieldset>

  <!-- Pointer Ends -->
  <fieldset *ngIf="marker.shape === 'free' && !marker.freeFormStyle.closePath || marker.shape === 'line'">

    <div class="row">
      <div class='col-sm-6'>
        <div class='form-group'>
          <label class="control-label" for="endType" translate="pointer_start"></label>
          <select id="beginType" name="beginType" class="form-control input-md" [(ngModel)]="marker.freeFormStyle.startType" (ngModelChange)="onChange()">
            <option *ngFor="let endType of defaultValues.endTypes" [value]="endType.value">{{endType.name}}</option>
          </select>
        </div>
      </div>
      <div class='col-sm-6'>
        <div class='form-group'>
          <label class="control-label" for="endType" translate="pointer_end"></label>
          <select id="endType" name="endType" class="form-control input-md" [(ngModel)]="marker.freeFormStyle.endType" (ngModelChange)="onChange()">
            <option *ngFor="let endType of defaultValues.endTypes" [value]="endType.value">{{endType.name}}</option>
          </select>
        </div>
      </div>
    </div>

    <div class='row'>
      <div class='col-sm-6'>
        <div class='form-group' *ngIf="marker.type !== 'text' && marker.freeFormStyle.startType === 'circle'">
          <label for="startRadius" class="control-label" translate="circle_radius"></label>
          <input type="range" class="form-control-range" id="startRadius" name="startRadius" [(ngModel)]="marker.freeFormStyle.startRadius" (ngModelChange)="onChange()" min="4" max="100" step="0.01">
          {{marker.freeFormStyle.startRadius}}
        </div>
      </div>
      <div class='col-sm-6'>
        <div class='form-group' *ngIf="marker.type !== 'text' && marker.freeFormStyle.endType === 'circle'">
          <label for="endRadius" class="control-label" translate="circle_radius"></label>
          <input type="range" class="form-control-range" id="endRadius" name="endRadius" [(ngModel)]="marker.freeFormStyle.endRadius" (ngModelChange)="onChange()" min="4" max="100" step="0.01">
          {{marker.freeFormStyle.endRadius}}
        </div>
      </div>
    </div>

    <hr />

  </fieldset>

  <!-- Label -->
  <fieldset *ngIf="marker.shape === 'line' || marker.shape === 'free'">
    <div class="form-group">
      <label for="labelText" translate="label_text"></label>
      <textarea id="labelText" name="labelText" class="form-control" object-edit [(ngModel)]="marker.label.text" (ngModelChange)="onChange()" rows="1"></textarea>
    </div>

    <hr/>
  </fieldset>


  <fieldset *ngIf="marker.shape !== 'line' && marker.shape !== 'free'">

    <div class="form-group">
      <label for="labelText" translate="label_text"></label>
      <textarea id="labelText" name="labelText" class="form-control" object-edit [(ngModel)]="marker.label.text" (ngModelChange)="onChange()"  rows="1"></textarea>
    </div>

    <div class="form-group editor-container" *ngIf="marker.label">
      <label translate="label_description"></label>
      <medsurf-editor 
        [(content)]="marker.label.description" 
        (contentChange)="onChange()" 
        [toolbarExcludes]="editorToolbarExcludes"
        [autogrow]="true"
        [minHeight]="150">
      </medsurf-editor>
    </div>

    <div class='row' *ngIf="marker.shape === 'interactiveArea'">
      <div class='col-sm-12'>
        <div class='form-group'>
          <input id="hiddenLabel" name="hiddenLabel" type="checkbox" [(ngModel)]="marker.label.hidden" (ngModelChange)="onChange()"> {{ 'Label im Viewer ausblenden' | translate }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class='form-group'>
          <label for="labelColor" translate="label_color"></label>
          <medsurf-color-palette id="labelColor" name="labelColor" [colorList]="defaultValues.colors" [(ngModel)]="marker.label.color" (ngModelChange)="onChange()"></medsurf-color-palette>
        </div>
      </div>
      <div class='col-sm-6'>
        <div class='form-group'>
          <label for="labelFontSize" translate="label_font_size"></label>
          <input id="labelFontSize" name="labelFontSize" class="form-control input-md" type="number" [(ngModel)]="marker.label.fontSize" (ngModelChange)="onChange()"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class='col-sm-6'>
        <div class='form-group'>
          <input id="labelBorder" name="labelBorder" type="checkbox" [(ngModel)]="marker.label.border" (ngModelChange)="onChange()"> {{ 'with_border' | translate }}
        </div>
      </div>
    </div>

    <div class="row" *ngIf="marker.label.border">
      <div class='col-sm-6'>
        <label for="labelStrokeColor" translate="label_stroke_color"></label>
        <medsurf-color-palette id="labelStrokeColor" name="labelStrokeColor" [colorList]="defaultValues.colors" [(ngModel)]="marker.label.strokeColor" (ngModelChange)="onChange()"></medsurf-color-palette>
      </div>
    </div>

    <div class="row" *ngIf="marker.label.border">
      <div class='col-sm-6'>
        <div class='form-group'>
          <label for="labelStrokeDashArray" translate="label_stroke_dash"></label>
          <select id="labelStrokeDashArray" name="labelStrokeDashArray" class="form-control input-md" [(ngModel)]="marker.label.dash" (ngModelChange)="onChange()">
            <option *ngFor="let dash of defaultValues.dashArray" [value]="dash.value">{{dash.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-sm-6">
        <div class='form-group'>
          <label for="labelStrokeWidth" translate="label_stroke_width"></label>
          <input id="labelStrokeWidth" name="strokeWidth" class="form-control input-md" type="number" [(ngModel)]="marker.label.strokeWidth" (ngModelChange)="onChange()"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class='col-sm-6'>
        <div class='form-group'>
          <input id="labelBackground" name="labelBackground" type="checkbox" [checked]="marker.label.background.opacity > 0" (click)="onToggleLabelBackground()"> {{ 'with_background' | translate }}
        </div>
      </div>
      <div class='col-sm-6'>
        <div class='form-group'>
          <input type="range" class="form-control-range" id="labelBackgroundOpacity" name="labelBackgroundOpacity" [(ngModel)]="marker.label.background.opacity" (ngModelChange)="onChange()" min="0" max="1" step="0.01"> {{ marker.label.background.opacity * 100 + "%" }}
        </div>
      </div>
    </div>

    <div class='row' *ngIf="marker.label.background.opacity > 0">
      <div class='col-sm-6'>
        <div class='form-group'>
          <label for="labelBackgroundColor" translate="label_background_color"></label>
          <medsurf-color-palette id="labelBackgroundColor" name="labelBackgroundColor" [colorList]="defaultValues.colors" [(ngModel)]="marker.label.background.color" (ngModelChange)="onChange()"></medsurf-color-palette>
        </div>
      </div>
      <div class='col-sm-6'>
        <div class='form-group'>
          <input id="labelBackgroundInBorderColor" name="labelBackgroundInBorderColor" type="checkbox" [(ngModel)]="marker.label.backgroundInBorderColor" (ngModelChange)="onChange()"> {{ 'label_background_bordercolor' | translate }}
        </div>
      </div>
    </div>

    <hr/>

  </fieldset>

  <!-- Points -->
  <fieldset *ngIf="marker.shape === 'interactiveArea'">

    <div class='row'>
      <div class='col-sm-6'>
        <div class='form-group'>
          <label class="control-label" for="endType" translate="pointer_end"></label>
          <select id="endType" name="endType" class="form-control input-md" [(ngModel)]="marker.target.endType" (ngModelChange)="onChange()">
            <option *ngFor="let endType of defaultValues.endTypes" [value]="endType.value">{{endType.name}}</option>
          </select>
        </div>
      </div>
      <div class='col-sm-6'>
        <div class='form-group' *ngIf="(marker.type !== 'text') && marker.target.endType === 'circle'">
          <label class="control-label" for="endTypeRadius"></label>
          <input type="range" class="form-control-range" id="endTypeRadius" name="endTypeRadius" [(ngModel)]="marker.target.endRadius" (ngModelChange)="onChange()" min="4" max="100" step="0.01">
        </div>
      </div>
    </div>

    <div class='row'>
      <div class='col-sm-6'>
        <div class="form-group">
          <label class="control-label" for="pivot" translate="pointer_pivot"></label>
          <div>
            <label class="radio-inline mr-3">
              <input type="radio" name="pivot" id="pivotBottomRight" value="bottomRight" disabled>
            </label>
            <label class="radio-inline mr-3">
              <input type="radio" name="pivot" id="pivotBottomCenter" value="bottomCenter" [(ngModel)]="marker.label.pivot" (ngModelChange)="onChange()" [checked]="marker.label.pivot === 'bottomCenter'">
            </label>
            <label class="radio-inline mr-3">
              <input type="radio" name="pivot" id="pivotBottomLeft" value="bottomLeft" disabled>
            </label>
          </div>
          <div>
            <label class="radio-inline mr-3">
              <input type="radio" name="pivot" id="pivotRightCenter" value="rightCenter" [(ngModel)]="marker.label.pivot" (ngModelChange)="onChange()" [checked]="marker.label.pivot === 'rightCenter'">
            </label>
            <label class="radio-inline mr-3">
              <input type="radio" name="pivot" id="pivotCenter" value="center" [(ngModel)]="marker.label.pivot" (ngModelChange)="onChange()" [checked]="marker.label.pivot === 'center'">
            </label>
            <label class="radio-inline mr-3">
              <input type="radio" name="pivot" id="pivotLeftCenter" value="leftCenter" [(ngModel)]="marker.label.pivot" (ngModelChange)="onChange()" [checked]="marker.label.pivot === 'leftCenter'">
            </label>
          </div>
          <div>
            <label class="radio-inline mr-3">
              <input type="radio" name="pivot" id="pivotTopRight" value="topRight" disabled>
            </label>
            <label class="radio-inline mr-3">
              <input type="radio" name="pivot" id="pivotTopCenter" value="topCenter" [(ngModel)]="marker.label.pivot" (ngModelChange)="onChange()" [checked]="marker.label.pivot === 'topCenter'">
            </label>
            <label class="radio-inline mr-3">
              <input type="radio" name="pivot" id="pivotTopLeft" value="topLeft" disabled>
            </label>
          </div>
        </div>
        <br/>
      </div>
    </div>

    <div class='row'>
      <div class='col-sm-6'>
        <div class='form-group'>
          <label class="control-label" for="pointerColor" translate="pointer_color"></label>
          <medsurf-color-palette id="pointerColor" name="pointerColor" [colorList]="defaultValues.colors" [(ngModel)]="marker.target.color" (ngModelChange)="onChange()"></medsurf-color-palette>
        </div>
      </div>
      <div class='col-sm-6'>
        <div class='form-group'>
          <label class="control-label" for="text"></label>
        </div>
      </div>
    </div>

    <hr/>

  </fieldset>

  <!-- Line -->
  <fieldset>

    <div class="row mb-3">
      <div class='col-sm-6'>
        <label for="strokeColor" translate="stroke_color"></label>
        <medsurf-color-palette id="strokeColor" name="strokeColor" [colorList]="defaultValues.colors" [(ngModel)]="marker.freeFormStyle.strokeColor" (ngModelChange)="onChange()"></medsurf-color-palette>
      </div>
    </div>

    <div class="row">
      <div class='col-sm-6'>
        <div class='form-group'>
          <label for="strokeDashArray" translate="stroke_dash"></label>
          <select id="strokeDashArray" name="strokeDashArray" class="form-control input-md" [(ngModel)]="marker.freeFormStyle.dash" (ngModelChange)="onChange()">
            <option *ngFor="let dash of defaultValues.dashArray" [value]="dash.value">{{dash.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-sm-6">
        <div class='form-group'>
          <label for="strokeWidth" translate="stroke_width"></label>
          <input id="strokeWidth" name="strokeWidth" class="form-control input-md" type="number" [(ngModel)]="marker.freeFormStyle.strokeWidth" (ngModelChange)="onChange()"/>
        </div>
      </div>
    </div>

    <hr />

  </fieldset>

  <!-- Background -->
  <fieldset *ngIf="marker.shape === 'ellipse' || marker.shape === 'rectangle' || marker.shape === 'arrow' || marker.shape === 'triangle' || (marker.shape === 'free' || marker.shape === 'interactiveArea') && marker.freeFormStyle.closePath">

    <div class='row'>
      <div class='col-sm-6'>
        <div class='form-group'>
          <input id="background" name="background" type="checkbox" [(ngModel)]="marker.freeFormStyle.background" (ngModelChange)="onChange()"> {{ 'with_background' | translate }}
        </div>
      </div>
      <div class='col-sm-6'>
        <div class='form-group'>
          <input type="range" class="form-control-range" id="backgroundOpacity" name="backgroundOpacity" [(ngModel)]="marker.freeFormStyle.opacity" (ngModelChange)="onChange()" min="0" max="1" step="0.01"> {{ marker.freeFormStyle.opacity * 100 + "%" }}
        </div>
      </div>
    </div>

    <div class='row' *ngIf="marker.freeFormStyle.background">
      <div class='col-sm-6'>
        <div class='form-group'>
          <label for="backgroundColor" translate="background_color"></label>
          <medsurf-color-palette id="backgroundColor" name="backgroundColor" [colorList]="defaultValues.colors" [(ngModel)]="marker.freeFormStyle.color" (ngModelChange)="onChange()"></medsurf-color-palette>
        </div>
      </div>
      <div class='col-sm-6'>
        <div class='form-group'>
          <input id="backgroundInBorderColor" name="backgroundInBorderColor" type="checkbox" [(ngModel)]="marker.freeFormStyle.backgroundInBorderColor" (ngModelChange)="onChange()"> {{ 'background_bordercolor' | translate }}
        </div>
      </div>
    </div>

    <hr />

  </fieldset>

  <!-- Link -->
  <fieldset>
    <div class='row mb-2'>
      <div class='col-sm-6'>
        <label for="linkedSite" translate="label_linked_site_type"></label>
        <div class="btn-group" role="group">
          <button *ngFor="let linkType of linkTypes"
                  type="button"
                  [class.btn-secondary]="linkType === marker?.link?.type"
                  [class.btn-primary]="linkType !== marker?.link?.type"
                  class="btn btn-sm"
                  (click)="selectLinkType(linkType)">
            {{ linkType }}
          </button>
        </div>
      </div>
    </div>
    <div class='row' *ngIf="marker.link">
      <div class='col-sm-6'>
        <div class='form-group'>
          <label for="linkedSite" translate="label_linked_site"></label>
          <input id="linkedSite" name="linkedSite" class="form-control input-md"
                  type="text"
                  [disabled]="!marker.link.type"
                  [(ngModel)]="marker.link.link" (ngModelChange)="onChange()"/>
        </div>
      </div>
    </div>
  </fieldset>

</form>
