import { Editor } from 'tinymce'

const boxTypes = ['attention', 'note'] as const;
export const BoxTypePlugin = {
    onSetup: (editor: Editor, api: any, type: typeof boxTypes[number]) => {
        const node = editor.selection.getNode()?.closest('.box-format');
        if (!node) return;
        if (editor.formatter.match('box-format-' + type, undefined, node)) api.setActive(true);
        const changed = editor.formatter.formatChanged('box-format-' + type, (state) => {
          api.setActive(state);
        })
        return () => changed.unbind();
    },
    onAction: (editor: Editor, api: any, type: typeof boxTypes[number]) => { 
        const node = editor.selection.getNode()?.closest('.box-format');
        if (!node) return;
        const addType = !editor.formatter.match('box-format-' + type, undefined, node);
        boxTypes.forEach(type => {
          editor.formatter.remove('box-format-' + type, undefined, node);
        })

        if (addType) {
          api.setActive(true);
          editor.formatter.apply('box-format-' + type, undefined, node);
        } else {
          api.setActive(false);
        }
        editor.fire('Change');
    }
}

const boxWidths = [100, 80, 60] as const;
export const BoxWidthPlugin = {
    fetch: (editor: Editor, cb) => {

          const bottomNode = editor.selection.getNode();
          const node = bottomNode.closest('.box-format');
          if (!node) return;
          const setTypeClass = (width?: typeof boxWidths[number]) => { 
            const addClass = width ? !node.classList.contains('w-' + width) : false;
            node.classList.remove(...boxWidths.map(w => 'w-' + w)); 
            if (addClass) node.classList.add('w-' + width); 
            editor.fire('Change') 
          };
          cb([
            { type: 'togglemenuitem', value: 'auto', text: 'auto', onAction: () => setTypeClass(), active: !node.className.includes('w-') },
            ...boxWidths.map(w => ({ type: 'togglemenuitem' as const, value: 'w-' + w, text: w + '%', onAction: () => setTypeClass(w), active: node.classList.contains('w-' + w) }))
          ]);
    },
}

const  boxAlignments = ['center', 'right'] as const;
export const BoxAlignmentPlugin = {
    fetch: (editor: Editor, cb) => {
      const pre = 'align-';

          const bottomNode = editor.selection.getNode();
          const node = bottomNode.closest('.box-format');
          if (!node) return;
          const setTypeClass = (align?: typeof boxAlignments[number]) => { 
            const addClass = align ? !node.classList.contains(pre + align) : false;
            node.classList.remove(...boxAlignments.map(a => pre + a)); 
            if (addClass) node.classList.add(pre + align); 
            editor.fire('Change') 
          };
          cb([
            { type: 'togglemenuitem', value: 'auto', text: 'auto', onAction: () => setTypeClass(), active: !node.className.includes(pre) },
            ...boxAlignments.map(align => ({ type: 'togglemenuitem' as const, value: pre + align, text: align, icon: 'objects-align-' + align, onAction: () => setTypeClass(align), active: node.classList.contains(pre + align) }))
          ]);
    },
}