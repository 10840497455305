<div class="d-flex flex-col h-100 scrollable">
  <medsurf-navigation></medsurf-navigation>
  <div class="panel panel-default scrollable d-flex flex-col">
    <div class="panel-body panel-body-full-margin">
      <div class="release-container">
        <h1>{{ 'updates' | translate }}</h1>
        <div class="release-list">
          <div class="mt-lg-5" *ngIf="isLoading">
            <div class="spinner-border"></div>
          </div>
          <ng-container *ngIf="isLoading === false">
            <div *ngFor="let release of releases; let latest = first" 
              class="release" 
              [class.active]="isActive(currentRelease, release)"
              [class.latest]="latest"
              [class.current]="release === currentRelease"
            >
              <div class="badges">
                <span class="badge badge-info" *ngIf="release === currentRelease">{{ 'current_version' | translate }}</span>
                <span class="badge badge-success" *ngIf="latest">{{ 'latest_version' | translate }}</span>
                <span class="badge badge-dark">{{release.commit.short_id}}</span>
              </div>
              <h2 class="release-tag">{{ release.tag_name }}</h2>
              <p class="release-date">{{ release.released_at | amDateFormat:'LLL' }}</p>
              <markdown class="markdown-body">
                {{release.description}}
              </markdown>
            </div>
            <div *ngIf="(releases$ | async).length === 0">
              {{ 'no_releases_message' | translate }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
