<table class="table table-hover table-vcenter" #siblingsSidebar>
  <tr *ngFor="let slideNode of siblings" 
      [class.d-none]="slideNode.deleted"
      [class.bg-primary]="slideNode.page.id === currentSlide?.id"
      (click)="slideSelected.emit(slideNode)"
      [id]="slideNode.id">
    <td class="pr-1">
      {{ slideNode.position + 1 }}.
    </td>
    <td class="pl-0 pr-1">
      <img
        class="slides"
        [src]="slideNode.page.thumbnailMedia? mediaService.getUrl(slideNode.page.thumbnailMedia, 'thumbnail') : '/assets/images/no-image.png'"
      />
    </td>
    <td class="pl-0">
      <div class="resource-type-icon" [title]="mediaService.getMediaTypeLabel({ type: slideNode.page.mediaType }) | translate">
        <span [class]="'fa ' + mediaService.getMediaTypeIcon({ type: slideNode.page.mediaType })"></span>
      </div>
    </td>
    <td class="pl-0">
      <ng-container *ngIf="slideNode.page?.modality?.name">
        <small>{{slideNode.page?.modality?.name}}</small><br/>
      </ng-container>
      {{slideNode.page.title}}
    </td>
  </tr>
</table>
