<div class="nav-tab-content">
  <form class="form">
    <div class="form-group">
      <label class="control-label" translate="slide_id"></label>
      <div>
        <input class="form-control" name="slide_id" type="number" [(ngModel)]="slide.slideId" (ngModelChange)="onChange()" [disabled]="props.lockslideid">
      </div>
    </div>

    <div class="form-group" *ngIf="slide?.layers?.length > 0 && slide?.layers[layerId]?.images">
      <label class="control-label" translate="background_color"></label>
      <div>
        <medsurf-color-palette 
          id="color" name="color" 
          [colorList]="defaultValues.backgroundColors" 
          [ngModel]="slide.layers[layerId]?.images[sequenceId]?.background?.color" 
          (ngModelChange)="onBackgroundChange($event)"></medsurf-color-palette>
      </div>
    </div>

    <div class="form-group mb-4" *ngIf="{ inherited: (settingsInherited$ | async), current: path.slide.settings || {}, default: settingsDefault} as settings">
      <label class="control-label" translate="hideAnnotations" for="hideAnnotations"></label>
      <div>
        <div class="btn-group mr-2" role="group">
          <button
              type="button"
              [class.btn-secondary]="settings.current.hideAnnotations === true"
              [class.btn-primary]="settings.current.hideAnnotations !== true"
              class="btn"
              (click)="onHideAnnotationsChange(true)">{{ 'yes' | translate }}</button>
          <button
              type="button"
              [class.btn-secondary]="settings.current.hideAnnotations === false"
              [class.btn-primary]="settings.current.hideAnnotations !== false"
              class="btn"
              (click)="onHideAnnotationsChange(false)">{{ 'no' | translate }}</button>
        </div>
        <span *ngIf="isUndefined(settings.current.hideAnnotations)">
            {{ 'inherited' | translate }}:  {{ (!isUndefined(settings.inherited.hideAnnotations)) ? settings.inherited.hideAnnotations : settings.default.hideAnnotations }}
        </span>
        <button *ngIf="!isUndefined(settings.current.hideAnnotations)" class="btn btn-sm btn-secondary" type="button" (click)="onHideAnnotationsClear()">{{ 'reset' | translate }}</button>
      </div>
      <hr>
    </div>

    <div class="form-group" *ngIf="slide?.layers?.length > 0 && slide?.layers[layerId]?.images">
      <label class="control-label" translate="sidebar_ratio"></label>
      <div>
        <select id="sidebarRatio" name="sidebarRatio"
                class="form-control input-md"
                [(ngModel)]="slide.sideBarSize" (ngModelChange)="onChange()">
          <option *ngFor="let sidebarRatio of defaultValues.sidebarRatios" [ngValue]="sidebarRatio.value">{{sidebarRatio.name}}</option>
        </select>
      </div>
      <hr/>
    </div>

    <div class="form-group">
      <label class="control-label" translate="visibility"></label>
      <div>
        <input type="checkbox" name="hidden" [(ngModel)]="slide.hidden" (ngModelChange)="onChange()"> {{ 'hide_slide' | translate }}
      </div>
      <div>
        <input type="checkbox" name="restricted" [(ngModel)]="slide.restricted" (ngModelChange)="onChange()"> {{ 'restrict_slide' | translate }}
      </div>
    </div>
    <hr/>

    <div class="form-group">
      <label class="control-label" translate="pdf"></label>
      <div class="align-items-start" *ngIf="!generating">

        <div class="mb-2">
          <div>
            <strong>Viewer</strong><br>
            <a *ngIf="slide.pdfMedia" target="_blank" [href]="mediaService.getUrl(slide.pdfMedia)" >
              <span>{{slide.pdfMedia.name}} ({{ slide.pdfMedia.uploadedAt | date:'dd.MM.yyyy HH:mm' }}) </span>
              <span class="fa fa-download"></span>
            </a>
            <span *ngIf="!slide.pdfMedia">{{ 'not_yet_generated' | translate }}</span>
          </div>
          <div>
            <strong>Autoren</strong><br>
            <a *ngIf="slide.pdfMediaAuthor" target="_blank" [href]="mediaService.getUrl(slide.pdfMediaAuthor)">
              <span>{{slide.pdfMediaAuthor.name}} ({{ slide.pdfMediaAuthor.uploadedAt | date:'dd.MM.yyyy HH:mm' }}) </span> 
              <span class="fa fa-download"></span>
            </a>
            <span *ngIf="!slide.pdfMediaAuthor">{{ 'not_yet_generated' | translate }}</span>
          </div>
        </div>

        <div class="mb-2">
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-primary" id="generatePdf" ngbDropdownToggle>
              {{ 'generate_new' | translate }}
            </button>
            <div ngbDropdownMenu aria-labelledby="generatePdf">
              <button ngbDropdownItem (click)="generatePdf()">{{ 'generate_pdf' | translate}}</button>
              <button ngbDropdownItem (click)="generateAuthorPdf()">{{ 'generate_author_pdf' | translate}}</button>
            </div>
          </div>
        </div>

      </div>
      <div *ngIf="generating">
        <div class="spinner-border"></div>
      </div>
    </div>
    <hr/>

    <div class="form-group">
      <div>
        <button class="btn btn-danger" (click)="localDeleteSlide.emit()" translate="delete_slide"></button>
      </div>
      <hr>
    </div>
    <div class="form-group">
      <label class="control-label" translate="status"></label>
      <div>
        <select id="status" name="status"
                class="form-control input-md"
                [(ngModel)]="slide.status" (ngModelChange)="onChange()">
          <option *ngFor="let state of defaultValues.states" [value]="state.value">{{state.name | translate}}</option>
        </select>
      </div>
    </div>

    <div class="form-group form-row" *ngIf="!!path?.slide">
      <div class="col-sm-12">
          <label for="nodeRoute">{{ 'route' | translate }}</label>
          <div class="input-group">
              <input type="text" class="form-control" id="nodeRoute" [(ngModel)]="path.slide.route" (ngModelChange)="onRouteChange()" [ngModelOptions]="{ standalone: true }">
              <div class="input-group-append">
                  <button class="btn btn-secondary" type="button" (click)="setRouteFromTitle()" [title]="'generate_route_from_title' | translate">
                      <i class="fa fa-fw fa-arrows-rotate"></i>
                  </button>
              </div>
          </div>
          <small id="nodeHelp" class="form-text text-muted">{{ 'route_change_warning' | translate }}</small>
      </div>
    </div>

    <div class="form-group">
      <label class="control-label" translate="comments"></label>
      <medsurf-comments [item]="slide" (changeEmitter)="onChange()"></medsurf-comments>
    </div>
  </form>
</div>
