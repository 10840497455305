<ng-template #node_template let-node="node" let-depth="depth" let-route="route" let-locked="locked">
  <div class="tree-node-styled align-items-center with-handle"
       [class.warning]="node.hidden" [class.tree-node-locked]="locked"
       (click)="selectItem.emit({item: node, depth: depth, route: route, locked: locked})">

    <div class="handle" [ngClass]="!locked ? 'handle-layer' + depth: ''">
      <i class="fa fa-grip-dots-vertical"></i>
    </div>

    <button class="btn btn-primary btn-sm mr-2" *ngIf="depth <= 2 && node.page.type !== 'QuizStart' && node.page.type !== 'Vocabulary'"  (click)="toggleNode(node)">
      <i class="fa fa-fw " [class.fa-caret-right]="!node.expanded" [class.fa-caret-down]="node.expanded" aria-hidden="true"></i>
    </button>
    <button class="btn btn-primary btn-sm mr-2" *ngIf="depth === 3">
      <span class="fa fa-fw fa-bars"></span>
    </button>

    <button class="btn btn-primary btn-sm mr-2"
            [style.background-color]="this.themeColorService.getHexValue(node.page.themeColor)"
            *ngIf="node.page.themeColor" data-nodrag>
      <span class="fa" title="{{ 'theme_color' | translate }}"></span>
    </button>
    <div class="tree-node-input" *ngIf="currentItem?.id !== node.id">{{node.page.title}}</div>
    <input class="tree-node-input" type="text" [disabled]="locked" spellcheck="false"
           *ngIf="currentItem?.id === node.id"
           [value]="node.page.title"
           (keyup.enter)="sendTitle(nodeInput, node)"
           (blur)="sendTitle(nodeInput, node)" #nodeInput>

    <ng-container *ngIf="!locked; else lock">
      <button class="btn btn-danger btn-sm ml-2" *ngIf="(!node.hasChildren && !node.children) || (node.children && (countItems(node.children) === 0))" (click)="removeItem(node)">
        <span class="fa fa-fw fa-times" title="{{ 'delete_entry_description' | translate }}"></span>
      </button>
      <button class="btn btn-primary btn-sm ml-2" *ngIf="depth <= 2 && node.page.type !== 'QuizStart' && node.page.type !== 'Vocabulary'" data-nodrag (click)="newItem(node, depth);node.expanded = true">
        <span class="fa fa-fw fa-plus" title="{{ 'new_entry_description' | translate }}"></span>
      </button>
      <button medsurfRestrict access="admin,author" class="btn btn-primary btn-sm border ml-2" (click)="selectVisibility(node)">
        <span class="fa fa-fw" [class.fa-eye]="!node.hidden" [class.fa-eye-slash]="node.hidden" title="{{ node.hidden ? 'select_hidden_description' : 'select_visible_description' | translate }}"></span>
      </button>
      <button medsurfRestrict access="admin,author" class="btn btn-primary btn-sm ml-2" *ngIf="depth <=1" (click)="selectRestriction(node)">
        <span class="fa fa-fw" [class.fa-lock]="node.restricted" [class.fa-unlock]="!node.restricted" title="{{ 'restrict_description' | translate }}"></span>
      </button>
      <button medsurfRestrict access="admin" class="btn btn-primary btn-sm ml-2" *ngIf="depth <= 1" (click)="selectShare(node)">
        <span class="fa fa-fw fa-share" title="{{ 'select_share_description' | translate }}"></span>
      </button>
    </ng-container>
    <ng-template #lock>
      <span class="fa fa-fw fa-lock"></span>
    </ng-template>
  </div>
  <div *ngIf="depth <= 2 && node.expanded" class="tree-layer" [dragula]="'layer' + (depth + 1)" [(dragulaModel)]="node.children" (dragulaModelChange)="nodeMoved(node)">
    <div *ngFor="let subNode of node.children; index as i; trackBy: trackbyNode">
      <ng-container *ngIf="!subNode.deleted">
        <ng-container *ngTemplateOutlet="node_template;context:{node:subNode,depth:depth + 1,route:route + '/' + subNode.route,locked:editLocked(subNode)}"></ng-container>
      </ng-container>
    </div>
  </div>
  <ol *ngIf="depth === 3"></ol>
</ng-template>

<aside class="mdsf-sidebar" *ngIf="(user$ | async)">
  <div class="container-navigation scrollable" medsurfAutoScroll>
    <div id="tree-root">
      <div dragula="layer0" [(dragulaModel)]="subjects" (dragulaModelChange)="nodeMoved({children: subjects})">
        <div *ngFor="let node of subjects; index as i; trackBy: trackbyNode">
          <ng-container *ngIf="!node.deleted">
            <ng-container *ngTemplateOutlet="node_template;context:{node:node,depth:0,route:node.route,locked:editLocked(node, (user$ | async))}"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</aside>
