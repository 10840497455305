import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, omit } from 'lodash';
import { DragulaService } from 'ng2-dragula';
import { Node, ElearningNode, User } from '@medsurf/models';
import { AuthState, IndexState } from '@medsurf/state';
import { IndexChanged } from '@medsurf/actions';
import { NodeService } from '@medsurf/services';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ThemeColorService } from '../main/settings/theme-color/theme-color.service';
import { PermissionDialogComponent } from '../../common/permission-dialog/permission-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getRouteString } from '@medsurf/helpers';
import * as MedsurfModels from '@medsurf/models';

export interface TreeEvent {
  item?: Node;
  depth?: number;
  route?: string;
  locked?: boolean;
}

@Component({
  selector: 'medsurf-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  /**
   * Selector for user
   * @type {Observable<User>}
   */
  @Select(AuthState.user)
  public user$: Observable<User>;

  /**
   * Selector for complete elearning index
   * @type {Observable<ElearningNode[]>}
   */
  @Select(IndexState.index)
  public subjects$: Observable<ElearningNode[]>;

  /**
   * Input current item
   */
  @Input()
  public currentItem: MedsurfModels.Node = null;

  /**
   * Output emitter for selected item (node)
   * @type {EventEmitter<TreeEvent>}
   */
  @Output()
  public selectItem = new EventEmitter<TreeEvent>();

  /**
   * Complete elearning index
   * @type {ElearningNode[]}
   */
  public subjects: ElearningNode[];

  /**
   * Destroy subject
   * @type {Subject<boolean>}
   * @private
   */
  private _destroyed = new Subject<boolean>();

  /**
   * Constructor
   * @param {DragulaService} dragulaService
   * @param {NodeService} nodeService
   * @param {Store} store
   * @param {ThemeColorService} themeColorService
   * @param {TranslateService} translate
   * @param {ModalService} modalService
   */
  constructor(private dragulaService: DragulaService,
              private nodeService: NodeService,
              private store: Store,
              public themeColorService: ThemeColorService,
              private translate: TranslateService,
              private modalService: NgbModal) {
    for (let i = 0; i <= 3; i++) {
      this.dragulaService.destroy('layer' + i);
      this.dragulaService.createGroup('layer' + i, {
        moves: (el, container, handle) => {
          const h = el.querySelector('.handle');
          return handle.classList.contains('handle') || h.contains(handle);
        }
      });
    }
  }

  /**
   * OnInit method
   */
  public ngOnInit(): void {
    this.subjects$
      .pipe(takeUntil(this._destroyed), filter((subjects: ElearningNode[]) => !!subjects))
      .subscribe((subjects: ElearningNode[]) => {
        this.subjects = subjects.sort((subjectA, subjectB) => subjectA.position - subjectB.position);
      });
  }

  /**
   * Opens share modal for selected node
   * @param {Node} node
   */
  public selectShare(node: Node): void {
    const modalRef = this.modalService.open(PermissionDialogComponent, {scrollable: true});
    modalRef.componentInstance.node = node;
  }

  /**
   * Update node page title according to input element value
   * @param {HTMLInputElement} titleInput
   * @param {Node} node
   */
  public sendTitle(titleInput: HTMLInputElement, node: Node): void {
    if (node.page.title !== titleInput.value) {
      node.page.title = titleInput.value;

      if (node.route.startsWith('neuer-titel')) {
        node.route = getRouteString(node.page.title);
        node.dirty = true;
      }

      this.update(node);
    }
    titleInput.blur();
  }

  /**
   * Toggle expansion of selected node
   * @param {Node} node
   */
  public toggleNode(node: Node): void {
    node.expanded = !node.expanded;
  }

  /**
   * Count all active children of a node for displaying delete button when none is present
   * @param {Node[]} nodes
   * @returns {number}
   */
  public countItems(nodes: Node[]): number {
    return nodes.filter(node => !node.deleted).length;
  }

  /**
   * Callback method for moved node items
   * @param {Node} parent
   */
  public nodeMoved(parent: Node) {
    this.nodeService.childrenMoved(parent);
  }

  /**
   * Check f a selected nod is locked for the current user
   * @param {Node} node
   * @returns {boolean}
   */
  public editLocked(node: Node): boolean {
    return this.nodeService.editLocked(
      node,
      this.store.selectSnapshot(IndexState.indexMap),
      this.store.selectSnapshot(AuthState.user)
    );
  }

  /**
   * Toggle visibility of a selected node
   * @param {Node} node
   */
  public selectVisibility(node: Node): void {
    if (node && node.hidden) {
      node.hidden = !node.hidden;
    } else {
      node.hidden = true;
    }
    this.update(node);
  }

  /**
   * Toggle restriction of a selected node
   * @param {Node} node
   */
  public selectRestriction(node: Node): void {
    if (node && node.restricted) {
      node.restricted = !node.restricted;
    } else {
      node.restricted = true;
    }
    this.update(node);
  }

  /**
   * Set deleted flag for a selected node
   * @param {Node} node
   */
  public removeItem(node: Node): void {
    node.deleted = true;
    this.update(node);
  }

  /**
   * Create a new node item
   * @param {Node} node
   * @param {number} depth
   */
  public newItem(node: Node, depth: number): void {
    this.translate.get(['new_title']).subscribe((translations) => {
      this.nodeService.newNode(depth, node, translations.new_title);
      this.update(node);
    });
  }


  /**
   * Deep clone node to update
   * @param {Node} node
   * @private
   */
  private update(node: Node): void {
    const clonedNode: Node = omit<Node>(cloneDeep<Node>(node), 'children');
    this.store.dispatch(new IndexChanged([clonedNode]));
  }

  public trackbyNode(index: number, item: Node) {
    return item.id || item;
  }

  /**
   * OnDestroy method
   */
  public ngOnDestroy(): void {
    for (let i = 1; i <= 3; i++) {
      this.dragulaService.destroy('layer' + i);
    }
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
