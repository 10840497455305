<div class="modal-body" tabindex="0">
  <form>
    <div class="row">

      <div class="col" *ngIf="layers && layers.length > 1">
        <select class="form-control input-md"
                [(ngModel)]="linkLayer"
                [ngModelOptions]="{standalone: true}"
                #layerinput>
          <option [value]="''">{{ "for_all_layer" | translate }}</option>
          <option *ngFor="let layer of layers; index as i" [value]="'layer-' + i">
            {{ layer.window ? layer.window : '' }}
          </option>
        </select>
      </div>

      <div class="col" *ngIf="sequences && sequences.length > 1">
        <select class="form-control input-md"
                [(ngModel)]="linkSequence"
                [ngModelOptions]="{standalone: true}"
                #sequenceinput>
          <option [value]="''">{{ "for_all_sequences" | translate }}</option>
          <option *ngFor="let image of sequences; index as i" [value]="'sequence-' + i">
            # {{ i + 1 }}
          </option>
        </select>
      </div>

      <div class="col">
        <select class="form-control input-md"
                [(ngModel)]="linkGroup"
                [ngModelOptions]="{standalone: true}"
                #markerinput>
          <optgroup label="{{ 'groups' | translate}}">
            <option *ngFor="let group of groups" [value]="'group-' + group.id">
              {{group.name}}
            </option>
          </optgroup>
        </select>
      </div>
      
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="modal.dismiss()" class="btn btn-secondary" translate="close"></button>
  <button (click)="save()" class="btn btn-primary mr-1" translate="add"></button>
</div>
