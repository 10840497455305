import { getBaseConfig } from './base.config';
import { getPluginsConfig } from './plugins.config';
import { getTableConfig } from './table.config';
import { getToolbarConfig } from './toolbar.config';
import { getFormatConfig } from './format.config';

export const getConfig = (settings: ConfigSettings) => {
  return {
    ...getBaseConfig(settings),
    ...getPluginsConfig(settings),
    ...getToolbarConfig(settings),
    ...getTableConfig(settings),
    ...getFormatConfig(settings)
  };
};

export interface ConfigSettings {
  height?: number | string;
  minHeight?: number;
  autogrow?: boolean;
  toolbarExcludes?: string[];
  toolbar?: string[][];
  placeholder?: string;
}
