import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedsurfCommonModule } from '../../common/medsurf-common.module';
import { CopyAnnotationsComponent } from './properties/copy-annotations/copy-annotations.component';
import { CopyPoisComponent } from './properties/copy-pois/copy-pois.component';
import { DefaultsComponent } from './properties/defaults/defaults.component';
import { FreeformComponent } from './properties/freeform/freeform.component';
import { MediaComponent } from './properties/media/media.component';
import { KeymapComponent } from './properties/keymap/keymap.component';
import { ListComponent } from './properties/list/list.component';
import { MarkerComponent } from './properties/marker/marker.component';
import { MetadataComponent } from './properties/metadata/metadata.component';
import { PoisComponent } from './properties/pois/pois.component';
import { GroupsComponent } from './properties/groups/groups.component';
import { PanelComponent } from './panel.component';
import { ReferenceDropdownComponent } from './properties/reference-dropdown/reference-dropdown.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MentionModule } from 'angular-mentions';
import { MomentModule } from 'ngx-moment';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { ColorPaletteComponent } from '../../common/color-palette/color-palette.component';
import { PoiNoteComponent } from './properties/pois/poi-note/poi-note.component';
import { PoiAnnotationComponent } from './properties/pois/poi-annotation/poi-annotation.component';
import { PoiHeaderComponent } from './properties/pois/poi-header/poi-header.component';
import { GroupAnnotationComponent } from './properties/groups/group-annotation/group-annotation.component';
import { GroupHeaderComponent } from './properties/groups/group-header/group-header.component';
import { GroupComponent } from './properties/groups/group/group.component';
import { MedsurfLibModule } from '@medsurf/module';

@NgModule({
  declarations: [
    CopyAnnotationsComponent,
    DefaultsComponent,
    FreeformComponent,
    MediaComponent,
    KeymapComponent,
    ListComponent,
    MarkerComponent,
    MetadataComponent,
    PoisComponent,
    GroupsComponent,
    PanelComponent,
    ColorPaletteComponent,
    PoiNoteComponent,
    PoiAnnotationComponent,
    PoiHeaderComponent,
    GroupAnnotationComponent,
    GroupHeaderComponent,
    GroupComponent,
    CopyPoisComponent,
    ReferenceDropdownComponent
  ],
  imports: [
    CommonModule,
    MedsurfCommonModule,
    RouterModule,
    FormsModule,
    TranslateModule.forChild(),
    MentionModule,
    MomentModule,
    ColorPickerModule,
    NgxDropzoneModule,
    NgbModule,
    MedsurfLibModule,
    DragulaModule.forRoot(),
  ],
  exports: [
    PanelComponent,
    ColorPaletteComponent
  ]
})
export class PanelModule {
}
