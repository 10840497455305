import Element from '../element';

import Line from './line';
import Path from './path';
import Free from './free';
import InteractiveArea from './interactiveArea';

class Freeform extends Element {
  private _shape: Path | Free | Line | InteractiveArea;

  constructor(
    model,
    private _format,
    private _localPaper,
    private _mainLayer,
    private _container,
    public _imageScale = 1,
    public _imageOffset?
  ) {
    super(model);
    this._init();
  }

  _init() {
    if (!this._element) {
      this._element = new this._localPaper.Group();
      this._mainLayer.addChild(this._element);
    }
    this.draw();
  }

  draw() {
    this._element.removeChildren();
    this._element.freeform = this;
    switch (this._model.shape) {
      case 'rectangle':
        this._shape = new Path(this._format, this, this._model, 'rectangle');
        break;
      case 'ellipse':
        this._shape = new Path(this._format, this, this._model, 'ellipse');
        break;
      case 'free':
        this._shape = new Free(this._format, this, this._model);
        break;
      case 'arrow':
        this._shape = new Path(this._format, this, this._model, 'arrow');
        break;
      case 'triangle':
        this._shape = new Path(this._format, this, this._model, 'triangle');
        break;
      case 'interactiveArea':
        this._shape = new InteractiveArea(this._model, this._localPaper, this._format, this);
        break;
      default:
        this._shape = new Line(this._model, this._localPaper, this._format, this);
        break;
    }
  }
}

export default Freeform;
