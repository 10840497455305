'use strict';

import MarkerElement from '../markerElement';
import paper from 'paper';

class Keymap extends MarkerElement {
  private _labels: any[];
  private _selection: any;
  private _cell: any;
  private _index: any;
  private _label: paper.PointText;
  private _rectangle: paper.Path.Rectangle;

  constructor(model: Keymap,
              private _format,
              private _localPaper,
              private _mainLayer,
              private _container,
              public _imageScale = 1,
              public _imageOffset?
  ) {
    super(model);
    this._labels = [];
    this._selection = null;

    this._init();
  }

  _init() {
    if (!this._element) {
      this._element = new this._localPaper.Group();
      this._mainLayer.addChild(this._element);
    }
    this.draw();
  }

  draw() {
    this._element._children = [];
    if (!this._model.columns || this._model.deleted) {
      return;
    }
    const rowHeight = [], columnWidth = [];
    const source = this._container.getAbsoluteCoords(
      this._model.source.position.x,
      this._model.source.position.y,
      this._imageOffset,
      this._imageScale
    );
    source.x *= this._imageScale;

    const startY = source.y;
    columnWidth[-1] = 0;
    rowHeight[-1] = 0;

    const digitModel = this.getDigitModel();
    const digitsCount = this.countIndexDigits();

    this._model.columns = this._model.columns.length > 0 ? this._model.columns : this.getDefaultLabel();

    for (const column of this._model.columns) {
      const columnId = this._model.columns.indexOf(column);
      columnWidth[columnId] = 0;
      source.y = startY;
      for (const label of column.labels) {
        const rowId = column.labels.indexOf(label),
          indexContent = ' '.repeat(digitsCount - label.index.toString().length) + label.index;
        rowHeight[rowId] = rowHeight[rowId] ? rowHeight[rowId] : 0;

        this._cell = new this._localPaper.Group();
        this._index = new this._localPaper.Group();

        for (let position = 0; position < digitsCount; position++) {
          const digit = this.drawDigit(source, position, digitModel, indexContent);
          this._index.addChild(digit);
        }

        this._label = new paper.PointText(new paper.Point(
          source.x + (this._model.keymapStyle.indexWidth / this._imageScale) + (digitsCount - 1) * digitModel.width,
          source.y)
        );

        this._label.fontFamily = this._format.fontFamily;
        this._label.fontWeight = this._format.fontWeight;
        this._label.fontSize = (this._model.keymapStyle.fontSize || this._format.fontSize) / this._imageScale;
        this._label.fillColor = this._model.keymapStyle.color || this._format.color;
        this._label.content = label.text;
        this._label['keymap'] = this;
        this._index['keymap'] = this;

        this._cell.addChild(this._index);
        this._cell.addChild(this._label);

        this._element.addChild(this._cell);

        columnWidth[columnId] = Math.max(this._cell.bounds.width, columnWidth[columnId]);
        rowHeight[rowId] = Math.max(this._cell.bounds.height, rowHeight[rowId]);
        source.y += rowHeight[rowId] + (this._model.keymapStyle.rowDistance / this._imageScale);
      }
      source.x += columnWidth[columnId] + (this._model.keymapStyle.columnDistance / this._imageScale);
    }
    this.drawRectangle(columnWidth, rowHeight);
    if (this._isSelected) {
      this.drawSelection();
    }
    this._element.reverseChildren();
  }

  drawSelection() {
    if (!this._label) return;
    const width = this._rectangle.bounds.width,
      height = this._rectangle.bounds.height;

    const source = this._container.getAbsoluteCoords(
      this._model.source.position.x,
      this._model.source.position.y,
      this._imageOffset,
      this._imageScale
    );
    source.x *= this._imageScale;

    source.x = source.x - (this._model.keymapStyle.columnDistance / this._imageScale / 2);
    source.y = source.y - (this._model.keymapStyle.rowDistance / this._imageScale / 2) - this._label.fontSize;

    this._selection = new this._localPaper.Path.Rectangle(new paper.Rectangle(
      new paper.Point(source.x, source.y),
      new paper.Size(width, height)
    ));
    this._selection.strokeColor = this._format.selection.color;
    this._selection.strokeWidth = this._container.calculateFixDimensions(this._format.selection.strokeWidth);
    this._selection.type = 'selection';
    this._selection.fillColor = this._format.selection.color;
    this._selection.fillColor.alpha = this._format.selection.opacity;
    this._selection.data = {
      'selectionType': 'label'
    };
    this._selection.marker = this._marker;
    this._element.addChild(this._selection);
  }

  getDigitModel() {
    const index = new paper.PointText(new paper.Point(0, 0)
    );
    index.fontFamily = this._format.fontFamily;
    index.fontWeight = this._format.fontWeight;
    index.fontSize = (this._model.keymapStyle.fontSize || this._format.fontSize) / this._imageScale;
    index.content = '0';
    index.remove();
    return {width: index.bounds.width, height: index.bounds.height};
  }

  drawDigit(source, position, digitModel, indexContent) {
    const digit = new paper.PointText(new paper.Point(source.x + position * digitModel.width, source.y));
    digit.fontFamily = this._format.fontFamily;
    digit.fontWeight = this._format.fontWeight;
    digit.fontSize = (this._model.keymapStyle.fontSize || this._format.fontSize) / this._imageScale;
    digit.fillColor = this._model.keymapStyle.color || this._format.color;
    digit.content = indexContent[position];
    digit.translate(new paper.Point((digitModel.width - digit.bounds.width) / 2, 0));
    return digit;
  }

  drawRectangle(columnWidth, rowHeight) {
    if (!this._label) return;

    const width = this._element.bounds.width;
    const height = this._element.bounds.height;


    const source = this._container.getAbsoluteCoords(
      this._model.source.position.x,
      this._model.source.position.y,
      this._imageOffset,
      this._imageScale);
    source.x *= this._imageScale;

    source.x = source.x - (this._model.keymapStyle.columnDistance / this._imageScale / 2);
    source.y = source.y - (this._model.keymapStyle.rowDistance / this._imageScale / 2) - <number>this._label.fontSize;

    const rectangle = new paper.Rectangle(
      new paper.Point(source.x, source.y),
      new paper.Size(
        width + (this._model.keymapStyle.columnDistance / this._imageScale),
        height + (this._model.keymapStyle.rowDistance / this._imageScale)
      )
    );
    this._rectangle = new paper.Path.Rectangle(rectangle);

    this._rectangle.fillColor = this._model.keymapStyle.background ?
      (this._model.keymapStyle.backgroundInBorderColor ?
        (this._model.keymapStyle.strokeColor || this._model.keymapStyle.backgroundColor || this._format.border.strokeColor) :
        (this._model.keymapStyle.backgroundColor || this._format.border.fillColor)) :
      undefined;
    if (this._rectangle.fillColor) {
      this._rectangle.fillColor.alpha = this._model.keymapStyle.opacity;
    }

    this._rectangle.strokeColor = this._model.keymapStyle.strokeColor || this._model.keymapStyle.color || this._format.border.strokeColor;
    this._rectangle.strokeWidth = (this._model.keymapStyle.border ?
      this._model.keymapStyle.strokeWidth || this._format.border.strokeWidth :
      0)
      / this._imageScale;
    this._element.dashArray = [1, 0];
    if (this._model?.freeFormStyle?.dash) {
      const [dashLength, gapLength]: string[] = this._model.freeFormStyle.dash.split(',');
      if (dashLength && gapLength) {
        const factor =  this._marker?._model?.freeFormStyle?.strokeWidth ? this._marker._model.freeFormStyle.strokeWidth : 1;
        this._element.dashArray = [
          Number.parseInt(dashLength, 10) * factor,
          Number.parseInt(gapLength, 10) * factor
        ];
      }
    }
    this._rectangle['keymap'] = this;

    if (this._model.keymapStyle.columnSeparators) {
      this.drawColumnSeparator(columnWidth, source.x, source.y);
    }

    if (this._model.keymapStyle.rowSeparators) {
      this.drawRowSeparator(rowHeight, source.x, source.y);
    }
    this._element.addChild(this._rectangle);
  }

  drawColumnSeparator(columnWidth, x, y) {
    const height = this._element.bounds._height;
    for (let column = 0; column < columnWidth.length - 1; column++) {
      x += columnWidth[column] + (this._model.keymapStyle.columnDistance / this._imageScale);
      const line = new paper.Path.Line(
        new paper.Point(x, y),
        new paper.Point(x, y + height + (this._model.keymapStyle.rowDistance / this._imageScale))
      );
      line.strokeColor = this._format.strokeColor;
      line.strokeWidth = this._format.strokeWidth / this._imageScale;
      this._element.addChild(line);
    }
  }

  drawRowSeparator(rowHeight, x, y) {
    const width = this._element.bounds._width;
    for (let row = 0; row < rowHeight.length - 1; row++) {
      y += (this._model.keymapStyle.rowDistance / this._imageScale) + rowHeight[row];
      const line = new paper.Path.Line(
        new paper.Point(x, y),
        new paper.Point(x + width + (this._model.keymapStyle.columnDistance / this._imageScale), y)
      );
      line.strokeColor = this._format.strokeColor;
      line.strokeWidth = this._format.strokeWidth / this._imageScale;
      this._element.addChild(line);
    }
  }

  countIndexDigits() {
    let max = 0;
    this._model.columns.forEach(currentColumn => {
      let columnMax = 0;
      currentColumn.labels.forEach(currentCell => {
        // tslint:disable-next-line:no-bitwise
        if (currentCell.index >>> 0 === Number.parseFloat(currentCell.index)) {
          columnMax = Math.max(Math.log10(currentCell.index === 0 ? 1 : currentCell.index), columnMax);
        } else {
          columnMax = Math.max(currentCell.index.length - 1, columnMax);
        }
      });
      max = Math.max(columnMax, max);
    });

    return Math.floor(max) + 1;
  }

  arrange() {
    this.draw();
  }

  cleanup() {
    this._element.removeChildren();
    super.cleanUp();
  }

  scale(zoom) {
    if (this._labels.length > 0) {
      this._labels[0].setScale(zoom);
    }
  }

  removeSelection() {
    if (this._selection) {
      this._selection.cleanUp();
      this._selection = null;
    }
  }

  /**
   * When no label is present in the keymap model, the image can not be drawn
   * @returns {[null]}
   */
  getDefaultLabel() {
    return [[
      {
        'index': 1,
        'text': 'Legende 1',
        'pivot': 'bottomCenter'
      }
    ]];
  }
}

export default Keymap;
