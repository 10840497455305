import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { UpdateUser, UpdateUserInvalid, AddAlert, GetUserById, UpdateUserSuccess } from '@medsurf/actions';
import { SETTINGS, Settings } from '@medsurf/services';
import { UpdateUserValidator } from '@medsurf/validators';
import { AlertType, User } from '@medsurf/models';
import { AuthState, UserState } from '@medsurf/state';
import { DEFAULT_VALUES } from '../../common/constants/default-values';
import { Observable, Subject } from 'rxjs';
import { AbstractControl } from '@angular/forms';
import { ClassValidatorFormBuilderService, ClassValidatorFormGroup } from 'ngx-reactive-form-class-validator';
import { assign, cloneDeep } from 'lodash';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'medsurf-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  /**
   * Members
   */
  @Select(AuthState.user)
  public currentUser$: Observable<User>;

  @Select(UserState.user)
  public user$: Observable<User>;

  private user: User;
  public changePassword = false;
  public useLegacyEditor = false;
  public profileForm: ClassValidatorFormGroup;

  private _destroyed = new Subject<boolean>();

  /**
   * Constructor
   * @param store
   * @param actions$
   * @param translate
   * @param fb
   */
  constructor(
    private store: Store,
    private actions$: Actions,
    private fb: ClassValidatorFormBuilderService,
    @Inject(DEFAULT_VALUES) public defaultValues,
    @Inject(SETTINGS) public settings: Settings) {
  }

  /**
   * NgOnInit
   */
  ngOnInit() {
    this.useLegacyEditor = localStorage.getItem(this.key) === 'true';
    this.profileForm = this.fb.group(UpdateUserValidator, {
      firstName: [''],
      lastName: [''],
      email: [''],
      password: [''],
      password_confirm: ['']
    }, {
      validators: this.passwordConfirming
    });

    this.currentUser$.pipe(takeUntil(this._destroyed)).subscribe((user: User) => {
      this.store.dispatch(new GetUserById(user.id));
    });
    this.user$.pipe(takeUntil(this._destroyed)).subscribe((user: User) => {
      if (user) {
        this.profileForm.patchValue(user);
        this.user = user;
      }
    });
    this.actions$.pipe(ofActionDispatched(UpdateUserInvalid), takeUntil(this._destroyed)).subscribe((action: UpdateUserInvalid) => {
      action.errors.forEach((err) => {
        this.profileForm.get(err.property).setErrors(err.constraints);
      });
    });

    this.actions$.pipe(ofActionDispatched(UpdateUserSuccess), takeUntil(this._destroyed)).subscribe(() => {
      this.changePassword = false;
      this.f.password.setValue('');
      this.f.password_confirm.setValue('');
      this.store.dispatch(new AddAlert({
        message: 'updated',
        type: AlertType.SUCCESS,
        duration: 5
      }));
    });
  }

  get f() {
    return this.profileForm.controls;
  }

  private passwordConfirming(c: AbstractControl) {
    if (c.get('password').value !== c.get('password_confirm').value) {
      c.get('password_confirm').setErrors({mustMatch: 'passwords_do_not_match'});
    } else {
      c.get('password_confirm').setErrors(null);
    }
  }

  public togglePasswordChange(e) {
    this.changePassword = e.target.checked;
    this.f.password.setValue('');
    this.f.password_confirm.setValue('');
  }

  public onSubmit() {
    if (this.profileForm.invalid) {
      return;
    }

    const formUser = cloneDeep(this.profileForm.value);

    delete formUser.password_confirm;
    if (formUser.password.length === 0) {
      delete formUser.password;
    }

    localStorage.setItem(this.key, this.useLegacyEditor.toString());

    assign(this.user, formUser);
    this.store.dispatch(new UpdateUser());
  }

  private get key() {
    return 'settings.editor.useLegacyEditor';
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
