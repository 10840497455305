'use strict';

import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Role, User } from '@medsurf/models';
import { AuthState } from '@medsurf/state';
import { Store } from '@ngxs/store';

@Directive({
  selector: '[medsurfRestrict]'
})
export class RestrictDirective implements OnInit {
  @Input() access: string;

  constructor(private el: ElementRef,
              private store: Store) {}

  ngOnInit(): void {
    const user: User = this.store.selectSnapshot(AuthState.user);
    let accessDenied = true;
    this.access.split(',').forEach(function (role: Role) {
      if (user && user.role === role) {
        accessDenied = false;
      }
    });
    if (accessDenied) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
