import { ConfigSettings } from './config';

export const getBaseConfig = (settings: ConfigSettings) => {
  return {
    base_url: '/tinymce',
    suffix: '.min',
    min_height: settings.minHeight,
    height: settings.height,
    menubar: false,
    toolbar_mode: 'sliding',
    language: 'de',
    convert_urls: false,
    language_url: '/assets/tinymce/langs/de.js',
    branding: true,
    placeholder: settings.placeholder ?? '',
    keep_styles: false,
    end_container_on_empty_block: 'blockquote, div',
  };
};
