import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Slide, Annotation, ImagePosition, PointOfInterest, Bounds, Layer, Image } from '@medsurf/models';
import { SetShowOnlyPoisOfImage, SlideChanged } from '@medsurf/actions';
import { DragulaService } from 'ng2-dragula';
import { v4 } from 'uuid';
import { SlideState } from '@medsurf/state';

type PointOfInterestExpandable = PointOfInterest & { isExpanded?: boolean } 

@Component({
  selector: 'medsurf-pois',
  templateUrl: './pois.component.html',
  styleUrls: ['./pois.component.scss']
})
export class PoisComponent {
  @Output() goToPoi = new EventEmitter();
  @Output() setHoverMarker = new EventEmitter<Annotation>();

  @Input() marker: Annotation;
  @Input() slide: Slide;
  @Input() currentPosition: ImagePosition;
  @Input() currentBounds: Bounds;
  @Input() sequenceId: number;
  @Input() layerId: number;

  public newPoiAdded = false;

  @Select(SlideState.poiToImageMap)
  public poiToImageMap$: typeof SlideState.poiToImageMap;

  @Select(SlideState.imagePoiSet)
  public imagePoiSet$: typeof SlideState.imagePoiSet;

  @Select(SlideState.displayedPois)
  public displayedPois$: typeof SlideState.displayedPois;

  @Select(SlideState.image)
  public image$: typeof SlideState.image;

  @Select(SlideState.showOnlyPoisOfImage)
  public showOnlyPoisOfImage$: typeof SlideState.showOnlyPoisOfImage;

  @Select(SlideState.imageAnnotationSet)
  public imageAnnotationSet$: typeof SlideState.imageAnnotationSet;

  constructor(
    private store: Store,
    private dragulaService: DragulaService,
  ) {
    dragulaService.destroy("POI");
    dragulaService.createGroup("POI", {
      moves: (el, container, handle) => {
        const h = el.querySelector('.handle');
        return handle.classList.contains('handle') || h.contains(handle);
      }
    });
  }

  public trackById(index: number, entity: { id: string}) {
    return entity.id || index;
  }

  public setShowOnlyPoisOfImage(value: boolean) {
    this.store.dispatch(new SetShowOnlyPoisOfImage(value));
  }

  public updatePoiImage(poi: PointOfInterest, images: Image[], add: boolean) {
    images.forEach(image => {
      const index = image.pois.findIndex((p) => p.id === poi.id);
      if (add && index === -1) {
        image.pois.push({ id: poi.id });
      } else if (!add && index !== -1) {
        image.pois.splice(index, 1);
      }
    })
    this.store.dispatch(new SlideChanged());
  }

  onChange() {
    this.store.dispatch(new SlideChanged());
  }

  public onSortPois(displayedPois: PointOfInterest[]) {
    const paritalPois = displayedPois.reduce<{[key: string]: PointOfInterest}>((map, poi) => { map[poi.id]= poi; return map; }, {})
    this.slide.pois.forEach((poi, index) => {
      if (paritalPois[poi.id]) {
        this.slide.pois[index] = displayedPois.shift();
      }
    })
    this.slide.pois.forEach((poi, i) => {
      poi.order = i;
    })
    this.onChange();
  }

  public addPoi() {
    const order = Math.max(-1,
      ...this.slide.pois.map((imagePoi: PointOfInterest) => imagePoi.order)
    ) + 1;

    const poi: PointOfInterestExpandable = {
      id: v4(),
      name: 'Neuer Poi',
      notes: [],
      annotations: [],
      position: { x: this.currentPosition.x, y: this.currentPosition.y },
      zoom: this.currentPosition.zoom,
      bounds: { ...this.currentBounds },
      order,
      isExpanded: true
    };

    this.newPoiAdded = true;
    this.slide.layers[this.layerId].images[this.sequenceId].pois = [...this.slide.layers[this.layerId].images[this.sequenceId].pois, poi];
    this.slide.pois = [...this.slide.pois, poi];
    this.onChange();
  }

  public goTo(poi: PointOfInterest) {
    this.goToPoi.emit(poi);
  }

  public removePoi(poi: PointOfInterest) {
    this.slide.layers.forEach(layer => {
      layer.images.forEach(image => {
        image.pois = image.pois.filter(p => p.id !== poi.id)
      })
    })
    poi.deleted = true;
    this.newPoiAdded = false;
    this.onChange();
  }
}
