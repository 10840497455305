import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { ChangelogComponent } from './changelog/changelog.component';
import { ChaptersComponent } from './chapters/chapters.component';
import { ViewerGuard } from './common/guards/viewer.guard';
import { DocumentationComponent } from './documentation/documentation.component';
import { SearchComponent } from './search/search.component';
import { LoginComponent } from './user/login/login.component';
import { SlideDetailComponent } from './slide-detail/slide-detail.component';
import { UserDetailComponent } from './admin/user-detail/user-detail.component';
import { UsersComponent } from './admin/users/users.component';
import { ConstantListComponent, ConstantType } from './admin/constant-list/constant-list.component';
import { ConstantDetailComponent } from './admin/constant-detail/constant-detail.component';
import { MetadataComponent } from './admin/metadata/metadata.component';
import { AuthGuard, OrderedChainGuard, UserGuard, RedirectGuard} from '@medsurf/module';
import { MediaLibraryComponent } from './admin/media-library/media-library.component';
import { DetachedSlidesComponent } from './admin/detached-slides/detached-slides.component';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';

const navigationMatcher = (url: UrlSegment[]): UrlMatchResult => {
  if (url.length <= 4) {
    const emptyUrlSegment = new UrlSegment(null, {});
    return {
      consumed: url,
      posParams: {
        eLearningSlug: url[0] || emptyUrlSegment,
        subjectSlug: url[1] || emptyUrlSegment,
        chapterSlug: url[2] || emptyUrlSegment,
        blockSlug: url[3] || emptyUrlSegment,
      }
    };
  }
  return null;
};

const appRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot_password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset_password',
    component: ResetPasswordComponent
  },
  {
    path: 'profile',
    component: UserProfileComponent,
    canActivate: [OrderedChainGuard],
    data: {
      title: 'user_profile',
      orderedGuards: [
        AuthGuard,
        UserGuard,
        ViewerGuard
      ]
    }
  },
  {
    path: 'media',
    component: MediaLibraryComponent,
    canActivate: [OrderedChainGuard],
    data: {
      title: 'media_library',
      orderedGuards: [
        AuthGuard,
        UserGuard,
        ViewerGuard
      ]
    }
  },
  {
    path: 'detached',
    component: DetachedSlidesComponent,
    canActivate: [OrderedChainGuard],
    data: {
      title: 'detached_slides',
      orderedGuards: [
        AuthGuard,
        UserGuard,
        ViewerGuard
      ]
    }
  },
  {
    path: 'metadata',
    component: MetadataComponent,
    canActivate: [OrderedChainGuard],
    data: {
      title: 'metadata',
      orderedGuards: [
        AuthGuard,
        UserGuard,
        ViewerGuard
      ]
    }
  },
  {
    path: 'modality',
    component: ConstantListComponent,
    canActivate: [OrderedChainGuard],
    data: {
      constantType: ConstantType.MODALITY,
      title: 'modality',
      orderedGuards: [
        AuthGuard,
        UserGuard,
        ViewerGuard
      ]
    }
  },
  {
    path: 'modality/detail',
    component: ConstantDetailComponent,
    canActivate: [OrderedChainGuard],
    data: {
      constantType: ConstantType.MODALITY,
      title: 'modality',
      orderedGuards: [
        AuthGuard,
        UserGuard,
        ViewerGuard
      ]
    }
  },
  {
    path: 'stain',
    component: ConstantListComponent,
    canActivate: [OrderedChainGuard],
    data: {
      constantType: ConstantType.STAIN,
      title: 'stain',
      orderedGuards: [
        AuthGuard,
        UserGuard,
        ViewerGuard
      ]
    }
  },
  {
    path: 'stain/detail',
    component: ConstantDetailComponent,
    canActivate: [OrderedChainGuard],
    data: {
      constantType: ConstantType.STAIN,
      title: 'stain',
      orderedGuards: [
        AuthGuard,
        UserGuard,
        ViewerGuard
      ]
    }
  },
  {
    path: 'copyright',
    component: ConstantListComponent,
    canActivate: [OrderedChainGuard],
    data: {
      constantType: ConstantType.COPYRIGHT,
      title: 'copyright',
      orderedGuards: [
        AuthGuard,
        UserGuard,
        ViewerGuard
      ]
    }
  },
  {
    path: 'copyright/detail',
    component: ConstantDetailComponent,
    canActivate: [OrderedChainGuard],
    data: {
      constantType: ConstantType.COPYRIGHT,
      title: 'copyright',
      orderedGuards: [
        AuthGuard,
        UserGuard,
        ViewerGuard
      ]
    }
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [OrderedChainGuard],
    data: {
      title: 'user_administration',
      orderedGuards: [
        AuthGuard,
        UserGuard,
        ViewerGuard
      ]
    }
  },
  {
    path: 'user/:userSlug',
    component: UserDetailComponent,
    canActivate: [OrderedChainGuard],
    data: {
      title: 'user_administration',
      orderedGuards: [
        AuthGuard,
        UserGuard,
        ViewerGuard
      ]
    }
  },
  {
    path: 'slide/:slideId',
    component: SlideDetailComponent,
    canActivate: [OrderedChainGuard],
    data: {
      orderedGuards: [
        AuthGuard,
        UserGuard,
        ViewerGuard
      ]
    }
  },
  {
    path: 'node/:id',
    canActivate: [RedirectGuard],
    component: SlideDetailComponent
  },
  {
    path: 'documentation',
    component: DocumentationComponent,
    canActivate: [OrderedChainGuard],
    data: {
      title: 'help',
      orderedGuards: [
        AuthGuard,
        UserGuard,
        ViewerGuard
      ]
    }
  },
  {
    path: 'changelog',
    component: ChangelogComponent,
    canActivate: [OrderedChainGuard],
    data: {
      title: 'help',
      orderedGuards: [
        AuthGuard,
        UserGuard,
        ViewerGuard
      ]
    }
  },
  {
    path: 'search',
    component: SearchComponent,
    canActivate: [OrderedChainGuard],
    data: {
      title: 'help',
      orderedGuards: [
        AuthGuard,
        UserGuard,
        ViewerGuard
      ]
    }
  },
  {
    matcher: navigationMatcher,
    component: ChaptersComponent,
    canActivate: [OrderedChainGuard],
    data: {
      index: true,
      title: 'index',
      orderedGuards: [
        AuthGuard,
        UserGuard,
        ViewerGuard
      ]
    }
  },
  {
    path: ':eLearningSlug/:subjectSlug/:chapterSlug/:blockSlug/:slideSlug',
    component: SlideDetailComponent,
    canActivate: [OrderedChainGuard],
    data: {
      title: 'slide_edit',
      orderedGuards: [
        AuthGuard,
        UserGuard,
        ViewerGuard
      ]
    }
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'ignore'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
