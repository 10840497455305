<h3 class="mt-4 mb-2 text-secondary">{{ 'chapter' | translate}}-{{ 'pdf' | translate}}</h3>

<div class="form-group">
  <div class="d-flex d-flex align-items-start">

    <div class="form-group">
      <div class="align-items-start" *ngIf="!generating">
        <div class="mb-2">
          <div>
            <strong>Viewer: </strong>
            <a *ngIf="selectedBlock.page?.pdfMedia" target="_blank" [href]="mediaService.getUrl(selectedBlock.page.pdfMedia)" >
              {{selectedBlock.page.pdfMedia.name}} ({{ selectedBlock.page.pdfMedia.uploadedAt | date:'dd.MM.yyyy HH:mm' }}) 
              <span class="fa fa-download"></span>
            </a>
            <span *ngIf="!selectedBlock.page?.pdfMedia">{{ 'not_yet_generated' | translate }}</span>
          </div>
          <div>
            <strong>Autoren: </strong>
            <a *ngIf="selectedBlock.page?.pdfMediaAuthor" target="_blank" [href]="mediaService.getUrl(selectedBlock.page.pdfMediaAuthor)">
              {{selectedBlock.page.pdfMediaAuthor.name}} ({{ selectedBlock.page.pdfMediaAuthor.uploadedAt | date:'dd.MM.yyyy HH:mm' }}) 
              <span class="fa fa-download"></span>
            </a>
            <span *ngIf="!selectedBlock.page?.pdfMediaAuthor">{{ 'not_yet_generated' | translate }}</span>
          </div>
        </div>

        <div class="mb-2">
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-primary" id="generatePdf" ngbDropdownToggle>
              {{ 'generate_new' | translate }}
            </button>
            <div ngbDropdownMenu aria-labelledby="generatePdf">
              <button ngbDropdownItem (click)="generatePdf(false)">{{ 'generate_pdf' | translate}}</button>
              <button ngbDropdownItem (click)="generatePdf(true)">{{ 'generate_pdf_force_slide_generation' | translate}}</button>
              <div class="dropdown-divider"></div>
              <button ngbDropdownItem (click)="generateAuthorPdf(false)">{{ 'generate_author_pdf' | translate}}</button>
              <button ngbDropdownItem (click)="generateAuthorPdf(true)">{{ 'generate_author_pdf_force_slide_generation' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="generating">
        <div class="spinner-border"></div>
      </div>
    </div>
  </div>
</div>
