<div class="d-flex flex-col h-100 scrollable">
  <medsurf-navigation></medsurf-navigation>
  <div class="panel panel-default scrollable d-flex flex-col">
    <div class="panel-heading">
      <div class="btn-group btn-group-toggle" ngbDropdown container="body">
        <label container="body" class="btn btn-secondary" ngbDropdownToggle>
          <span >{{ 'bulk_actions' | translate }} ({{ bulkSelectedSlides?.length || 0 }})</span>
        </label>
        <div class="dropdown-menu px-1 py-2" ngbDropdownMenu>
          <button ngbDropdownItem [disabled]="!bulkSelectedSlides || bulkSelectedSlides.length === 0" (click)="bulkAddSlideIds()">{{ 'add_slide_ids' | translate }}</button>
            <button ngbDropdownItem [disabled]="!bulkSelectedSlides || bulkSelectedSlides.length === 0" (click)="bulkDeleteSlides()">{{ 'delete_selected_slides' | translate }}</button>
        </div>
      </div>
    </div>
    <div class="panel-body panel-body-full-margin">
      <div class="container-fluid thumbnail-container">
        <div 
          class="card panel-grid"
          [id]="slide.id"
          *ngFor="let slide of (detachedSlides$ | async)"
          [class.d-none]="slide.deleted"
        >
          <div class="card-image-container" (dblclick)="selectSlide(slide)">
            <img
              class="card-image"
              [src]="slide.thumbnailMedia ? mediaService.getUrl(slide.thumbnailMedia, 'thumbnail') : '/assets/images/no-image.png'"
            />
            <div class="bulk-select-checkbox">
              <input type="checkbox" [value]="slide.id" [checked]="isBulkSelected(slide)" (click)="toggleBulkSelect(slide, $event)">
            </div>

            <div class="number-container">
              <span class="badge badge-light">{{ slide.slideId }}</span>
            </div>

            <div class="label-container">
              <span class="badge badge-light text-info" [title]="slide.status | translate">
                <span [class]="slideService.getSlideStatusIcon(slide)"></span>
              </span>
              <span class="badge badge-light" [title]="mediaService.getMediaTypeLabel({ type: slide.mediaType }) | translate">
                <span [class]="'fa ' + mediaService.getMediaTypeIcon({ type: slide.mediaType })"></span>
              </span>
              <span *ngIf="slide.type === 'Training'" class="badge badge-light" [title]="'quiz' | translate">
                <span class="fas fa-question-square"></span>
              </span>
            </div>
          </div>
          <div class="card-body" (click)="selectSlide(slide)">
            <div class="card-title truncate">{{ slide.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Media remove confirmation modal -->
<ng-template #ConfirmBulkRemoveDialog let-modal>
  <header class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">{{ 'bulk_remove_slides' | translate }}</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Button')">
          <span aria-hidden="true">&times;</span>
      </button>
  </header>
  <div class="modal-body">
    <p>{{ 'bulk_remove_slides_confirm' | translate }}</p>
    <ngb-accordion #acc="ngbAccordion">
      <ngb-panel type="light" *ngFor="let slide of bulkSelectedSlides">
        <ng-template ngbPanelTitle>
          <img *ngIf="slide.thumbnailMedia" [src]="mediaService.getUrl(slide.thumbnailMedia, 'thumbnail')" class="thumbnail-sm mr-3" [alt]="slide.thumbnailMedia.altText">
          <div class="thumbnail-sm border bg-primary text-dark mr-3" *ngIf="!slide.thumbnailMedia">
            <span [class]="'fa ' + mediaService.getMediaTypeIcon({ type: slide.mediaType })"></span>
          </div>
          <span>{{ slide.title }}</span>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-container>
            <p>{{ 'is_not_used' | translate }}</p>
          </ng-container>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <footer class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">{{ 'cancel' | translate }}</button>
      <button type="button" class="btn btn-danger" (click)="modal.close()">{{ 'delete' | translate }}</button>
  </footer>
</ng-template>


