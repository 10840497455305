import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ChaptersMainComponent } from './main/chapters-main.component';
import { TreeEvent } from './sidebar/sidebar.component';

import * as MedsurfActions from '@medsurf/actions';
import * as MedsurfState from '@medsurf/state';
import * as MedsurfModels from '@medsurf/models';
import * as MedsurfServices from '@medsurf/services';

import { SlideEvent } from './main/grid/grid.component';
import { Actions, ofActionCompleted, ofActionDispatched, Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { cloneDeep, omit } from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationState } from '@medsurf/state';

@Component({
  selector: 'medsurf-chapters',
  templateUrl: './chapters.component.html',
  styleUrls: ['./chapters.component.scss']
})
export class ChaptersComponent implements OnInit, OnDestroy {

  @Select(MedsurfState.IndexState.isDirty)
  public isIndexDirty$: Observable<boolean>;

  @Select(MedsurfState.SlideState.isDirty)
  public isSlidesDirty$: Observable<boolean>;

  @Select(MedsurfState.IndexState.index)
  public subjects$: Observable<MedsurfModels.ElearningNode[]>;

  @Select(MedsurfState.SharedHistoryState.canUndo('index'))
  public canUndo$: Observable<boolean>;

  @Select(MedsurfState.SharedHistoryState.canRedo('index'))
  public canRedo$: Observable<boolean>;

  @ViewChild('ConfirmBulkRemoveDialog')
  public confirmBulkRemoveDialog: TemplateRef<any>;

  @ViewChild('chaptersMain')
  private chaptersMain: ChaptersMainComponent;

  public bulkSelectedSlideIds: string[] = [];

  public backState: { state: string } = {state: 'subjects'};
  public message: string;
  public currentItem: MedsurfModels.Node;
  public isCurrentItemLocked: boolean;
  public slidesToDelete: { slide: MedsurfModels.Slide; nodes: MedsurfModels.Node[] }[] = [];
  private _destroyed = new Subject<boolean>();

  constructor(
    @Inject(MedsurfServices.SETTINGS) private settings: MedsurfServices.Settings,
    private router: Router,
    private translate: TranslateService,
    private nodeService: MedsurfServices.NodeService,
    private store: Store,
    private route: ActivatedRoute,
    private actions$: Actions,
    public modalService: NgbModal,
    public mediaService: MedsurfServices.MediaService
  ) {
    /* TODO remove * /
    const note1_1 = {id: uuid(), order: 0, name: "1", text: "ein Test 1", title: "test 1"};
    this.store.dispatch(new NoteEntityActions.AddEntity(note1_1));
    this.store.dispatch(new HistoryControlActions.FinishSnapshot());

    const note1_2 = this.store.selectSnapshot(NoteEntityState.entity(note1_1.id));
    note1_2.name = "blubber";
    this.store.dispatch(new NoteEntityActions.UpdateEntity(note1_2));
    this.store.dispatch(new HistoryControlActions.FinishSnapshot());

    const note2_1 = {id: uuid(), order: 0, name: "test2", text: "ein Test 2", title: "test 2"};
    this.store.dispatch(new NoteEntityActions.AddEntity(note2_1));
    this.store.dispatch(new HistoryControlActions.FinishSnapshot());

    const note1_3 = this.store.selectSnapshot(NoteEntityState.entity(note1_2.id));
    note1_3.name = "johannes der Säufende";
    this.store.dispatch(new NoteEntityActions.UpdateEntity(note1_3));
    this.store.dispatch(new HistoryControlActions.FinishSnapshot());

    this.store.dispatch(new HistoryControlActions.UndoSnapshot());
    this.store.dispatch(new HistoryControlActions.UndoSnapshot());
    this.store.dispatch(new HistoryControlActions.RedoSnapshot());
    this.store.dispatch(new HistoryControlActions.RedoSnapshot());

    this.store.dispatch(new HistoryControlActions.SaveSnapshots());

    /*
    const note2_2 = this.store.selectSnapshot(NoteEntityState.entity(note2_1.id));
    note2_2.title = "ddddddd";
    this.store.dispatch(new NoteEntityActions.UpdateEntity(note2_2));
    this.store.dispatch(new HistoryControlActions.FinishSnapshot());
    * /

    const note1_4 = this.store.selectSnapshot(NoteEntityState.entity(note1_3.id));
    this.store.dispatch(new NoteEntityActions.RemoveEntity(note1_4));
    // this.store.dispatch(new HistoryControlActions.FinishSnapshot());

    this.store.dispatch(new HistoryControlActions.UndoSnapshot());

    const note3_1 = {id: uuid(), order: 0, name: "3", text: "ein Test 3", title: "test 3"};
    this.store.dispatch(new NoteEntityActions.AddEntity(note3_1));
    this.store.dispatch(new HistoryControlActions.FinishSnapshot());

    /*
    const note3_2 = this.store.selectSnapshot(NoteEntityState.entity(note3_1.id));
    note3_2.title = "upsii";
    this.store.dispatch(new NoteEntityActions.UpdateEntity(note3_2))
    this.store.dispatch(new HistoryControlActions.FinishSnapshot());
    * /
    */
  }

  get isBlockSelected(): boolean {
    return this.currentItem ?
      (this.currentItem.page.type === MedsurfModels.PageType.GRID ||
        this.currentItem.page.type === MedsurfModels.PageType.CASE ||
        this.currentItem.page.type === MedsurfModels.PageType.QUIZ_RUN) :
      false;
  }

  ngOnInit() {
    this.actions$.pipe(takeUntil(this._destroyed), ofActionDispatched(MedsurfActions.GetRootNodesSuccess))
      .subscribe((action: MedsurfActions.GetRootNodesSuccess) => {
        this._selectCurrentRoute(action.nodes);
      });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionDispatched(MedsurfActions.GetNodesBySlideIdSuccess))
      .subscribe((action: MedsurfActions.GetNodesBySlideIdSuccess) => {
        if (action.slide) {
          const slide: MedsurfModels.Slide = action.slide;
          const nodes: MedsurfModels.SlideNode[] = action.nodes;
          this.slidesToDelete.push({slide, nodes});
        }
      });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionDispatched(MedsurfActions.SaveIndexSuccess))
      .subscribe((action: MedsurfActions.SaveIndexSuccess) => {
        this.translate.get(['updated']).subscribe((translations) => {
          this.message = translations.updated;
        });

        // Check if route was changed and navigate there
        const currentUrl = this.store.selectSnapshot(NavigationState.currentURL);
        const currentPath = this.store.selectSnapshot(NavigationState.currentPath);
        if (currentPath) {
          const realRoute = NavigationState.order.map(key => currentPath?.[key]?.route).filter(path => !!path).join('/');
          if (currentUrl !== realRoute) {
            this.router.navigateByUrl(realRoute, { replaceUrl: true });
            return;
          }
        }
        this.store.dispatch(new MedsurfActions.GetRootNodes());
      });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionCompleted(MedsurfActions.SharedHistory.Undo, MedsurfActions.SharedHistory.Redo))
      .subscribe(() => {
        const index: MedsurfModels.Node[] = this.store.selectSnapshot(MedsurfState.IndexState.index);
        this._selectCurrentRoute(index);
      });

    this.store.dispatch(new MedsurfActions.GetRootNodes());

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.store.dispatch(new MedsurfActions.GetNodeForUrl());
    });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionCompleted(MedsurfActions.GetNodeForUrlSuccess))
      .subscribe(() => {
        const index: MedsurfModels.Node[] = this.store.selectSnapshot(MedsurfState.IndexState.index);
        this._selectCurrentRoute(index);
      });
  }

  newSlide(isTraining: boolean) {
    this.translate.get(['new_title']).subscribe((translations) => {
      const newItem = this.nodeService.newNode(3, this.currentItem, translations.new_title, isTraining);
      setTimeout(() => this.chaptersMain.scrollToId(newItem.id), 500);
      this.store.dispatch(new MedsurfActions.SaveIndex());
    });
  }

  newElearning() {
    this.translate.get(['new_title']).subscribe((translations) => {
      const newItem = this.nodeService.newNode(0, null, translations.new_title);
      this._update(newItem);
    });
  }

  async deleteSlides(idstoDelete) {
    if (!idstoDelete || idstoDelete.length === 0) {
      return;
    }

    this.slidesToDelete = [];
    for (const id of idstoDelete) {
      this.store.dispatch(new MedsurfActions.GetNodesBySlideId(id));
    }

    await this.modalService.open(this.confirmBulkRemoveDialog, {size: 'xs'}).result;

    for (const id of idstoDelete) {
      const index = this.currentItem.children ?
        this.currentItem.children.findIndex((child) => (child.page as MedsurfModels.Slide).slideId === Number(id)) : -1;
      if (index !== -1) {
        const existingSlide: MedsurfModels.SlideNode = this.currentItem.children[index];
        existingSlide.page.deleted = true;
        existingSlide.deleted = true;
        this._update(this.currentItem.children[index]);
        continue;
      }

      this.store.dispatch(new MedsurfActions.GetSlide({slideId: id, withAnnotations: true}));
      const action: MedsurfActions.GetSlideSuccess = await this.actions$.pipe(ofActionDispatched(MedsurfActions.GetSlideSuccess), take(1)).toPromise();
      const slide: MedsurfModels.SlideNode = action.slide;
      slide.page.deleted = true;
      slide.deleted = true;
      this._update(this.currentItem.children[index]);
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(1);
        }, 200);
      });
    }
  }

  async linkSlides(idsToAdd) {
    if (!idsToAdd || idsToAdd.length === 0) {
      return;
    }

    for (const id of idsToAdd) {
      const index = this.currentItem.children ?
        this.currentItem.children.findIndex((child) => (child.page as MedsurfModels.Slide).slideId === Number(id)) : -1;
      const existingSlide: MedsurfModels.SlideNode = index !== -1 ? this.currentItem.children[index] : null;
      if (existingSlide && !existingSlide.page.deleted) {
        if (existingSlide.deleted) {
          delete existingSlide.deleted;
          this._update(this.currentItem.children[index]);
        } else {
          this.store.dispatch(new MedsurfActions.AddAlert({
            message: 'slide_already_linked_in_chapter',
            params: {slideTitle: `[${(existingSlide.page as MedsurfModels.Slide)?.slideId}] ${existingSlide.page?.title}`},
            type: MedsurfModels.AlertType.ERROR,
            duration: 3
          }));
        }
        continue;
      }

      this.store.dispatch(new MedsurfActions.GetSlide({slideId: id, withAnnotations: true}));
      const action: MedsurfActions.GetSlideSuccess = await this.actions$.pipe(ofActionDispatched(MedsurfActions.GetSlideSuccess), take(1)).toPromise()
      if (action.slide) {
        if (existingSlide?.page?.deleted) {
          delete existingSlide.page.deleted;
          this._update(this.currentItem.children[index]);
        }
        const newItem: MedsurfModels.SlideNode = {
          id: uuid(),
          children: [],
          page: action.slide
        };
        this.nodeService.addChildToNode(this.currentItem, newItem);
      } else {
        this.store.dispatch(new MedsurfActions.AddAlert({
          message: 'error_slide_not_found',
          params: {slideId: id},
          code: 404,
          duration: 10
        }));
        continue;
      }
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(1);
        }, 200);
      });
    }
  }

  async copySlides(idsToCopy: number[], withAnnotations = false, asTraining = false) {
    if (!idsToCopy || idsToCopy.length === 0) {
      return;
    }
    let lastId = null;

    for (const id of idsToCopy) {
      this.store.dispatch(new MedsurfActions.GetSlide({slideId: id, withAnnotations: true}));
      const action = await this.actions$.pipe(ofActionDispatched(MedsurfActions.GetSlideSuccess), take(1)).toPromise();
      const slide: MedsurfModels.Slide = action.slide;

      if (!slide) {
        this.store.dispatch(new MedsurfActions.AddAlert({
          message: 'error_slide_not_found',
          params: {slideId: id},
          code: 404,
          duration: 10
        }));
        continue;
      }

      slide.id = undefined;
      slide.slideId = undefined;

      if (asTraining) {
        slide.type = MedsurfModels.PageType.TRAINING;
      } else {
        slide.type = MedsurfModels.PageType.SLIDE;
      }

      const annotationMap = new Map(slide.annotations?.map(annotation => {
        const newId = uuid();
        return [annotation.id, {full: {...annotation, id: newId}, min: { id: newId }}]
      }) || []);

      const poiMap = new Map(slide.pois?.map(poi => {
        const newId = uuid();
        return [poi.id, {full: {...poi, id: newId}, min: { id: newId }}]
      }) || []);

      if (withAnnotations) {
        slide.annotations = slide.annotations?.map((annotation) => {
          const {full} = annotationMap.get(annotation.id);

          // reset ids for keymaps
          if (full.type === MedsurfModels.AnnotationType.KEYMAP) {
            const keymap = full as MedsurfModels.Keymap;
            if (keymap.keymapStyle) keymap.keymapStyle.id = undefined;
              keymap.columns?.sort((a, b) => (a.order > b.order) ? 1 : -1);
              keymap.columns = keymap.columns?.map(column => {
                column.id = undefined;

                column.labels?.sort((a, b) => (a.order > b.order) ? 1 : -1);
                column.labels = column.labels?.map(label => {
                  label.id = undefined;

                  if (annotationMap.has(label.annotation?.id)) {
                    label.annotation = annotationMap.get(label.annotation.id).min;
                  }
                  return label;
                }) || [];
                return column;
              }) || [];
          }

          // reset ids for freeforms
          if (full.type === MedsurfModels.AnnotationType.FREE_FORM) {
            const freeform = full as MedsurfModels.FreeForm;
            if (freeform.freeFormStyle) freeform.freeFormStyle.id = undefined;
            if (freeform.target) freeform.target.id = undefined;
          }

          // reset ids for annotations
          full.dirty = true;
          if (full.label) full.label.id = undefined;
          if (full.source) full.source.id = undefined;
          full.targets = full.targets?.map(target => ({...target, id: undefined })) || [];

          return full;
        }) || [];

        // reset ids of groups
        slide.groups = slide.groups?.map(group => {
          const newGroupId = uuid();

          // replace intext group references
          slide.body = slide.body?.replaceAll(group.id, newGroupId);
          slide.questions?.forEach((question) => {
            question.content = question?.content?.replaceAll(group.id, newGroupId);
            question.explanation = question?.explanation?.replaceAll(group.id, newGroupId);
            question?.choices?.forEach(choice => choice.label = choice.label?.replaceAll(group.id, newGroupId));
          });

          group.id = newGroupId;
          group.annotations = group.annotations?.map(annotation => annotationMap.get(annotation.id).min) || [];
          return group;
        }) || [];

        slide.pois = slide.pois?.map(poi => {
          const { full } = poiMap.get(poi.id);
          full.notes = full.notes?.map(note => ({...note, id: undefined})) || [];
          full.annotations = full.annotations?.map(annotation => annotationMap.get(annotation.id).min) || [];
          return full;
        }) || [];
      } else {
        slide.pois = [];
        slide.groups = [];
        slide.annotations = [];
      }

      // reset ids of layers and images
      slide.layers = slide.layers?.map(layer => {
        layer.id = undefined;
        layer.images = layer.images?.map(image => {
          image.id = undefined;
          if (withAnnotations) {
            image.pois = image.pois?.map(poi => poiMap.get(poi.id).min) || [];
            image.annotations = image.annotations?.map(annotation => annotationMap.get(annotation.id).min) || [];
          } else {
            image.pois = [];
            image.annotations = [];
          }
          return image;
        }) || [];
        return layer;
      }) || [];

      // reset ids of questions and choices
      slide.questions = slide.questions?.map(question => {
        question.id = undefined;
        question.choices = question.choices?.map(choice => ({ ...choice, id: undefined })) || [];
        return question;
      }) || [];

      const newItem: MedsurfModels.SlideNode = {
        id: uuid(),
        children: [],
        page: slide
      };
      this.nodeService.addChildToNode(this.currentItem, newItem);
      lastId = newItem.id;
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(1);
        }, 200);
      });
    }

    if (lastId) {
      this.chaptersMain.scrollToId(lastId);
    }
  }

  unlinkSlides(idsToRemove) {
    if (!idsToRemove || idsToRemove.length === 0) {
      return;
    }
    for (const id of idsToRemove) {
      const index = this.currentItem.children ? 
        this.currentItem.children.findIndex(node => (<MedsurfModels.Slide>node.page).slideId === Number(id)) : -1;
      if (index === -1) {
        continue;
      }
      this.currentItem.children[index].deleted = true;
      this._update(this.currentItem.children[index]);
    }
  }

  handleBulkSelection(event: SlideEvent) {
    const slideId = event.slide.page.slideId.toString();

    const index = this.bulkSelectedSlideIds.indexOf(slideId);
    if (index !== -1) {
      this.bulkSelectedSlideIds.splice(index, 1);
    } else {
      this.bulkSelectedSlideIds.push(slideId);
    }
  }

  handleIndexProperties() {
    this._update(this.currentItem);
  }

  handleUndo() {
    this.store.dispatch(new MedsurfActions.SharedHistory.Undo('index'));
  }

  handleRedo() {
    this.store.dispatch(new MedsurfActions.SharedHistory.Redo('index'));
  }

  gotoSlideDetail() {
    const path: string = this.store.selectSnapshot((state) => state.navigation.currentURL);
    const url = `${this.settings.viewerUrl}${path}`;
    open(url, '_medsurf_preview');
  }

  public selectItem(event: TreeEvent) {
    this.currentItem = event.item;
    this.isCurrentItemLocked = this.editLocked(this.currentItem);
    this.router.navigate([event.route], {replaceUrl: true});
  }

  public selectSlide(event: SlideEvent) {
    const currentRoute = this.store.selectSnapshot(state => state.navigation.currentURL);
    this.router.navigate([currentRoute, event.slide.route]);
  }

  public editLocked(node: MedsurfModels.Node) {
    return this.nodeService.editLocked(
      node,
      this.store.selectSnapshot(MedsurfState.IndexState.indexMap),
      this.store.selectSnapshot(MedsurfState.AuthState.user)
    );
  }

  public ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  private _update(node: MedsurfModels.Node) {
    const clonedNode: MedsurfModels.Node = omit<MedsurfModels.Node>(cloneDeep<MedsurfModels.Node>(node), 'children');
    this.store.dispatch(new MedsurfActions.IndexChanged([clonedNode]));
  }

  private _selectCurrentRoute(index: MedsurfModels.ElearningNode[]) {
    const currentRoute = this.store.selectSnapshot(NavigationState.currentURL);
    const treeEvent = this._expandPath(currentRoute.split('/'), index);
    if (treeEvent) {
      this.selectItem({item: treeEvent.item, route: currentRoute, locked: treeEvent.locked});
    } else {
      this.selectItem({item: index[0], route: index[0].route, locked: this.editLocked(index[0])});
    }
  }

  private _expandPath(segments: string[], nodes: MedsurfModels.Node[]): TreeEvent {
    const segment = segments.shift();
    for (const node of nodes) {
      if (node.route === segment || node.id === this.currentItem?.id) {
        if (segments.length > 0) {
          node.expanded = true;
          const treeEvent = this._expandPath(segments, node.children);
          if (treeEvent) {
            return {item: treeEvent.item, locked: this.editLocked(node)};
          } else {
            return null;
          }
        }
        node.expanded = node.id === this.currentItem?.id ? !!this.currentItem?.expanded : false;
        return {item: node, locked: this.editLocked(node)};
      }
    }
    return null;
  }
}
