import { Directive, ElementRef, HostListener } from '@angular/core';
import { clamp } from 'lodash';

@Directive({
  selector: '[medsurfAutoScroll]'
})
export class AutoScrollDirective {
  private isDragging: boolean;
  private isScrolling: boolean;
  private scrollTimeout: object;
  private direction: number;
  private stepSize = 10;

  constructor(
    private el: ElementRef
  ) {
  }

  private scroll() {
    if (this.scrollTimeout) {
      return false;
    }
    this.scrollTimeout = setTimeout(() => {
      this.el.nativeElement.scrollBy(0, this.stepSize * this.direction);
      this.scrollTimeout = null;
      if (this.isScrolling) {
        this.scroll();
      }
    }, 30);
  }

  @HostListener('mousedown')
  mousedown() {
    this.isDragging = true;
  }

  @HostListener('window:mousemove', ['$event'])
  mousemove(event) {
    if (this.isDragging) {
      const rect = this.el.nativeElement.getBoundingClientRect();
      const top = event.clientY - rect.top;
      const bottom = rect.bottom - event.clientY;

      this.stepSize = clamp(Math.abs(Math.min(top, bottom) - 50), 0, 100) / 3;
      if (top < 50) {
        this.isScrolling = true;
        this.direction = -1;
        this.scroll();
      } else if (bottom < 50) {
        this.isScrolling = true;
        this.direction = 1;
        this.scroll();
      } else {
        this.isScrolling = false;
        this.direction = 0;
      }
    }
  }

  @HostListener('window:mouseup')
  mouseup() {
    this.isDragging = false;
    this.isScrolling = false;
    this.direction = 0;
  }
}
