import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ConstantType } from '../constant-list/constant-list.component';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import {
  SaveCopyright,
  SaveModality,
  SaveStain,
  DeleteModality,
  DeleteStain,
  DeleteCopyright,
  GetCopyrights, GetModalities, GetStains
} from '@medsurf/actions';
import { Copyright, Modality, Stain, } from '@medsurf/models';
import { ConstantState } from '@medsurf/state';



@Component({
  selector: 'medsurf-constant-detail',
  templateUrl: './constant-detail.component.html',
  styleUrls: ['./constant-detail.component.scss']
})
export class ConstantDetailComponent implements OnInit {

  @Select(ConstantState.modalities)
  public modalities$: Observable<Modality[]>;
  @Select(ConstantState.stains)
  public stains$: Observable<Stain[]>;
  @Select(ConstantState.copyrights)
  public copyrights$: Observable<Copyright[]>;

  public constant: Modality & Stain & Copyright;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private store: Store) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(
        switchMap((params: ParamMap) => {
          if (params.has('id')) {
            return this.constantType$.pipe(
                switchMap((constantType) => {
                  switch (constantType) {
                    case ConstantType.STAIN:
                      this.store.dispatch(new GetStains());
                      return this.stains$.pipe(
                          filter((array) => array?.length > 0),
                          map((stains) => stains.find(stain => stain.id === params.get('id')))
                      );
                    case ConstantType.MODALITY:
                      this.store.dispatch(new GetModalities());
                      return this.modalities$.pipe(
                          filter((array) => array?.length > 0),
                          map((modalities) => modalities.find(modality => modality.id === params.get('id')))
                      );
                    case ConstantType.COPYRIGHT:
                      this.store.dispatch(new GetCopyrights());
                      return this.copyrights$.pipe(
                          filter((array) => array?.length > 0),
                          map((copyrights) => copyrights.find(copyright => copyright.id === params.get('id')))
                      );
                  }
                })
            );
          } else {
            return of({});
          }
        })
    ).subscribe((constant) => this.constant = JSON.parse(JSON.stringify(constant)));
  }

  private goBack() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  public get constantType$(): Observable<ConstantType> {
    return this.route.data.pipe(map(data => data.constantType));
  }

  public discardChanges() {
    this.goBack();
  }

  updateModality(modality: Modality) {
    this.store.dispatch(new SaveModality(modality));
    this.goBack();
  }

  updateStain(stain: Stain) {
    this.store.dispatch(new SaveStain(stain));
    this.goBack();
  }

  updateCopyright(copyright: Copyright) {
    this.store.dispatch(new SaveCopyright(copyright));
    this.goBack();
  }

  deleteModality(modality: Modality) {
    this.store.dispatch(new DeleteModality(modality));
    this.goBack();
  }

  deleteStain(stain: Stain) {
    this.store.dispatch(new DeleteStain(stain));
    this.goBack();
  }

  deleteCopyright(copyright: Copyright) {
    this.store.dispatch(new DeleteCopyright(copyright));
    this.goBack();
  }
}
