import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DEFAULT_VALUES } from '../../../../common/constants/default-values';
import {
  Slide,
  Annotation,
  PointOfInterest,
  LinkType,
  Marker,
  AnnotationType
} from '@medsurf/models';
import { AnnotationService } from '@medsurf/services';
import { SlideChanged } from '@medsurf/actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'medsurf-marker',
  templateUrl: './marker.component.html',
  styleUrls: ['./marker.component.scss']
})
export class MarkerComponent {

  @Input() marker: Annotation;
  @Input() currentPosition;

  public linkTypes: LinkType[] = [LinkType.SITE, LinkType.SLIDE];

  constructor(private store: Store,
              @Inject(DEFAULT_VALUES) public defaultValues,
              public annotationService: AnnotationService) {
  }

  onChange() {
    this.marker.dirty = true;
    this.store.dispatch(new SlideChanged());
  }

  public onToggleLabelBackground() {
    if (this.marker.label.background.opacity === 0) {
      this.marker.label.background.opacity = 0.4;
    } else {
      this.marker.label.background.opacity = 0;
    }
    this.onChange();
  }

  public changeEndType() {
    for (const target in this.marker.targets) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.marker.targets.hasOwnProperty(target)) {
        this.marker.targets[target].endType = this.marker.targets[0].endType;
      }
    }
    this.onChange();
  }

  public changeEndRadius() {
    for (const target in this.marker.targets) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.marker.targets.hasOwnProperty(target)) {
        this.marker.targets[target].endRadius = this.marker.targets[0].endRadius;
      }
    }
    this.onChange();
  }

  public addTarget() {
    this.annotationService.addTarget(this.marker, undefined, this.currentPosition.zoom);
    this.onChange();
  }

  public removeTarget() {
    this.annotationService.removeTarget(this.marker);
    this.onChange();
  }

  public removeMakerFromPoi(poi: PointOfInterest) {
    const index = poi?.annotations?.findIndex(a => a?.id === this.marker?.id);
    if (index !== -1) {
      poi.annotations.splice(index, 1);
      this.onChange();
    }
  }

  onSelfTestChange($event: any) {
    this.marker.selfTest.ignore = !$event.target.checked;
    this.onChange();
  }

  public selectLinkType(linkType: LinkType) {
    if (!this.marker.link) {
      this.marker.link = {};
    }
    this.marker.link.type = linkType;
    this.onChange();
  }

  public isMarker(marker: Marker): boolean {
    return marker.type === AnnotationType.MARKER;
  }
}
