import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Settings, SETTINGS, MediaService } from '@medsurf/services';
import { Image, Slide } from '@medsurf/models';
import { SlideChanged, SetSequenceNumber } from '@medsurf/actions';
import { SlideState } from '@medsurf/state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DragulaService } from 'ng2-dragula';
import { v4 } from 'uuid';

@Component({
  selector: 'medsurf-sequence-image',
  templateUrl: './sequence-image.component.html',
  styleUrls: ['./sequence-image.component.scss']
})
export class SequenceImageComponent {
  @Select(SlideState.layerNumber)
  public layerNumber$: Observable<number>;

  @Select(SlideState.sequenceNumber)
  public sequenceNumber$: Observable<number>;

  @Output() navigationChange = new EventEmitter();

  @Input() slide: Slide;

  constructor(
    private store: Store,
    private translate: TranslateService,
    public mediaService: MediaService,
    private dragulaService: DragulaService,
    @Inject(SETTINGS) private settings: Settings
  ) {
    dragulaService.destroy("IMAGE");
    dragulaService.createGroup("IMAGE", {
      moves: (el, container, handle) => {
        const h = el.querySelector('.handle');
        return handle.classList.contains('handle') || h.contains(handle);
      }
    });
  }

  public get layer() {
    const layerId = this.store.selectSnapshot<number>(state => state.slide.layerNumber);
    return this.slide.layers ? this.slide.layers[layerId] : null;
  }

  public setSequenceId(sequenceNumber) {
    this.store.dispatch(new SetSequenceNumber(sequenceNumber));
  }

  public addImage() {
    const layerId = this.store.selectSnapshot<number>(state => state.slide.layerNumber);
    // add image
    const newImage: Image = {
      id: v4(),
      background: {
        color: 'Standardwert',
      },
      link: {},
      annotations: [],
      pois: [],
      sequenceNumber: this.slide.layers[layerId].images.length
    };
    this.slide.layers[layerId].images.push(newImage);
    this.navigationChange.emit();
  }

  public onSequenceChanged() {
    const layerId = this.store.selectSnapshot<number>(state => state.slide.layerNumber);
    this.slide.layers[layerId].images.forEach((image, idx) => {
      image.sequenceNumber = idx;
    });
    this.navigationChange.emit();
  }

  public deleteImage(sequenceId, image) {
    const layerId = this.store.selectSnapshot<number>(state => state.slide.layerNumber);
    this.translate.get(['confirm_delete_image']).subscribe((translations) => {
      if (confirm(translations.confirm_delete_image)) {
        this.setSequenceId(0);
        if (this.slide.layers[layerId].images[sequenceId] !== undefined) {
          this.slide.layers[layerId].images.splice(sequenceId, 1);
        }
        if (image.$parent) {
          image.$parent.remove(image);
        }
        this.navigationChange.emit();
      }
    });
  }

  public setDefault(sequenceNumber) {
    const layerId = this.store.selectSnapshot<number>(state => state.slide.layerNumber);
    for ( const image of this.slide.layers[layerId].images) {
      image.default = false;
    }
    this.slide.layers[layerId].images[sequenceNumber].default = true;

    if (layerId === 0 && this.slide.layers[layerId].images[sequenceNumber].media) {
      this.slide.thumbnailMedia = this.slide.layers[layerId].images[sequenceNumber].media;
    }

    this.store.dispatch(new SlideChanged());
  }
}
