import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { AddAlert } from '@medsurf/actions';
import { Node, Media } from '@medsurf/models';
import { SETTINGS, Settings } from '@medsurf/services';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { merge, Observable } from 'rxjs';
import { UploadService } from '../../services/upload.service';
import { RejectedFile } from 'ngx-dropzone/lib/ngx-dropzone.service';
import { Store } from '@ngxs/store';

interface Upload {
  file?: File;
  chunksReturned?: number;
  chunksQuantity?: number;
  started?: boolean;
  completed?: boolean;
  uploaded?: boolean;
  error?: Error;
  result?: Media;
}

@Component({
  selector: 'medsurf-upload-dropzone',
  templateUrl: './upload-dropzone.component.html',
  styleUrls: ['./upload-dropzone.component.scss']
})
export class UploadDropzoneComponent {
  @Output() uploaded = new EventEmitter();

  @Input() node: Node;

  public uploading = false;
  public uploads: Upload[] = [];
  public totalFileSize = 0;

  constructor(
    @Inject(SETTINGS) private settings: Settings,
    private uploadService: UploadService,
    private store: Store
  ) {
  }

  public async onFileDrop(event: NgxDropzoneChangeEvent) {
    if (this.uploading) {
      return;
    }

    if (event.rejectedFiles.length > 0) {
      const rejection: RejectedFile = event.rejectedFiles[0];

      this.store.dispatch(new AddAlert({
        message: `file_upload_${rejection.reason}_error`,
        duration: 5
      }));
      return;
    }

    this.uploads = [];
    this.uploading = true;
    this.totalFileSize = 0;
    for (const file of event.addedFiles) {
      const upload: Upload = {
        file: file,
        chunksQuantity: 10,
        chunksReturned: 0,
        started: false,
        uploaded: false,
        completed: false,
        error: null
      };
      this.uploads.push(upload);
      this.totalFileSize += file.size;
    }

    if (this.totalFileSize > 1024 * 1024 * 1024) {
      const errorMessage = `file_upload_size_error`;
      this.store.dispatch(new AddAlert({
        message: errorMessage,
        duration: 5
      }));
      this.uploading = false;
      this.uploaded.emit(null);
      for (const upload of this.uploads) {
        upload.error = new Error(errorMessage);
      }
      return;
    }

    for (const upload of this.uploads) {
      // eslint-disable-next-line no-async-promise-executor
      await new Promise(async (resolve, reject) => {
        const sendObservables: Observable<any>[] = await this.uploadService.getSendObservables<any>(upload.file);
        const total = sendObservables.length;
        upload.started = true;
        upload.chunksQuantity = total;
        upload.chunksReturned++;
        upload.uploaded = total > 1 ? false : true;

        merge(...sendObservables).subscribe((response: any) => {
          upload.chunksReturned++;

          if (upload.chunksReturned === upload.chunksQuantity) {
            upload.uploaded = true;
          }

          if (response.status === 'finished') {
            upload.completed = true;
            upload.result = response.result;
            resolve(0);
          }
        }, (httpError: HttpErrorResponse) => {
          upload.error = new Error(httpError?.error?.error ?? httpError.message);
          resolve(1);
        });
      });
    }

    const results = this.uploads.filter(u => u.completed).map(u => u.result).slice(-1);
    const errors = this.uploads.filter(u => u.error);

    this.uploading = false;
    if (errors.length > 0) {
      this.uploaded.emit(null);
    } else {
      this.uploaded.emit(results[0] || null);
    }

  }
}
