import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ElearningNode, Folder, Node, SubjectNode } from '@medsurf/models';
import { Settings, SETTINGS } from '@medsurf/services';
import { IndexChanged } from '@medsurf/actions';
import { IndexState } from '@medsurf/state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GridComponent, SlideEvent } from './grid/grid.component';
import { Router } from '@angular/router';
import { TableComponent } from './table/table.component';

@Component({
  selector: 'medsurf-chapters-main',
  templateUrl: './chapters-main.component.html',
  styleUrls: ['./chapters-main.component.scss']
})
export class ChaptersMainComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public currentItem: Node;

  @Input()
  public isBlockSelected: boolean;

  @Input()
  public bulkSelectedSlideIds: string[];

  @Output()
  public handleIndexProperties = new EventEmitter<void>();

  @Output()
  public selectItem = new EventEmitter<void>();

  @Output()
  public selectBulk = new EventEmitter<SlideEvent>();

  @ViewChild('grid')
  private grid: GridComponent;

  @ViewChild('table')
  private table: TableComponent;

  public currentPage: Folder;
  public activePanel: string;
  public isSubject: boolean;
  private _destroyed = new Subject<boolean>();

  constructor(@Inject(SETTINGS) private settings: Settings,
              private http: HttpClient,
              private router: Router,
              private store: Store) {
  }

  ngOnInit(): void {
    this.isSubject = this.isSubjectTest();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes?.currentItem?.currentValue) {
      this.currentPage = this.currentItem.page
      this.activePanel = this.isBlockSelected ? 'panel.grid' : 'panel.description';
      this.isSubject = this.isSubjectTest();
    }
  }

  public handleCommentsChange() {
    this.store.dispatch(new IndexChanged([this.currentItem]));
  }

  private isSubjectTest(): boolean {
    const elearnings = this.store.selectSnapshot(IndexState.index);
    if (!elearnings || !this.currentItem) {
      return false;
    }
    return elearnings.some((elearning: ElearningNode) =>
      elearning?.children?.some((subject: SubjectNode) => subject.page.id === this.currentItem.page.id));
  }

  selectSlide(event: SlideEvent) {
    const currentRoute = this.store.selectSnapshot(state => state.navigation.currentURL);
    this.router.navigate([currentRoute, event.slide.route]);
  }

  public scrollToId(id: string): void {
    this.grid?.scrollToId(id);
    this.table?.scrollToId(id);
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }

}
