<h3 class="mt-4 mb-4 text-secondary">{{ 'quiz' | translate}}</h3>


<ng-container *ngIf="(rootTrainingNode$ | async); let rootTrainingNode">
  <!--<button (click)="generateQuizRun()" class="btn btn-primary">Generate QuizStart</button>-->

  <div class="card mb-3" *ngFor="let chapter of rootTrainingNode.children">
    <div class="card-header bg-primary">
      <h3 class="card-title text-white">{{  chapter.page.title }}</h3>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item" *ngFor="let block of chapter.children">
        {{ block.page.title }} <a><span class="badge badge-secondary float-right" (click)="goTo(chapter, block)">{{ block.children.length }}</span></a>
      </li>

    </ul>

  </div>
</ng-container>

