import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MedsurfLibModule } from '@medsurf/module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DragulaModule } from 'ng2-dragula';
import { MedsurfCommonModule } from '../common/medsurf-common.module';
import { PanelModule } from '../slide-detail/panel/panel.module';
import { ChaptersComponent } from './chapters.component';
import { GridComponent } from './main/grid/grid.component';
import { ChaptersMainComponent } from './main/chapters-main.component';
import { ThemeColorComponent } from './main/settings/theme-color/theme-color.component';
import { CoverImageComponent } from './main/cover-image/cover-image.component';
import { ExportComponent } from './main/export/export.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FormsModule } from '@angular/forms';
import { VocabularyComponent } from './main/vocabulary/vocabulary.component';
import { TypeComponent } from './main/settings/type/type.component';
import { SettingsComponent } from './main/settings/settings.component';
import { TableComponent } from './main/table/table.component';
import { TitleComponent } from './main/title/title.component';
import { QuizStartComponent } from './main/quiz-start/quiz-start.component';
import { PdfComponent } from './main/settings/pdf/pdf.component';

@NgModule({
  declarations: [
    ChaptersComponent,
    ChaptersMainComponent,
    ThemeColorComponent,
    CoverImageComponent,
    ExportComponent,
    GridComponent,
    SidebarComponent,
    VocabularyComponent,
    TypeComponent,
    SettingsComponent,
    TableComponent,
    TitleComponent,
    QuizStartComponent,
    PdfComponent,
  ],
  imports: [
    CommonModule,
    MedsurfCommonModule,
    TranslateModule,
    MedsurfLibModule,
    NgbModule,
    DragulaModule,
    FormsModule,
    PanelModule,
    NgOptimizedImage
  ]
})
export class ChaptersModule {
}
