<div class="d-flex flex-col h-100 scrollable">
  <medsurf-navigation></medsurf-navigation>
  <div class="panel panel-default scrollable d-flex flex-col">
    <div class="panel-heading">
      <button *ngIf="!isUploading" class="btn btn-secondary" type="button" (click)="toggleUploader()">{{ 'upload_new_media' | translate }}</button>
      <button *ngIf="isUploading" class="btn btn-secondary" type="button" (click)="toggleUploader()">{{ 'cancel_upload' | translate }}</button>
    </div>
    <div class="panel-body panel-body-full-margin">
      <medsurf-media-library-base
        [(upload)]="isUploading"
      ></medsurf-media-library-base>
    </div>
  </div>
</div>


