import { Injectable } from '@angular/core';

export interface ThemeColor {
  value: string;
  name: string;
  hexValue: string;
}

@Injectable({
  providedIn: 'root'
})
export class ThemeColorService {
  public get themeColors(): ThemeColor[] {
    return [
      {
        value: 'yellow', name: 'Gelb', hexValue: '#f5a623'
      },
      {
        value: 'violet', name: 'Violett', hexValue: '#9E32B3'
      },
      {
        value: 'magenta', name: 'Magenta', hexValue: '#E12599'
      },
      {
        value: 'cyan', name: 'Cyan', hexValue: '#4A90E2'
      },
      {
        value: 'mint', name: 'Mintgrün', hexValue: '#50e3c2'
      },
      {
        value: 'green', name: 'Grün', hexValue: '#BFE125'
      }
    ];
  }

  public getHexValue(name: string): string {
    return this.themeColors.find((color: ThemeColor) => color.name === name || color.value === name)?.hexValue;
  }
}
