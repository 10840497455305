import Element from '../element';
import Selection from './selection';
import Label from './label/label';
import Line from './pointer/line';
import Pointer from './pointer/pointer';

class Marker extends Element {
  private _labels: any[];
  private _toolTips: any[];
  private _pointers: any[];
  private _labelOffset: { x: number; y: number };
  private _selection: any;
  private _forkLine: any;

  constructor(
    model,
    private _format,
    private _localPaper,
    private _mainLayer,
    private _container,
    public _imageScale = 1,
    public _imageOffset?
  ) {
    super(model);
    this._labels = [];
    this._toolTips = [];
    this._pointers = [];
    this._labelOffset = {x: 10, y: 10};
    this._selection = null;
    this._forkLine = null;
    this._init();
  }

  _init() {
    this._element = new this._localPaper.Group();
    this._mainLayer.addChild(this._element);
    this.draw();
  }

  draw() {
    if (!this._model.deleted) {
      this.cleanUp();
      if (this.hasPointer()) {
        this.drawPointers();
        this.drawForkLine();
      }
      this.drawLabels();
      this.drawSelection();
      this.arrange();
    }
  }

  drawLabels() {
    if (!this._model.label) {
      return;
    }
    const label = new Label(this._model.label.text, this._format, this);
    this._element.addChild(label.element);
    this._labels.push(label);
  }

  drawPointers() {
    for (const target of this._model.targets) {
      let source = this._container.getAbsoluteCoords(
        this._model.source.position.x,
        this._model.source.position.y,
        this._imageOffset,
        this._imageScale
      );
      if (this._model.fork.x && this._model.fork.y) {
        source = this._container.getAbsoluteCoords(
          this._model.fork.x,
          this._model.fork.y,
          this._imageOffset,
          this._imageScale
        );
      }
      const pointer = new Pointer(
        (target.endType) ? (target.endType) : 'dot',
        this._format,
        source,
        this._container.getAbsoluteCoords(target.position.x, target.position.y, this._imageOffset, this._imageScale),
        this
      );
      this._element.addChild(pointer.end.element.element);
      this._element.addChild(pointer.line.element);
      this._pointers.push(pointer);
    }
  }

  drawSelection() {
    if (this._selection) {
      this.removeSelection();
    }
    this._selection = new Selection(this._model, this._format, this._localPaper, this._container, this);
    this._element.addChild(this._selection.element);
  }

  drawForkLine() {
    if (this._model.fork.x && this._model.fork.y) {
      if (this._forkLine) {
        this.removeForkLine();
      }
      const source = this._container.getAbsoluteCoords(
        this._model.source.position.x,
        this._model.source.position.y,
        this._imageOffset,
        this._imageScale
      );
      const target = this._container.getAbsoluteCoords(
        this._model.fork.x,
        this._model.fork.y,
        this._imageOffset,
        this._imageScale
      );
      this._forkLine = new Line(this._format, source, target, this);
      this._forkLine.element.marker = this;
      this._element.addChild(this._forkLine.element);
    }
  }

  arrange() {
    const zoom = this._localPaper.view.zoom;
    const coords = this._container.getAbsoluteCoords(
      this._model.source.position.x,
      this._model.source.position.y,
      this._imageOffset,
      this._imageScale
    );
    let forkCoords = coords;
    this.scale(zoom);
    if (this._labels.length > 0) {
      this._labels[0].setPosition(coords.x, coords.y);
    }
    if (this._forkLine) {
      this._forkLine.setScale(zoom);
    }
    if (this._isSelected) {
      if (this._selection) {
        if (this._model.fork.x && this._model.fork.y) {
          forkCoords = this._container.getAbsoluteCoords(
            this._model.fork.x,
            this._model.fork.y,
            this._imageOffset,
            this._imageScale
          );
        }
        this._selection.setScale(zoom);
        this._selection.rectangle.position = coords;
        if (this._selection.circle) {
          this._selection.circle.position = forkCoords;
        }
        this._selection.arrange();
        this._selection.element.bringToFront();
      }
    } else {
      this.removeSelection();
    }
  }

  autoFormat(line, label) {
    let h = '',
      v = '',
      pivot = '';
    if (!this._format.pivot) {
      if (line.element.segments[1].point.x < label.element.bounds.x) {
        h = 'Left';
      } else {
        h = 'Right';
      }
      if (line.element.segments[1].point.y < label.element.bounds.y) {
        v = 'top';
      } else {
        v = 'bottom';
      }
      pivot = v + h;
      switch (pivot) {
        case 'top':
          break;
      }
      this._format.pivot = pivot;
      // this._model.label.justification = this._format.justification;
      this._model.label.pivot = this._format.pivot;
    }
  }

  angle(cx, cy, ex, ey) {
    const dy = ey - cy;
    const dx = ex - cx;
    let theta = Math.atan2(dy, dx);
    theta *= 180 / Math.PI;
    return theta;
  }

  scale(zoom) {
    if (this._labels.length > 0) {
      this._labels[0].setScale(zoom);
    }
    for (const pointer of this._pointers) {
      pointer.setScale(zoom);
    }
  }

  isInViewPort(rect) {
    const viewRect = this._localPaper.view.bounds;
    if ((rect.x + rect.width) < (viewRect.x + viewRect.width) &&
      (rect.x) > (viewRect.x) &&
      (rect.y) > (viewRect.y) &&
      (rect.y + rect.height) < (viewRect.y + viewRect.height)) {
      return true;
    } else {
      return false;
    }
  }

  setPosition(x, y) {
    this._element.position.x = x;
    this._element.position.y = y;
  }

  resize() {
    this.cleanUp();
    this.draw();
  }

  hasPointer() {
    return (this._model.targets && this._model.targets.length > 0);
  }

  get useSymbol() {
    return this._labels[0].useSymbol;
  }

  get pointers() {
    return (this._pointers) ? this._pointers : null;
  }

  get labels() {
    return (this._labels) ? this._labels : null;
  }

  removeLabels() {
    for (let label of this._labels) {
      label.cleanUp();
      label = null;
    }
    this._labels = [];
  }

  removeToolTips() {
    for (let toolTip of this._toolTips) {
      toolTip.cleanUp();
      toolTip = null;
    }
    this._toolTips = [];
  }

  removePointers() {
    for (let pointer of this._pointers) {
      pointer.cleanUp();
      pointer = null;
    }
    this._pointers = [];
  }

  removeSelection() {
    if (this._selection) {
      this._selection.cleanUp();
      this._selection = null;
    }
  }

  removeForkLine() {
    if (this._forkLine) {
      this._forkLine.cleanUp();
      this._forkLine = null;
    }
  }

  cleanUp() {
    this.removeSelection();
    this.removeLabels();
    this.removeToolTips();
    this.removePointers();
    this.removeForkLine();
  }
}

export default Marker;
