import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { DEFAULT_VALUES } from '../../../../common/constants/default-values';
import { AnnotationService, KeymapService } from '@medsurf/services';
import { SlideChanged, AddMarker, DeleteMarker } from '@medsurf/actions';
import { AnnotationType, Keymap, Slide, TipType } from '@medsurf/models';
import { Store } from '@ngxs/store';

@Component({
  selector: 'medsurf-keymap',
  templateUrl: './keymap.component.html',
  styleUrls: ['./keymap.component.scss']
})
export class KeymapComponent implements OnInit {
  @Output() deleteMarker = new EventEmitter(); // TODO
  @Output() setSelftestKeymap = new EventEmitter(); // TODO

  @Input() keymap: Keymap;
  @Input() slide: Slide;

  public selectedColumn: number;
  public sorting = 'vertical';

  constructor(private store: Store,
              private keymapService: KeymapService,
              private annotationService: AnnotationService,
              @Inject(DEFAULT_VALUES) public defaultValues) { }

  ngOnInit() {
    this.selectedColumn = 0;
  }

  onChange() {
    this.keymap.dirty = true;
    this.store.dispatch(new SlideChanged());
  }

  public addColumn(keymap: Keymap) {
    this.keymapService.addColumn(keymap);
  }

  public addKey(keymap: Keymap, columnId: number) {
    const key = this.keymapService.addKey(columnId, keymap);
    const marker = this.annotationService.createMarker(TipType.DOT, key.index);
    key.annotation = marker;
    this.store.dispatch(new AddMarker(marker));
  }

  public removeKey(keymap: Keymap, columnId: number, rowId: number) {
    const column = this.keymapService.removeKey(columnId, rowId, keymap);
    for (const key of column) {
      const marker = this.slide.annotations.find(m => {
          return m.type === AnnotationType.MARKER && m.id === key.annotation?.id;
        });
      if (marker) {
        this.store.dispatch(new DeleteMarker(marker));
      } else {
        this.onChange();
      }
    }
  }

  public removeColumn(keymap: Keymap) {
      const column = this.keymapService.removeColumn(keymap);
      for (const key of column.labels) {
        const marker = this.slide.annotations.find(m => {
          return m.type === AnnotationType.MARKER && m.id === key.annotation.id;
        });
        if (marker) {
          this.store.dispatch(new DeleteMarker(marker));
        }
      }
  }

  public setActiveColumn(columnId) {
    this.selectedColumn = columnId;
  }

  public sortIndex(keymap: Keymap, type) {
    let index = 0, oldValue;
    if (type === 'vertical') {
      keymap.columns.forEach((column, columnId) => {
        column.order = columnId;
        column.labels.forEach((key, keyId) => {
          key.order = keyId;
          oldValue = key.index;
          key.index = '' + ++index;
          this.switchKeymapIndex(keymap, columnId, keyId, oldValue);
        });
      });
    } else {
      let row = 0, maxColumnSize = 1;
      while (row < maxColumnSize) {
        keymap.columns.forEach((column, columnId) => {
          column.order = columnId;
          const labels = column.labels;
          maxColumnSize = maxColumnSize < labels.length ? labels.length : maxColumnSize;
          if (labels[row]) {
            oldValue = labels[row].index;
            labels[row].index = '' + ++index;
            this.switchKeymapIndex(keymap, columnId, row, oldValue);
          }
        });
        row++;
      }
    }
  }

  public switchKeymapIndex(keymap: Keymap, columnId: number, keyId: number, oldValue) {
    const label = keymap.columns[columnId]?.labels[keyId];
    if (label?.annotation?.id) {
      const marker = this.slide.annotations.find(m => {
        return m.type === AnnotationType.MARKER && m.id === label.annotation.id;
      });
      if (marker) {
        marker.label.text = String(label.index);
        marker.dirty = true;
      }
      this.onChange();
    }
  }

  public isKeymap(marker: Keymap): boolean {
    return marker.type === AnnotationType.KEYMAP;
  }
}
