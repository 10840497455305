import { Base } from './base.model';

export interface Link extends Base {
  type?: LinkType;
  link?: string;
}

export enum LinkType {
  SLIDE = 'Slide',
  SITE = 'Site',
}
