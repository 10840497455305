import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface ColorPaletteModel {
  name: string;
  value: string;
}

@Component({
  selector: 'medsurf-color-palette',
  templateUrl: './color-palette.component.html',
  styleUrls: ['./color-palette.component.scss'],
  providers: [
  {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ColorPaletteComponent),
    multi: true
  }
]
})
export class ColorPaletteComponent implements ControlValueAccessor {
  @Input() colorList: Array<ColorPaletteModel>;

  public color: string;
  private debouncer = new Subject<string>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (color: string) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  constructor() {
    this.debouncer.pipe(debounceTime(100))
      .subscribe((val) => {
        this.writeValue(val);
        this.onChange(this.color);
      });
  }

  public onColorChange(color: string) {
    this.debouncer.next(color);
  }

  public onColorSelect(color: ColorPaletteModel) {
    if (color.value === undefined) {
      this.debouncer.next(null);
    } else {
      this.debouncer.next(color.value);
    }
  }

  supportsEyeDropper() {
    return !!((window as any)?.EyeDropper);
  }

  async startEyeDropper() {
    const ed = new (window as any).EyeDropper();
    try {
      const color = await ed.open();
      if (color?.sRGBHex) {
        this.writeValue(color.sRGBHex);
        this.onChange(this.color);
      }
    } catch (err) {
      console.log(err);
    }
  }

  registerOnChange(fn: (color: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(color: string): void {
    this.color = color;
  }
}
