<medsurf-title
  [currentItem]="currentItem"
  [isSubject]="isSubject"
  [isBlockSelected]="isBlockSelected"
>
</medsurf-title>
<ul ngbNav [activeId]="activePanel" #panelNav="ngbNav" class="nav-pills">
  <li [ngbNavItem]="'panel.description'" *ngIf="!isBlockSelected && currentPage">
    <a ngbNavLink>{{ 'description' | translate }}</a>
    <ng-template ngbNavContent class="panelContent" style="height: 100%">
      <medsurf-editor
        class="pt-2"
        [(content)]="currentPage.description"
        [toolbarExcludes]="['marker']"
        [fullHeight]="true"
        (contentChange)="handleIndexProperties.emit()">
      </medsurf-editor>
    </ng-template>
  </li>

  <li [ngbNavItem]="'panel.grid'" *ngIf="isBlockSelected">
    <a ngbNavLink>{{ 'grid' | translate }}</a>
    <ng-template ngbNavContent class="panelContent" style="height: 100%">
      <medsurf-grid
        #grid
        [selectedBlock]="currentItem"
        [bulkSelectedSlideIds]="bulkSelectedSlideIds"
        (selectSlide)="selectSlide($event)"
        (selectBulk)="selectBulk.emit($event)">
      </medsurf-grid>
    </ng-template>
  </li>

  <li [ngbNavItem]="'panel.table'" *ngIf="isBlockSelected">
    <a ngbNavLink>{{ 'table' | translate }}</a>
    <ng-template ngbNavContent class="panelContent" style="height: 100%">
      <medsurf-table
        #table
        [selectedBlock]="currentItem"
        [bulkSelectedSlideIds]="bulkSelectedSlideIds"
        (selectSlide)="selectSlide($event)"
        (selectBulk)="selectBulk.emit($event)">
      </medsurf-table>
    </ng-template>
  </li>

  <li [ngbNavItem]="'panel.settings'">
    <a ngbNavLink>{{ 'settings' | translate }}</a>
    <ng-template ngbNavContent class="panelContent" style="height: 100%">
      <medsurf-settings
        [selectedBlock]="currentItem"
        [isBlockSelected]="isBlockSelected"
        [isSubject]="isSubject">
      </medsurf-settings>
    </ng-template>
  </li>

  <li [ngbNavItem]="'panel.comments'">
    <a ngbNavLink>{{ 'comments' | translate }}</a>
    <ng-template ngbNavContent class="panelContent" style="height: 100%">
      <medsurf-comments
        class="d-block pt-2"
        [item]="currentPage"
        (changeEmitter)="handleCommentsChange()">
      </medsurf-comments>
    </ng-template>
  </li>

  <li [ngbNavItem]="'panel.image'" *ngIf="!isBlockSelected && currentPage">
    <a ngbNavLink>{{ 'cover_picture' | translate }}</a>
    <ng-template ngbNavContent class="panelContent" style="height: 100%">
      <medsurf-cover-image
        [(page)]="currentPage"
        (imageChange)="handleIndexProperties.emit()">
      </medsurf-cover-image>
    </ng-template>
  </li>

  <li [ngbNavItem]="'panel.vocabulary'" *ngIf="currentItem.page.type === 'Vocabulary'">
    <a ngbNavLink>{{ 'vocabulary' | translate }}</a>
    <ng-template ngbNavContent class="panelContent" style="height: 100%">
      <medsurf-vocabulary
        [selectedBlock]="currentItem">
      </medsurf-vocabulary>
    </ng-template>
  </li>

  <li [ngbNavItem]="'panel.quiz'" *ngIf="currentItem.page.type === 'QuizStart'">
    <a ngbNavLink>{{ 'quiz' | translate }}</a>
    <ng-template ngbNavContent class="panelContent" style="height: 100%">
      <medsurf-quiz-start
        [selectedBlock]="currentItem">
      </medsurf-quiz-start>
    </ng-template>
  </li>

  <li [ngbNavItem]="'panel.export'">
    <a ngbNavLink>{{ 'text_export' | translate }}</a>
    <ng-template ngbNavContent class="panelContent" style="height: 100%">
      <medsurf-export
        [selectedBlock]="currentItem">
      </medsurf-export>
    </ng-template>
  </li>

</ul>
<div [ngbNavOutlet]="panelNav"></div>

