<div class="nav-tab-content">

  <div class="position-relative">
    <div class="btn-toolbar position-absolute m-2" *ngIf="media">
      <div class="btn-group mr-2">
        <button (click)="selectMedia()" class="btn btn-secondary">{{ 'exchange_media' | translate }}</button>
      </div>
      <div class="btn-group">
        <button (click)="removeMedia()" class="btn btn-primary">{{ 'remove_media' | translate }}</button>
      </div>
    </div>
    <img class="cover-image mb-2" *ngIf="media" [src]="mediaService.getUrl(media, 'thumbnail')" [alt]="media.altText">
    <div *ngIf="!media">
      <button class="btn btn-secondary" (click)="selectMedia()" translate="choose_media"></button>
    </div>
  </div>
  <form>
    <fieldset>
      <div class="row" *ngIf="mediaService.isDeepzoom(media) || mediaService.isImage(media)">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="control-label" for="image-background-color" translate="background_color"></label>
            <div>
              <medsurf-color-palette 
                id="image-background-color" name="image-background-color" 
                [colorList]="defaultValues.backgroundColors" 
                [ngModel]="slide.layers[layerId]?.images[sequenceId]?.background?.color" 
                (ngModelChange)="onBackgroundChange($event)"></medsurf-color-palette>
            </div>
          </div>
          <hr/>
        </div>
      </div>
      <div class='row mb-2'>
        <div class='col-sm-6'>
          <label for="linkedSite" translate="label_linked_site_type"></label>
          <div class="btn-group" role="group">
            <button *ngFor="let linkType of linkTypes"
                    type="button"
                    [class.btn-secondary]="linkType === slide.layers[layerId].images[sequenceId].link.type"
                    [class.btn-primary]="linkType !== slide.layers[layerId].images[sequenceId].link.type"
                    class="btn btn-sm"
                    (click)="selectLinkType(linkType)">
              {{ linkType }}
            </button>
          </div>
        </div>
      </div>
      <div class='row'>
        <div class='col-sm-6'>
          <div class='form-group'>
            <label for="linkedSite" translate="label_linked_site"></label>
            <input id="linkedSite" name="linkedSite" class="form-control input-md"
                   type="text"
                   [disabled]="!slide.layers[layerId].images[sequenceId].link.type"
                   [(ngModel)]="slide.layers[layerId].images[sequenceId].link.link" (ngModelChange)="onChange()"/>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</div>
