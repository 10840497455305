import { Injectable } from '@angular/core';
import { SlideStatus } from '@medsurf/models';


@Injectable({
  providedIn: 'root'
})
export class SlideService {
  public getSlideStatusIcon(slide) {
    if (!slide?.status) {
      return '';
    }
    switch (slide.status) {
      case SlideStatus.DRAFT:
        return 'far fa-edit';
      case SlideStatus.IN_REVIEW:
        return 'far fa-file-search';
      case SlideStatus.REVIEW_NOK:
        return 'far fa-exclamation-square text-danger';
      case SlideStatus.FINAL:
        return 'far fa-check-square text-success';
    }
  }
}
